import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LocationService } from './location.service';
import { Location } from './location';
import { ActivatedRoute, Router} from '@angular/router';
import { MapComponent } from './map.component';
import { ILocationSave} from '../models/ilocationsave';
import { UtilsService} from '../shared/utils.service';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { ILocationPerson} from '../models/ilocationperson';
import { saveAs} from 'file-saver';

@Component({
    selector: 'app-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})

export class LocationComponent implements OnInit, OnDestroy {
    @ViewChild(MapComponent, {static: true}) mapComponent: MapComponent;
    @ViewChild('t', {static: true}) t;

    countries: Array<any>;
    activeLocationDescription: string;
    activeId: string;
    showDescriptionHelp = false;
    isSystemLocation = false;

    locationForm: FormGroup;
    locationId = new FormControl();
    description = new FormControl(null, [Validators.required, Validators.maxLength(250)]);
    country = new FormControl(null, Validators.required);
    countryLabel = new FormControl();
    notes = new FormControl(null, Validators.maxLength(2000));
    latitude = new FormControl(null, [Validators.pattern('^-?\\d+(\\.\\d+)?$'),
        Validators.max(90.0),
        Validators.min(-90.0)
    ]);
    longitude = new FormControl(null, [Validators.pattern('^-?\\d+(\\.\\d+)?$'),
        Validators.max(180.0),
        Validators.min(-180.0)
    ]);
    version = new FormControl();
    closeResult: string;
    eventCount: number;
    location: Location;
    persons: ILocationPerson[];

    showForm = false;

    private routeSubscription: Subscription;
    private nameOfLocationSubscription: Subscription;

    constructor(private locationService: LocationService,
                public utilsService: UtilsService,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.countries = this.utilsService.getCountries();

        this.locationForm = new FormGroup({
            locationId: this.locationId,
            description: this.description,
            notes: this.notes,
            latitude: this.latitude,
            longitude: this.longitude,
            country: this.country,
            countryLabel: this.countryLabel,
            version: this.version
        });

        this.routeSubscription = this.route.queryParams.subscribe(
            params => {
                this.activeId = params['id'];
                this.setLocation(this.activeId);
            }
        );

        this.nameOfLocationSubscription = this.locationService.locationDescription.subscribe( activeLocation => {
            this.activeLocationDescription = activeLocation;
        });

    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.nameOfLocationSubscription.unsubscribe();
    }

    setLocation(id: string) {
        if (id === '' || id === null || id === undefined) {
            this.locationForm.patchValue({
                locationId: '',
                description: '',
                notes: '',
                latitude: '',
                longitude: '',
                country: '',
                countryLabel: '',
                version: ''
            });
            this.activeId = '';
            this.locationService.setNameOfLocation('New Location');
            this.isSystemLocation = false;
            this.location = null;
            this.persons = [];
            this.showForm = true;
            this.mapComponent.setLatLong('', '');
        } else {
            this.locationService.getLocation(+id).subscribe(location => {
                this.location = <Location>location;
                this.activeId = id;
                this.locationService.setNameOfLocation(location.description);
                this.locationForm.patchValue({
                    locationId: this.location.id,
                    description: this.location.description,
                    notes: this.location.notes,
                    latitude: this.location.latitude,
                    longitude: this.location.longitude,
                    country: location.country !== null ? location.country : '',
                    countryLabel: location.country !== null ? this.utilsService.getCountryLabel(location.country) : '',
                    version: '4444'
                });

                for (let i = 0; this.location.persons.length > i; i++) {
                  this.location.persons[i].fullname = this.utilsService.getDisplayName(this.location.persons[i].locationPerson);
                  this.location.persons[i].eventTypeLabel =
                      this.utilsService.getEventType(this.location.persons[i].locationEvent.eventTypeCd);
                  this.location.persons[i].locationEvent.formattedDate =
                      this.utilsService.getDisplayDate(this.location.persons[i].locationEvent);
                }

                this.persons = location.persons;
                this.mapComponent.setLatLong(this.location.latitude, this.location.longitude);

                this.isSystemLocation = (this.location.externalId !== null) &&
                    (this.location.externalId.trim().charAt(0) === 'G');  // K means saved by Kinalytic user
            });
        }
    }

    showLocationForm() {
        // If already in EditMode
        // Cancel: showForm will always be true
        if (this.showForm) {
            const saveButton = <HTMLButtonElement> document.getElementById('saveButton');
            saveButton.disabled = true;
            const cancelButton = <HTMLButtonElement> document.getElementById('cancelButton');
            cancelButton.disabled = true;
            this.setLocation(this.activeId);
        }
        this.showForm = !this.showForm;
    }

    // for the undo button
    getLocation() {
        this.setLocation(this.activeId);
    }

    public cancelLocation(id: string) {
        if (id === '') {
            this.router.navigate(['/locationlist']);
        } else {
            this.router.navigate(['/location'], { queryParams: { id: id } });
        }
    }

    public goToPerson(id: string, gender: string) {
        this.router.navigate(['/person'], { queryParams: { id: id, g: gender } });
    }

    downloadLocationReport() {
        const fileName = this.utilsService.dateToYMD(new Date()) + this.activeLocationDescription;
        this.locationService.downloadLocationReport(this.activeId)
            .toPromise().then(response => {
            const blob = new Blob([response], {type: 'application/pdf'});
            saveAs(blob, fileName + '.pdf');
        });
    }

    open(content, size) {
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    saveLocation() {
        const saveButton = <HTMLButtonElement> document.getElementById('saveButton');
        saveButton.disabled = true;
        const cancelButton = <HTMLButtonElement> document.getElementById('cancelButton');
        cancelButton.disabled = true;

        const newLocation: Location = new Location();
        newLocation.description  = this.description.value;
        newLocation.latitude     = (this.latitude.value !== null && this.latitude.value !== '') ? this.latitude.value : '';
        newLocation.longitude    = (this.longitude.value !== null && this.longitude.value !== '') ? this.longitude.value : '';
        newLocation.country      = (this.country.value !== null && this.country.value !== '') ? this.country.value : '';
        newLocation.notes        = (this.notes.value !== null && this.notes.value !== '' ) ? this.notes.value : '';
        newLocation.id   = (this.locationId.value !== null && this.locationId.value !== 0 ) ? this.locationId.value : '';
        newLocation.version      = '4444'; // this.version.value;

        this.locationService.saveLocation(newLocation.id, JSON.stringify(newLocation), '4444')
            .toPromise().then(data => {
                const saveData = <ILocationSave>data;
                this.locationService.setNameOfLocation(newLocation.description);
                this.locationId.setValue(saveData.ids.id);
                this.activeId = saveData.ids.id;
                this.countryLabel.setValue(newLocation.country !== null ? this.utilsService.getCountryLabel(newLocation.country) : '');
                this.showForm = false;
                return data;
                },
                err => {
                    console.log('location saving error ' + err.toString());
                });
        this.mapComponent.setLatLong(this.latitude.value, this.longitude.value);
    }

    openDelete(content, size) {
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    deleteLocation(id: string) {
        if (id === '') {
            this.router.navigate(['/locationlist']);
        } else {
            this.locationService.deleteLocation(id).toPromise().then(deleted => {
                this.router.navigate(['/locationlist']);
            });
        }
        this.locationService.deleteLocation(this.locationId.value);
    }

}
