import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UtilsService} from '../../shared/utils.service';
import { PersonService} from '../person.service';
import { LocationService} from '../../location/location.service';
import { ActivatedRoute, Router} from '@angular/router';
import { Person} from '../person';
import { IPerson} from '../../models/iperson';
import { PersonCreate} from '../personcreate';
import { PersonUpdate} from '../personupdate';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { ILocationSuggestion} from '../../models/ilocationsuggestion';
import { ILocationSearchResults} from '../../models/ilocationsearchresults';
import { Location} from '../../location/location';
import { ILocationSave} from '../../models/ilocationsave';
import { ParentComponent} from '../family/parent.component';
import { SpouseComponent} from '../family/spouse.component';
import { saveAs} from 'file-saver';
import { IPersonSave} from '../../models/ipersonsave';
import { BothEmpty} from './bothempty';
import { DatesInvalid} from './datesinvalid';
import { DateIncorrect} from './dateincorrect';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
    @ViewChild(ParentComponent, {static: true})
    private parentsComponent: ParentComponent;

    @ViewChild(SpouseComponent, {static: true})
    private spouseComponent: SpouseComponent;

    @ViewChild('editperson', {static: true}) editpersonContent: TemplateRef<any>;
    editPersonTitle = '';
    showForm = false;

    profileForm: FormGroup;
    days: Array<any>;
    months: Array<any>;
    genderTypes: Array<any>;
    countries: Array<any>;
    filteredBirthLocations: ILocationSearchResults[];
    filteredDeathLocations: ILocationSearchResults[];
    filteredBurialLocations: ILocationSearchResults[];

    personName = '';
    showGenderHelp = false;
    activeId: string;
    activeGender: string;
    activePersonName: string;

    showNewLocationDescriptionHelp = false;
    showNewLocationCountryHelp = false;
    saveNewLocationDisabled: boolean;

    isValidDeceased = true;

    private routeSubscription: Subscription;
    private nameOfPersonSubscription: Subscription;

    constructor(private utilsService: UtilsService,
                private personService: PersonService,
                private locationService: LocationService,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private router: Router,
                private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.days = this.utilsService.getDays();
        this.months = this.utilsService.getMonths();
        this.genderTypes = this.utilsService.getGenders();
        this.countries = this.utilsService.getCountries();
        this.filteredBirthLocations = null;
        this.filteredDeathLocations = null;
        this.filteredBurialLocations = null;

        document.addEventListener('click', () => this.closeList());

        this.profileForm = this.formBuilder.group({
            givenName: ['', [Validators.maxLength(150)]],
            familyName: ['', [Validators.maxLength(150)]],
            middleNames: ['', [Validators.maxLength(150)]],
            altNames: ['', [Validators.maxLength(200)]],
            nameSuffix: ['', [Validators.maxLength(45)]],
            gender: ['', Validators.required],
            birthDate: [''],
            birthDay: [''],
            birthMonth: [''],
            birthYear: ['', [Validators.maxLength(4)]],
            birthLocation: [''],
            birthLocationId: [''],
            birthExternalId: [''],
            birthLat: [''],
            birthLon: [''],
            birthCountry: [''],
            birthId: [''],
            birthDescription: [''],
            deathDate: [''],
            deathDay: [''],
            deathMonth: [''],
            deathYear: ['', [Validators.maxLength(4)]],
            deathLocation: [''],
            deathLocationId: [''],
            deathExternalId: [''],
            deathLat: [''],
            deathLon: [''],
            deathCountry: [''],
            deathId: [''],
            deathDescription: [''],
            causeOfDeath: [''],
            deceased: [''],
            burialDate: [''],
            burialDay: [''],
            burialMonth: [''],
            burialYear: ['', [Validators.maxLength(4)]],
            burialLocation: [''],
            burialLocationId: [''],
            burialExternalId: [''],
            burialLat: [''],
            burialLon: [''],
            burialCountry: [''],
            burialId: [''],
            burialDescription: [''],
            occupation: ['', [Validators.maxLength(250)]],
            religion: ['', [Validators.maxLength(250)]],
            attributes: ['', [Validators.maxLength(250)]],
            isResearch: [''],
            version: [''],
            newLocationDescription: [''],
            newLocationCountry: [''],
            newLocationType: ['']
        }, {
            updateOn: 'blur',
            validators: [
                BothEmpty(),
                DateIncorrect(this.utilsService),
                DatesInvalid(this.utilsService)
            ]
        });

        this.routeSubscription = this.route.queryParams.subscribe(params => {
            this.activeId = params['id'];
            this.activeGender = params['g'];
            this.setPerson(this.activeId);
        });

        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe( activePersonName => {
            this.activePersonName = activePersonName;
        });
    }

    get f() { return this.profileForm.controls; }

    onDestroy() {
        this.routeSubscription.unsubscribe();
        this.nameOfPersonSubscription.unsubscribe();
    }

    closeList() {
        this.filteredBirthLocations = null;
        this.filteredDeathLocations = null;
        this.filteredBurialLocations = null;
    }

    onBirthDayChange($event) {
        const newBirthDay = $event.target.value;
        const birthMask = this.utilsService.dateMask(newBirthDay, this.f.birthMonth.value, this.f.birthYear.value);
        this.parentsComponent.activePersonBirthMask = birthMask;
        this.spouseComponent.activePersonBirthMask = birthMask;
        this.f.birthDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            newBirthDay, this.f.birthMonth.value, this.f.birthYear.value, this.f.birthLocation.value));
    }

    onBirthMonthChange($event) {
        const newBirthMonth = $event.target.value;
        const birthMask = this.utilsService.dateMask(this.f.birthDay.value, newBirthMonth, this.f.birthYear.value);
        this.parentsComponent.activePersonBirthMask = birthMask;
        this.spouseComponent.activePersonBirthMask = birthMask;
        this.f.birthDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            this.f.birthDay.value, newBirthMonth, this.f.birthYear.value, this.f.birthLocation.value));
    }

    onBirthYearChange($event) {
        const newBirthYear = $event.target.value;
        const birthMask = this.utilsService.dateMask(this.f.birthDay.value, this.f.birthMonth.value, newBirthYear);
        this.parentsComponent.activePersonBirthMask = birthMask;
        this.spouseComponent.activePersonBirthMask = birthMask;
        this.f.birthDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            this.f.birthDay.value, this.f.birthMonth.value, newBirthYear, this.f.birthLocation.value));
    }

    onBirthLocationChange($event) {
        const newBirthLocation = $event.target.value;
        console.log('Birth location ' + newBirthLocation);
        this.f.birthDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            this.f.birthDay.value, this.f.birthMonth.value, this.f.birthYear.value, newBirthLocation));
    }

    onDeceasedChange($event) {
        const deathMask = this.utilsService.dateMask(this.f.deathDay.value, this.f.deathMonth.value, this.f.deathYear.value);
        if (deathMask !== '00000000' && ($event.target.value === false)) {
            this.isValidDeceased = false;
        } else {
            this.isValidDeceased = true;
        }
    }

    onDeathDayChange($event) {
        const newDeathDay = $event.target.value;
        const deathMask = this.utilsService.dateMask(newDeathDay, this.f.deathMonth.value, this.f.deathYear.value);
        this.parentsComponent.activePersonDeathMask = deathMask;
        this.spouseComponent.activePersonDeathMask = deathMask;
        if (deathMask !== '00000000' && (this.f.deceased.value === false)) {
            this.isValidDeceased = false;
        } else {
            this.isValidDeceased = true;
        }
        this.f.deathDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            newDeathDay, this.f.deathMonth.value, this.f.deathYear.value, this.f.deathLocation.value));
    }

    onDeathMonthChange($event) {
        const newDeathMonth = $event.target.value;
        const deathMask = this.utilsService.dateMask(this.f.deathDay.value, newDeathMonth, this.f.deathYear.value);
        this.parentsComponent.activePersonDeathMask = deathMask;
        this.spouseComponent.activePersonDeathMask = deathMask;
        if (deathMask !== '00000000' && (this.f.deceased.value === false)) {
            this.isValidDeceased = false;
        } else {
            this.isValidDeceased = true;
        }
        this.f.deathDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            this.f.deathDay.value, newDeathMonth, this.f.deathYear.value, this.f.deathLocation.value));
    }

    onDeathYearChange($event) {
        const newDeathYear = $event.target.value;
        const deathMask = this.utilsService.dateMask(this.f.deathDay.value, this.f.deathMonth.value, newDeathYear);
        this.parentsComponent.activePersonDeathMask = deathMask;
        this.spouseComponent.activePersonDeathMask = deathMask;
        if (deathMask !== '00000000' && (this.f.deceased.value === false)) {
            this.isValidDeceased = false;
        } else {
            this.isValidDeceased = true;
        }
        this.f.deathDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            this.f.deathDay.value, this.f.deathMonth.value, newDeathYear, this.f.deathLocation.value));
    }

    onDeathLocationChange($event) {
        const newDeathLocation = $event.target.value;
        this.f.deathDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            this.f.deathDay.value, this.f.deathMonth.value, this.f.deathYear.value, newDeathLocation));
    }

    onBurialDayChange($event) {
        const newBurialDay = $event.target.value;
        const burialMask = this.utilsService.dateMask(newBurialDay, this.f.burialMonth.value, this.f.burialYear.value);
        this.parentsComponent.activePersonBurialMask = burialMask;
        this.f.burialDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            newBurialDay, this.f.burialMonth.value, this.f.burialYear.value, this.f.burialLocation.value));
    }

    onBurialMonthChange($event) {
        const newBurialMonth = $event.target.value;
        const burialMask = this.utilsService.dateMask(this.f.burialDay.value, newBurialMonth, this.f.burialYear.value);
        this.parentsComponent.activePersonBurialMask = burialMask;
        this.f.burialDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            this.f.burialDay.value, newBurialMonth, this.f.burialYear.value, this.f.burialLocation.value));
    }

    onBurialYearChange($event) {
        const newBurialYear = $event.target.value;
        const burialMask = this.utilsService.dateMask(this.f.burialDay.value, this.f.burialMonth.value, newBurialYear);
        this.parentsComponent.activePersonBurialMask = burialMask;
        this.f.burialDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            this.f.burialDay.value, this.f.burialMonth.value, newBurialYear, this.f.burialLocation.value));
    }

    onBurialLocationChange($event) {
        const newBurialLocation = $event.target.value;
        this.f.burialDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
            this.f.burialDay.value, this.f.burialMonth.value, this.f.burialYear.value, newBurialLocation));
    }

    classValuesForBirth() {
        if (this.f.birthDay.hasError('dateIncorrect')) {
            return 'form-control form-control-sm error';
        } else {
            return 'form-control form-control-sm';
        }
    }

    classValuesForDeceased() {
        if (this.isValidDeceased) {
            return 'btn-group btn-group-sm btn-group-toggle genderInvalid mr-2';
        } else {
            return 'btn-group btn-group-sm btn-group-toggle genderInvalid mr-2 error';
        }
    }

    classValuesForDeath() {
        if (this.f.deathDay.hasError('dateIncorrect')) {
            return 'form-control form-control-sm error';
        } else {
            return 'form-control form-control-sm';
        }
    }

    classValuesForBurial() {
        if (this.f.burialDay.hasError('dateIncorrect')) {
            return 'form-control form-control-sm error';
        } else {
            return 'form-control form-control-sm';
        }
    }

    autocompleteBirthLocation(value) {
        if (this.f.birthLocation.dirty || this.f.birthLocation.touched) {
            this.locationService.searchLocation(value).toPromise().then(locList => {
                const tmp = <ILocationSuggestion>locList;
                this.filteredBirthLocations = tmp.searchResults;
            });
        }
    }

    setLocationInfo(type: string, location: string, id: string, externalId: string,
                     locationId: string, lat: string, lon: string, country: string) {
        if (type === 'birth') {
            this.filteredBirthLocations = null;
            this.f.birthLocation.setValue(location, {onlySelf: true, emitEvent: false});
            this.f.birthLocationId.setValue(locationId);
            this.f.birthExternalId.setValue(externalId); // when this is saved to the database, add 'G'
            this.f.birthLat.setValue(lat);
            this.f.birthLon.setValue(lon);
            this.f.birthCountry.setValue(country);
            this.f.birthDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
                this.f.birthDay.value, this.f.birthMonth.value, this.f.birthYear.value, location));
        } else if (type === 'death') {
            this.filteredDeathLocations = null;
            this.f.deathLocation.setValue(location, {onlySelf: true, emitEvent: false});
            this.f.deathLocationId.setValue(locationId);
            this.f.deathExternalId.setValue(externalId); // when this is saved to the database, add 'G'
            this.f.deathLat.setValue(lat);
            this.f.deathLon.setValue(lon);
            this.f.deathCountry.setValue(country);
            this.f.deathDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
                this.f.deathDay.value, this.f.deathMonth.value, this.f.deathYear.value, location));
        } else if (type === 'bural') {
            this.filteredBurialLocations = null;
            this.f.burialLocation.setValue(location, {onlySelf: true, emitEvent: false});
            this.f.burialLocationId.setValue(locationId);
            this.f.burialExternalId.setValue(externalId); // when this is saved to the database, add 'G'
            this.f.burialLat.setValue(lat);
            this.f.burialLon.setValue(lon);
            this.f.burialCountry.setValue(country);
            this.f.burialDate.setValue(this.utilsService.getDisplayDateBeforeLocationFromFields(
                this.f.burialDay.value, this.f.burialMonth.value, this.f.burialYear.value, location));
        }
    }

    autocompleteDeathLocation(value) {
        if (this.f.deathLocation.dirty || this.f.deathLocation.touched) {
            this.locationService.searchLocation(value).toPromise().then(locList => {
                const tmp = <ILocationSuggestion>locList;
                this.filteredDeathLocations = tmp.searchResults;
            });
        }
    }

    autocompleteBurialLocation(value) {
        if (this.f.burialLocation.dirty || this.f.burialLocation.touched) {
            this.locationService.searchLocation(value).toPromise().then(locList => {
                const tmp = <ILocationSuggestion>locList;
                this.filteredBurialLocations = tmp.searchResults;
            });
        }
    }

    private setPerson(id: string) {
        const utils = this.utilsService;
        if (id === '' || id === null || id === undefined) {
            this.personService.setNameOfPerson('New Person');
            this.editPersonTitle = 'New Person';
            this.activeId = '';
            this.activeGender = '';
            this.f.givenName.setValue('');
            this.f.familyName.setValue('');
            this.f.middleNames.setValue('');
            this.f.altNames.setValue('');
            this.f.nameSuffix.setValue('');
            this.f.gender.setValue('');
            this.f.birthDate.setValue('');
            this.f.birthDay.setValue('');
            this.f.birthMonth.setValue('');
            this.f.birthYear.setValue('');
            this.f.birthLocation.setValue('');
            this.f.birthLocationId.setValue('');
            this.f.birthExternalId.setValue('');
            this.f.birthLat.setValue('');
            this.f.birthLon.setValue('');
            this.f.birthCountry.setValue('');
            this.f.birthId.setValue('');
            this.f.birthDescription.setValue('');
            this.f.deathDate.setValue('');
            this.f.deathDay.setValue('');
            this.f.deathMonth.setValue('');
            this.f.deathYear.setValue('');
            this.f.deathLocation.setValue('');
            this.f.deathLocationId.setValue('');
            this.f.deathExternalId.setValue('');
            this.f.deathLat.setValue('');
            this.f.deathLon.setValue('');
            this.f.deathCountry.setValue('');
            this.f.deathId.setValue('');
            this.f.deathDescription.setValue('');
            this.f.causeOfDeath.setValue('');
            this.f.deceased.setValue('false');
            this.f.burialDate.setValue('');
            this.f.burialDay.setValue('');
            this.f.burialMonth.setValue('');
            this.f.burialYear.setValue('');
            this.f.burialLocation.setValue('');
            this.f.burialLocationId.setValue('');
            this.f.burialExternalId.setValue('');
            this.f.burialLat.setValue('');
            this.f.burialLon.setValue('');
            this.f.burialCountry.setValue('');
            this.f.burialId.setValue('');
            this.f.burialDescription.setValue('');
            this.f.occupation.setValue('');
            this.f.religion.setValue('');
            this.f.attributes.setValue('');
            this.f.isResearch.setValue('false');
            this.f.version.setValue('');
            this.f.newLocationDescription.setValue('');
            this.f.newLocationCountry.setValue('');
            this.f.newLocationType.setValue('');
            this.showForm = true;
            this.parentsComponent.activePersonBirthMask = '00000000';
            this.parentsComponent.activePersonDeathMask = '00000000';
            this.spouseComponent.activePersonBirthMask  = '00000000';
            this.spouseComponent.activePersonDeathMask  = '00000000';
            this.profileForm.updateValueAndValidity();
        } else {
            this.personService.getPerson(+id).toPromise().then( person => {
                const activePerson = <Person>person;
                this.activeId = id;
                this.activeGender = activePerson.gender;
                this.personName = this.utilsService.generateName(<IPerson>person);
                this.personService.setNameOfPerson(this.personName);

                this.f.givenName.setValue(activePerson.givenName);
                this.f.familyName.setValue(activePerson.familyName);
                this.f.middleNames.setValue(activePerson.middleNames);
                this.f.altNames.setValue(activePerson.altNames);
                this.f.nameSuffix.setValue(activePerson.nameSuffix);
                this.f.gender.setValue(activePerson.gender);
                this.f.birthId.setValue(activePerson.birth !== null ? activePerson.birth.id : '');
                this.f.birthDescription.setValue(activePerson.birth !== null ? activePerson.birth.description : '');
                this.f.birthDate.setValue(utils.getDisplayDateBeforeLocation(activePerson.birth));
                this.f.birthDay.setValue(utils.getDay(activePerson.birth));
                this.f.birthMonth.setValue(utils.getMonth(activePerson.birth));
                this.f.birthYear.setValue(utils.getYear(activePerson.birth));
                this.f.birthLocationId.setValue((activePerson.birth !== null && activePerson.birth.location !== null) ?
                    activePerson.birth.location.id : '');
                this.f.birthLocation.setValue((activePerson.birth !== null && activePerson.birth.location !== null) ?
                    activePerson.birth.location.description : '');
                this.f.birthExternalId.setValue((activePerson.birth !== null && activePerson.birth.location !== null) ?
                    activePerson.birth.location.externalId : '');
                this.f.birthLat.setValue((activePerson.birth !== null && activePerson.birth.location !== null) ?
                    activePerson.birth.location.latitude : '');
                this.f.birthLon.setValue((activePerson.birth !== null && activePerson.birth.location !== null) ?
                    activePerson.birth.location.longitude : '');
                this.f.birthCountry.setValue((activePerson.birth !== null && activePerson.birth.location !== null) ?
                    activePerson.birth.location.country : '');
                this.f.deathId.setValue((activePerson.death !== null ? activePerson.death.id : ''));
                this.f.deathDescription.setValue((activePerson.death !== null ? activePerson.death.description : ''));
                this.f.deathDate.setValue(utils.getDisplayDateBeforeLocation(activePerson.death));
                this.f.deathDay.setValue(utils.getDay(activePerson.death));
                this.f.deathMonth.setValue(utils.getMonth(activePerson.death));
                this.f.deathYear.setValue(utils.getYear(activePerson.death));
                this.f.deathLocationId.setValue((activePerson.death !== null && activePerson.death.location !== null) ?
                    activePerson.death.location.id : '');
                this.f.deathLocation.setValue((activePerson.death !== null && activePerson.death.location !== null) ?
                    activePerson.death.location.description : '');
                this.f.deathExternalId.setValue((activePerson.death !== null && activePerson.death.location !== null) ?
                    activePerson.death.location.externalId : '');
                this.f.deathLat.setValue((activePerson.death !== null && activePerson.death.location !== null) ?
                    activePerson.death.location.latitude : '');
                this.f.deathLon.setValue((activePerson.death !== null && activePerson.death.location !== null) ?
                    activePerson.death.location.longitude : '');
                this.f.deathCountry.setValue((activePerson.death !== null && activePerson.death.location !== null) ?
                    activePerson.death.location.country : '');
                this.f.causeOfDeath.setValue(activePerson.causeOfDeath);
                this.f.deceased.setValue(activePerson.deceased);
                this.f.burialId.setValue(activePerson.burial !== null ? activePerson.burial.id : '');
                this.f.burialDescription.setValue(activePerson.burial !== null ? activePerson.burial.description : '');
                this.f.burialDate.setValue(utils.getDisplayDateBeforeLocation(activePerson.burial));
                this.f.burialDay.setValue(utils.getDay(activePerson.burial));
                this.f.burialMonth.setValue(utils.getMonth(activePerson.burial));
                this.f.burialYear.setValue(utils.getYear(activePerson.burial));
                this.f.burialLocationId.setValue((activePerson.burial !== null && activePerson.burial.location !== null) ?
                    activePerson.burial.location.id : '');
                this.f.burialLocation.setValue((activePerson.burial !== null && activePerson.burial.location !== null) ?
                    activePerson.burial.location.description : '');
                this.f.burialExternalId.setValue((activePerson.burial !== null && activePerson.burial.location !== null) ?
                    activePerson.burial.location.externalId : '');
                this.f.burialLat.setValue((activePerson.burial !== null && activePerson.burial.location !== null) ?
                    activePerson.burial.location.latitude : '');
                this.f.burialLon.setValue((activePerson.burial !== null && activePerson.burial.location !== null) ?
                    activePerson.burial.location.longitude : '');
                this.f.burialCountry.setValue((activePerson.burial !== null && activePerson.burial.location !== null) ?
                    activePerson.burial.location.country : '');
                this.f.occupation.setValue(activePerson.occupation);
                this.f.religion.setValue(activePerson.religion);
                this.f.isResearch.setValue(activePerson.isResearch);
                this.f.attributes.setValue(activePerson.attributes);

                const birthMask = this.utilsService.dateMask(
                    activePerson.birth.startDateDay,
                    activePerson.birth.startDateMonth,
                    '' + activePerson.birth.startDateYear);

                const deathMask = this.utilsService.dateMask(
                    activePerson.death.startDateDay,
                    activePerson.death.startDateMonth,
                    '' + activePerson.death.startDateYear);

                this.parentsComponent.activePersonBirthMask = birthMask;
                this.parentsComponent.activePersonDeathMask = deathMask;
                this.spouseComponent.activePersonBirthMask  = birthMask;
                this.spouseComponent.activePersonDeathMask  = deathMask;

                this.profileForm.updateValueAndValidity();
            });
        }
    }

    showPersonForm() {
        // If already in EditMode
        // Cancel: showForm will always be true
        if (this.showForm) {
            const saveButton = <HTMLButtonElement> document.getElementById('saveButton');
            saveButton.disabled = true;
            const cancelButton = <HTMLButtonElement> document.getElementById('cancelButton');
            cancelButton.disabled = true;
            this.setPerson(this.activeId);
        }
        this.showForm = !this.showForm;
    }

    deletePerson(id: string) {
        if (id === '') {
            this.router.navigate(['/personlist']);
        } else {
            this.personService.deletePerson(id).toPromise().then(deleted => {
                this.router.navigate(['/personlist']);
            });
        }
    }

    savePerson() {
        const saveButton = <HTMLButtonElement> document.getElementById('saveButton');
        saveButton.disabled = true;
        const cancelButton = <HTMLButtonElement> document.getElementById('cancelButton');
        cancelButton.disabled = true;

        const utils = this.utilsService;

        /* if the locations for birth, death, burial don't have an ID, then save and get it */
        if (this.activeId === null || this.activeId === '' ) {
            const p: PersonCreate = new PersonCreate();
            p.givenName = utils.emptyValue(this.f.givenName);
            p.middleNames = utils.emptyValue(this.f.middleNames);
            p.familyName = utils.emptyValue(this.f.familyName);
            p.altNames = utils.emptyValue(this.f.altNames);
            p.nameSuffix = utils.emptyValue(this.f.nameSuffix);
            p.gender = utils.emptyValue(this.f.gender);
            p.causeOfDeath = utils.emptyValue(this.f.causeOfDeath);
            p.deceased = utils.emptyBoolean(this.f.deceased);
            p.occupation = utils.emptyValue(this.f.occupation);
            p.attributes = utils.emptyValue(this.f.attributes);
            p.religion = utils.emptyValue(this.f.religion);
            p.isResearch = utils.emptyBoolean(this.f.isResearch);
            p.version = '4444'; // this.version.value;

            // create a new birth record
            p.birth.eventTypeCd = 'birth';
            p.birth.participantType = 'subjt';
            p.birth.description = utils.generateNameFromFields(this.f.givenName.value, this.f.middleNames.value,
                this.f.familyName.value) + ' was born.';
            p.birth.startDateDay = utils.emptyValue(this.f.birthDay);
            p.birth.startDateMonth = utils.emptyValue(this.f.birthMonth);
            p.birth.startDateYear = +utils.emptyValue(this.f.birthYear);

            if (this.f.birthLocationId.value === '0') {
                // this means the user has selected a geonames location
                p.birth.location.id = '';
            } else {
                // this is a user created location
                p.birth.location.id = this.f.birthLocationId.value;
            }

            p.birth.location.id = this.f.birthLocationId.value;
            p.birth.location.description = this.f.birthLocation.value;
            p.birth.location.externalId = this.f.birthExternalId.value;
            p.birth.location.latitude = (this.f.birthLat.value !== null && this.f.birthLat.value !== '') ? this.f.birthLat.value : '';
            p.birth.location.longitude = (this.f.birthLon.value !== null && this.f.birthLon.value !== '') ? this.f.birthLon.value : '';
            p.birth.location.country = this.f.birthCountry.value;
            p.birth.location.transactionId = this.utilsService.uuid();

            // create a new death record
            p.death.eventTypeCd = 'death';
            p.death.participantType = 'subjt';
            p.death.description = utils.generateNameFromFields(this.f.givenName.value, this.f.middleNames.value,
                this.f.familyName.value) + ' died.';
            p.death.startDateDay = utils.emptyValue(this.f.deathDay);
            p.death.startDateMonth = utils.emptyValue(this.f.deathMonth);
            p.death.startDateYear = +utils.emptyValue(this.f.deathYear);

            if (this.f.deathLocationId.value === '0') {
                // this means the user has selected a geonames location
                p.death.location.id = '';
            } else {
                // this is a user created location
                p.death.location.id = this.f.deathLocationId.value;
            }

            p.death.location.description = this.f.deathLocation.value;
            p.death.location.externalId = this.f.deathExternalId.value;
            p.death.location.latitude = (this.f.deathLat.value !== null && this.f.deathLat.value !== '') ? this.f.deathLat.value : '';
            p.death.location.longitude = (this.f.deathLon.value !== null && this.f.deathLon.value !== '') ? this.f.deathLon.value : '';
            p.death.location.country = this.f.deathCountry.value;
            p.death.location.transactionId = this.utilsService.uuid();

            // create a new burial record
            p.burial.eventTypeCd = 'bural';
            p.burial.participantType = 'subjt';
            p.burial.description = utils.generateNameFromFields(this.f.givenName.value, this.f.middleNames.value,
                this.f.familyName.value) + ' was buried.';
            p.burial.startDateDay = utils.emptyValue(this.f.burialDay);
            p.burial.startDateMonth = utils.emptyValue(this.f.burialMonth);
            p.burial.startDateYear = +utils.emptyValue(this.f.burialYear);

            if (this.f.burialLocationId.value === '0') {
                // this means the user has selected a geonames location
                p.burial.location.id = '';
            } else {
                // this is a user created location
                p.burial.location.id = this.f.burialLocationId.value;
            }

            p.burial.location.description = this.f.burialLocation.value;
            p.burial.location.externalId = this.f.burialExternalId.value;
            p.burial.location.latitude = (this.f.burialLat.value !== null && this.f.burialLat.value !== '') ? this.f.burialLat.value : '';
            p.burial.location.longitude = (this.f.burialLon.value !== null && this.f.burialLon.value !== '') ? this.f.burialLon.value : '';
            p.burial.location.country = this.f.burialCountry.value;
            p.burial.location.transactionId = this.utilsService.uuid();
            p.fatherId = this.parentsComponent.getFatherId();
            p.motherId = this.parentsComponent.getMotherId();

            this.personService.savePerson('', JSON.stringify(p), '4444').toPromise().then(data => {
                    const saveData = <IPersonSave>data;
                    this.personName = this.utilsService.generateNamePersonCreate(p);
                    this.personService.setNameOfPerson(this.personName);
                    this.activeId = saveData.ids.id;
                    this.activeGender = p.gender;

                    const birthMask = this.utilsService.dateMask(
                        p.birth.startDateDay,
                        p.birth.startDateMonth,
                        '' + p.birth.startDateYear);

                    const deathMask = this.utilsService.dateMask(
                        p.death.startDateDay,
                        p.death.startDateMonth,
                        '' + p.death.startDateYear);

                    this.parentsComponent.activePersonBirthMask = birthMask;
                    this.parentsComponent.activePersonDeathMask = deathMask;
                    this.spouseComponent.activePersonBirthMask  = birthMask;
                    this.spouseComponent.activePersonDeathMask  = deathMask;
                    this.showForm = false;
                    return saveData; },
                err => {
                    return null;
                });
        } else {
            const p: PersonUpdate = new PersonUpdate();
            p.id = (this.activeId !== null && this.activeId !== '0') ? this.activeId : '';
            p.givenName = utils.emptyValue(this.f.givenName);
            p.middleNames = utils.emptyValue(this.f.middleNames);
            p.familyName = utils.emptyValue(this.f.familyName);
            p.altNames = utils.emptyValue(this.f.altNames);
            p.nameSuffix = utils.emptyValue(this.f.nameSuffix);
            p.gender = utils.emptyValue(this.f.gender);
            p.causeOfDeath = utils.emptyValue(this.f.causeOfDeath);
            p.deceased = utils.emptyBoolean(this.f.deceased);
            p.occupation = utils.emptyValue(this.f.occupation);
            p.attributes = utils.emptyValue(this.f.attributes);
            p.religion = utils.emptyValue(this.f.religion);
            p.isResearch = utils.emptyBoolean(this.f.isResearch);
            p.version = '4444'; // this.version.value;

            // update birth record
            p.birth.id = this.f.birthId.value;
            p.birth.eventTypeCd = 'birth';
            p.birth.participantType = 'subjt';
            p.birth.description = this.f.birthDescription.value;
            p.birth.startDateDay = utils.emptyValue(this.f.birthDay);
            p.birth.startDateMonth = utils.emptyValue(this.f.birthMonth);
            p.birth.startDateYear = +utils.emptyValue(this.f.birthYear);

            if (this.f.birthLocationId.value === '0') {
                // this means the user has selected a geonames location
                p.birth.location.id = '';
            } else {
                // this is a user created location
                p.birth.location.id = this.f.birthLocationId.value;
            }

            p.birth.location.description = this.f.birthLocation.value;
            p.birth.location.externalId = this.f.birthExternalId.value;
            p.birth.location.latitude = (this.f.birthLat.value !== null && this.f.birthLat.value !== '') ?
                this.f.birthLat.value : '';
            p.birth.location.longitude = (this.f.birthLon.value !== null && this.f.birthLon.value !== '') ?
                this.f.birthLon.value : '';
            p.birth.location.country = this.f.birthCountry.value;
            p.birth.location.transactionId = this.utilsService.uuid();

            // update death record
            p.death.id = this.f.deathId.value;
            p.death.eventTypeCd = 'death';
            p.death.participantType = 'subjt';
            p.death.description = this.f.deathDescription.value;
            p.death.startDateDay = utils.emptyValue(this.f.deathDay);
            p.death.startDateMonth = utils.emptyValue(this.f.deathMonth);
            p.death.startDateYear = +utils.emptyValue(this.f.deathYear);

            if (this.f.deathLocationId.value === '0') {
                // this means the user has selected a geonames location
                p.death.location.id = '';
            } else {
                // this is a user created location
                p.death.location.id = this.f.deathLocationId.value;
            }

            p.death.location.description = this.f.deathLocation.value;
            p.death.location.externalId = this.f.deathExternalId.value;
            p.death.location.latitude = (this.f.deathLat.value !== null && this.f.deathLat.value !== '') ?
                this.f.deathLat.value : '';
            p.death.location.longitude = (this.f.deathLon.value !== null && this.f.deathLon.value !== '') ?
                this.f.deathLon.value : '';
            p.death.location.country = this.f.deathCountry.value;
            p.death.location.transactionId = this.utilsService.uuid();

            // update burial record
            p.burial.id = this.f.burialId.value;
            p.burial.eventTypeCd = 'bural';
            p.burial.participantType = 'subjt';
            p.burial.description = this.f.burialDescription.value;
            p.burial.startDateDay = utils.emptyValue(this.f.burialDay);
            p.burial.startDateMonth = utils.emptyValue(this.f.burialMonth);
            p.burial.startDateYear = +utils.emptyValue(this.f.burialYear);

            if (this.f.burialLocationId.value === '0') {
                // this means the user has selected a geonames location
                p.burial.location.id = '';
            } else {
                // this is a user created location
                p.burial.location.id = this.f.burialLocationId.value;
            }

            p.burial.location.description = this.f.burialLocation.value;
            p.burial.location.externalId = this.f.burialExternalId.value;
            p.burial.location.latitude = (this.f.burialLat.value !== null && this.f.burialLat.value !== '') ?
                this.f.burialLat.value : '';
            p.burial.location.longitude = (this.f.burialLon.value !== null && this.f.burialLon.value !== '') ?
                this.f.burialLon.value : '';
            p.burial.location.country = this.f.burialCountry.value;
            p.burial.location.transactionId = this.utilsService.uuid();
            p.fatherId = this.parentsComponent.getFatherId();
            p.motherId = this.parentsComponent.getMotherId();

            this.personService.savePerson(p.id, JSON.stringify(p), '4444').toPromise().then(data => {
                    const saveData = <IPersonSave>data;
                    this.personName = this.utilsService.generateNamePersonUpdate(p);
                    this.personService.setNameOfPerson(this.personName);
                    this.activeId = saveData.ids.id;
                    this.activeGender = p.gender;

                    const birthMask = this.utilsService.dateMask(
                        p.birth.startDateDay,
                        p.birth.startDateMonth,
                        '' + p.birth.startDateYear);

                    const deathMask = this.utilsService.dateMask(
                        p.death.startDateDay,
                        p.death.startDateMonth,
                        '' + p.death.startDateYear);

                    this.parentsComponent.activePersonBirthMask = birthMask;
                    this.parentsComponent.activePersonDeathMask = deathMask;
                    this.spouseComponent.activePersonBirthMask  = birthMask;
                    this.spouseComponent.activePersonDeathMask  = deathMask;
                    this.showForm = false;
                    return saveData; },
                err => {
                    return null;
                });

        }
    }

    openPerson(content, size, type) {
        this.modalService.open(content, size).result.then((result) => {
        }, (reason) => {
            this.router.navigate(['/personlist']);
        });
    }

    openLocation(content, size, type) {
        this.saveNewLocationDisabled = true;
        this.f.newLocationType.setValue(type);
        this.f.newLocationDescription.setValue('');
        this.f.newLocationCountry.setValue('');
        this.f.newLocationDescription.setValidators([Validators.required, Validators.maxLength(1000)]);
        this.f.newLocationCountry.setValidators(Validators.required);
        this.f.newLocationDescription.updateValueAndValidity();
        this.f.newLocationCountry.updateValueAndValidity();
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    openDelete(content, size) {
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    validateNewLocation() {
        const vDescription = this.f.newLocationDescription;
        const vCountry = this.f.newLocationCountry;

        const isDescriptionValid = (vDescription.value !== '');
        const isCountryValid = (vCountry.value !== '');

        if (isDescriptionValid && isCountryValid) {
            this.saveNewLocationDisabled = false;
            this.f.newLocationDescription.setValidators([Validators.required, Validators.maxLength(1000)]);
            this.f.newLocationCountry.setValidators(Validators.required);
            this.showNewLocationDescriptionHelp = false;
            this.showNewLocationCountryHelp = false;

        } else {
            if (!isDescriptionValid) {
                this.saveNewLocationDisabled = true;
                this.f.newLocationDescription.setValidators([Validators.required, Validators.maxLength(1000)]);
                this.f.newLocationCountry.setValidators(Validators.required);
                this.showNewLocationDescriptionHelp = true;
            }

            if (!isCountryValid) {
                this.saveNewLocationDisabled = true;
                this.f.newLocationDescription.setValidators([Validators.required, Validators.maxLength(1000)]);
                this.f.newLocationCountry.setValidators(Validators.required);
                this.showNewLocationCountryHelp = true;
            }
        }
    }

    quickSaveLocation() {
        const nLocationDescription = this.f.newLocationDescription.value;
        const nLocationCountry = this.f.newLocationCountry.value;
        const newLocation: Location = new Location();
        newLocation.description  = nLocationDescription;
        newLocation.country      = nLocationCountry;
        newLocation.latitude     = '';
        newLocation.longitude    = '';
        newLocation.notes        = '';
        newLocation.id           = '';
        newLocation.version      = '4444'; // this.version.value;

        this.f.newLocationDescription.setValidators(null);
        this.f.newLocationCountry.setValidators(null);
        this.profileForm.patchValue({
            newLocationDescription: '',
            newLocationCountry: ''
        });
        this.profileForm.updateValueAndValidity();

        this.locationService.saveLocation(newLocation.id, JSON.stringify(newLocation), '4444')
            .subscribe(data => {
                    const saveData = <ILocationSave>data;
                    const type = this.f.newLocationType.value;
                    this.setLocationInfo(
                            type,
                            nLocationDescription,
                            saveData.ids.id,
                            'K' + saveData.ids.id,
                            saveData.ids.id,
                            '',
                            '',
                            nLocationCountry);
                    this.f.newLocationType.setValue('');
                    return data;
                },
                err => {
                });
    }

    removeLocationButton(type: string) {
        if (type === 'birth') {
            this.f.birthLocation.setValue('', {onlySelf: true, emitEvent: false});
            this.f.birthLocationId.setValue('');
            this.f.birthExternalId.setValue(''); // when this is saved to the database, add 'G'
            this.f.birthLat.setValue('');
            this.f.birthLon.setValue('');
            this.f.birthCountry.setValue('');
        } else if (type === 'death') {
            this.f.deathLocation.setValue('', {onlySelf: true, emitEvent: false});
            this.f.deathLocationId.setValue('');
            this.f.deathExternalId.setValue(''); // when this is saved to the database, add 'G'
            this.f.deathLat.setValue('');
            this.f.deathLon.setValue('');
            this.f.deathCountry.setValue('');
        } else if (type === 'burial') {
            this.f.burialLocation.setValue('', {onlySelf: true, emitEvent: false});
            this.f.burialLocationId.setValue('');
            this.f.burialExternalId.setValue(''); // when this is saved to the database, add 'G'
            this.f.burialLat.setValue('');
            this.f.burialLon.setValue('');
            this.f.burialCountry.setValue('');
        }
        this.profileForm.updateValueAndValidity();
    }

    addNewFamilyButton() {
        this.spouseComponent.addNewFamilyButton();
    }

    downloadFamilyGroupSheet() {
        const fileName = this.activePersonName + this.utilsService.dateToYMD(new Date());
        this.personService.downloadFamilyGroupSheet(this.activeId)
            .toPromise().then(response => {
            const blob = new Blob([response], {type: 'application/pdf'});
            saveAs(blob, fileName + '.pdf');
        });
    }

}
