import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { WorldmapComponent} from './worldmap/worldmap.component';
import { DashboardService} from './dashboard.service';
import { DashboardResolver} from './dashboard-resolver.service';
import { MissingdataComponent } from './missingdata/missingdata.component';
import { MissingeventComponent } from './missingevent/missingevent.component';
import { MissingsourceComponent } from './missingsource/missingsource.component';
import { MissinglocationComponent } from './missinglocation/missinglocation.component';
import { TrackingComponent } from './tracking/tracking.component';
import { TasksComponent } from './tasks/tasks.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        DashboardComponent,
        WorldmapComponent,
        MissingdataComponent,
        MissingeventComponent,
        MissingsourceComponent,
        MissinglocationComponent,
        TrackingComponent,
        TasksComponent
    ],
    providers: [
        DashboardService,
        DashboardResolver
    ]

})
export class DashboardModule { }
