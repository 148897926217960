import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pedigree',
  templateUrl: './pedigree.component.html',
  styleUrls: ['./pedigree.component.scss']
})
export class PedigreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
