import { Component, OnInit, ViewChild} from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RepositoryService } from './repository.service';
import { Repository } from './repository';
import { ActivatedRoute, Router} from '@angular/router';
import { IRepositorySave} from '../models/irepositorysave';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { UtilsService} from '../shared/utils.service';
import { IRepositorySource} from '../models/irepositorysource';


@Component({
    selector: 'app-repository',
    templateUrl: './repository.component.html',
    styleUrls: ['./repository.component.scss']
})

export class RepositoryComponent implements OnInit {
    @ViewChild('t', {static: true}) t;

    activeRepositoryName: string;
    activeId: string;
    showNameHelp = false;

    private routeSubscription: Subscription;
    private nameOfRepositorySubscription: Subscription;

    repositoryForm: FormGroup;
    repositoryId = new FormControl();
    name = new FormControl(null, [Validators.required, Validators.maxLength(250)]);
    address = new FormControl(null, Validators.maxLength(2000));
    detail = new FormControl(null, Validators.maxLength(2000));
    email = new FormControl(null, [Validators.email, Validators.maxLength(250)]);
    phoneNum = new FormControl(null, [Validators.maxLength(250)]);
    url = new FormControl(null, [Validators.maxLength(500)]);
    notes = new FormControl(null, Validators.maxLength(2000));
    version = new FormControl();
    closeResult: string;
    sources: IRepositorySource[];

    showEditButton: boolean;

    constructor(private repositoryService: RepositoryService,
                private utilsService: UtilsService,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.showEditButton = true;

        this.repositoryForm = new FormGroup({
            repositoryId: this.repositoryId,
            name: this.name,
            address: this.address,
            detail: this.detail,
            email: this.email,
            phoneNum: this.phoneNum,
            notes: this.notes,
            url: this.url,
            version: this.version
        });

        // document.addEventListener('click', () => this.closeList());

        this.routeSubscription = this.route.queryParams.subscribe(
            params => {
                this.activeId = params['id'];
                this.setRepository(this.activeId);
            }
        );

        this.nameOfRepositorySubscription = this.repositoryService.nameOfRepository.subscribe( activeRepository => {
            this.activeRepositoryName = activeRepository;
        });

    }

    onDestroy() {
        this.routeSubscription.unsubscribe();
        this.nameOfRepositorySubscription.unsubscribe();
    }

    setRepository(id: string) {
        this.repositoryService.setNameOfRepository('');
        if (id === '' || id === null || id === undefined) {
            this.repositoryForm.patchValue({
                repositoryId: '',
                name: '',
                address: '',
                detail: '',
                email: '',
                phoneNum: '',
                url: '',
                notes: '',
                version: ''
            });
            this.enableForm();
            this.activeId = '';
            // this.sources = [];
        } else {
            this.repositoryService.getRepository(+id).subscribe(repository => {
                const activeRepository = <Repository>repository;

                // for sources attached to this repository
                // for (let i = 0; this.repository..length > i; i++) {
                //     this.repository.persons[i].fullname =
                //         UtilsService.generateNameOnly(<IPerson>this.repository.persons[i].repositoryPerson);
                //     this.repository.persons[i].eventTypeLabel =
                //         this.resourcebundleService.getEventType(this.repository.persons[i].repositoryEvent.eventTypeCd);
                //     this.repository.persons[i].repositoryEvent.formattedDate =
                //         this.utilsService.generateDateOnly(this.repository.persons[i].repositoryEvent);
                // }

                // this.sources = repository.persons;

                this.repositoryForm.patchValue({
                    repositoryId: activeRepository.id,
                    name: activeRepository.name,
                    address: activeRepository.address,
                    detail: activeRepository.detail,
                    email: activeRepository.email,
                    phoneNum: activeRepository.phoneNum,
                    url: activeRepository.url,
                    notes: activeRepository.notes,
                    version: '4444'
                });
                this.activeRepositoryName = activeRepository.name;
                this.repositoryForm.updateValueAndValidity();
                this.disableForm();
                this.activeId = id;
            });
        }
    }

    // for the undo button
    getRepository() {
        this.setRepository(this.activeId);
    }

    public cancelRepository(id: string) {
        if (id === '') {
            this.router.navigate(['/repositorylist']);
        } else {
            this.router.navigate(['/repository'], { queryParams: { id: id } });
        }
    }

    public goToSource(id: string) {
        this.router.navigate(['/source'], { queryParams: { id: id } });
    }

    downloadRepositoryReport() {
    }

    open(content, size) {
        this.modalService.open(content, size).result.then((result) => {
            this.closeResult = 'Closed with: ${result}';
        }, (reason) => {
            this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  'with: ${reason}';
        }
    }

    saveRepository() {
        this.showEditButton = true;
        this.disableForm();

        const newRepository: Repository = new Repository();
        newRepository.id = (this.repositoryId.value !== null && this.repositoryId.value !== 0) ? this.repositoryId.value : '';
        newRepository.name = (this.name.value !== null && this.name.value !== '') ? this.name.value : '';
        newRepository.address = (this.address.value !== null && this.address.value !== '') ? this.address.value : '';
        newRepository.detail = (this.detail.value !== null && this.detail.value !== '') ? this.detail.value : '';
        newRepository.notes = (this.notes.value !== null && this.notes.value !== '') ? this.notes.value : '';
        newRepository.email = (this.email.value !== null && this.email.value !== '') ? this.email.value : '';
        newRepository.phoneNum = (this.phoneNum.value !== null && this.phoneNum.value !== '') ? this.phoneNum.value : '';
        newRepository.url = (this.url.value !== null && this.url.value !== '') ? this.url.value : '';
        newRepository.version = '4444'; // this.version.value;

        this.repositoryService.saveRepository(newRepository.id, JSON.stringify(newRepository), '4444')
            .subscribe(data => {
                    const saveData = <IRepositorySave>data;
                    this.repositoryId.setValue(saveData.ids.id);
                    return data;
                },
                err => {
                    console.log('repository saving error ' + err.toString());
                });
    }

    enableForm() {
        this.showEditButton = false;
        this.showNameHelp = false;

        this.name.enable();
        this.address.enable();
        this.detail.enable();
        this.email.enable();
        this.phoneNum.enable();
        this.url.enable();
        this.notes.enable();
    }

    disableForm() {
        this.name.disable();
        this.address.disable();
        this.detail.disable();
        this.email.disable();
        this.phoneNum.disable();
        this.url.disable();
        this.notes.disable();
    }

    deleteRepository(id: string) {
        if (id === '') {
            this.router.navigate(['/repositorylist']);
        } else {
            this.repositoryService.deleteRepository(id).toPromise().then(deleted => {
                this.router.navigate(['/repositorylist']);
            });
        }
    }

    public sortBy(column: string) {
        if (column === 'name') {
            // this.isDescriptionDesc = !this.isDescriptionDesc;
            // this.isNotesDesc = true;

            // this.isFilterDescription = true;
            // this.isFilterNotes = false;

        } else if (column === 'address') {
            // this.isNotesDesc = !this.isNotesDesc;
            // this.isDescriptionDesc = true;

            // this.isFilterDescription = false;
            // this.isFilterNotes = true;
        }
        // this.loadData();

        // re-order the list
    }
}
