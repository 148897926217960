export class StringBuilder {
    strArray: Array<string> = new Array<string>();
    constructor() {}

    get(nIndex: number): string {
        let str: string = null;
        if ((this.strArray.length > nIndex) && (nIndex >= 0) ) {
            str = this.strArray[nIndex];
        }
        return(str);
    }

    isEmpty(): boolean {
        if (this.strArray.length === 0) {
            return true;
        }
        return(false);
    }

    append(str: string): void {
        if (str != null) {
            this.strArray.push(str);
        }
    }

    toString(): string {
        const str: string = this.strArray.join('');
        return(str);
    }

    toArrayString(delimeter: string): string {
        const str: string = this.strArray.join(delimeter);
        return(str);
    }

    clear() {
        this.strArray.length = 0;
    }
}
