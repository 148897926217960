import { Component, Input, OnInit} from '@angular/core';
import { PersonService } from '../person.service';
import { LifeeventService } from './lifeevent.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { ILifeEvent } from '../../models/ilifeevent';
import { UtilsService} from '../../shared/utils.service';
import { LocationService} from '../../location/location.service';
import { ActivatedRoute, Router} from '@angular/router';
import { saveAs } from 'file-saver';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { LifeEvent} from './lifeevent';
import { Person} from '../person';

@Component({
  selector: 'app-lifeeventlist',
  templateUrl: './lifeeventlist.component.html',
  styleUrls: ['./lifeeventlist.component.scss']
})
export class LifeEventlistComponent implements OnInit {
    @Input() activeId: string;
    @Input() activeGender: string;

    activePersonName: string;

    events: LifeEvent[];
    closeResult: string;

    selectedId: string;
    otherPersonId: string;
    otherPersonGender: string;

    showNewButton = true;

    private routeSubscription: Subscription;
    private nameOfPersonSubscription: Subscription;
    private loadPage: Subscription;

    constructor(private utilsService: UtilsService,
                private personService: PersonService,
                private eventService: LifeeventService,
                private locationService: LocationService,
                protected http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private modalService: NgbModal) {
    }

    ngOnInit() {
        this.events = undefined;

        this.routeSubscription = this.route.queryParams.subscribe(params => {
            this.activeId = params['id'];
            this.activeGender = params['g'];
        });

        this.loadPage = this.personService.showEvent.subscribe( eventListRefresh => {
            this.setEvents(this.activeId);
        });

        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe( activePersonName => {
            this.activePersonName = activePersonName;
        });
    }

    onDestroy() {
        this.routeSubscription.unsubscribe();
        this.nameOfPersonSubscription .unsubscribe();
        this.loadPage.unsubscribe();
    }

    hideEventForm(itemId: string) {
        if (itemId === 'new') {
            const tmpEventArray: LifeEvent[] = [];
            this.events.forEach(function (item) {
                if (item.id !== itemId) {
                    tmpEventArray.push(item);
                }
            });
            this.events = tmpEventArray;
            this.showNewButton = true;
        } else {
            this.events.forEach(function (item) {
                if (item.id === itemId) {
                    item.showForm = false;
                }
            });
        }
    }

    updateEvent(item: LifeEvent) {
        let isEventFound = false;
        let personBirthYear;
        let personDeathYear;

        item.eventTypeLabel = this.utilsService.getEventType(item.eventTypeCd);
        item.formattedDate = this.utilsService.getDisplayDate(item);

        const isChild = (item.participantType === 'child');
        const displayName = this.utilsService.generateNameFromFields(item.personGiven, item.personMiddle, item.personFamily);

        if (item.eventTypeCd === 'birth' && !isChild) {
            personBirthYear = item.startDateYear;
            item.eventTypeLabel = 'Birth';
        } else if (item.eventTypeCd === 'birth' && isChild) {
            item.eventTypeLabel = 'Birth of ';
            if (item.personGender === 'mal') {
                item.eventTypeLabel = item.eventTypeLabel + 'Son';
            } else {
                item.eventTypeLabel = item.eventTypeLabel + 'Daughter';
            }
        } else if (item.eventTypeCd === 'death' && isChild) {
            item.eventTypeLabel = 'Death of ';
            if (item.personGender === 'mal') {
                item.eventTypeLabel = item.eventTypeLabel + 'Son';
            } else {
                item.eventTypeLabel = item.eventTypeLabel + 'Daughter';
            }
        } else if (item.eventTypeCd === 'death' && !isChild) {
            personDeathYear = item.startDateYear;
            item.eventTypeLabel = 'Death';
        } else if (item.eventTypeCd === 'bural' && isChild) {
            item.eventTypeLabel = 'Burial of ';
            if (item.personGender === 'mal') {
                item.eventTypeLabel = item.eventTypeLabel + 'Son';
            } else {
                item.eventTypeLabel = item.eventTypeLabel + 'Daughter';
            }
        } else if (item.eventTypeCd === 'bural' && !isChild) {
            item.eventTypeLabel = 'Burial';
        } else if (item.eventTypeCd === 'marrd') {
            item.eventTypeLabel = 'Marriage';
        }

        // calculate approx age
        item.age = this.utilsService.generateAge(personBirthYear, personDeathYear, item.startDateYear);

        // for each event get the participants
        this.personService.getParticipants(+item.id, +this.activeId).toPromise().then(participants => {
            const parts = <Person[]>participants;

            if (parts !== null && parts.length > 0) {
                if (item.eventTypeCd === 'marrd') {
                    item.description = displayName + ' was in a relationship with ' +
                        this.utilsService.generateNameFromFields(
                            (parts[0].givenName !== null) ? parts[0].givenName : '',
                            (parts[0].middleNames !== null) ? parts[0].middleNames : '',
                            (parts[0].familyName !== null) ? parts[0].familyName : '');
                }

                item.participants = parts;
            }
        });

        this.events.map((eventItem, i) => {
            if (eventItem.id === item.id) {
                this.events[i] = item;
                isEventFound = true;
            }
        });
        if (!isEventFound) {
            this.events.push(item);
        }
    }

    showEventForm(item: LifeEvent) {
        item.showForm = true;
    }

    newEvent() {
        this.showNewButton = false;

        const newLifeEvent = new LifeEvent();
        newLifeEvent.showForm = true;
        newLifeEvent.personId = this.activeId;
        newLifeEvent.id = 'new';
        this.events.push(newLifeEvent);
    }

    setEvents(personId: string) {
        this.events = [];
        const ps = this.personService;
        const utils = this.utilsService;
        const eventsList = this.events;

        if (personId !== null && personId !== '') {
            this.eventService.getEvents(personId).toPromise().then(eventList => {
                const tmpEvents = <LifeEvent[]>eventList;
                if (tmpEvents !== null) {
                    tmpEvents.forEach(function (item) {
                        let personBirthYear;
                        let personDeathYear;

                        item.eventTypeLabel = utils.getEventType(item.eventTypeCd);
                        item.formattedDate = utils.getDisplayDate(item);

                        const isChild = (item.participantType === 'child');
                        const displayName = utils.generateNameFromFields(item.personGiven, item.personMiddle, item.personFamily);

                        if (item.eventTypeCd === 'birth' && !isChild) {
                            personBirthYear = item.startDateYear;
                            item.eventTypeLabel = 'Birth';
                        } else if (item.eventTypeCd === 'birth' && isChild) {
                            item.eventTypeLabel = 'Birth of ';
                            if (item.personGender === 'mal') {
                                item.eventTypeLabel = item.eventTypeLabel + 'Son';
                            } else {
                                item.eventTypeLabel = item.eventTypeLabel + 'Daughter';
                            }
                        } else if (item.eventTypeCd === 'death' && isChild) {
                            item.eventTypeLabel = 'Death of ';
                            if (item.personGender === 'mal') {
                                item.eventTypeLabel = item.eventTypeLabel + 'Son';
                            } else {
                                item.eventTypeLabel = item.eventTypeLabel + 'Daughter';
                            }
                        } else if (item.eventTypeCd === 'death' && !isChild) {
                            personDeathYear = item.startDateYear;
                            item.eventTypeLabel = 'Death';
                        } else if (item.eventTypeCd === 'bural' && isChild) {
                            item.eventTypeLabel = 'Burial of ';
                            if (item.personGender === 'mal') {
                                item.eventTypeLabel = item.eventTypeLabel + 'Son';
                            } else {
                                item.eventTypeLabel = item.eventTypeLabel + 'Daughter';
                            }
                        } else if (item.eventTypeCd === 'bural' && !isChild) {
                            item.eventTypeLabel = 'Burial';
                        } else if (item.eventTypeCd === 'marrd') {
                            item.eventTypeLabel = 'Marriage';
                        }

                        // calculate approx age
                        item.age = utils.generateAge(personBirthYear, personDeathYear, item.startDateYear);

                        // for each event get the participants
                        ps.getParticipants(+item.id, +personId).toPromise().then(participants => {
                            const parts = <Person[]>participants;

                            if (parts !== null && parts.length > 0) {
                                if (item.eventTypeCd === 'marrd') {

                                    item.description = displayName + ' was in a relationship with ' +
                                        utils.generateNameFromFields(
                                            (parts[0].givenName !== null) ? parts[0].givenName : '',
                                            (parts[0].middleNames !== null) ? parts[0].middleNames : '',
                                            (parts[0].familyName !== null) ? parts[0].familyName : '');
                                }

                                item.participants = parts;
                            }
                        });

                        eventsList.push(item);

                    });
                    this.showNewButton = true;
                }
            });
        }
    }

    open(content, size, selectedEventId) {
        this.selectedId = selectedEventId;
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    selectEvent(index: number, eventId: string, participantType: string, eventTypeCd: string,
                otherPersonId: string, otherPersonGender: string) {
        this.selectedId = eventId;
        this.otherPersonId = otherPersonId;
        this.otherPersonGender = otherPersonGender;

        if (participantType !== 'subjt' && participantType !== 'spous') {
            this.router.navigate(['/person'], { queryParams: { id: otherPersonId, g: otherPersonGender } });
        } else {
            alert('Fill me in');
        }
    }

    delete(eventId: string, personId: string) {
        this.eventService.deleteEvent(eventId, personId).toPromise().then(
            data => {
                const tmpEventArray: LifeEvent[] = [];
                this.events.forEach(function (item) {
                    if (item.id !== eventId) {
                        tmpEventArray.push(item);
                    }
                });
                this.events = tmpEventArray;
                return data;
            },
            err => {
                console.log('event delete error ' + err.toString());
            }
        );
    }

    isActivePersonEvent (event: ILifeEvent): boolean {
        return this.activeId === event.personId.toString();
    }

    downloadTimelineReport() {
        const fileName = this.activePersonName + this.utilsService.dateToYMD(new Date());
        this.personService.downloadTimelineReport(this.activeId)
            .toPromise().then(response => {
            const blob = new Blob([response], {type: 'application/pdf'});
            saveAs(blob, fileName + '.pdf');
        });
    }

}
