import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { UtilsService } from '../../shared/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location} from '@angular/common';
import { PaginationService } from '../../shared/pagination.service';
import { RepositoryComponent } from '../repository.component';
import { RepositoryService } from '../repository.service';
import { Repository } from '../repository';
import { FormControl, FormGroup} from '@angular/forms';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';

@Component({
  selector: 'app-repositorylist',
  templateUrl: './repositorylist.component.html',
  styleUrls: ['./repositorylist.component.scss']
})
export class RepositorylistComponent implements OnInit {

    @ViewChild(RepositoryComponent, {static: true}) repositoryComponent: RepositoryComponent;
    repositories: Repository[];
    itemsPerPage = 25;
    pager: any = {};

    isNameDesc = true;
    isSortName = false;
    isAddressDesc = true;
    isSortAddress = false;

    repositorySortOptions: Array<any>;

    filterForm: FormGroup;
    filterName = new FormControl();
    filterAddress = new FormControl();
    sortTable = new FormControl();

    filterLabel: string;

    closeResult: string;

    private queryParams: Subscription;

    constructor(private repositoryService: RepositoryService,
                private utilsService: UtilsService,
                private router: Router,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private location: Location,
                private pagerService: PaginationService) {
    }

    public ngOnInit(): void {
        this.filterForm = new FormGroup({
            filterName: this.filterName,
            filterAddress: this.filterAddress,
            sortTable: this.sortTable
        });
        this.repositorySortOptions = this.utilsService.getRepositorySortOptions();
        this.filterLabel = 'Show Filter';

        const fName = document.getElementById('filterName');

        // Execute a function when the user releases a key on the keyboard
        fName.addEventListener('keyup', function(event) {
            // Number 13 is the 'Enter' key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        const fAddress = document.getElementById('filterAddress');

        // Execute a function when the user releases a key on the keyboard
        fAddress.addEventListener('keyup', function(event) {
            // Number 13 is the 'Enter' key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        this.queryParams = this.route.queryParams.subscribe(params => {
            const fN = (this.route.snapshot.params['name'] === undefined) ? '' : this.route.snapshot.params['name'];
            const fA = (this.route.snapshot.params['address'] === undefined) ? '' : this.route.snapshot.params['address'];
            const sortOrder = (this.route.snapshot.params['sort'] === undefined) ? '' : this.route.snapshot.params['sort'];
            this.sortTable.setValue(sortOrder, {onlySelf: true});
            this.setSortOrder(sortOrder);
            this.filterForm.patchValue({
                filterName: fN,
                filterAddress: fA,
                sortTable: sortOrder
            });
            this.loadPage(1);
        });
    }

    onDestroy() {
        this.queryParams.unsubscribe();
    }

    handleClick() {
        this.filterLabel = (this.filterLabel === 'Show Filter' ? 'Hide Filter' : 'Show Filter');
    }

    private setSortOrder(column) {
        // ascending is the default
        this.isNameDesc = false;
        this.isSortName = false;
        this.isAddressDesc = false;
        this.isSortAddress = false;

        if (column === 'nameAsc') {
            this.isSortName = true;
        } else if (column === 'nameDesc') {
            this.isNameDesc = true;
            this.isSortName = true;
        } else if (column === 'addressAsc') {
            this.isSortAddress = true;
        } else if (column === 'addressDesc') {
            this.isAddressDesc = true;
            this.isSortAddress = true;
        }
    }

    loadPage(selectedPage: number) {
        this.repositoryService.getRepositories(
            selectedPage,
            this.itemsPerPage,
            this.filterName.value, (this.isSortName ? (this.isNameDesc ? 'DESC' : 'ASC') : ''),
            this.filterAddress.value, (this.isSortAddress ? (this.isAddressDesc ? 'DESC' : 'ASC') : ''))
                .toPromise().then(repositoryList => {
                this.repositories = <Repository[]>repositoryList.items;
                this.setPager(repositoryList.total, selectedPage);
        });
    }

    applyFilters() {
        this.updateURL();
        this.handleClick();
    }

    repositorySortBy(event) {
        this.sortTable.setValue(event.target.value, {onlySelf: true});
        this.setSortOrder(event.target.value);
        this.updateURL();
    }

    updateURL() {
        const sort = this.sortTable.value;
        const url = this
            .router
            .createUrlTree([{
                name: this.filterName.value,
                address: this.filterAddress.value,
                sort: sort}],
                {relativeTo: this.route})
            .toString();
        this.location.go(url);
        this.loadPage(1);
    }

    clearFilters() {
        this.filterName.setValue('');
        this.filterAddress.setValue('');
        this.updateURL();
    }


    public setRepository(id: string) {
        this.router.navigate(['/repository'], {queryParams: { id: id }});
    }

    setPager(repositoryListTotal, selectedPage) {
        console.log('Setting the Pager for Repositorylist');
        this.pager = this.pagerService.getPager(repositoryListTotal, selectedPage, this.itemsPerPage);
    }

}
