import { Component, OnInit } from '@angular/core';
import { UtilsService} from '../shared/utils.service';
import { ActivatedRoute} from '@angular/router';
import { GedcomService} from './gedcom.service';
import { HttpEventType, HttpResponse} from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-gedcom',
  templateUrl: './gedcom.component.html',
  styleUrls: ['./gedcom.component.scss']
})
export class GedcomComponent implements OnInit {
    fileToUpload: File = null;

    constructor(private route: ActivatedRoute,
                private   utils: UtilsService,
                private gedcomService: GedcomService) { }

    ngOnInit() {

    }

    onDestroy() {}

    // At the file input element
    // (change)='selectFile($event)'
    selectFile(event) {
        this.uploadFile(event.target.files.item(0));
    }

    downloadFile() {
        const fileName = 'Kinalytic-GEDCOM-' + this.utils.dateToYMD(new Date());
        this.gedcomService.downloadFile()
            .toPromise().then(response => {
            const blob = new Blob([response], {type: 'text/plain'});
            saveAs(blob, fileName + '.ged');
        });
    }

    uploadFile(gedcomFile: File) {
        if (gedcomFile === undefined || gedcomFile.size === 0) {
            console.log('No file selected!');
            return;
        }

        this.gedcomService.uploadFile(gedcomFile).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round(100 * event.loaded / event.total);
                        console.log(`File is ${percentDone}% loaded.`);
                    } else if (event instanceof HttpResponse) {
                        console.log('File is completely loaded!');
                    }
                },
                (err) => {
                    console.log('Upload Error:', err);
                }, () => {
                    console.log('Upload done');
                }
            );
    }
}
