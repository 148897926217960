import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { LocationService} from '../location.service';
import { ILocation} from '../../models/ilocation';

@Injectable()
export class LocationlistResolver implements Resolve<ILocation[]> {

    constructor(private locationService: LocationService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.locationService.getLocations();
    }
}
