import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from '../shared/utils.service';
// tslint:disable-next-line:import-blacklist
import { Observable} from 'rxjs';
import { Router, ActivatedRoute} from '@angular/router';
import { IDashboardSummary} from '../models/idashboardsummary';

@Injectable()
export class DashboardService {

    constructor(private   ms: NgbModal,
                protected http: HttpClient,
                private   router: Router,
                private   route: ActivatedRoute,
                private   utils: UtilsService) {
    }

    getDashboardSummary(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };

        const URL = this.utils.getGENURL() + '/gen/dashboard';
        return this.http.get<IDashboardSummary>(URL, httpOptions);
    }

}
