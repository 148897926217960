import { ILifeEvent } from '../../models/ilifeevent';
import { Person} from '../person';
import { Location } from '../../location/location';

export class LifeEvent implements ILifeEvent {
    id:                  string;
    version:             string;
    description:         string;
    notes:               string;
    startDateDayZero:    string;
    startDateDay:        string;
    startDateMonth:      string;
    startDateMonthShort: string;
    startDateYear:       number;
    eventTypeCd:         string;
    eventTypeLabel:      string;
    participantType:     string;
    personId:            string;
    personGender:        string;
    personGiven:         string;
    personMiddle:        string;
    personFamily:        string;
    roleCd:              string;
    formattedDate:       string;
    location:            Location;
    participants:        Person[];
    skip:                boolean;
    age:                 string;
    showForm:            boolean;
    formattedStartDateDay:   string;
    formattedStartDateMonth: string;
    formattedStartDateYear:  string;

    constructor() {
        this.skip                = false;
        this.id                  = '';
        this.version             = '';
        this.description         = '';
        this.notes               = '';
        this.startDateDay        = '';
        this.startDateDayZero    = '';
        this.startDateMonth      = '';
        this.startDateMonthShort = '';
        this.startDateYear       = null;
        this.eventTypeCd         = '';
        this.eventTypeLabel      = '';
        this.participantType     = '';
        this.roleCd              = '';
        this.personId            = '';
        this.personGender        = '';
        this.personGiven         = '';
        this.personMiddle        = '';
        this.personFamily        = '';
        this.formattedDate       = '';
        this.location            = new Location();
        this.participants        = [];
        this.age                 = null;
        this.showForm            = false;
        this.formattedStartDateDay   = '';
        this.formattedStartDateMonth = '';
        this.formattedStartDateYear  = '';
    }

    toJSON() {
        return {
            id: this.id,
            description: this.description,
            notes: this.notes,
            startDateDay: this.startDateDay,
            startDateMonth: this.startDateMonth,
            startDateYear: this.startDateYear,
            eventTypeCd: this.eventTypeCd,
            participantType: this.participantType,
            roleCd: this.roleCd,
            personId: this.personId,
            personGender: this.personGender,
            personGiven: this.personGiven,
            personMiddle: this.personMiddle,
            personFamily: this.personFamily,
            version: this.version,
            location: this.location
        };
    }
}

