import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, Observable} from 'rxjs';
import { Router, ActivatedRoute} from '@angular/router';
import { PersonCreate} from './personcreate';
import { Person} from './person';
import { UtilsService} from '../shared/utils.service';
import { SVGObject} from './chart/svgobject';
import { PersonSuggestion} from './personsuggestion';
import { FormArray, FormGroup} from '@angular/forms';
import { RelationshipCreate} from './family/relationshipcreate';
import { RelationshipUpdate} from './family/relationshipupdate';
import { IRelationshipSave} from '../models/irelationshipsave';
import { LocalStorageService} from 'ngx-webstorage';

@Injectable()
export class PersonService {

    private personName = new BehaviorSubject<string>('');
    nameOfPerson = this.personName.asObservable();

    private loadEventData = new BehaviorSubject<string>('');
    showEvent = this.loadEventData.asObservable();

    private loadChartData = new BehaviorSubject<string>('');
    showChart = this.loadChartData.asObservable();

    private loadStoryData = new BehaviorSubject<string>('');
    showStory = this.loadStoryData.asObservable();

    PERSONLIST = '.personlist';
    PG = 'pg';
    FNAME = 'fName';
    FNAMEDIR = 'fNameDir';
    FBDATE = 'fBirthDate';
    FBDATEDIR = 'fBirthDdateDir';
    FBLOC = 'fBirthLoc';
    FBLOCDIR = 'fBirthLocDir';
    FDDATE = 'fDeathDate';
    FDDATEDIR = 'fDeathDateDir';
    FDLOC = 'fDeathLoc';
    FDLOCDIR = 'fDeathLocDir';
    SORT = 'sort';
    MGIVEN = 'mGiven';
    MFAMILY = 'mFamily';
    MOCC = 'mOcc';
    MREL = 'mRel';
    MCAUSE = 'mCause';
    MFATHER = 'mFather';
    MMOTHER = 'mMother';
    MBIRTHDATE = 'mBirthDate';
    MBIRTHLOC = 'mBirthLoc';
    MDEATHDATE = 'mDeathDate';
    MDEATHLOC = 'mDeathLoc';
    MMARRIAGEDATE = 'mMarriageDate';
    MMARRIAGELOC = 'mMarriageLoc';
    MOTHERDATE = 'mOtherDate';
    MOTHERLOC = 'mOtherLoc';

    constructor(private   ms: NgbModal,
                protected http: HttpClient,
                private   router: Router,
                private   route: ActivatedRoute,
                private   utilsService: UtilsService,
                private   localStorage: LocalStorageService) {
    }

    setShowChart(show: string) {
        this.loadChartData.next(show);
    }

    setShowEvent(show: string) {
        this.loadEventData.next(show);
    }

    setShowstory(show: string) {
        this.loadStoryData.next(show);
    }

    setNameOfPerson(personName: string) {
        this.personName.next(personName);
    }

    getPersons(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };

        let params = '';

        params = params + this.getParams(this.PG, false);
        params = params + this.getParams(this.FNAME, false);
        params = params + this.getParams(this.FNAMEDIR, false);
        params = params + this.getParams(this.FBDATE, false);
        params = params + this.getParams(this.FBDATEDIR, false);
        params = params + this.getParams(this.FBLOC, false);
        params = params + this.getParams(this.FBLOCDIR, false);
        params = params + this.getParams(this.FDDATE, false);
        params = params + this.getParams(this.FDDATEDIR, false);
        params = params + this.getParams(this.FDLOC, false);
        params = params + this.getParams(this.FDLOCDIR, false);
        params = params + this.getParams(this.MGIVEN, true);
        params = params + this.getParams(this.MFAMILY, true);
        params = params + this.getParams(this.MOCC, true);
        params = params + this.getParams(this.MREL, true);
        params = params + this.getParams(this.MCAUSE, true);
        params = params + this.getParams(this.MFATHER, true);
        params = params + this.getParams(this.MMOTHER, true);
        params = params + this.getParams(this.MBIRTHDATE, true);
        params = params + this.getParams(this.MBIRTHLOC, true);
        params = params + this.getParams(this.MDEATHDATE, true);
        params = params + this.getParams(this.MDEATHLOC, true);
        params = params + this.getParams(this.MMARRIAGEDATE, true);
        params = params + this.getParams(this.MMARRIAGELOC, true);
        params = params + this.getParams(this.MOTHERDATE, true);
        params = params + this.getParams(this.MOTHERLOC, true);

        if (params.length === 0) { params = params + '&' + this.FNAMEDIR + '=ASC'; }

        console.log('>>>>>> PersonService -> GetPersons() - calls the server with params ' + params);
        const URL = this.utilsService.getGENURL() + '/gen/persons' + '?' + params;
        return this.http.get<any>(URL, httpOptions);
    }

    getParams(key: string, setToTrue: boolean): string {
        const value = this.localStorage.retrieve(key + this.PERSONLIST);
        if (value != null && value !== '') {
            if (setToTrue) {
                 return '&' + key + '=true';

            } else {
                return '&' + key + '=' + value;
            }
        }
        return '';
    }

    getParamValue(key: string) {
        return this.localStorage.retrieve(key + this.PERSONLIST);
    }

    setParams(key: string, value: string) {
        this.localStorage.store(key + this.PERSONLIST, value);
    }

    clearParams() {
        this.localStorage.clear(this.PG + this.PERSONLIST);
        this.localStorage.clear(this.SORT + this.PERSONLIST);
        this.localStorage.clear(this.FNAME + this.PERSONLIST);
        this.localStorage.clear(this.FNAMEDIR + this.PERSONLIST);
        this.localStorage.clear(this.FBDATE + this.PERSONLIST);
        this.localStorage.clear(this.FBDATEDIR + this.PERSONLIST);
        this.localStorage.clear(this.FBLOC + this.PERSONLIST);
        this.localStorage.clear(this.FBLOCDIR + this.PERSONLIST);
        this.localStorage.clear(this.FDDATE + this.PERSONLIST);
        this.localStorage.clear(this.FDDATEDIR + this.PERSONLIST);
        this.localStorage.clear(this.FDLOC + this.PERSONLIST);
        this.localStorage.clear(this.FDLOCDIR + this.PERSONLIST);
        this.localStorage.clear(this.MGIVEN + this.PERSONLIST);
        this.localStorage.clear(this.MFAMILY + this.PERSONLIST);
        this.localStorage.clear(this.MOCC + this.PERSONLIST);
        this.localStorage.clear(this.MREL + this.PERSONLIST);
        this.localStorage.clear(this.MCAUSE + this.PERSONLIST);
        this.localStorage.clear(this.MFATHER + this.PERSONLIST);
        this.localStorage.clear(this.MMOTHER + this.PERSONLIST);
        this.localStorage.clear(this.MBIRTHDATE + this.PERSONLIST);
        this.localStorage.clear(this.MBIRTHLOC + this.PERSONLIST);
        this.localStorage.clear(this.MDEATHDATE + this.PERSONLIST);
        this.localStorage.clear(this.MDEATHLOC + this.PERSONLIST);
        this.localStorage.clear(this.MMARRIAGEDATE + this.PERSONLIST);
        this.localStorage.clear(this.MMARRIAGELOC + this.PERSONLIST);
        this.localStorage.clear(this.MOTHERDATE + this.PERSONLIST);
        this.localStorage.clear(this.MOTHERLOC + this.PERSONLIST);
    }

    getPerson(id: number): Observable<Person> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<Person>(this.utilsService.getGENURL() + '/gen/persons/person/' + id , httpOptions);
    }

    getMales(text: string, excludeId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };

        let params = '';
        if (text != null && text !== '') {
            params = params + '&fName=' + text;
        }

        if (excludeId != null && excludeId !== '') {
            params = params + '&fExcludeId=' + excludeId;
        }

        const URL = this.utilsService.getGENURL() + '/gen/persons/parent' + '?fGender=mal' + params;
        return this.http.get<any>(URL, httpOptions);
    }

    getFemales(text: string, excludeId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };

        let params = '';
        if (text != null && text !== '') {
            params = params + '&fName=' + text;
        }

        if (excludeId != null && excludeId !== '') {
            params = params + '&fExcludeId=' + excludeId;
        }

        const URL = this.utilsService.getGENURL() + '/gen/persons/parent' + '?fGender=fem' + params;
        return this.http.get<any>(URL, httpOptions);
    }

    getAnyPerson(id: string, text: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };

        if (id === null || id === undefined || id === '') {
            id = '0';
        }
        const URL = this.utilsService.getGENURL() + '/gen/persons/anyperson/' + id + '/' + text;
        return this.http.get<any>(URL, httpOptions);
    }

    searchPerson(text: string): Observable<PersonSuggestion> {
        const strippedText = text.replace(',', '');
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-search.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<PersonSuggestion>(this.utilsService.getSRCHURL() + '/search/persons/suggest/?query=' + text , httpOptions);
    }

    quickAdd(givenName: string, familyName: string, gender: string): Observable<any> {
        const p: PersonCreate = new PersonCreate();
        p.givenName = givenName;
        p.familyName = familyName;
        p.gender = gender;
        p.middleNames = '';
        p.altNames = '';
        p.nameSuffix = '';
        p.causeOfDeath = '';
        p.deceased = 'true';
        p.occupation = '';
        p.attributes = '';
        p.religion = '';
        p.version = '4444';

        // create a new birth record
        p.birth.eventTypeCd = 'birth';
        p.birth.participantType = 'subjt';
        p.birth.description = this.utilsService.generateNameFromFields(givenName, '', familyName) + ' was born.';
        p.birth.startDateDay = '';
        p.birth.startDateMonth = '';
        p.birth.startDateYear = +'';
        p.birth.location.id = '';
        p.birth.location.description = '';
        p.birth.location.externalId = '';
        p.birth.location.latitude = '';
        p.birth.location.longitude = '';
        p.birth.location.transactionId = this.utilsService.uuid();

        // create a new death record
        p.death.eventTypeCd = 'death';
        p.death.participantType = 'subjt';
        p.death.description = this.utilsService.generateNameFromFields(givenName, '', familyName) + ' died.';
        p.death.startDateDay = '';
        p.death.startDateMonth = '';
        p.death.startDateYear = +'';
        p.death.location.id = '';
        p.death.location.description = '';
        p.death.location.externalId = '';
        p.death.location.latitude = '';
        p.death.location.longitude = '';
        p.death.location.transactionId = this.utilsService.uuid();

        // create a new burial record
        p.burial.eventTypeCd = 'bural';
        p.burial.participantType = 'subjt';
        p.burial.description = this.utilsService.generateNameFromFields(givenName, '', familyName) + ' was buried.';
        p.burial.startDateDay = '';
        p.burial.startDateMonth = '';
        p.burial.startDateYear = +'';
        p.burial.location.id = '';
        p.burial.location.description = '';
        p.burial.location.externalId = '';
        p.burial.location.latitude = '';
        p.burial.location.longitude = '';
        p.burial.location.transactionId = this.utilsService.uuid();

        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        const URLString = this.utilsService.getGENURL() + '/gen/persons/person/' + this.utilsService.uuid();
        return this.http.post(URLString, JSON.stringify(p), httpOptions);
    }

    savePerson(id: string, body: string, version: string): Observable<any> {
        if (id === '' || id === null) { // new person to be saved
            console.log('Saving NEW person');
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utilsService.getGENURL() + '/gen/persons/person/' + this.utilsService.uuid();
            return this.http.post(URLString, body, httpOptions);
        } else { // updated person to be saved
            console.log('Saving EXISTING person');
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json',
                    'If-Match': version
                })
            };
            const URLString = this.utilsService.getGENURL() + '/gen/persons/person/' + this.utilsService.uuid() + '/' + id;
            // console.log('Saving person: ' + URLString);
            return this.http.put(URLString, body, httpOptions);
        }
    }

    saveFamilies(activePersonId: string, activePersonName: string, activePersonGender: string, familiesArray: FormArray) {
        let findex = 0;
        while (findex < familiesArray.length) {
            const family = familiesArray.controls[findex] as FormGroup;
            const isSpouseNameAvailable = !(family.get('spouse').value === null ||
                family.get('spouse').value === '');
            const isSpouseIdAvailable = !(family.get('spouseId').value === null ||
                family.get('spouseId').value === '');
            const isSpouseRelationshipAvailable =
                !(family.get('spouseRelationshipId').value === null ||
                    family.get('spouseRelationshipId').value === '');

            if (isSpouseNameAvailable && isSpouseIdAvailable && !isSpouseRelationshipAvailable) {
                // this also saves the children by adding the parents
                // const activePersonName = p.givenName + ' ' + p.middleNames + ' ' + p.familyName;
                const spouseRelationship = this.createMarriageCreate(family, activePersonId, activePersonName);
                this.saveNewSpouseRelationship(family, spouseRelationship, activePersonId, activePersonGender);
            } else if (isSpouseNameAvailable && isSpouseIdAvailable && isSpouseRelationshipAvailable) {
                // this also saves the parents of the children
                // const activePersonName = p.givenName + ' ' + p.middleNames + ' ' + p.familyName;
                const spouseRelationship = this.createMarriageUpdate(family, activePersonId, activePersonName);
                this.saveExistingSpouseRelationship(family, spouseRelationship, activePersonId, activePersonGender);
            } else if (!isSpouseNameAvailable && isSpouseRelationshipAvailable) {
                // this also saves the parents of the children
                this.removeSpouseRelationship(familiesArray, family, findex, activePersonId, activePersonGender);
            } else {
                let cfatherId = (activePersonGender === 'mal') ? activePersonId : family.get('spouseId').value;
                let cmotherId = (activePersonGender === 'fem') ? activePersonId : family.get('spouseId').value;
                cfatherId = (cfatherId !== '' && cfatherId !== null) ? cfatherId : '0';
                cmotherId = (cmotherId !== '' && cmotherId !== null) ? cmotherId : '0';
                this.saveChildren(family, cfatherId, cmotherId, activePersonId, activePersonGender);
            }
            findex = findex + 1;
        }
    }

    createMarriageCreate(family: FormGroup, activeId: string, activePersonName: string): RelationshipCreate {
        const utils = this.utilsService;
        const spouseRelationship: RelationshipCreate = new RelationshipCreate();
        spouseRelationship.personId = activeId;
        spouseRelationship.personRole = 'spous';
        spouseRelationship.relationPersonId = family.get('spouseId').value;
        spouseRelationship.relationPersonRole = 'spous';
        spouseRelationship.relationType = 'marrd';
        spouseRelationship.transactionId = this.utilsService.uuid();

        // Note: if there is no marriage date - then it is just a relationship
        spouseRelationship.marriage.description = activePersonName + ' was in a relationship with ' + family.get('marriageDay');
        spouseRelationship.marriage.eventTypeCd = 'marrd';
        spouseRelationship.marriage.participantType = 'spous';
        spouseRelationship.marriage.startDateDay = utils.emptyValue(family.get('marriageDay'));
        spouseRelationship.marriage.startDateMonth = utils.emptyValue(family.get('marriageMonth'));
        spouseRelationship.marriage.startDateYear = +utils.emptyValue(family.get('marriageYear'));

        spouseRelationship.marriage.location.id = family.get('marriageLocationId').value;
        spouseRelationship.marriage.location.description = family.get('marriageLocation').value;
        spouseRelationship.marriage.location.externalId = family.get('marriageExternalId').value;
        spouseRelationship.marriage.location.latitude =
            (family.get('marriageLat').value !== null && family.get('marriageLat').value !== '') ? family.get('marriageLat').value : '';
        spouseRelationship.marriage.location.longitude =
            (family.get('marriageLon').value !== null && family.get('marriageLon').value !== '') ? family.get('marriageLon').value : '';
        spouseRelationship.marriage.location.country = family.get('marriageCountry').value;

        spouseRelationship.marriage.location.transactionId = this.utilsService.uuid();
        spouseRelationship.marriage.location.version = '4444';
        spouseRelationship.childrenIds = this.childrenForMarriage(family);
        return spouseRelationship;
    }

    createMarriageUpdate(family: FormGroup, activeId: string, activePersonName: string): RelationshipUpdate {
        const utils = this.utilsService;
        const spouseRelationship: RelationshipUpdate = new RelationshipUpdate();
        spouseRelationship.id = family.get('spouseRelationshipId').value;
        spouseRelationship.personId = activeId;
        spouseRelationship.personRole = 'spous';
        spouseRelationship.relationPersonId = family.get('spouseId').value;
        spouseRelationship.relationPersonRole = 'spous';
        spouseRelationship.relationType = 'marrd';
        spouseRelationship.transactionId = this.utilsService.uuid();
        spouseRelationship.marriage.id = family.get('marriageId').value;
        spouseRelationship.marriage.eventTypeCd = 'marrd';
        spouseRelationship.marriage.participantType = 'spous';
        spouseRelationship.marriage.startDateDay = utils.emptyValue(family.get('marriageDay'));
        spouseRelationship.marriage.startDateMonth = utils.emptyValue(family.get('marriageMonth'));
        spouseRelationship.marriage.startDateYear = +utils.emptyValue(family.get('marriageYear'));
        spouseRelationship.marriage.location.id = family.get('marriageLocationId').value;
        spouseRelationship.marriage.location.description = family.get('marriageLocation').value;
        spouseRelationship.marriage.location.externalId = family.get('marriageExternalId').value;
        spouseRelationship.marriage.location.latitude =
            (family.get('marriageLat').value !== null && family.get('marriageLat').value !== '') ?
                family.get('marriageLat').value : '';
        spouseRelationship.marriage.location.longitude =
            (family.get('marriageLon').value !== null && family.get('marriageLon').value !== '') ?
                family.get('marriageLon').value : '';
        spouseRelationship.marriage.location.country = family.get('marriageCountry').value;
        spouseRelationship.marriage.location.version = '4444';
        spouseRelationship.marriage.location.transactionId = this.utilsService.uuid();
        spouseRelationship.childrenIds = this.childrenForMarriage(family);
        return spouseRelationship;
    }

    childrenForMarriage(family: FormGroup): string[] {
        let cindex = 0;
        const childrenForMarriage: string[] = [];
        const childrenArray = family.get('children') as FormArray;
        if (childrenArray !== null && childrenArray !== undefined) {
            while (cindex < childrenArray.length) {
                const child = childrenArray.controls[cindex] as FormGroup;
                child.get('child').disable();
                const childId = child.get('childId').value;
                childrenForMarriage.push(childId);
                cindex = cindex + 1;
            }
        }
        return childrenForMarriage;
    }

    saveNewSpouseRelationship(family: FormGroup, spouseCRelationship: RelationshipCreate, activeId: string, gender: string) {
        this.saveRelationship(family.get('spouseRelationshipId').value, JSON.stringify(spouseCRelationship), '4444')
            .toPromise().then(saveSpouseResponse => {
            const saveRData = <IRelationshipSave>saveSpouseResponse;
            family.get('originalSpouseId').setValue(family.get('spouseId').value);
            family.get('spouseRelationshipId').setValue(saveRData.ids.relationshipId);
            family.get('marriageId').setValue(saveRData.ids.marriageId);
            family.get('marriageLocationId').setValue(saveRData.ids.marriageLocationId);
        });
    }

    saveExistingSpouseRelationship(family: FormGroup, spouseURelationship: RelationshipUpdate, activeId: string, gender: string) {
        this.saveRelationship(family.get('spouseRelationshipId').value, JSON.stringify(spouseURelationship), '4444')
            .toPromise().then(saveSpouseResponse => {
            const saveRData = <IRelationshipSave>saveSpouseResponse;
            family.get('originalSpouseId').setValue(family.get('spouseId').value);
            family.get('spouseRelationshipId').setValue(saveRData.ids.relationshipId);
            family.get('marriageId').setValue(saveRData.ids.marriageId);
            family.get('marriageLocationId').setValue(saveRData.ids.marriageLocationId);
        });
    }

    saveChildren(family: FormGroup, cfatherId: string, cmotherId: string, activeId: string, gender: string) {
        let cindex = 0;
        const childrenArray = family.get('children') as FormArray;
        if (childrenArray !== null && childrenArray !== undefined) {
            while (cindex < childrenArray.length) {
                const child = childrenArray.controls[cindex] as FormGroup;
                child.get('child').disable();
                const childId = child.get('childId').value;

                if (childId !== null && childId !== '') {
                    this.saveParents(childId, cfatherId, cmotherId, '4444')
                        .toPromise().then(cdata => {
                            return cdata;
                        },
                        err => {
                            console.log('child saving error ' + err.toString());
                        });
                }
                cindex = cindex + 1;
            }
        }
    }

    fetchPerson(activeId: string, gender: string) {
        this.router.navigate(['/person'], { queryParams: { id: activeId, g: gender} });
    }

    removeFamilyButton(familiesArray: FormArray, family: FormGroup, findex: number, activeId: string) {
        familiesArray.removeAt(findex);
        if (family.get('spouseRelationshipId').value !== null) {
            this.removeFamily(activeId, family.get('spouseRelationshipId').value, '4444')
                .toPromise().then(response => {
            });
        }
    }

    removeSpouseRelationship(familiesArray: FormArray, family: FormGroup, findex: number, activeId: string, gender: string) {
        this.deleteRelationship(family.get('spouseRelationshipId').value, activeId)
            .toPromise().then(data => {
            family.get('spouseRelationshipId').setValue('');
            family.get('spouseId').setValue('');
            family.get('spouse').setValue('');
            family.get('spouseGender').setValue('');
            family.get('marriageId').setValue('');
            familiesArray.removeAt(findex);
            this.fetchPerson(activeId, gender);
        });
    }

    saveStory(id: string, body: string, version: string): Observable<any> {
        console.log('Saving STORY for person');
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json',
                'If-Match': version
            })
        };
        const URLString = this.utilsService.getGENURL() + '/gen/persons/story/' + this.utilsService.uuid() + '/' + id;
        return this.http.put(URLString, body, httpOptions);
    }

    removeChildrenForParents(fatherId: string, motherId: string, version: string): Observable<any> {
        // if (fatherId !== '' && fatherId !== null && motherId !== '' && motherId !== null) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json',
                    'If-Match': version
                })
            };
            const URLString = this.utilsService.getGENURL() + '/gen/persons/person/parents/removechildren/' +
                this.utilsService.uuid() + '/' + fatherId + '/' + motherId;
            return this.http.delete(URLString, httpOptions);
        // }
    }

    removeFamily(personId: string, spouseRelationshipId: string, version: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json',
                'If-Match': version
            })
        };
        const URLString = this.utilsService.getGENURL() + '/gen/relationships/removefamily/' + spouseRelationshipId + '/' +  personId;
        return this.http.delete(URLString, httpOptions);
    }

    saveParents(id: string, fatherId: string, motherId: string, version: string): Observable<any> {
        const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json',
                    'If-Match': version
                })
            };
        const URLString = this.utilsService.getGENURL() + '/gen/persons/person/parents/' + this.utilsService.uuid() +
            '/' + id + '/' + fatherId + '/' + motherId;
        return this.http.put(URLString, '', httpOptions);
    }

    deletePerson(id: string): Observable<any> {
        if (id !== '' && id !== null) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utilsService.getGENURL() + '/gen/persons/person/' + id;
            return this.http.delete(URLString, httpOptions);
        }
    }

    getParents(id: number): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(this.utilsService.getGENURL() + '/gen/persons/parents/' + id , httpOptions);
    }

    getSpouses(id: number, gender: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(this.utilsService.getGENURL() + '/gen/relationships/spouses/' + id + '/' + gender, httpOptions);
    }

    getParticipants(eventId: number, personId: number): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(this.utilsService.getGENURL() + '/gen/persons/participants/' + eventId + '/' + personId, httpOptions);
    }

    getChildren(fatherId: number, motherId: number): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(this.utilsService.getGENURL() + '/gen/persons/children/' + fatherId + '/' + motherId, httpOptions);
    }

    getRelationshipType(person1Id: number, person2Id: number, genderPerson2: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<any>(this.utilsService.getGENURL() + '/gen/persons/reltype/' +
            person1Id + '/' + person2Id + '/' + genderPerson2, httpOptions);
    }

    saveRelationship(relationshipId: string, body: string, version: string): Observable<any> {
        // console.log('Calling saveRelationship');
        if (relationshipId === undefined || relationshipId === '' || relationshipId === null ) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utilsService.getGENURL() + '/gen/relationships/relationship/' + this.utilsService.uuid();
            return this.http.post(URLString, body, httpOptions);
        } else {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utilsService.getGENURL() + '/gen/relationships/relationship/' + relationshipId;
            return this.http.put(URLString, body, httpOptions);
        }
    }

    deleteRelationship(relationshipId: string, personId: string): Observable<any> {
        if (relationshipId !== '' && relationshipId !== null) { // parent relationship to be delete
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };

            const URLString = this.utilsService.getGENURL() + '/gen/relationships/relationship/' + relationshipId + '/' + personId;
            return this.http.delete(URLString, httpOptions);
        }
    }

    downloadFamilyGroupSheet(personId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json',
                'Observe': 'body'
            }), responseType: 'blob' as 'blob'}
        ;
        return this.http.get(this.utilsService.getGENURL() + '/gen/charting/familygroupsheet/' + personId, httpOptions);
  }

    downloadTimelineReport(personId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json',
                'Observe': 'body'
            }), responseType: 'blob' as 'blob'}
        ;
        return this.http.get(this.utilsService.getGENURL() + '/gen/charting/timelinereport/' + personId, httpOptions);
    }

    downloadPedigreeChart(personId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json',
                'Observe': 'body'
            }), responseType: 'blob' as 'blob'}
        ;
        return this.http.get(this.utilsService.getGENURL() + '/gen/charting/pedigreechart/' + personId, httpOptions);
    }

    downloadDescendantReport(personId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json',
                'Observe': 'body'
            }), responseType: 'blob' as 'blob'}
        ;
        return this.http.get(this.utilsService.getGENURL() + '/gen/charting/descendantpdf/' + personId, httpOptions);
    }

    downloadAhnentafelReport(personId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json',
                'Observe': 'body'
            }), responseType: 'blob' as 'blob'}
        ;
        return this.http.get(this.utilsService.getGENURL() + '/gen/charting/ancestorpdf/' + personId, httpOptions);
    }

    getPedigreeChart(personId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })};
        return this.http.get(this.utilsService.getGENURL() + '/gen/persons/pedigreechart/' + personId, httpOptions);
    }

    getAncestorsForReport(personId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })};
        return this.http.get(this.utilsService.getGENURL() + '/gen/charting/ancestorslist/' + personId, httpOptions);
    }

    getDescendants(personId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })};
        return this.http.get(this.utilsService.getGENURL() + '/gen/persons/descendants/' + personId, httpOptions);
    }

    getDescendantsList(personId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })};
        return this.http.get(this.utilsService.getGENURL() + '/gen/charting/descendantslist/' + personId, httpOptions);
    }

    downloadDescendantChart(svg: string): Observable<any> {
        const s: SVGObject = new SVGObject();
        s.svg = svg;

        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json',
                'Observe': 'body'
            }), responseType: 'blob' as 'blob'};

        const URLString = this.utilsService.getGENURL() + '/gen/charting/descendantchart/' + this.utilsService.uuid();
        return this.http.post(URLString, JSON.stringify(s), httpOptions);
    }
}
