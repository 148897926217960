import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, Observable} from 'rxjs';
import { share} from 'rxjs/operators';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { UtilsService} from '../shared/utils.service';
import { ActivatedRoute, Router} from '@angular/router';
import { Source } from './source';

@Injectable()
export class SourceService {
    private update = new BehaviorSubject<boolean>(false);

    private titleSource = new BehaviorSubject<string>('');
    sourceTitle = this.titleSource.asObservable();

    constructor(private   ms: NgbModal,
                protected http: HttpClient,
                private   router: Router,
                private   route: ActivatedRoute,
                private   utils: UtilsService) {
    }

    setTitleOfSource(sourceTitle: string) {
        this.titleSource.next(sourceTitle);
    }

    getDefaultSources(): Observable<any> {
        return this.getSources(1, 25, '', '', '', '', '', '').pipe(share());
    }

    getSources(page, itemsPerPage, filterTitle, filterTitleDir,
               filterRecord, filterRecordDir, filterRepository, filterRepositoryDir): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };

        let params = '';
        if (filterTitle != null && filterTitle !== '') {params = params + '&filterTitle=' + filterTitle; }
        if (filterTitleDir != null && filterTitleDir !== '') {params = params + '&filterTitleDir=' + filterTitleDir; }
        if (filterRecord != null && filterRecord !== '') {params = params + '&filterRecord=' + filterRecord; }
        if (filterRecordDir != null && filterRecordDir !== '') {params = params + '&filterRecordDir=' + filterRecordDir; }
        if (filterRepository != null && filterRepository !== '') {params = params + '&filterRepository=' + filterRepository; }
        if (filterRepositoryDir != null && filterRepositoryDir !== '') {params = params + '&filterRepositoryDir=' + filterRepositoryDir; }

        if (page.length === 0) {
            page = 1;
        }
        if (itemsPerPage.length === 0) {
            itemsPerPage = 10;
        }
        if (params.length === 0) {
            params = params + '&filterNameDir=ASC';
        }
        const URL = this.utils.getGENURL() + '/gen/sources' + '?pg=' + page + ',' + itemsPerPage + params;
        return this.http.get<any>(URL, httpOptions);
    }

    getSource(id: number): Observable<Source> {
        this.update.next(false);
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<Source>(this.utils.getGENURL() + '/gen/sources/source/' + id, httpOptions);
    }

    getSourceRepositories(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        const URL = this.utils.getGENURL() + '/gen/repositorys';
        return this.http.get<any>(URL, httpOptions);
    }


    saveSource(id: string, body: string, version: string): Observable<any> {
        console.log('Saving source ');

        // new source to be saved
        if (id === undefined || id === '' || id === null) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utils.getGENURL() + '/gen/sources/source/' + this.utils.uuid();
            return this.http.post(URLString, body, httpOptions);
        } else {  // updated source to be saved
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json',
                    'If-Match': version
                })
            };

            const URLString = this.utils.getGENURL() + '/gen/sources/source/' + id;
            return this.http.put(URLString, body, httpOptions);
        }
    }

    deleteSource(id: string): Observable<any> {
        if (id !== '' && id !== null) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utils.getGENURL() + '/gen/sources/source/' + id;
            return this.http.delete(URLString, httpOptions);
        }
    }

    updateList(): Observable<any> {
        return this.update.asObservable();
    }

}
