import { ILifeEventCreate } from '../../models/ilifeeventcreate';
import { Location } from '../../location/location';

export class LifeEventCreate implements ILifeEventCreate {
    public version:             string;
    public description:         string;
    public notes:               string;
    public startDateDay:        string;
    public startDateMonth:      string;
    public startDateYear:       number;
    public eventTypeCd:         string;
    public participantType:     string;
    public location:            Location;

    constructor() {
        this.version = '';
        this.description = '';
        this.notes = '';
        this.startDateDay = '';
        this.startDateMonth = '';
        this.eventTypeCd = '';
        this.participantType = '';
        this.location = new Location();
    }

    toJSON() {
        return {
            description: this.description,
            notes: this.notes,
            startDateDay: this.startDateDay,
            startDateMonth: this.startDateMonth,
            startDateYear: this.startDateYear,
            location: this.location,
            eventTypeCd: this.eventTypeCd,
            participantType: this.participantType,
            version: this.version
        };
    }
}

