import { Injectable } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { IPerson} from '../models/iperson';
import { PersonService} from '../person/person.service';
import { LocationService} from '../location/location.service';

@Injectable()
export class AutocompleteService {

    constructor(private personService: PersonService,
                private locationService: LocationService) {
    }

    autocompleteParent(form: FormGroup, fieldName: string, gender: string, text: string, excludeId: string) {
        const inp =  document.getElementById(fieldName) as HTMLInputElement;
        const parent = form.get(fieldName);
        const fid = form.get(fieldName + 'Id');

        const personService = this.personService;
        let currentFocus;

        // execute a function when someone writes in the text field:
        inp.addEventListener('input', function (e) {
            const val = inp.value;
            currentFocus = -1;

            if (!val) {
                fid.setValue('');
                return false;
            }

            // clear out the hidden id if the user types
            fid.setValue('');

            const list = document.getElementById(fieldName + 'List');
            const children = list.childNodes;
            let i = 0;
            for (i = 0; i < children.length; i++) {
                list.removeChild(children[i]);
            }

            if (gender === 'males') {
                personService.getMales(text, excludeId).subscribe(perList => {
                    document.getElementById(fieldName + 'List').innerHTML = '';

                    const males = <IPerson[]>perList.items;
                    if (males !== null) {
                        males.forEach(function (mitem) {
                            const maleId = mitem.id;
                            const bitem = document.createElement('button');
                            bitem.setAttribute('id', maleId);
                            bitem.setAttribute('class', 'list-group-item list-group-item-action');

                            const gName = mitem.givenName;
                            const mName = ' ' + mitem.middleNames;
                            const fName = ' ' + mitem.familyName;
                            const name = gName + mName + fName;

                            bitem.innerHTML = name;
                            bitem.innerHTML += '<input type="hidden" id="' + maleId + 'Name" value="' + name + '">';
                            bitem.innerHTML += '<input type="hidden" id="' + maleId + fieldName + 'Id" value="' + maleId + '">';
                            // locationId

                            bitem.addEventListener('click', function () {
                                const bLN = document.getElementById(maleId + 'Name') as HTMLInputElement;
                                const bLI = document.getElementById(maleId + fieldName + 'Id') as HTMLInputElement;
                                const prelationshipId = document.getElementById('parentsRelationshipId')as HTMLInputElement;

                                parent.setValue(bLN.value);
                                fid.setValue(bLI.value);
                                // clear any relationship ids
                                prelationshipId.value = '';
                                document.getElementById(+fieldName + 'List').innerHTML = '';
                            });

                            list.appendChild(bitem);

                        });
                    }

                });
            } else if (gender === 'females') {
                personService.getFemales(text, excludeId).subscribe(perList => {
                    document.getElementById(fieldName + 'List').innerHTML = '';

                    const females = <IPerson[]>perList.items;
                    if (females !== null) {
                        females.forEach(function (fitem) {
                            const femaleId = fitem.id;
                            const bitem = document.createElement('button');
                            bitem.setAttribute('id', femaleId);
                            bitem.setAttribute('class', 'list-group-item list-group-item-action');

                            const gName = fitem.givenName;
                            const mName = ' ' + fitem.middleNames;
                            const fName = ' ' + fitem.familyName;
                            const name = gName + mName + fName;

                            bitem.innerHTML = name;
                            bitem.innerHTML += '<input type="hidden" id="' + femaleId + 'Name" value="' + name + '">';
                            bitem.innerHTML += '<input type="hidden" id="' + femaleId + fieldName + 'Id" value="' + femaleId + '">';

                            bitem.addEventListener('click', function () {
                                const bLN = document.getElementById(femaleId + 'Name') as HTMLInputElement;
                                const bLI = document.getElementById(femaleId + fieldName + 'Id') as HTMLInputElement;
                                const prelationshipId = document.getElementById('parentsRelationshipId')as HTMLInputElement;

                                parent.setValue(bLN.value);
                                fid.setValue(bLI.value);
                                // clear any relationship ids
                                prelationshipId.value = '';
                                document.getElementById(+fieldName + 'List').innerHTML = '';
                            });

                            list.appendChild(bitem);

                        });
                    }

                });
            }
        });

        // execute a function when someone clicks in the document:
        document.addEventListener('click', function (e) {
            if (document.getElementById(fieldName + 'List') !== null &&
                document.getElementById(fieldName + 'List') !== undefined) {
                document.getElementById(fieldName + 'List').innerHTML = '';
            }
        });
    }

}

