import { Injectable } from '@angular/core';
import { UtilsService } from '../../shared/utils.service';

@Injectable()
export class ChartService {
  getRoot() {
    return myTreeData;
  }

  getPedigree() {
    return pedigreeData;
  }

  constructor(private utils: UtilsService) {}
}

const myTreeData = [{
  name: 'Alexander Morrison',
  class: 'man',
  extra: {
    gender: 'man',
    birth: '1760',
    bplace: 'Galson, Isle of Lewis, Scotland, UK',
    death: '1820',
    dplace: 'Barvas, Isle of Lewis, Scotland, UK'
  },
  marriages: [{
    spouse: {
      name: 'Catherine MacDonald',
      class: 'woman',
      extra: {
        gender: 'woman',
        birth: '1760',
        bplace: 'Galson, Isle of Lewis, Scotland, UK',
        death: '1820',
        dplace: 'Barvas, Isle of Lewis, Scotland, UK'
      }
    },
    children: [{
      name: 'Murdo Morrison',
      class: 'man',
      extra: {
        gender: 'man',
        birth: '',
        bplace: '',
        death: '',
        dplace: ''
      },
      marriages: [{
        spouse: {
          name: 'Henrietta (Effie) Morrison',
          class: 'woman',
          extra: {
            gender: 'woman',
            birth: '',
            bplace: '',
            death: '',
            dplace: ''
          }
        },
        children: [{
          name: 'Malcolm Morrison',
          class: 'man',
          extra: {
            gender: 'man',
            birth: '',
            bplace: '',
            death: '',
            dplace: ''
          },
          marriages: [{
            spouse: {
              name: 'Christena Graham',
              class: 'woman',
              extra: {
                gender: 'woman',
                birth: '',
                bplace: '',
                death: '',
                dplace: ''
              }
            },
            children: [{
              name: 'Mary Morrison',
              class: 'woman',
              extra: {
                gender: 'woman',
                birth: '1760',
                bplace: 'Galson, Isle of Lewis, Scotland, UK',
                death: '1820',
                dplace: 'Barvas, Isle of Lewis, Scotland, UK'
              }
            }, {
              name: 'Isabella Morrison',
              class: 'woman',
              extra: {
                gender: 'woman',
                birth: '',
                bplace: '',
                death: '',
                dplace: ''
              }
            }, {
              name: 'John Morrison',
              class: 'man',
              extra: {
                gender: 'man',
                birth: '',
                bplace: '',
                death: '',
                dplace: ''
              }
            }, {
              name: 'Sarah Morrison',
              class: 'woman',
              extra: {
                gender: 'woman',
                birth: '',
                bplace: '',
                death: '',
                dplace: ''
              }
            }, {
              name: 'Angus Morrison',
              class: 'man',
              extra: {
                gender: 'man',
                birth: '1760',
                bplace: 'Galson, Isle of Lewis, Scotland, UK',
                death: '1820',
                dplace: 'Barvas, Isle of Lewis, Scotland, UK'
              }
            }, {
              name: 'Anne Morrison',
              class: 'woman',
              extra: {
                gender: 'woman',
                birth: '',
                bplace: '',
                death: '',
                dplace: ''
              }
            }, {
              name: 'Roderick Morrison',
              class: 'man',
              extra: {
                gender: 'man',
                birth: '',
                bplace: '',
                death: '',
                dplace: ''
              }
            }, {
              name: 'Janie (Margaret) Morrison',
              class: 'woman',
              extra: {
                gender: 'woman',
                birth: '',
                bplace: '',
                death: '',
                dplace: ''
              }
            }, {
              name: 'Donald Morrison',
              class: 'man',
              extra: {
                gender: 'man',
                birth: '1760',
                bplace: 'Galson, Isle of Lewis, Scotland, UK',
                death: '1820',
                dplace: 'Barvas, Isle of Lewis, Scotland, UK'
              }
            }]
          }],
        }, {
          name: 'John Morrison',
          class: 'man',
          extra: {
            gender: 'man',
            birth: '',
            bplace: '',
            death: '',
            dplace: ''
          }
        }, {
          name: 'Catherine Morrison',
          class: 'woman',
          extra: {
            gender: 'woman',
            birth: '',
            bplace: '',
            death: '',
            dplace: ''
          }
        }, {
          name: 'Donald Morrison',
          class: 'man',
          extra: {
            gender: 'man',
            birth: '',
            bplace: '',
            death: '',
            dplace: ''
          }
        }, {
          name: 'Roderick Morrison',
          class: 'man',
          extra: {
            gender: 'man',
            birth: '',
            bplace: '',
            death: '',
            dplace: ''
          }
        }, {
          name: 'Murdo Morrison',
          class: 'man',
          extra: {
            gender: 'man',
            birth: '1760',
            bplace: 'Galson, Isle of Lewis, Scotland, UK',
            death: '1820',
            dplace: 'Barvas, Isle of Lewis, Scotland, UK'
          }
        }, {
          name: 'Angus Morrison',
          class: 'man',
          extra: {
            gender: 'man',
            birth: '',
            bplace: '',
            death: '',
            dplace: ''
          }
        }, {
          name: 'Isabella Morrison',
          class: 'woman',
          extra: {
            gender: 'woman',
            birth: '',
            bplace: '',
            death: '',
            dplace: ''
          }
        }]
      }]
    }]
  }],
}];

const treeData =
  [{
    name: 'Niclas Superlongsurname',
    class: 'man',
    textClass: 'emphasis',
    marriages: [{
      spouse: {
        name: 'Iliana',
        class: 'woman',
        extra: {
          nickname: 'Illi'
        }
      },
      children: [{
        name: 'James',
        class: 'man',
        marriages: [{
          spouse: {
            name: 'Alexandra',
            class: 'woman'
          },
          children: [{
            name: 'Eric',
            class: 'man',
            marriages: [{
              spouse: {
                name: 'Eva',
                class: 'woman'
              }
            }]
          }, {
            name: 'Jane',
            class: 'woman'
          }, {
            name: 'Jasper',
            class: 'man'
          }, {
            name: 'Emma',
            class: 'woman'
          }, {
            name: 'Julia',
            class: 'woman'
          }, {
            name: 'Jessica',
            class: 'woman'
          }]
        }]
      }]
    }]
  }];

const pedigreeData =
  [{
    'name': 'Clifford Shanks',
    'born': 1862,
    'died': 1906,
    'location': 'Petersburg, VA',
    'parents': [
      {
        'name': 'James Shanks',
        'born': 1831,
        'died': 1884,
        'location': 'Petersburg, VA',
        'parents': [
          {
            'name': 'Robert Shanks',
            'born': 1781,
            'died': 1871,
            'location': 'Ireland/Petersburg, VA'
          },
          {
            'name': 'Elizabeth Shanks',
            'born': 1795,
            'died': 1871,
            'location': 'Ireland/Petersburg, VA'
          }
        ]
      },
      {
        'name': 'Ann Emily Brown',
        'born': 1826,
        'died': 1866,
        'location': 'Brunswick/Petersburg, VA',
        'parents': [
          {
            'name': 'Henry Brown',
            'born': 1792,
            'died': 1845,
            'location': 'Montgomery, NC'
          },
          {
            'name': 'Sarah Houchins',
            'born': 1793,
            'died': 1882,
            'location': 'Montgomery, NC'
          }
        ]
      }
    ]
  }
];
