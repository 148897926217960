import { Component, Input, OnInit} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router} from '@angular/router';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    @Input() title: String;
    isCollapsed = true;

    userDetails: KeycloakProfile;

    constructor(private keycloakService: KeycloakService,
              private cookieService: CookieService,
              private router: Router) {
    }

    toggleCollapsed(): void {
        this.isCollapsed = !this.isCollapsed;
    }

    onActivate(event) {
        window.scroll(0, 0);
    }

    async ngOnInit() {
        console.log('App ngOnInit');
        if (await this.keycloakService.isLoggedIn()) {
            this.userDetails = await this.keycloakService.loadUserProfile();
        }
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    async doLogout() {
        await this.keycloakService.logout();
    }

}
