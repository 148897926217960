import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router} from '@angular/router';
import { PersonService} from './person.service';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { UtilsService} from '../shared/utils.service';

@Component({
  selector: 'app-persontabs',
  templateUrl: './persontabs.component.html',
  styleUrls: ['./persontabs.component.scss']
})
export class PersontabsComponent implements OnInit, AfterViewChecked {
    activePersonName: string;
    activeId: string;
    gender: string;
    disabled = true;
    active = 1;

    @ViewChild('tabs', {static: true})
    private tabs: NgbTabset;

    private routeSubscription: Subscription;
    private nameOfPersonSubscription: Subscription;

    constructor(private utilsService: UtilsService,
                private route: ActivatedRoute,
                private router: Router,
                private personService: PersonService,
                private changeDetector: ChangeDetectorRef ) {
    }

    ngOnInit(): void {
        this.routeSubscription = this.route.queryParams.subscribe(params => {
                this.activeId = params['id'];
                this.gender = params['g'];
                if (this.activeId === undefined || this.activeId === null || this.activeId === '') {
                    this.disabled = true;
                } else {
                    this.disabled = false;
                }
            }
        );

        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe( activePersonName => {
            this.activePersonName = activePersonName;
        });


    }

    onDestroy() {
        this.routeSubscription.unsubscribe();
        this.nameOfPersonSubscription.unsubscribe();
    }

    beforeChange($event: NgbTabChangeEvent) {
        const tabName = $event.nextId;
        if (tabName === 'details') {
            // this.personService.setShowDetail('true');
        } else if (tabName === 'chart') {
            this.personService.setShowChart('true');
        } else if (tabName === 'event') {
            this.personService.setShowEvent('true');
        } else if (tabName === 'stories') {
            this.personService.setShowstory('true');
        } else if (tabName === 'sources') {
            // this.personService.setShowEvent('true');
        }
        this.changeDetector.detectChanges();
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    public setPerson(id: string, gender: string) {
        this.router.navigate(['/person'], {queryParams: { id: id, g: gender }});
    }

}
