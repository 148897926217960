import {AfterContentInit, Component, OnInit} from '@angular/core';
import * as Datamap from '../../../../node_modules/datamaps/dist/datamaps.world.min.js';

@Component({
  selector:    'app-worldmap',
  templateUrl: './worldmap.component.html',
  styleUrls: ['./worldmap.component.scss']
})

export class WorldmapComponent implements OnInit, AfterContentInit {

    constructor() { }

    ngOnInit() {}

    ngAfterContentInit(): void {

        const map = new Datamap({

            element: document.getElementById('worldmap'),
            projection: 'mercator',
            fills: {
                defaultFill: '#D3D3D3',
                authorHasTraveledTo: '#008000'
            }, data: {
                USA: { fillKey: 'authorHasTraveledTo' },
                JPN: { fillKey: 'authorHasTraveledTo' },
                ITA: { fillKey: 'authorHasTraveledTo' },
                CRI: { fillKey: 'authorHasTraveledTo' },
                KOR: { fillKey: 'authorHasTraveledTo' },
                DEU: { fillKey: 'authorHasTraveledTo' },
            }
        });
    }
}
