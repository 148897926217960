import { FormGroup } from '@angular/forms';

export function BothEmpty() {
    return (formGroup: FormGroup) => {
        const firstControl = formGroup.controls['givenName'];
        const secondControl = formGroup.controls['familyName'];

        if (firstControl.errors && !firstControl.errors.bothEmpty) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        if (secondControl.errors && !secondControl.errors.bothEmpty) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        const firstEmpty = firstControl.value === null || firstControl.value === undefined ||
            firstControl.value.toString().trim() === '';

        const secondEmpty = secondControl.value === null || secondControl.value === undefined ||
            secondControl.value.toString().trim() === '';

        if (firstEmpty && secondEmpty) {
            firstControl.setErrors({ bothEmpty: true });
            secondControl.setErrors({ bothEmpty: true });
        } else {
            firstControl.setErrors(null);
            secondControl.setErrors(null);
        }
    };
}
