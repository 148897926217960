import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { ChartComponent} from './chart.component';
import { ChartService} from './chart.service';
import { PchartComponent} from './pchart.component';
import { DchartComponent } from './dchart.component';

// AIzaSyApOsTq4qTfBZ5yLiJ1VSYNJkR1jtl9bVo

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    AgmCoreModule
  ],
  declarations: [
    ChartComponent,
    PchartComponent,
    DchartComponent
  ],
  providers: [ChartService],
  exports: []
})
export class ChartModule { }
