import { Component, OnInit, ViewChild} from '@angular/core';
import { UtilsService} from '../shared/utils.service';
import { DashboardService} from './dashboard.service';
import { ActivatedRoute} from '@angular/router';
import { IDashboardSummary} from '../models/idashboardsummary';
import { MissingdataComponent} from './missingdata/missingdata.component';
import { MissingeventComponent} from './missingevent/missingevent.component';
import { MissingsourceComponent} from './missingsource/missingsource.component';
import { MissinglocationComponent} from './missinglocation/missinglocation.component';
import { TasksComponent} from './tasks/tasks.component';
import { TrackingComponent} from './tracking/tracking.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
    @ViewChild(MissingdataComponent, {static: true})
    private missingDataComponent: MissingdataComponent;

    @ViewChild(MissingeventComponent, {static: true})
    private missingEventComponent: MissingeventComponent;

    @ViewChild(MissingsourceComponent, {static: true})
    private missingSourceComponent: MissingsourceComponent;

    @ViewChild(MissinglocationComponent, {static: true})
    private missingLocationComponent: MissinglocationComponent;

    @ViewChild(TasksComponent, {static: true})
    private tasksComponent: TasksComponent;

    @ViewChild(TrackingComponent, {static: true})
    private trackingComponent: TrackingComponent;

    summary: IDashboardSummary;

    constructor(private utils: UtilsService,
                private route: ActivatedRoute,
                private dashboardService: DashboardService) { }

    public ngOnInit(): void {
        this.summary = <IDashboardSummary>this.route.snapshot.data.counts || null;
        this.setupMissingData();
        this.setupMissingEvents();
        this.setupMissingSources();
        this.setupMissingLocations();
        this.setupTasks();
        this.setupTracking();
    }

    // events
    public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
        console.log(event, active);
    }

    public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
        console.log(event, active);
    }

    private setupMissingData() {
        this.missingDataComponent.givenNameMissing    = this.summary.missingGivenCount;
        this.missingDataComponent.familyNameMissing   = this.summary.missingFamilyCount;
        this.missingDataComponent.occupationMissing   = this.summary.missingOccupationCount;
        this.missingDataComponent.religionMissing     = this.summary.missingReligionCount;
        this.missingDataComponent.causeOfDeathMissing = this.summary.missingCauseOfDeathCount;
        this.missingDataComponent.fatherMissing       = this.summary.missingFatherCount;
        this.missingDataComponent.motherMissing       = this.summary.missingMotherCount;
        this.missingDataComponent.spouseMissing       = this.summary.missingSpouseCount;
        this.missingDataComponent.photoMissing        = this.summary.missingPhotoCount;
        this.missingDataComponent.drawDonut();
    }

    private setupMissingEvents() {
        this.missingEventComponent.birthDate        = this.summary.missingBirthDateCount;
        this.missingEventComponent.birthLocation    = this.summary.missingBirthLocationCount;
        this.missingEventComponent.deathDate        = this.summary.missingDeathDateCount;
        this.missingEventComponent.deathLocation    = this.summary.missingDeathLocationCount;
        this.missingEventComponent.marriageDate     = this.summary.missingMarriageDateCount;
        this.missingEventComponent.marriageLocation = this.summary.missingMarriageLocationCount;
        this.missingEventComponent.otherDate        = this.summary.missingOtherDateCount;
        this.missingEventComponent.otherLocation    = this.summary.missingOtherLocationCount;
        this.missingEventComponent.drawDonut();
    }

    private setupMissingSources() {
        this.missingSourceComponent.citationCount = this.summary.missingCitationCount;
        this.missingSourceComponent.transcriptionCount = this.summary.missingTranscriptionCount;
        this.missingSourceComponent.noLinkedCount = this.summary.missingLinkToPersonCount;
        this.missingSourceComponent.noRecordCount = this.summary.missingRecordCount;
        this.missingSourceComponent.drawDonut();
    }

    private setupMissingLocations() {
        this.missingLocationComponent.latitudeCount = this.summary.missingLatitudeCount;
        this.missingLocationComponent.longitudeCount = this.summary.missingLongitudeCount;
        this.missingLocationComponent.notLinkedCount = this.summary.missingLinkToEventCount;
        this.missingLocationComponent.photoCount = this.summary.missingLocationPhotoCount;
        this.missingLocationComponent.drawDonut();
    }

    private setupTasks() {
        // this.tasksComponent
    }

    private setupTracking() {
        this.trackingComponent.nameTracking = this.summary.nameTracking;
        this.trackingComponent.ageTracking = this.summary.ageTracking;
        this.trackingComponent.birthDateTracking = this.summary.birthDateTracking;
        this.trackingComponent.birthLocationTracking = this.summary.birthLocationTracking;
        this.trackingComponent.deathDateTracking = this.summary.deathDateTracking;
        this.trackingComponent.deathLocationTracking = this.summary.deathLocationTracking;
        this.trackingComponent.burialDateTracking = this.summary.burialDateTracking;
        this.trackingComponent.burialLocationTracking = this.summary.burialLocationTracking;
        this.trackingComponent.ethnicTracking = this.summary.ethnicTracking;
        this.trackingComponent.familyTracking = this.summary.familyTracking;
        this.trackingComponent.historyTracking = this.summary.historyTracking;
        this.trackingComponent.immigrationTracking = this.summary.immigrationTracking;
        this.trackingComponent.livingRelativesTracking = this.summary.livingRelativesTracking;
        this.trackingComponent.maidenAliasTracking = this.summary.maidenAliasTracking;
        this.trackingComponent.marriageDateTracking = this.summary.marriageDateTracking;
        this.trackingComponent.marriageLocationTracking = this.summary.marriageLocationTracking;
        this.trackingComponent.divorceTracking = this.summary.divorceTracking;
        this.trackingComponent.militaryTracking = this.summary.militaryTracking;
        this.trackingComponent.occupationTracking = this.summary.occupationTracking;
        this.trackingComponent.physicalTracking = this.summary.physicalTracking;
        this.trackingComponent.politicalTracking = this.summary.politicalTracking;
        this.trackingComponent.residenceTracking = this.summary.residenceTracking;
        this.trackingComponent.religionTracking = this.summary.religionTracking;
        this.trackingComponent.socialTracking = this.summary.socialTracking;
        this.trackingComponent.genealogyTracking = this.summary.genealogyTracking;
        this.trackingComponent.calculateTotal();
    }

    public loadData() {
        this.dashboardService.getDashboardSummary().subscribe(counts => {
            console.log(counts);
                this.summary = <IDashboardSummary>counts;
            }
        );
    }
}
