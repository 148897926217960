import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-missingsource',
    templateUrl: './missingsource.component.html',
    styleUrls: ['./missingsource.component.scss']
})
export class MissingsourceComponent implements OnInit {
    svg;
    margin = 80;
    width = 450;
    height = 450;
    radius = Math.min(this.width, this.height) / 2 - this.margin;
    color;
    data = [];

    citationCount: number;
    transcriptionCount: number;
    noLinkedCount: number;
    noRecordCount: number;

    constructor(private route: ActivatedRoute,
                private router: Router) {}

    ngOnInit() {}

    public drawDonut() {
        this.createSvg();
        this.createColors();
        this.drawChart();
    }

    private createSvg(): void {
        this.svg = d3.select('figure#missingsource')
            .append('svg')
            .attr('width', this.width)
            .attr('height', this.height)
            .append('g')
            .attr(
                'transform',
                'translate(' + this.width / 2 + ',' + this.height / 2 + ')'
            );
    }

    private createColors(): void {
        const domain = [];
        const range = [];
        const colours = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072',
            '#80b1d3', '#fdb462', '#b3de69', '#fccde5',
            '#b5b4b4'];

        // note build this array ONLY IF the values are non-zero
        if (this.citationCount > 0) {
            this.data.push({'key': 'Citation', 'value': this.citationCount});
            domain.push('Citation');
            range.push(colours[0]);
        }

        if (this.transcriptionCount > 0) {
            this.data.push({'key': 'Transcription', 'value': this.transcriptionCount});
            domain.push('Transcription');
            range.push(colours[1]);
        }

        if (this.noLinkedCount > 0) {
            this.data.push({'key': 'Not Linked', 'value': this.noLinkedCount});
            domain.push('Not Linked');
            range.push(colours[2]);
        }

        if (this.noRecordCount > 0) {
            this.data.push({'key': 'No Record', 'value': this.noRecordCount});
            domain.push('No Record');
            range.push(colours[3]);
        }

        this.color = d3.scaleOrdinal().domain(domain).range(range);
    }

    private drawChart(): void {
        // Compute the position of each group on the pie:
        const pie = d3.pie<any>()
            .sort(null)
            .value((d: any) => d.value);

        const arc = d3.arc()
            .innerRadius(this.radius * 0.5)
            .outerRadius(this.radius * 0.8);

        const outerArc = d3.arc()
            .innerRadius(this.radius * 0.9)
            .outerRadius(this.radius * 0.9);

        // Build the pie chart: Basically, each part of the pie is a path
        // that we build using the arc function.
        const rad = this.radius;
        const sliceColor = this.color;
        const rtr = this.router;

        this.svg
            .selectAll('pieces')
            .data(pie(this.data))
            .enter()
            .append('path')
            .attr('d', d3.arc().innerRadius(this.radius * 0.5).outerRadius(this.radius * 0.8))
            .attr('fill', function(d) { return(sliceColor(d.data.key)); })
            .attr('stroke', 'white')
            .style('stroke-width', '2px')
            .style('opacity', 1.0)
            .style('cursor', 'pointer')
            .on('click', function(d) {
                rtr.navigate(['/sourcelist'], { queryParams: { missingData: d.data.key} });
            });

        // Add the polylines between chart and labels:
        this.svg
            .selectAll('allPolylines')
            .data(pie(this.data))
            .enter()
            .append('polyline')
            .attr('stroke', 'black')
            .style('fill', 'none')
            .attr('stroke-width', 1)
            .attr('points', function(d) {
                // line insertion in the slice
                const posA = arc.centroid(d);
                // line break: we use the other arc generator that has been built only for that
                const posB = outerArc.centroid(d);
                // Label position = almost the same as posB
                const posC = outerArc.centroid(d);
                // we need the angle to see if the X position will be at the extreme right or extreme left
                const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                // multiply by 1 or -1 to put it on the right or on the left
                posC[0] = rad * 0.95 * (midangle < Math.PI ? 1 : -1);
                return [posA, posB, posC];
            });

        // Add the polylines between chart and labels:
        this.svg
            .selectAll('allLabels')
            .data(pie(this.data))
            .enter()
            .append('text')
            .text( function(d) { return d.data.key + ' (' + d.data.value + ')'; } )
            .attr('transform', function(d) {
                const pos = outerArc.centroid(d);
                const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                pos[0] = rad * 0.99 * (midangle < Math.PI ? 1 : -1);
                return 'translate(' + pos + ')';
            })
            .style('text-anchor', function(d) {
                const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                return (midangle < Math.PI ? 'start' : 'end');
            });

    }
}
