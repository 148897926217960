import { Component, OnInit, ViewEncapsulation, OnChanges, AfterViewInit} from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';
import { PersonService} from '../person.service';
import { UtilsService} from '../../shared/utils.service';
import { ActivatedRoute, Router} from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';

@Component({
  selector: 'app-descendant-chart',
  templateUrl: './descendant-chart.component.html',
  styleUrls: ['./descendant-chart.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DescendantChartComponent implements OnInit, OnChanges {
    activeId: string;
    activeGender: string;
    activePersonName: string;

    private w;
    private h;
    private nodeSize: Array<any>;
    private opts: any;
    private svg: any;
    private root: any;
    private allNodes: any;
    private siblings: any;
    private routeSubscription: Subscription;
    private nameOfPersonSubscription: Subscription;
    private minNodeHeight;
    private nodeWidth;

    constructor(private utilsService: UtilsService,
                private router: Router,
                private route: ActivatedRoute,
                private personService: PersonService) { }

    ngOnInit() {
        console.log('INIT Descendant chart.');
        this.routeSubscription = this.route.queryParams.subscribe(params => {
            this.activeId = params['id'];
            this.activeGender = params['g'];
        });

        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe(activePersonName => {
            this.activePersonName = activePersonName;
        });

        this.w = 800;
        this.h = 1000;
        this.minNodeHeight = 225;  // was 100
        this.nodeWidth = 175;
    }

    onDestroy() {
        this.routeSubscription.unsubscribe();
        this.nameOfPersonSubscription.unsubscribe();
    }

    drawDescendants() {
        console.log('Drawing Descendants Chart ');

        d3.select('#dgraph').select('svg').remove();

        this.personService.getDescendants(this.activeId).toPromise().then(descendantData => {

            function _textRenderer(name, extra, textClass) { console.log('Calling _textRenderer'); return ''; }

            const rtr = this.router;
            const minNodeHeight = this.minNodeHeight;

            this.opts = _.defaultsDeep({}, {
                target: '#dgraph',
                width: this.w,
                height: this.h,
                margin: {top: 0, right: 0, bottom: 0, left: 0},
                nodeWidth: this.nodeWidth,
                nodeHeight: this.minNodeHeight,
                callbacks: {
                    nodeClick: function (name, extra, id) {
                        rtr.navigate(['/person'], {queryParams: { id: extra.id, g: extra.gender }});
                    },
                    nodeRenderer: function (name, x, y, height, width, extra, id, nodeClass, textClass) { },
                    nodeSize: function (nodes, width) {
                        const maxHeight = minNodeHeight; // 100
                        _.map(nodes, function (n: any) {
                            n.cHeight = maxHeight;
                            if (n.data.hidden) {
                                n.cWidth = 0;
                            } else {
                                n.cWidth = width;
                            }
                        });
                        return [width, maxHeight];
                    },
                    textRenderer: function (name, extra, textClass) {
                        console.log('Calling textRenderer');
                        // return _textRenderer(name, extra, textClass);
                        return '';
                    }
                },
                styles: {}
            });

            const data = this._preprocess(descendantData, this.opts);

            this.root = data.root;
            this.siblings = data.sibs;
            this.constructTree(data.root);
            this.create();

            // and now the title
            this.svg.append('text')
                .attr('x', 0)
                .attr('y', 200)
                .attr('text-anchor', 'middle')
                .attr('font-family', 'FontAwesome')
                .style('font-size', '22px')
                .style('font-weight', 'bold')
                .style('text-decoration', 'none')
                .text('Descendants Chart for ' + this.activePersonName);
        });
    }

    _preprocess(data, options) {
        const sibs = [];
        let id = 0;
        const opts = options;
        const root = {name: '', id: id++, hidden: true, children: []};

        function reconstructTree(person, parent) {
            // convert to person to d3 node
            const node = {
                name: person.fullName,
                id: id++,
                hidden: false,
                children: [],
                noParent: false,
                isSpouse: false,
                extra: person.extra
            };

            // hide linages to the hidden root node
            if (parent === root) {
                node.noParent = true;
            }

            // apply depth offset
            for (let i = 0; i < person.depthOffset; i++) {
                const pushNode = {
                    name: '',
                    id: id++,
                    hidden: true,
                    children: [],
                    noParent: node.noParent,
                    isSpouse: false
                };

                parent.children.push(pushNode);
                parent = pushNode;
            }

            // sort children
            if (person.children !== undefined) {
                // add 'direct' children
                let pindex;
                for (pindex = 0; pindex < person.children.length; ++pindex) {
                    reconstructTree(person.children[pindex], node);
                }
            }

            parent.children.push(node);

            // sort marriages
            if (person.marriages !== undefined) {
                // go through marriage
                let mindex = 0;
                for (mindex = 0; mindex < person.marriages.length; ++mindex) {
                    const marriage = person.marriages[mindex];

                    const m = {
                        name: '',
                        id: id++,
                        hidden: true,
                        noParent: true,
                        isSpouse: false,
                        children: [],
                        extra: marriage.extra
                    };

                    const sp = marriage.spouse;

                    const spouse = {
                        name: sp.fullName,
                        id: id++,
                        hidden: false,
                        noParent: true,
                        isSpouse: true,
                        children: [],
                        extra: sp.extra,
                        marriageNode: m
                    };

                    parent.children.push(m, spouse);

                    if (marriage.children !== undefined && Array.isArray(marriage.children)) {
                        let pmindex = 0;
                        for (pmindex = 0; pmindex < marriage.children.length; ++pmindex) {
                            reconstructTree(marriage.children[pmindex], m);
                        }
                    }

                    sibs.push({
                        source: {
                            id: node.id
                        },
                        target: {
                            id: spouse.id
                        },
                        number: mindex
                    });
                }
            }
        }

        let index;
        const dlength = data.length;
        for (index = 0; index < dlength; ++index) {
            reconstructTree(data[index], root);
        }

        const hroot = (<any>d3).hierarchy(root);
        return {root: hroot, sibs: sibs};
    }

    ngOnChanges() {
        console.log(' And we have loaded the component for Descendants chart again');
    }

    constructTree(croot) {
        // flatten nodes
        this.allNodes = this._flatten(croot);

        // Calculate node size
        const visibleNodes = _.filter(this.allNodes, function (n) {
            return !n.hidden;
        });

        this.nodeSize = this.opts.callbacks.nodeSize(visibleNodes, this.opts.nodeWidth);
    }

    // Tree building functions
    create() {
        const utils = this.utilsService;
        const options = this.opts;
        const nSize = this.nodeSize;
        const width = options.width + options.margin.left + options.margin.right;
        const height = options.height + options.margin.top + options.margin.bottom;
        const viewBoxValues = '0 0 ' + width + ' ' + height;

        const zoom = (<any>d3).zoom()
            .scaleExtent([0.1, 10])
            .on('zoom', function () {
                svg.attr('transform', d3.event.transform.translate(width / 2, options.margin.top));
            });

        (<any>d3).select('#zoom_in').on('click', function () {
            // Smooth zooming
            zoom.scaleBy(svg.transition().duration(750), 1.3);
        });

        (<any>d3).select('#zoom_out').on('click', function () {
            // Ordinal zooming
            zoom.scaleBy(svg.transition().duration(750), 1 / 1.3);
        });

        (<any>d3).select('#reset').on('click', function () {
            // Smooth zooming
            svg.transition().duration(750).call(zoom.transform, d3.zoomIdentity);
        });

        // make an SVG

        const svg = this.svg = d3.select(options.target)
            .append('svg')
            .attr('xmlns', 'http://www.w3.org/2000/svg')
            .attr('width', width)
            .attr('height', height)
            .attr('id', 'svgelement')
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .attr('viewBox', viewBoxValues)
            .classed('svg-content', true)
            .call(zoom)
            .append('g')
            .attr('transform', 'translate(' + width / 2 + ',' + options.margin.top + ')');

        // Compute the layout.
        const spaceBetweenNodesHorz = 2; // eg. Husband and Wife joining line
        const spaceBetweenNodesVert = 2;  // eg. Line joining Parents to Children

        const tree = (<any>d3).tree().nodeSize([nSize[0] * spaceBetweenNodesHorz, nSize[1] * spaceBetweenNodesVert]);
        tree.separation(function separation(a: any, b: any) {
            if (a.data.hidden || b.data.hidden) { return 0.3; } else { return 0.6; }
        });

        const rtree = tree(this.root);
        const hnodes = rtree.descendants();
        const hlinks = rtree.links();

        function _elbow(d, i) {
            if (d.target.data.noParent) { return 'M0,0L0,0'; }

            // this is the height of the vertical lines extending UP from the boxes
            // connecting to the horizontal line that connects siblings
            const ny = d.target.y + .5 * (d.source.y - d.target.y);  // .50, .75

            const linedata = [
                { x: d.target.x,
                    y: d.target.y},
                { x: d.target.x,
                    y: ny },
                { x: d.source.x,
                    y: d.source.y}
            ];

            const fun = (<any>d3).line().curve((<any>d3).curveStepAfter)
                .x(function (v) { return v.x; })
                .y(function (v) { return v.y; });
            return fun(linedata);
        }

        // Create the link lines.
        this.svg.selectAll('.link')
            .data(hlinks)
            .enter()
            .filter(function (l: any) {
                // filter links with no parents to prevent empty nodes
                return !l.target.data.noParent;
            })
            .append('path')
            .attr('stroke', 'black')
            .attr('fill', 'none')
            .attr('d', _elbow);

        this._linkSiblings();

        // Draw siblings (marriage)
        this.svg.selectAll('.sibling')
            .data(this.siblings)
            .enter()
            .append('path')
            .attr('stroke', 'black')
            .attr('fill', 'none')
            .attr('d', _.bind(this._siblingLine, this));

        const tnodes = this.svg.selectAll('.node').data(hnodes).enter();

        // gender coloured name box
        tnodes.append('rect')
            .filter(function (d) { return !d.data.hidden; })
            .attr('x', function (d) { return d.x - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y - d.cHeight / 2 + 'px'; })
            .attr('width', function (d) { return d.cWidth + 'px'; })
            .attr('height', function (d) { return d.cHeight + 'px'; })
            .attr('id', function (d) { return d.data.id; })
            .attr('stroke', function (d) {
                if (d.data.extra.gender === 'fem') { return '#e8ced9'; } else { return '#d0e1ed'; }
            })
            .attr('fill', function (d) {
                if (d.data.extra.gender === 'fem') { return '#e8ced9'; } else { return '#d0e1ed'; }
            });

        // full name
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('font-family', 'FontAwesome')
            .on('click', function(d) { if (d3.event.defaultPrevented) { return; } })
            .style('font-size', '12px')
            .style('font-weight', 'bold')
            .attr('x', function (d) { return d.x + 'px'; })
            .attr('y', function (d) { return d.y + 20 - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .attr('fill', 'black')
            .attr('text-anchor', 'middle')
            .text(function (d) {
                return d.data.name;
            });

        const increaseToNextLine = 15;
        let startAt = 50;

        // birthInfo Date
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine1')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                let birthInfo = 'B: ';
                if (d.data.extra && d.data.extra.birthDate) {
                    birthInfo = birthInfo + d.data.extra.birthDate;
                }
                return birthInfo;
            });

        // birthInfo Place 1
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine1')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                const birthInfo = 'P: ';
                if (d.data.extra && d.data.extra.birthLocation) {
                    const stringLocations = utils.splitTextToLines(birthInfo + d.data.extra.birthLocation);
                    return stringLocations[0];
                }
                return birthInfo;
            });

        // birthInfo Place 2
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine1')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                const birthInfo = 'P: ';
                if (d.data.extra && d.data.extra.birthLocation) {
                    const stringLocations = utils.splitTextToLines(birthInfo + d.data.extra.birthLocation);
                    return stringLocations[1];
                }
                return '';
            });

        // birthInfo Place 3
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine1')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                const birthInfo = 'P: ';
                if (d.data.extra && d.data.extra.birthLocation) {
                    const stringLocations = utils.splitTextToLines(birthInfo + d.data.extra.birthLocation);
                    return stringLocations[2];
                }
                return '';
            });

        // deathInfo OR marriageInfo dates
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine2')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                let info = 'D: ';
                if (d.data.isSpouse) {
                    info = 'M: ';
                }
                if (d.data.isSpouse && d.data.extra && d.data.extra.marriageDate) {
                    return info + d.data.extra.marriageDate;
                } else if (!d.data.isSpouse && d.data.extra && d.data.extra.deathDate) {
                    return info + d.data.extra.deathDate;
                }
                return info;
            });

        // deathInfo OR marriageInfo 1
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine2')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                const info = 'P: ';
                if (d.data.isSpouse && d.data.extra && d.data.extra.marriageLocation) {
                    const stringLocations = utils.splitTextToLines(info + d.data.extra.marriageLocation);
                    return stringLocations[0];
                } else if (!d.data.isSpouse && d.data.extra && d.data.extra.deathLocation) {
                    const stringLocations = utils.splitTextToLines(info + d.data.extra.deathLocation);
                    return stringLocations[0];
                }
                return info;
            });

        // deathInfo OR marriageInfo 2
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine2')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                const info = 'P: ';
                if (d.data.isSpouse && d.data.extra && d.data.extra.marriageLocation) {
                    const stringLocations = utils.splitTextToLines(info + d.data.extra.marriageLocation);
                    return stringLocations[1];
                } else if (!d.data.isSpouse && d.data.extra && d.data.extra.deathLocation) {
                    const stringLocations = utils.splitTextToLines(info + d.data.extra.deathLocation);
                    return stringLocations[1];
                }
                return '';
            });

        // deathInfo OR marriageInfo 3
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine2')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                const info = 'P: ';
                if (d.data.isSpouse && d.data.extra && d.data.extra.marriageLocation) {
                    const stringLocations = utils.splitTextToLines(info + d.data.extra.marriageLocation);
                    return stringLocations[2];
                } else if (!d.data.isSpouse && d.data.extra && d.data.extra.deathLocation) {
                    const stringLocations = utils.splitTextToLines(info + d.data.extra.deathLocation);
                    return stringLocations[2];
                }
                return '';
            });

        // deathInfo date
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine3')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                const info = 'D: ';
                if (d.data.isSpouse && d.data.extra && d.data.extra.deathDate) {
                    return info + d.data.extra.deathDate;
                } else if (d.data.isSpouse) {
                    return info;
                }
                return '';
            });

        // deathInfo 1
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine3')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                const info = 'P: ';
                if (d.data.isSpouse && d.data.extra && d.data.extra.deathLocation) {
                    const stringLocations = utils.splitTextToLines(info + d.data.extra.deathLocation);
                    return stringLocations[0];
                } else if (d.data.isSpouse) {
                    return info;
                }
                return '';
            });

        // deathInfo 2
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine3')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                const info = 'P: ';
                if (d.data.isSpouse && d.data.extra && d.data.extra.deathLocation) {
                    const stringLocations = utils.splitTextToLines(info + d.data.extra.deathLocation);
                    return stringLocations[1];
                }
                return '';
            });

        // deathInfo 3
        startAt = startAt + increaseToNextLine;
        tnodes.filter(function (d) { return !d.data.hidden; })
            .append('text')
            .attr('class', 'infoLine3')
            .attr('x', function (d) { return d.x + 10 - d.cWidth / 2 + 'px'; })
            .attr('y', function (d) { return d.y + startAt - d.cHeight / 2 + 'px'; })
            .attr('dy', function (d) { return 0 + 'px'; })
            .text(function (d) {
                const info = 'P: ';
                if (d.data.isSpouse && d.data.extra && d.data.extra.deathLocation) {
                    const stringLocations = utils.splitTextToLines(info + d.data.extra.deathLocation);
                    return stringLocations[2];
                }
                return '';
            });
    }

    _flatten(root) {
        const n = [];
        let i = 0;

        function recurse(node) {
            if (node.children !== undefined) { // && Array.isArray(node.children)
                for (let c = 0, len = node.children.length; c < len; c++) {
                    recurse(node.children[c]);
                }
            }
            if (node.data.id === undefined) { node.data.id = ++i; }
            n.push(node);
        }

        recurse(root);
        return n;
    }

    _linkSiblings() {
        const e = this.allNodes;
        _.forEach(this.siblings, function (t) {
            // find the node in ALLNODES that is this sibling...
            const n = e.filter(function (v) { return t.source.id === v.data.id; });
            const r = e.filter(function (v) { return t.target.id === v.data.id; });

            t.source.x = n[0].x;
            t.source.y = n[0].y;
            t.target.x = r[0].x;
            t.target.y = r[0].y;

            let a = null;
            if (n[0].data.marriageNode !== undefined && n[0].data.marriageNode !== null) {
                a = n[0].data.marriageNode.id;
            } else if (r[0].data.marriageNode !== undefined && r[0].data.marriageNode !== null) {
                a = r[0].data.marriageNode.id;
            }

            if (a !== undefined && a !== null) {
                const i = e.find(function (v) { return v.data.id === a; });
                t.source.marriageNode = i;
                t.target.marriageNode = i;
            }
        });
    }

    _siblingLine(d, i) {
        let ny = d.target.y + (d.source.y - d.target.y);  // this is fine as is
        const nodeWidth = this.nodeSize[0];
        const nodeHeight = this.nodeSize[1];

        // Not first marriage
        if (d.number > 0) {
            // this is the vertical height of the line to connect to second+ marriages
            ny -= nodeHeight * .75;  // multiple spouse node
        }

        const linedata = [
            {x: d.source.x, y: d.source.y},
            {x: d.source.x + nodeWidth, y: d.source.y},
            {x: d.source.x + nodeWidth * .6, y: ny},
            {x: d.target.marriageNode.x, y: ny},
            {x: d.target.marriageNode.x, y: d.target.y},
            {x: d.target.x, y: d.target.y}
        ];

        const cur = (<any>d3).line()
            .curve((<any>d3).curveStepAfter)
            .x(function (v) { return v.x; })
            .y(function (v) { return v.y; });

        return cur(linedata);
    }

}

