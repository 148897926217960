import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationlistComponent } from './list/locationlist.component';
import { LocationService} from './location.service';
import { LocationlistResolver} from './list/locationlist-resolver.service';
import { LocationComponent} from './location.component';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { MapComponent} from './map.component';
import { AgmCoreModule} from '@agm/core';
import {RouterModule} from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyApOsTq4qTfBZ5yLiJ1VSYNJkR1jtl9bVo'
        }),
        RouterModule
    ],
    declarations: [
        LocationlistComponent,
        LocationComponent,
        MapComponent
    ],
    providers: [
        LocationService,
        LocationlistResolver
    ]

})
export class LocationModule { }
