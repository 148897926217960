import { ApplicationRef, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormArray, Validators, FormControl, FormBuilder} from '@angular/forms';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { PersonService} from '../person.service';
import { LifeeventService} from '../lifeevent/lifeevent.service';
import { LocationService} from '../../location/location.service';
import { UtilsService} from '../../shared/utils.service';
import { AutocompleteService} from '../../shared/autocomplete.service';
// tslint:disable-next-line:import-blacklist
import { Subscription } from 'rxjs';
import { IPersonSave } from '../../models/ipersonsave';
import { ILocationSearchResults } from '../../models/ilocationsearchresults';
import { Location } from '../../location/location';
import { ILocationSave } from '../../models/ilocationsave';
import { IRelationship } from '../../models/irelationship';
import { Relationship} from './relationship';
import { Person} from '../person';
import { IRelationshipType} from '../../models/irelationshiptype';
import { MarriageIncorrect} from './marriageincorrect';

@Component({
  moduleId: module.id,
  selector: 'app-spouse',
  templateUrl: './spouse.component.html',
  styleUrls: ['./spouse.component.scss']
})

export class SpouseComponent implements OnInit {
    activeId: string;
    activeGender: string;
    activePersonName: string;
    activePersonBirthMask: string;
    activePersonDeathMask: string;
    activePersonBurialMask: string;

    days: Array<any>;
    months: Array<any>;
    countries: Array<any>;
    childrenTypes: Array<any>;
    genders: Array<any>;
    familyForm: FormGroup;
    closeResult: string;
    filteredSpouses: Array<Person[]>;
    filteredLocations: ILocationSearchResults[];
    filteredChildren: Array<Person[]>;

    showNewLocationDescriptionHelp = false;
    showNewLocationCountryHelp = false;
    saveNewLocationDisabled: boolean;

    isValidActiveId = false;
    areFamiliesValid = false;

    showForm = false;

    private routeSubscription: Subscription;
    private nameOfPersonSubscription: Subscription;

    constructor(private utilsService: UtilsService,
                private locationService: LocationService,
                private autocompleteService: AutocompleteService,
                private personService: PersonService,
                private lifeeventService: LifeeventService,
                private modalService: NgbModal,
                private router: Router,
                private route: ActivatedRoute,
                private appRef: ApplicationRef,
                private changeDetector: ChangeDetectorRef,
                private fb: FormBuilder) {
    }

    ngOnInit() {
        this.days = this.utilsService.getDays();
        this.months = this.utilsService.getMonths();
        this.childrenTypes = this.utilsService.getChildRelationshipTypes();
        this.genders = this.utilsService.getGenders();
        this.countries = this.utilsService.getCountries();
        this.filteredSpouses = [];
        this.filteredLocations = [];
        this.filteredChildren = [];
        this.familyForm = this.fb.group({
            families: this.fb.array([])
        });

        document.addEventListener('click', () => this.closeList());

        this.routeSubscription = this.route.queryParams.subscribe(params => {
            this.activeId = params['id'];
            this.activeGender = params['g'];
            this.setFamilies();
            if (this.activeId !== undefined && this.activeId !== null && this.activeId !== '') {
                this.isValidActiveId = true;
            }
        });

        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe( activePersonName => {
            this.activePersonName = activePersonName;
        });
    }

    cancelFamily(id: string) {
        const saveButton = <HTMLButtonElement> document.getElementById('saveButton');
        saveButton.disabled = true;
        const cancelButton = <HTMLButtonElement> document.getElementById('cancelButton');
        cancelButton.disabled = true;
        this.showForm = false;
    }

    onDestroy() {
        this.routeSubscription.unsubscribe();
        this.nameOfPersonSubscription.unsubscribe();
    }

    closeList() {
        this.filteredSpouses = [];
        this.filteredLocations = [];
        this.filteredChildren = [];
    }

    clear() {
        this.familyForm.reset();
        this.familyForm = this.fb.group({
            families: this.fb.array([])
        });
    }

    get families() {
        return this.familyForm.get('families') as FormArray;
    }

    setFamilies() {
        this.clear();
        const utils = this.utilsService;
        const locService = this.locationService;
        const setFilterLocs = this.setFilteredLocations;

        if (this.activeId !== undefined && this.activeId !== null && this.activeId !== '') {
            this.personService.getSpouses(+this.activeId, this.activeGender).toPromise().then(responseList => {
                if (responseList !== null && responseList !== undefined && (<IRelationship[]>responseList).length === 0) {
                    const families = this.familyForm.get('families') as FormArray;
                    const family = new FormGroup({
                        spouse: new FormControl(''),
                        originalSpouseId: new FormControl(''),
                        spouseId: new FormControl(''),
                        spouseRelationshipId: new FormControl(''),
                        spouseGender: new FormControl(''),
                        isSpouseValid: new FormControl(''),
                        spouseBirthMask: new FormControl(''),
                        spouseDeathMask: new FormControl(''),
                        personGender: new FormControl(''),
                        marriageId: new FormControl(''),
                        marriageDate: new FormControl(''),
                        marriageDay: new FormControl(''),
                        marriageMonth: new FormControl(''),
                        marriageYear: new FormControl('', Validators.maxLength(4)),
                        isMarriageValid: new FormControl(''),
                        isMarriageSpouseValid: new FormControl(''),
                        marriageLocation: new FormControl(''),
                        marriageLocationId: new FormControl(''),
                        marriageExternalId: new FormControl(''),
                        marriageLat: new FormControl(''),
                        marriageLon: new FormControl(''),
                        marriageCountry: new FormControl(''),
                        newSpouseFirstName: new FormControl(''),
                        newSpouseLastName: new FormControl(''),
                        newSpouseGender: new FormControl(''),
                        showSpouseHelp: new FormControl(''),
                        newLocationDescription: new FormControl(''),
                        newLocationCountry: new FormControl(''),
                        newLocationType: new FormControl(''),
                        newChildFirstName: new FormControl(''),
                        newChildLastName: new FormControl(''),
                        newChildGender: new FormControl(''),
                        children: new FormArray([])
                    }, {
                        updateOn: 'blur',
                        validators: [
                            MarriageIncorrect(utils)
                        ]
                    });
                    family.get('spouseBirthMask').setValue('00000000');
                    family.get('spouseDeathMask').setValue('00000000');
                    families.push(family);
                } else {
                    let fCount = 0;
                    const families = this.familyForm.get('families') as FormArray;
                    const util = this.utilsService;
                    (<Relationship[]>responseList).forEach(function (f) {
                        const family = new FormGroup({
                            spouse: new FormControl(''),
                            originalSpouseId: new FormControl(''),
                            spouseId: new FormControl(''),
                            spouseRelationshipId: new FormControl(''),
                            spouseGender: new FormControl(''),
                            isSpouseValid: new FormControl(''),
                            spouseBirthMask: new FormControl(''),
                            spouseDeathMask: new FormControl(''),
                            personGender: new FormControl(''),
                            marriageId: new FormControl(''),
                            marriageDate: new FormControl(''),
                            marriageDay: new FormControl(''),
                            marriageMonth: new FormControl(''),
                            marriageYear: new FormControl('', Validators.maxLength(4)),
                            isMarriageValid: new FormControl(''),
                            isMarriageSpouseValid: new FormControl(''),
                            marriageLocation: new FormControl(''),
                            marriageLocationId: new FormControl(''),
                            marriageExternalId: new FormControl(''),
                            marriageLat: new FormControl(''),
                            marriageLon: new FormControl(''),
                            marriageCountry: new FormControl(''),
                            newSpouseFirstName: new FormControl(''),
                            newSpouseLastName: new FormControl(''),
                            newSpouseGender: new FormControl(''),
                            showSpouseHelp: new FormControl(''),
                            newLocationDescription: new FormControl(''),
                            newLocationCountry: new FormControl(''),
                            newLocationType: new FormControl(''),
                            newChildFirstName: new FormControl(''),
                            newChildLastName: new FormControl(''),
                            newChildGender: new FormControl(''),
                            children: new FormArray([])
                        }, {
                            updateOn: 'blur',
                            validators: [
                                MarriageIncorrect(utils)
                            ]
                        });
                        families.push(family);
                        family.get('marriageLocation').valueChanges.toPromise().then(value => {
                            if (family.get('marriageLocation').dirty || family.get('marriageLocation').touched) {
                                locService.searchLocation(value).toPromise().then(locList => {
                                    setFilterLocs(locList.searchResults);
                                });
                            }
                        });

                        const isSpouse = (f !== undefined) && (f !== null);
                        const isMarriage = isSpouse && (f.marriage !== undefined && f.marriage !== null);
                        const isMarriageLocation = (isSpouse && isMarriage && (f.marriage.location !== undefined
                            && f.marriage.location !== null));

                        if (isSpouse && f.id !== null) {
                            family.get('spouseId').setValue(f.relationPersonId);
                            family.get('spouseRelationshipId').setValue(f.id);
                            const displayName = utils.getDisplayNameWithDates(f);
                            family.get('spouse').setValue(displayName);
                            family.get('spouseGender').setValue(f.relationGender);
                            family.get('personGender').setValue(f.personGender);

                            const spouseBirthMask = utils.dateMask(
                                 f.birthDay, f.birthMonth, '' + f.birthYear);

                            const spouseDeathMask = utils.dateMask(
                                f.deathDay, f.deathMonth, '' + f.deathYear);

                            family.get('spouseBirthMask').setValue(spouseBirthMask);
                            family.get('spouseDeathMask').setValue(spouseDeathMask);

                            if (isMarriage) {
                                family.get('marriageId').setValue(f.marriage.id);
                                family.get('marriageDate').setValue(utils.getDisplayDateBeforeLocation(f.marriage));
                                family.get('marriageDay').setValue(utils.getDay(f.marriage));
                                family.get('marriageMonth').setValue(utils.getMonth(f.marriage));
                                family.get('marriageYear').setValue(utils.getYear(f.marriage));
                            } else {
                                family.get('marriageId').setValue('');
                                family.get('marriageDate').setValue('');
                                family.get('marriageDay').setValue('');
                                family.get('marriageMonth').setValue('');
                                family.get('marriageYear').setValue('');
                            }

                            if (isMarriageLocation) {
                                family.get('marriageLocationId').setValue(f.marriage.location.id);
                                family.get('marriageLocation').setValue(f.marriage.location.description);
                                family.get('marriageExternalId').setValue(f.marriage.location.externalId);
                                family.get('marriageLat').setValue(f.marriage.location.latitude);
                                family.get('marriageLon').setValue(f.marriage.location.longitude);
                                family.get('marriageCountry').setValue(f.marriage.location.country);
                            } else {
                                family.get('marriageLocationId').setValue('');
                                family.get('marriageLocation').setValue('');
                                family.get('marriageExternalId').setValue('');
                                family.get('marriageLat').setValue('');
                                family.get('marriageLon').setValue('');
                                family.get('marriageCountry').setValue('');
                            }
                            family.get('originalSpouseId').setValue(f.relationPersonId);
                        }
                        family.get('newSpouseFirstName').setValue('');
                        family.get('newSpouseLastName').setValue('');
                        family.get('newSpouseGender').setValue('');
                        family.get('showSpouseHelp').setValue(false);
                        family.get('newChildFirstName').setValue('');
                        family.get('newChildLastName').setValue('');
                        family.get('newChildGender').setValue('');

                        if ((f.children !== null) && (f.children !== undefined) && (f.children.length > 0)) {
                            const childrenArray = family.get('children') as FormArray;
                            f.children.forEach(c => {
                                const tmpChild = <Person>c;
                                const childFormGroup = new FormGroup({
                                    isChildNew: new FormControl(''),
                                    child: new FormControl(''),
                                    childId: new FormControl(''),
                                    childGender: new FormControl(''),
                                    childGenderLabel: new FormControl(''),
                                    isChildValid: new FormControl(''),
                                    childRelationshipTypePerson: new FormControl(''),
                                    childRelationshipTypeSpouse: new FormControl(''),
                                    isChildRelationshipFoundPerson: new FormControl(''),
                                    isChildRelationshipFoundSpouse: new FormControl('')
                                });
                                childrenArray.push(childFormGroup);
                                const displayChildName = utils.generateName(tmpChild);
                                childFormGroup.get('child').setValue(displayChildName);
                                childFormGroup.get('childId').setValue(tmpChild.id);
                                childFormGroup.get('childGenderLabel').setValue(utils.getGenderLabel(tmpChild.gender));
                                childFormGroup.get('childGender').setValue(tmpChild.gender);
                                childFormGroup.get('isChildNew').setValue(false);
                                childFormGroup.get('isChildValid').setValue(true);
                                childFormGroup.get('childRelationshipTypePerson').setValue('');
                                childFormGroup.get('childRelationshipTypeSpouse').setValue('');
                                childFormGroup.get('isChildRelationshipFoundPerson').setValue(false);
                                childFormGroup.get('isChildRelationshipFoundSpouse').setValue(false);
                            });
                        }
                        fCount = fCount + 1;
                    });
                }
                this.changeDetector.detectChanges();
            });
        }
    }

    setFilteredLocations(filteredLocations: ILocationSearchResults[]) {
        this.filteredLocations = filteredLocations;
    }

    autocompleteSpouse(value, index) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[index] as FormGroup;

        if (family.get('spouse').dirty || family.get('spouse').touched) {
            if (value !== null && value !== '') {
                if (this.activeGender === 'fem') {
                    this.personService.getMales(value, this.activeId).toPromise().then(perList => {
                        this.filteredSpouses[index] = <Person[]>perList.items;
                    });
                } else {
                    this.personService.getFemales(value, this.activeId).toPromise().then(perList => {
                        this.filteredSpouses[index] = <Person[]>perList.items;
                    });

                }
            }
        }
    }

    autocompleteMarriageLocation(value, index, cindex) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[index] as FormGroup;

        if (family.get('marriageLocation').dirty || family.get('marriageLocation').touched) {
            this.locationService.searchLocation(value).toPromise().then(locList => {
                this.filteredLocations = <ILocationSearchResults[]>locList.searchResults;
            });
        }
    }

    autocompleteChild(value, index, cindex) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[index] as FormGroup;
        const children = family.get('children') as FormArray;
        const child = children.controls[cindex];

        if (child.get('child').dirty || child.get('child').touched) {
            this.personService.getAnyPerson(this.activeId, value).toPromise().then(perList => {
                this.filteredChildren[cindex] = <Person[]>perList.items;
            });
        }
    }

    addNewFamilyButton() {
        const families = this.familyForm.get('families') as FormArray;
        const family = new FormGroup({
            spouse: new FormControl(''),
            originalSpouseId: new FormControl(''),
            spouseId: new FormControl(''),
            spouseRelationshipId: new FormControl(''),
            spouseGender: new FormControl(''),
            isSpouseValid: new FormControl(''),
            personGender: new FormControl(''),
            marriageId: new FormControl(''),
            marriageDate: new FormControl(''),
            marriageDay: new FormControl(''),
            marriageMonth: new FormControl(''),
            marriageYear: new FormControl('', Validators.maxLength(4)),
            isMarriageValid: new FormControl(''),
            isMarriageSpouseValid: new FormControl(''),
            marriageLocation: new FormControl(''),
            marriageLocationId: new FormControl(''),
            marriageExternalId: new FormControl(''),
            marriageLat: new FormControl(''),
            marriageLon: new FormControl(''),
            marriageCountry: new FormControl(''),
            newSpouseFirstName: new FormControl(''),
            newSpouseLastName: new FormControl(''),
            newSpouseGender: new FormControl(''),
            showSpouseHelp: new FormControl(''),
            newLocationDescription: new FormControl(''),
            newLocationCountry: new FormControl(''),
            newLocationType: new FormControl(''),
            newChildFirstName: new FormControl(''),
            newChildLastName: new FormControl(''),
            newChildGender: new FormControl(''),
            children: new FormArray([])
        }, {
            updateOn: 'blur',
            validators: [
            MarriageIncorrect(this.utilsService)
        ]});
        families.push(family);
        const index = this.families.length - 1;
        this.addNewChildButton(index);
        this.familyForm.updateValueAndValidity();
    }

    addNewChildButton(index: number) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[index] as FormGroup;
        const childrenArray = family.get('children') as FormArray;
        const child = new FormGroup({
            isChildNew: new FormControl(''),
            child: new FormControl(''),
            childId: new FormControl(''),
            childGender: new FormControl(''),
            childGenderLabel: new FormControl(''),
            childBirthDate: new FormControl(''),
            childBirthDay: new FormControl(''),
            childBirthMonth: new FormControl(''),
            childBirthYear: new FormControl('', Validators.maxLength(4)),
            childBirthLocation: new FormControl(''),
            childBirthLocationId: new FormControl(''),
            childDeathDate: new FormControl(''),
            childDeathDay: new FormControl(''),
            childDeathMonth: new FormControl(''),
            childDeathYear: new FormControl(''),
            childDeathLocation: new FormControl(''),
            childDeathLocationId: new FormControl(''),
            isChildValid: new FormControl(''),
            childRelationshipTypePerson: new FormControl(''),
            childRelationshipTypeSpouse: new FormControl(''),
            isChildRelationshipFoundPerson: new FormControl(''),
            isChildRelationshipFoundSpouse: new FormControl('')
        });
        child.get('isChildValid').setValue(true);
        child.get('isChildNew').setValue(true);
        child.get('childRelationshipTypePerson').setValue('');
        child.get('childRelationshipTypeSpouse').setValue('');
        child.get('isChildRelationshipFoundPerson').setValue(false);
        child.get('isChildRelationshipFoundSpouse').setValue(false);
        childrenArray.push(child);
    }

    setSelectedSpouse(findex: number, tmpSpouse: Person) {
        this.filteredSpouses = [];
        const name = this.utilsService.generateName(tmpSpouse);
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        family.get('spouse').setValue(name, {onlySelf: true, emitEvent: false});
        family.get('spouseId').setValue(tmpSpouse.id);

        const spouseBirthMask = this.utilsService.dateMask(
            tmpSpouse.birth.startDateDay,
            tmpSpouse.birth.startDateMonth,
            '' + tmpSpouse.birth.startDateYear);

        const spouseDeathMask = this.utilsService.dateMask(
            tmpSpouse.death.startDateDay,
            tmpSpouse.death.startDateMonth,
            '' + tmpSpouse.death.startDateYear);

        family.get('spouseBirthMask').setValue(spouseBirthMask);
        family.get('spouseDeathMask').setValue(spouseDeathMask);

        const marriageDateMask = this.utilsService.dateMask(
            family.get('marriageDay').value,
            family.get('marriageMonth').value,
            family.get('marriageYear').value);

        this.validateFamily(findex, spouseBirthMask, spouseDeathMask, marriageDateMask);
    }

    showPotentialSpouse(tmpSpouse: Person): string {
        return this.utilsService.generateName(tmpSpouse);
    }

    setSelectedMarriageLocation(findex: number, location: string, id: string,
                        locationId: string, externalId: string,
                        lat: number, lon: number, country: string) {
        this.filteredLocations = [];
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        family.get('marriageLocation').setValue(location, {onlySelf: true, emitEvent: false});
        family.get('marriageLocationId').setValue(locationId);
        family.get('marriageExternalId').setValue(externalId);
        family.get('marriageLat').setValue(lat);
        family.get('marriageLon').setValue(lon);
        family.get('marriageCountry').setValue(country);
        const marriageDateMask = this.utilsService.dateMask(
            family.get('marriageDay').value,
            family.get('marriageMonth').value,
            family.get('marriageYear').value);
        this.validateFamily(findex, family.get('spouseBirthMask').value, family.get('spouseDeathMask').value, marriageDateMask);
    }

    setSelectedChild(findex: number, cindex: number, tmpChild: Person) {
        this.filteredChildren = [];
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const childrenArray = family.get('children') as FormArray;
        const childFormGroup = childrenArray.controls[cindex] as FormGroup;
        childFormGroup.get('child').setValue(this.utilsService.getDisplayName(tmpChild));
        childFormGroup.get('childId').setValue(tmpChild.id);
        childFormGroup.get('childGenderLabel').setValue(this.utilsService.getGenderLabel(tmpChild.gender));
        childFormGroup.get('childGender').setValue(tmpChild.gender);
        childFormGroup.get('isChildNew').setValue(false);
        childFormGroup.get('isChildValid').setValue(true);
        childFormGroup.get('childRelationshipTypePerson').setValue('');
        childFormGroup.get('childRelationshipTypeSpouse').setValue('');
        childFormGroup.get('isChildRelationshipFoundPerson').setValue(false);
        childFormGroup.get('isChildRelationshipFoundSpouse').setValue(false);

        const childBirthMask = this.utilsService.dateMask(
            tmpChild.birth.startDateDay,
            tmpChild.birth.startDateMonth,
            tmpChild.birth.startDateYear + '');
        childFormGroup.get('childBirthMask').setValue(childBirthMask);

        const childDeathMask = this.utilsService.dateMask(
            tmpChild.death.startDateDay,
            tmpChild.death.startDateMonth,
            tmpChild.death.startDateYear + '');
        childFormGroup.get('childDeathMask').setValue(childDeathMask);
        this.validateChild(family, childFormGroup);

        const marriageDateMask = this.utilsService.dateMask(
            family.get('marriageDay').value,
            family.get('marriageMonth').value,
            family.get('marriageYear').value);
        this.validateFamily(findex, family.get('spouseBirthMask').value, family.get('spouseDeathMask').value, marriageDateMask);
    }

    removeSpouseRelationship(findex: number) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        this.personService.deleteRelationship(family.get('spouseRelationshipId').value, this.activeId)
            .toPromise().then(data => {
            family.get('spouseRelationshipId').setValue('');
            family.get('spouseId').setValue('');
            family.get('spouse').setValue('');
            family.get('spouseGender').setValue('');
            family.get('isSpouseValid').setValue('true');
            family.get('isMarriageSpouseValid').setValue('true');
            family.get('spouseBirthMask').setValue('');
            family.get('spouseDeathMask').setValue('');
            family.get('marriageId').setValue('');
            families.removeAt(findex);
            this.appRef.tick(); // forces a reindex
        });
    }

    removeSpouseButton(findex) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        family.get('spouse').setValue('');
        family.get('spouseId').setValue('');
        family.get('spouseGender').setValue('');
        family.get('isSpouseValid').setValue('true');
        family.get('isMarriageSpouseValid').setValue('true');
        family.get('spouseBirthMask').setValue('');
        family.get('spouseDeathMask').setValue('');

        const marriageDateMask = this.utilsService.dateMask(
            family.get('marriageDay').value,
            family.get('marriageMonth').value,
            family.get('marriageYear').value);

        this.validateFamily(findex, '00000000', '00000000', marriageDateMask);
    }

    removeChildButton(findex, cindex) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const childrenArray = family.get('children') as FormArray;
        const child = childrenArray.controls[cindex];
        child.get('child').setValue('');
        child.get('childId').setValue('');
        child.get('childGender').setValue('');
        child.get('childGenderLabel').setValue('');
        child.get('childBirthDate').setValue('');
        child.get('childDeathDate').setValue('');
        child.get('isChildValid').setValue(true);
        child.get('childRelationshipTypePerson').setValue('');
        child.get('childRelationshipTypeSpouse').setValue('');
        child.get('isChildRelationshipFoundPerson').setValue(false);
        child.get('isChildRelationshipFoundSpouse').setValue(false);

        childrenArray.removeAt(cindex);

        if (childrenArray.length === 0) {
            this.addNewChildButton(findex);
        }
        this.appRef.tick(); // forces a reindex
        this.familyForm.updateValueAndValidity();
        this.changeDetector.detectChanges();
    }

    removeFamilyButton(findex) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        this.personService.removeFamilyButton(families, family, findex, this.activeId);
        this.changeDetector.detectChanges();
    }

    onSpouseChange(findex, $event) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const marriageDateMask = this.utilsService.dateMask(
            family.get('marriageDay').value,
            family.get('marriageMonth').value,
            family.get('marriageYear').value);
        this.validateFamily(findex, family.get('spouseBirthMask').value, family.get('spouseDeathMask').value, marriageDateMask);
    }

    onMarriageLocationChange(findex, $event) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const marriageDateMask = this.utilsService.dateMask(
            family.get('marriageDay').value,
            family.get('marriageMonth').value,
            family.get('marriageYear').value);
        this.validateFamily(findex, family.get('spouseBirthMask').value, family.get('spouseDeathMask').value, marriageDateMask);
    }

    onMarriageDayChange(findex, $event) {
        console.log('Marriage Day Change');
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const newMarriageDay = $event.target.value;
        const marriageDateMask = this.utilsService.dateMask(
            newMarriageDay,
            family.get('marriageMonth').value,
            family.get('marriageYear').value);
        this.validateFamily(findex, family.get('spouseBirthMask').value, family.get('spouseDeathMask').value, marriageDateMask);
    }

    onMarriageMonthChange(findex, $event) {
        console.log('Marriage Month Change');
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const newMarriageMonth = $event.target.value;
        const marriageDateMask = this.utilsService.dateMask(
            family.get('marriageDay').value,
            newMarriageMonth,
            family.get('marriageYear').value);
        this.validateFamily(findex, family.get('spouseBirthMask').value, family.get('spouseDeathMask').value, marriageDateMask);
    }

    onMarriageYearChange(findex, $event) {
        console.log('Marriage Year Change');
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const newMarriageYear = $event.target.value;
        const marriageDateMask = this.utilsService.dateMask(
            family.get('marriageDay').value,
            family.get('marriageMonth').value,
            newMarriageYear);
        this.validateFamily(findex, family.get('spouseBirthMask').value, family.get('spouseDeathMask').value, marriageDateMask);
    }

    onChildChange(findex, cindex, $event) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const marriageDateMask = this.utilsService.dateMask(
            family.get('marriageDay').value,
            family.get('marriageMonth').value,
            family.get('marriageYear').value);
        this.validateFamily(findex, family.get('spouseBirthMask').value, family.get('spouseDeathMask').value, marriageDateMask);
    }

    validateAllFamilies() {
        let areSpousesValid = true;
        let areMarriagesValid = true;
        let areMarriageSpousesValid = true;
        // let areChildrenValid = true;
        const families = this.familyForm.get('families') as FormArray;
        for (const control of families.controls) {
            if (control instanceof FormGroup) {
                const family = <FormGroup>control;
                if (!family.get('isSpouseValid').value) {
                    areSpousesValid = false;
                }
                if (!family.get('isMarriageValid').value) {
                    areMarriagesValid = false;
                }
                if (!family.get('isMarriageSpouseValid').value) {
                    areMarriageSpousesValid = false;
                }
            }
        }
        this.areFamiliesValid = (areSpousesValid && areMarriagesValid && areMarriageSpousesValid);
    }

    validateFamily(findex: number, spouseBirthMask: string, spouseDeathMask: string, marriageDateMask: string) {
        // if active person or spouse died before marriage
        // if active person or spouse was not born before marriage
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;

        let isMarriageGreaterThanPersonBirth = true;
        let isMarriageLessThanPersonDeath = true;
        let isMarriageGreaterThanSpouseBirth = true;
        let isMarriageLessThanSpouseDeath = true;

        // check marriage date against person and spouse births and deaths
        if (parseInt(marriageDateMask, 10) > 9999) {

            if ( (parseInt(this.activePersonBirthMask, 10) > 9999) &&
                (marriageDateMask < this.activePersonBirthMask)) {
                isMarriageGreaterThanPersonBirth = false;
            }

            if ( (parseInt(spouseBirthMask, 10) > 9999) &&
                (marriageDateMask < spouseBirthMask)) {
                isMarriageGreaterThanSpouseBirth = false;
            }

            if ((parseInt(this.activePersonDeathMask, 10) > 9999) &&
                (marriageDateMask > this.activePersonDeathMask)) {
                isMarriageLessThanPersonDeath = false;
            }

            if ((parseInt(spouseDeathMask, 10) > 9999) &&
                (marriageDateMask > spouseDeathMask)) {
                isMarriageLessThanSpouseDeath = false;
            }
        }

        const isMarriageValid = (isMarriageGreaterThanPersonBirth && isMarriageLessThanPersonDeath &&
                               isMarriageGreaterThanSpouseBirth && isMarriageLessThanSpouseDeath);

        if (isMarriageValid) {
            family.get('isMarriageValid').setValue('true');
        } else {
            family.get('isMarriageValid').setValue('false');
        }

        // check spouse birth against person death
        family.get('isSpouseValid').setValue('true');

        if ((parseInt(spouseBirthMask, 10) > 9999) && (parseInt(this.activePersonDeathMask, 10) > 9999)) {
            if (spouseBirthMask > this.activePersonDeathMask) {
                family.get('isSpouseValid').setValue('false');
            }
        }

        // check spouse death against person birth
        if ((parseInt(this.activePersonBirthMask, 10) > 9999) && (parseInt(spouseDeathMask, 10) > 9999)) {
            if (this.activePersonBirthMask > spouseDeathMask) {
                family.get('isSpouseValid').setValue('false');
            }
        }

        // check that marriage date has a spouse
        family.get('isMarriageSpouseValid').setValue('true');

        if (parseInt(marriageDateMask, 10) > 9999) {
            if (family.get('spouseId').value === '' ||
                family.get('spouseId').value === null ||
                family.get('spouseId').value === undefined) {
                family.get('isMarriageSpouseValid').setValue('false');
            } else {
                family.get('isMarriageSpouseValid').setValue('true');
            }
        }

        family.get('spouse').updateValueAndValidity();
        family.get('isSpouseValid').updateValueAndValidity();
        family.get('marriageDay').updateValueAndValidity();
        family.get('marriageMonth').updateValueAndValidity();
        family.get('marriageYear').updateValueAndValidity();
        family.get('isMarriageValid').updateValueAndValidity();
        family.get('isMarriageSpouseValid').updateValueAndValidity();
        this.validateAllFamilies();
    }

    public validateChild(family: FormGroup, child: FormGroup) {
        const childId = child.get('childId').value;
        const childGender = child.get('childGender').value;
        let isValidChild = true;

        const childBirthDateMask = child.get('childBirthMask');
        const dateDiffBirth = (+this.activePersonBirthMask - +childBirthDateMask) / 10000;
        const spouseId = family.get('spouseId').value;
        const spouseGender = family.get('spouseGender').value;
        const spouseBirthMask = family.get('spouseBirthMask').value;
        const dateDiffSBirth = (+spouseBirthMask - +childBirthDateMask) / 10000;

        // yyyymmdd 140000 - parent should be 14 or 12 years older and less than 85 or 60 years older
        if (this.activeGender === 'mal') {
            if (dateDiffBirth < 14 || dateDiffBirth > 85) {
                isValidChild = false;
            }
        } else {
            if (dateDiffBirth < 12 || dateDiffBirth > 60) {
                isValidChild = false;
            }
        }

        if (spouseGender === 'mal') {
            if (dateDiffSBirth < 14 || dateDiffSBirth > 85) {
                isValidChild = false;
            }
        } else {
            if (dateDiffSBirth < 12 || dateDiffSBirth > 60) {
                isValidChild = false;
            }
        }

        // child cannot be a Father, Grandfather, Greatgrandfather etc.
        this.personService.getRelationshipType(+this.activeId, +childId, childGender).toPromise().then(relationships => {
            for (const reltype of relationships) {
                const rType = <IRelationshipType>reltype;
                child.get('childRelationshipTypePerson').setValue(rType.relationType);
                child.get('isChildRelationshipFoundPerson').setValue(!this.utilsService.isEmpty(rType.relationType));

                if (rType.relationType.includes('Father') ||
                    rType.relationType.includes('Grandfather') ||
                    rType.relationType.includes('Mother') ||
                    rType.relationType.includes('Grandmother')) {
                    isValidChild = false;
                }
            }

            if (spouseId !== null && spouseId !== undefined && spouseId !== '') {
                this.personService.getRelationshipType(spouseId, +childId, childGender).toPromise().then(srelationships => {
                    for (const sreltype of srelationships) {
                        const srType = <IRelationshipType>sreltype;
                        child.get('childRelationshipTypeSpouse').setValue(srType.relationType);
                        child.get('isChildRelationshipFoundSpouse').setValue(!this.utilsService.isEmpty(srType.relationType));

                        if (srType.relationType.includes('Father') ||
                            srType.relationType.includes('Grandfather') ||
                            srType.relationType.includes('Mother') ||
                            srType.relationType.includes('Grandmother')) {
                            isValidChild = false;
                        }
                    }
                }, err => {
                    console.log('error ' + err.toString());
                });
            }
            child.get('isChildValid').setValue(isValidChild);

        }, err => {
            console.log('error ' + err.toString());
        });
    }

    quickSaveSpouseButton(findex: number) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const fName  = family.get('newSpouseFirstName').value;
        const gName  = family.get('newSpouseLastName').value;
        const gender = family.get('newSpouseGender').value;

        this.personService.quickAdd(fName, gName, gender)
            .toPromise().then(data => {
                    const saveData = <IPersonSave>data;
                    family.get('spouseId').setValue(data.ids.id);
                    family.get('spouse').setValue(fName + ' ' + gName);
                    family.get('spouseGender').setValue(gender);
                    family.get('isSpouseValid').setValue('true');
                    family.get('isMarriageSpouseValid').setValue('true');
                    family.get('spouseBirthMask').setValue('00000000');
                    family.get('spouseDeathMask').setValue('00000000');
                return data;
                    },
                err => {
                    console.log('new spouse person saving error ' + err.toString());
                });
    }

    quickSaveChildButton(index: number) {
        const utils = this.utilsService;
        const family = this.families.controls[index] as FormGroup;
        const childrenArray = family.get('children') as FormArray;

        const fName  = family.get('newChildFirstName').value;
        const gName  = family.get('newChildLastName').value;
        const gender = family.get('newChildGender').value;

        this.personService.quickAdd(fName, gName, gender)
            .toPromise().then(data => {
                const saveData = <IPersonSave>data;
                const childFormGroup = new FormGroup({
                    isChildNew: new FormControl(''),
                    child: new FormControl(''),
                    childId: new FormControl(''),
                    childGender: new FormControl(''),
                    childGenderLabel: new FormControl(''),
                    isChildValid: new FormControl(''),
                    childRelationshipTypePerson: new FormControl(''),
                    childRelationshipTypeSpouse: new FormControl(''),
                    isChildRelationshipFoundPerson: new FormControl(''),
                    isChildRelationshipFoundSpouse: new FormControl(''),
                });

                childFormGroup.get('child').setValue(utils.generateNameFromFields(fName, '', gName));
                childFormGroup.get('childId').setValue(saveData.ids.id);
                childFormGroup.get('childGenderLabel').setValue(utils.getGenderLabel(gender));
                childFormGroup.get('childGender').setValue(gender);
                childFormGroup.get('isChildNew').setValue(true);
                childFormGroup.get('isChildValid').setValue(true);
                childFormGroup.get('childRelationshipTypePerson').setValue('');
                childFormGroup.get('childRelationshipTypeSpouse').setValue('');
                childFormGroup.get('isChildRelationshipFoundPerson').setValue(false);
                childFormGroup.get('isChildRelationshipFoundSpouse').setValue(false);
                childrenArray.removeAt(childrenArray.length - 1);
                childrenArray.push(childFormGroup);
                    return data;
                    },
                err => {
                    console.log('new child person saving error ' + err.toString());
                });
    }

    validatePerson(v: KeyboardEvent, field: string, findex: number, type: string) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;

        let saveNewSpouseDisabled = true;
        let areNamesValid = false;
        let isGenderValid = false;

        if (field === 'given' || field === 'family') {
            const charCode = v.keyCode;
            const isBackspace = charCode === 8 || charCode === 46;
            const value = (!isBackspace) ? v.key.replace(/ /g, '') :
                (<HTMLInputElement>v.currentTarget).value.replace(/ /g, '');
            const fieldEmpty = (value === null || value === undefined || value === '');

            const spouseGivenName = family.get('new' + type + 'FirstName').value;
            const isGivenNameValid = this.isValidFirstName(family, spouseGivenName, findex, type);
            if (field === 'family' && fieldEmpty && isGivenNameValid) {
                areNamesValid = true;
            } else if (field === 'family' && !fieldEmpty) {
                areNamesValid = true;
            }

            const spouseFamilyName = family.get('new' + type + 'LastName').value;
            const isFamilyNameValid = this.isValidLastName(family, spouseFamilyName, findex, type);
            if (field === 'given' && fieldEmpty && isFamilyNameValid) {
                areNamesValid = true;
            } else if (field === 'given' && !fieldEmpty) {
                areNamesValid = true;
            }

            if (areNamesValid) {
                document.getElementById('input' + type + 'NameHelpBlock' + findex).className = 'help-block error text-muted';
                family.get('new' + type + 'FirstName').setValidators([Validators.maxLength(250)]);
                family.get('new' + type + 'LastName').setValidators([Validators.maxLength(250)]);
            } else {
                document.getElementById('input' + type + 'NameHelpBlock' + findex).className = 'help-block error';
                family.get('new' + type + 'FirstName').setValidators([Validators.required, Validators.maxLength(250)]);
                family.get('new' + type + 'LastName').setValidators([Validators.required, Validators.maxLength(250)]);
            }

            const genderValue = family.get('new' + type + 'Gender').value;
            isGenderValid = this.isGenderValid(family, genderValue, findex, type);
        } else if (field === 'gender') {
            const spouseGivenName  = family.get('new' + type + 'FirstName').value;
            const spouseFamilyName = family.get('new' + type + 'LastName').value;
            const genderValue      = document.getElementById('new' + type + 'Gender' + findex).valueOf();

            const isFirstNameValid = this.isValidFirstName(family, spouseGivenName.value, findex, type);
            const isLastNameValid  = this.isValidLastName(family, spouseFamilyName.value, findex, type);

            areNamesValid = (isFirstNameValid && isLastNameValid);

            if (areNamesValid) {
                document.getElementById('input' + type + 'NameHelpBlock' + findex).className = 'help-block error text-muted';
            } else {
                document.getElementById('input' + type + 'NameHelpBlock' + findex).className = 'help-block error';
            }

            isGenderValid = this.isGenderValid(family, genderValue, findex, type);
        }

        family.get('new' + type + 'FirstName').updateValueAndValidity();
        family.get('new' + type + 'LastName').updateValueAndValidity();
        family.get('new' + type + 'Gender').updateValueAndValidity();

        // change this to a form variable
        if (areNamesValid && isGenderValid) {
            saveNewSpouseDisabled = false;
        }
        (<HTMLButtonElement>document.getElementById('saveNew' + type + findex)).disabled = saveNewSpouseDisabled;
    }

    validateNewPersonNoEvent(findex: number, type: string) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        let saveNewSpouseDisabled = true;

        const spouseGivenName  = family.get('new' + type + 'FirstName').value;
        const spouseFamilyName = family.get('new' + type + 'LastName').value;
        const genderValue      = document.getElementById('new' + type + 'Gender' + findex).valueOf();

        const isFirstNameValid = this.isValidFirstName(family, spouseGivenName.value, findex, type);
        const isLastNameValid  = this.isValidLastName(family, spouseFamilyName.value, findex, type);

        const areNamesValid = (isFirstNameValid && isLastNameValid);

        if (areNamesValid) {
            document.getElementById('input' + type + 'NameHelpBlock' + findex).className = 'help-block error text-muted';
        } else {
            document.getElementById('input' + type + 'NameHelpBlock' + findex).className = 'help-block error';
        }

        const isGenderValid = this.isGenderValid(family, genderValue, findex, type);

        family.get('new' + type + 'FirstName').updateValueAndValidity();
        family.get('new' + type + 'LastName').updateValueAndValidity();
        family.get('new' + type + 'Gender').updateValueAndValidity();

        if (areNamesValid && isGenderValid) {
            saveNewSpouseDisabled = false;
        }
        // change this to a form variable
        (<HTMLButtonElement>document.getElementById('saveNew' + type + findex)).disabled = saveNewSpouseDisabled;
    }

    isValidFirstName(family: FormGroup, firstName: string, index: number, type: string): boolean {
        if (firstName === '') {
            family.get('new' + type + 'FirstName').setValidators([Validators.required, Validators.maxLength(250)]);
            document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error';
            return false;
        } else {
            family.get('new' + type + 'FirstName').setValidators([Validators.maxLength(250)]);
            family.get('new' + type + 'LastName').setValidators([Validators.maxLength(250)]);
            document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error text-muted';
            return family.get('new' + type + 'FirstName').valid;
        }
    }

    isValidLastName(family: FormGroup, lastName: string, index: number, type: string): boolean {
        if (lastName === '') {
            family.get('new' + type + 'LastName').setValidators([Validators.required, Validators.maxLength(250)]);
            document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error';
            return false;
        } else {
            family.get('new' + type + 'FirstName').setValidators([Validators.maxLength(250)]);
            family.get('new' + type + 'LastName').setValidators([Validators.maxLength(250)]);
            document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error text-muted';
            return family.get('new' + type + 'LastName').valid;
        }
    }

    isGenderValid(family: FormGroup, gender, index: number, type: string): boolean {
        if (gender === null || gender === undefined || gender === '') {
            if (document.getElementById('input' + type + 'GenderHelpBlock' + index) != null) {
                document.getElementById('input' + type + 'GenderHelpBlock' + index).className = 'help-block error';
                document.getElementById('new' + type + 'Gender' + index).className = 'btn-group btn-group-toggle genderInvalid';
                return false;
            }
        } else {
            if (document.getElementById('input' + type + 'GenderHelpBlock' + index) != null) {
                document.getElementById('input' + type + 'GenderHelpBlock' + index).className = 'help-block text-muted';
                document.getElementById('new' + type + 'Gender' + index).className = 'btn-group btn-group-toggle genderValid';
                return true;
            }
        }
    }

    validateNewLocation(findex) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const vDescription = family.controls['newLocationDescription'];
        const vCountry = family.controls['newLocationCountry'];
        const isDescriptionValid = (vDescription.value !== '');
        const isCountryValid = (vCountry.value !== '');

        if (isDescriptionValid && isCountryValid) {
            this.saveNewLocationDisabled = false;
            family.controls['newLocationDescription'].setValidators([Validators.required, Validators.maxLength(250)]);
            family.controls['newLocationCountry'].setValidators(Validators.required);
            this.showNewLocationDescriptionHelp = false;
            this.showNewLocationCountryHelp = false;

        } else {
            if (!isDescriptionValid) {
                this.saveNewLocationDisabled = true;
                family.controls['newLocationDescription'].setValidators([Validators.required, Validators.maxLength(250)]);
                family.controls['newLocationCountry'].setValidators(Validators.required);
                this.showNewLocationDescriptionHelp = true;
            }

            if (!isCountryValid) {
                this.saveNewLocationDisabled = true;
                family.controls['newLocationDescription'].setValidators([Validators.required, Validators.maxLength(250)]);
                family.controls['newLocationCountry'].setValidators(Validators.required);
                this.showNewLocationCountryHelp = true;
            }
        }
    }

    quickSaveLocation(findex) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        const nLocationDescription = family.get('newLocationDescription').value;
        const nLocationCountry = family.get('newLocationCountry').value;
        const newLocation: Location = new Location();
        newLocation.description  = nLocationDescription;
        newLocation.country      = nLocationCountry;
        newLocation.latitude     = '';
        newLocation.longitude    = '';
        newLocation.notes        = '';
        newLocation.id           = '';
        newLocation.version      = '4444'; // this.version.value;

        family.controls['newLocationDescription'].setValidators(null);
        family.controls['newLocationCountry'].setValidators(null);
        family.patchValue({
            newLocationDescription: '',
            newLocationCountry: ''
        });
        family.updateValueAndValidity();

        this.locationService.saveLocation(newLocation.id, JSON.stringify(newLocation), '4444')
            .toPromise().then(data => {
                    this.filteredLocations = null;
                    const saveData = <ILocationSave>data;
                    family.get('marriageLocation').setValue(nLocationDescription, {onlySelf: true, emitEvent: false});
                    family.get('marriageLocationId').setValue(saveData.ids.id);
                    family.get('marriageExternalId').setValue('K' + saveData.ids.id);
                    family.get('marriageLat').setValue('');
                    family.get('marriageLon').setValue('');
                    family.get('marriageCountry').setValue(nLocationCountry);
                    return data;
                },
                err => {
                    console.log('location saving error ' + err.toString());
                });
    }

    removeLocationButton(findex, event: any) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        family.get('marriageLocation').setValue('');
        family.get('marriageLocationId').setValue('');
        family.updateValueAndValidity();
    }

    openSpouse(content, size, findex) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        family.get('newSpouseFirstName').setValue('');
        family.get('newSpouseFirstName').setValidators([Validators.required, Validators.maxLength(250)]);
        family.get('newSpouseFirstName').updateValueAndValidity();
        family.get('newSpouseLastName').setValue('');
        family.get('newSpouseLastName').setValidators([Validators.required, Validators.maxLength(250)]);
        family.get('newSpouseLastName').updateValueAndValidity();
        family.get('newSpouseGender').setValue('');
        family.get('newSpouseGender').updateValueAndValidity();
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    open(content, size, findex) {
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    openLocation(content, size, type, findex) {
        this.saveNewLocationDisabled = true;
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        family.get('newLocationType').setValue(type);
        family.get('newLocationDescription').setValue('');
        family.get('newLocationCountry').setValue('');
        family.controls['newLocationDescription'].setValidators([Validators.required, Validators.maxLength(250)]);
        family.controls['newLocationCountry'].setValidators(Validators.required);
        family.controls['newLocationDescription'].updateValueAndValidity();
        family.controls['newLocationCountry'].updateValueAndValidity();
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    openChild(content, size, findex) {
        const families = this.familyForm.get('families') as FormArray;
        const family = families.controls[findex] as FormGroup;
        family.get('newChildFirstName').setValue('');
        family.get('newChildFirstName').setValidators([Validators.required, Validators.maxLength(250)]);
        family.get('newChildFirstName').updateValueAndValidity();
        family.get('newChildLastName').setValue('');
        family.get('newChildLastName').setValidators([Validators.required, Validators.maxLength(250)]);
        family.get('newChildLastName').updateValueAndValidity();
        family.get('newChildGender').setValue('');
        family.get('newChildGender').updateValueAndValidity();
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    openDelete(content, size) {
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    saveSpouses() {
        const saveButton = <HTMLButtonElement> document.getElementById('saveFamilyButton');
        saveButton.disabled = true;
        const cancelButton = <HTMLButtonElement> document.getElementById('cancelFamilyButton');
        cancelButton.disabled = true;
        const families = this.familyForm.get('families') as FormArray;
        this.showForm = false;
        this.personService.saveFamilies(this.activeId, this.activePersonName, this.activeGender, families);
    }

    showSpousesForm() {
        // this.isValidFather = true;
        // this.isValidMother = true;
        // this.fatherRelationshipType = '';
        // this.motherRelationshipType = '';
        // this.isFatherRelationshipFound = false;
        // this.isMotherRelationshipFound = false;

        if (this.showForm) {
            // this.setParents(this.activeId);
        }
        this.showForm = !this.showForm;
    }

}
