import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { UtilsService } from '../shared/utils.service';
// tslint:disable-next-line:import-blacklist
import { Observable} from 'rxjs';
import { Router, ActivatedRoute} from '@angular/router';

@Injectable()
export class GedcomService {

    constructor(private   ms: NgbModal,
                protected http: HttpClient,
                private   router: Router,
                private   route: ActivatedRoute,
                private   utils: UtilsService) {
    }

    // file from event.target.files[0]
    uploadFile(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);

        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json'
            })
        };
        const URLString = this.utils.getGENURL() + '/gen/gedcom/' + this.utils.uuid();
        return this.http.post(URLString, formData, httpOptions);
    }

    downloadFile(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'text/plain',
                'Observe': 'body'
            }), responseType: 'text' as 'text'};
        return this.http.get(this.utils.getGENURL() + '/gen/gedcom/', httpOptions);
    }
}
