import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Ancestor} from './ancestor';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { UtilsService} from '../../shared/utils.service';
import { PersonService} from '../person.service';

@Component({
  selector: 'app-ahnentafel-report',
  templateUrl: './ahnentafel-report.component.html',
  styleUrls: ['./ahnentafel-report.component.scss']
})
export class AhnentafelReportComponent implements OnInit, OnDestroy {
    ahnentafellist: Ancestor[];
    activePersonName: string;
    private nameOfPersonSubscription: Subscription;

    // below  will get called when ever the items are passed in by the parent component
    @Input('ahnentafellist') set ahnentafel (ahnentafel: Ancestor[]) {
        console.log('AncestorReportComponent: set ahnentafel');
        this.ahnentafellist = ahnentafel;
    }

    constructor(private utilsService: UtilsService,
                private personService: PersonService) { }

    ngOnInit() {
        console.log('AhnentafelReport: OnInit');
        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe( activePersonName => {
            this.activePersonName = activePersonName;
        });
    }

    ngOnDestroy(): void {
        this.nameOfPersonSubscription.unsubscribe();
    }
}
