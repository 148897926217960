import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SourceService} from './source.service';
import { SourceComponent } from './source.component';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxGalleryModule} from 'ngx-gallery';
import { RouterModule} from '@angular/router';
import { SourcelistComponent} from './list/sourcelist.component';
import { SourcelistResolver} from './list/sourcelist-resolver.service';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        NgxGalleryModule,
        RouterModule
    ],
    declarations: [
        SourceComponent,
        SourcelistComponent
    ],
    providers: [
        SourceService,
        SourcelistResolver
    ]

})
export class SourceModule { }
