import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import { KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  searchForm: FormGroup;
  search = new FormControl();
  username: string;
  status: boolean;

  constructor(private keyCloak: KeycloakService) { }

  ngOnInit() {
      this.searchForm = new FormGroup({
          search: this.search
      });

      if (this.keyCloak.isLoggedIn()) {
           this.username = this.keyCloak.getUsername();
      } else {
           this.username = '';
      }
  }

    signOut(): void {
        this.keyCloak.logout();
    }

    toggleSidebar(): void {
        if (this.status) {
            (<HTMLDivElement>document.getElementById('sidebar')).className = 'active';
        } else {
            (<HTMLDivElement>document.getElementById('sidebar')).className = '';
        }
        this.status = !this.status;
    }

}
