import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepositoryService} from './repository.service';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxGalleryModule} from 'ngx-gallery';
import { RouterModule} from '@angular/router';
import { RepositoryComponent } from './repository.component';
import { RepositorylistComponent} from './list/repositorylist.component';
import { RepositorylistResolver} from './list/repositorylist-resolver.service';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        NgxGalleryModule,
        RouterModule
    ],
    declarations: [
        RepositorylistComponent,
        RepositoryComponent
    ],
    providers: [
        RepositoryService,
        RepositorylistResolver
    ]

})
export class RepositoryModule { }
