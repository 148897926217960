import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { IPerson} from '../../models/iperson';
import { PersonService} from '../person.service';

@Injectable()
export class PersonlistResolver implements Resolve<IPerson[]> {

    constructor(private personService: PersonService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.personService.getPersons();
    }
}
