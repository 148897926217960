import { Injectable } from '@angular/core';
import { StringBuilder} from '../../shared/stringbuffer';
import { IAncestor} from '../../models/iancestor';
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { UtilsService} from '../../shared/utils.service';
import { ActivatedRoute, Router} from '@angular/router';
import { PersonService} from '../person.service';
import { LifeEvent } from '../lifeevent/lifeevent';

@Injectable({
  providedIn: 'root'
})
export class AhnentafelReportService {

    constructor(private utilsService: UtilsService,
                private router: Router,
                private route: ActivatedRoute,
                private personService: PersonService,
                private sanitizer: DomSanitizer) { }

    drawReport(activeId, activePersonName): any {
        const html = new StringBuilder();
        let htmlToPage: SafeHtml;
        let currentGeneration = 1;

        const person = this.personService.getPerson(+activeId);

        html.append('<table class="table table-borderless">');
        html.append('<tr><td colspan="4" class="text-center"><h4>Ancestors of ' + activePersonName + '</h4></td></tr>');
        html.append(this.generationHeader(currentGeneration));

        this.personService.getAncestorsForReport(activeId).toPromise().then(ancestors => {
            if (ancestors !== undefined) {
                let count = 0;
                for (const item of ancestors) {
                    const ancestor = <IAncestor>item;
                    if (count === 0 || ancestor.level > currentGeneration ) {
                        html.append(this.generationHeader(ancestor.level));
                        currentGeneration = ancestor.level;
                    }

                    // html.append(this.parentRowText(ancestor, ancestor.marriage));
                    count = count + 1;
                }
            }

            html.append('</table>');
            html.append('<br>');
            htmlToPage = this.sanitizer.bypassSecurityTrustHtml(html.toString());
        });
    }

    private generationHeader(generationNumber: number): string {
        return '<tr><td colspan="4" class="text-center"><h5><i>Generation ' + generationNumber + '</i></h5></td></tr>';
    }

    private parentRowText(ancestor: IAncestor, marriage: LifeEvent): string {
        const sb = new StringBuilder();

        sb.append('<tr><td colspan="4" style="white-space: pre-wrap !important;">');
        sb.append('<b>' + ancestor.level + '. ');
        const givenName = ancestor.givenName;
        const familyName = ancestor.familyName;

        if (givenName != null && givenName.trim().length > 0) {
            sb.append(givenName.trim());
        }

        sb.append('<sup>' + ancestor.level + '</sup>');

        if (familyName != null && familyName.trim().length > 0) {
            sb.append(' ' + familyName.trim());
        }

        sb.append('</b>');

        if (ancestor.birth !== null) {

            const isBirthAvailable = ancestor.birth.formattedDate != null && ancestor.birth.formattedDate.trim().length > 0;
            const isBYearAvailable = ancestor.birth.startDateYear != null;
            const isBMonthAvailable = ancestor.birth.startDateMonth != null;
            const isBDayAvailable = ancestor.birth.startDateDay != null;
            const isBLocationAvailable = ancestor.birth.location != null && ancestor.birth.location.description.trim().length > 0;

            if (isBirthAvailable) {
                sb.append(' born ');
                if (isBYearAvailable || isBMonthAvailable || isBDayAvailable) {
                    sb.append(ancestor.birth.formattedDate.trim());
                }
                if (isBLocationAvailable) {
                    sb.append(' at ');
                    sb.append(ancestor.birth.location.description.trim());
                }
            }

        }

        if (ancestor.death !== null) {
            const isDeathAvailable = ancestor.death.formattedDate != null && ancestor.death.formattedDate.trim().length > 0;
            const isDYearAvailable = ancestor.death.startDateYear != null;
            const isDMonthAvailable = ancestor.death.startDateMonth != null;
            const isDDayAvailable = ancestor.death.startDateDay != null;
            const isDLocationAvailable = ancestor.death.location != null && ancestor.death.location.description.trim().length > 0;


            if (isDeathAvailable) {
                sb.append('; died ');
                if (isDYearAvailable || isDMonthAvailable || isDDayAvailable) {
                    sb.append(ancestor.death.formattedDate.trim());
                }
                if (isDLocationAvailable) {
                    sb.append(' at ');
                    sb.append(ancestor.death.location.description.trim());
                }
            }
        }

        sb.append('.  ');

        /*
        if (marriage !== null && ancestor.spouseId !== null) {
            if (ancestor.gender === 'mal') {
                sb.append('He married ');
            } else {
                sb.append('She married ');
            }

            const utils = this.utilsService;
            const spouseId = ancestor.spouseId;

            if (spouseId !== null) {
                const isMYearAvailable = marriage !== null
                    && marriage.startDateYear !== null
                    && String(marriage.startDateYear) !== '0';
                const isMMonthAvailable = marriage !== null
                    && marriage.startDateMonth !== null
                    && String(marriage.startDateMonth) !== '0';
                const isMDayAvailable = marriage !== null
                    && marriage.startDateDay !== null
                    && String(marriage.startDateDay) !== '0';
                const isMarriageLocationAvailable = marriage != null
                    && marriage.location !== null
                    && marriage.location.description.trim().length > 0;

                const spouseGiven = ancestor.spouseGivenName;
                const spouseFamily = ancestor.spouseFamilyName;

                if (spouseGiven !== null && spouseGiven.trim().length > 0 || spouseFamily !== null && spouseFamily.trim().length > 0) {

                    if (spouseGiven !== null && spouseGiven.trim().length > 0) {
                        sb.append(spouseGiven.trim());
                    }
                    if (spouseFamily !== null && spouseFamily.trim().length > 0) {
                        sb.append(' ' + spouseFamily.trim());
                    }
                    sb.append('</b>');
                }

                if (ancestor.spouseBirth !== null) {
                    const isSBirthAvailable =
                        ancestor.spouseBirth.formattedDate !== null &&
                        ancestor.spouseBirth.formattedDate.trim().length > 0;
                    const isSBYearAvailable = ancestor.spouseBirth.startDateYear !== null &&
                        ancestor.spouseBirth.startDateYear > 0;
                    const isSBMonthAvailable = ancestor.spouseBirth.startDateMonth !== null &&
                        ancestor.spouseBirth.startDateMonth.trim().length > 0;
                    const isSBDayAvailable = ancestor.spouseBirth.startDateDay !== null &&
                        ancestor.spouseBirth.startDateDay.trim().length > 0;
                    const isSBLocationAvailable = ancestor.spouseBirth.location !== null &&
                        ancestor.spouseBirth.location.description.trim().length > 0;

                    if (isSBirthAvailable) {
                        sb.append(' born ');
                        if (isSBYearAvailable || isSBMonthAvailable || isSBDayAvailable) {
                            sb.append(ancestor.spouseBirth.formattedDate.trim());
                        }
                        if (isSBLocationAvailable) {
                            sb.append(' at ');
                            sb.append(ancestor.spouseBirth.location.description.trim());
                        }
                    }
                }

                if (ancestor.spouseDeath !== null) {
                    const isSDeathAvailable = ancestor.spouseDeath.formattedDate !== null &&
                        ancestor.spouseDeath.formattedDate.trim().length > 0;
                    const isSDYearAvailable = ancestor.spouseDeath.startDateYear !== null &&
                        ancestor.spouseDeath.startDateYear > 0;
                    const isSDMonthAvailable = ancestor.spouseDeath.startDateMonth !== null &&
                        ancestor.spouseDeath.startDateMonth.trim().length > 0;
                    const isSDDayAvailable = ancestor.spouseDeath.startDateDay !== null &&
                        ancestor.spouseDeath.startDateDay.trim().length > 0;
                    const isSDLocationAvailable = ancestor.spouseDeath.location !== null &&
                        ancestor.spouseDeath.location.description.trim().length > 0;

                    if (isSDeathAvailable) {
                        sb.append('; died ');
                        if (isSDYearAvailable || isSDMonthAvailable || isSDDayAvailable) {
                            sb.append(ancestor.spouseDeath.formattedDate.trim());
                        }
                        if (isSDLocationAvailable) {
                            sb.append(' at ');
                            sb.append(ancestor.spouseDeath.location.description.trim());
                        }
                    }
                }

                if (isMYearAvailable || isMMonthAvailable || isMDayAvailable || isMarriageLocationAvailable) {
                    sb.append('; wed ');
                    if (isMYearAvailable || isMMonthAvailable || isMDayAvailable) {
                        sb.append(utils.getDisplayDate(marriage));
                    }
                    if (isMarriageLocationAvailable) {
                        sb.append(' at ');
                        sb.append(marriage.location.description);
                    }
                }
            }
        }
        */

        sb.append('.');
        sb.append('</td></tr>');

        return sb.toString();
    }

    private childRowText(childGeneration: number, child: IAncestor, marriage: any): string {
        const sb = new StringBuilder();
        const givenName = child.givenName;
        const familyName = child.familyName;

        if (givenName !== null && givenName.trim().length > 0) {
            sb.append(givenName.trim().toUpperCase());
        }

        // FIX ME
        // if (child.marriage.children.length > 0) {
        //     sb.append('<sup>' + childGeneration + '</sup>');
        //  }

        if (familyName !== null && familyName.trim().length > 0) {
            sb.append(' ' + familyName.trim().toUpperCase());
        }

        const isCBirthAvailable = child.birth.formattedDate != null && child.birth.formattedDate.trim().length > 0;
        const isCBLocationAvailable = child.birth.location != null && child.birth.location.description.trim().length > 0;

        if (isCBirthAvailable || isCBLocationAvailable) {
            sb.append(', born ');
            if (isCBirthAvailable) {
                sb.append(child.birth.formattedDate.trim());
            }
            if (isCBLocationAvailable) {
                sb.append(' at ');
                sb.append(child.birth.location.description.trim());
            }
        }

        if (marriage !== null && marriage.spouse !== null) {
            sb.append('; married');

            const spouse = marriage.spouse;
            const childSpouseGiven = spouse.givenName;
            const childSpouseMiddle = spouse.middleNames;
            const childSpouseFamily = spouse.familyName;
            if (childSpouseGiven !== null && childSpouseGiven.trim().length > 0) {
                sb.append(' ' + childSpouseGiven.trim());
            }
            if (childSpouseMiddle != null && childSpouseMiddle.trim().length > 0) {
                sb.append(' ' + childSpouseMiddle.trim());
            }
            if (childSpouseFamily != null && childSpouseFamily.trim().length > 0) {
                sb.append(' ' + childSpouseFamily.trim());
            }
        }

        const isCDeathAvailable = child.death.formattedDate != null &&
            child.death.formattedDate.trim().length > 0;
        const isCDLocationAvailable = child.death.formattedDate != null &&
            child.death.location != null && child.death.location.description.trim().length > 0;

        if (isCDeathAvailable || isCDLocationAvailable) {
            sb.append('; died ');
            if (isCDeathAvailable) {
                sb.append(child.death.formattedDate.trim());
            }
            if (isCDLocationAvailable) {
                sb.append(' at ');
                sb.append(child.death.location.description.trim());
            }
        }

        if (!isCBirthAvailable && !isCBLocationAvailable &&
            (marriage.spouse === undefined || marriage.spouse === null) &&
            isCDeathAvailable && isCDLocationAvailable) {
            sb.append(' No futher information.');
        } else {
            sb.append('.');
        }
        return sb.toString();
    }
}
