import { IRelationshipCreate } from '../../models/irelationshipcreate';
import { LifeEventCreate } from '../lifeevent/lifeeventcreate';

export class RelationshipCreate implements IRelationshipCreate {
    transactionId:       string;
    relationType:        string;
    personId:            string;
    personRole:          string;
    relationPersonId:    string;
    relationPersonRole:  string;
    marriage:            LifeEventCreate;
    childrenIds:         string[];

    constructor() {
        this.transactionId = '';
        this.relationType = '';
        this.personId = '';
        this.personRole = '';
        this.relationPersonId = '';
        this.relationPersonRole = '';
        this.marriage = new LifeEventCreate();
        this.childrenIds = [];
    }

    toJSON() {
        return {
            transactionId:       this.transactionId,
            relationType:        this.relationType,
            personId:            this.personId,
            personRole:          this.personRole,
            relationPersonId:    this.relationPersonId,
            relationPersonRole:  this.relationPersonRole,
            marriage:            this.marriage,
            childrenIds:         this.childrenIds
        };
    }
}
