import {ISVGObject} from "../../models/isvgobject";

export class SVGObject implements ISVGObject {
    svg:    string;
    width:  number;
    height: number;

    constructor() {
        this.svg = '';
        this.width = 0;
        this.height = 0;
    };

    toJSON() {
        return {
            svg: this.svg,
            width: this.width,
            height: this.height
        };
    }
}
