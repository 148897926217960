import { Component, OnDestroy, OnInit} from '@angular/core';
import { PersonService } from '../person.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../../shared/utils.service';
import { LifeEvent} from '../lifeevent/lifeevent';
import { PaginationService} from '../../shared/pagination.service';
import { FormControl, FormGroup} from '@angular/forms';
import { Person} from '../person';

@Component({
    templateUrl: './personlist.component.html',
    styleUrls: ['./personlist.component.scss']
})
export class PersonlistComponent implements OnInit, OnDestroy {
    filterForm: FormGroup;
    filterName = new FormControl();
    filterBDate = new FormControl();
    filterBPlace = new FormControl();
    filterDDate = new FormControl();
    filterDPlace = new FormControl();
    filterMGiven = new FormControl();
    filterMFamily = new FormControl();
    filterMOcc = new FormControl();
    filterMRel = new FormControl();
    filterMCause = new FormControl();
    filterMFather = new FormControl();
    filterMMother = new FormControl();
    filterMBirthDate = new FormControl();
    filterMBirthLoc = new FormControl();
    filterMDeathDate = new FormControl();
    filterMDeathLoc = new FormControl();
    filterMMarriageDate = new FormControl();
    filterMMarriageLoc = new FormControl();
    filterMOtherDate = new FormControl();
    filterMOtherLoc = new FormControl();
    sortTable = new FormControl();

    personSortOptions: Array<any>;

    persons: Person[];
    itemsPerPage = 25;
    page = 1;
    pager: any = {};

    isNameDesc      = true;
    isBirthDesc     = true;
    isBLocationDesc = true;
    isDeathDesc     = true;
    isDLocationDesc = true;
    isSortName      = false;
    isSortBirth     = false;
    isSortBLocation = false;
    isSortDeath     = false;
    isSortDLocation = false;

    closeResult: string;
    filterLabel: string;

    dataLoaded: Promise<boolean>;
    DESC = 'DESC';
    ASC = 'ASC';

    constructor(private personService: PersonService,
                private utilsService: UtilsService,
                private router: Router,
                private modalService: NgbModal,
                private activatedRoute: ActivatedRoute,
                private pagerService: PaginationService) {
    }

    public ngOnInit(): void {
        console.log('>>>>>> 1. PersonList -> ngOnInit()');
        this.personSortOptions = this.utilsService.getPersonSortOptions();
        this.filterLabel = 'Show Filter';
        this.filterForm = new FormGroup({
            filterName: this.filterName,
            filterBDate: this.filterBDate,
            filterBPlace: this.filterBPlace,
            filterDDate: this.filterDDate,
            filterDPlace: this.filterDPlace,
            filterMGiven: this.filterMGiven,
            filterMFamily: this.filterMFamily,
            filterMOcc: this.filterMOcc,
            filterMRel: this.filterMRel,
            filterMCause: this.filterMCause,
            filterMFather: this.filterMFather,
            filterMMother: this.filterMMother,
            filterMBirthDate: this.filterMBirthDate,
            filterMBirthLoc: this.filterMBirthLoc,
            filterMDeathDate: this.filterMDeathDate,
            filterMDeathLoc: this.filterMDeathLoc,
            filterMMarriageDate: this.filterMMarriageDate,
            filterMMarriageLoc: this.filterMMarriageLoc,
            filterMOtherDate: this.filterMOtherDate,
            filterMOtherLoc: this.filterMOtherLoc,
            sortTable: this.sortTable
        });

        // when a person hits the enter button, get the input field
        // execute the function when the user releases a key on the keyboard
        document.getElementById('filterName').addEventListener('keyup', function(event) {
            // Number 13 is the "Enter" key on the keyboard
            if (event.code === '13') {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        document.getElementById('filterBDate').addEventListener('keyup', function(event) {
            // Number 13 is the "Enter" key on the keyboard
            if (event.code === '13') {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        document.getElementById('filterBPlace').addEventListener('keyup', function(event) {
            // Number 13 is the "Enter" key on the keyboard
            if (event.code === '13') {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        document.getElementById('filterDDate').addEventListener('keyup', function(event) {
            // Number 13 is the "Enter" key on the keyboard
            if (event.code === '13') {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        document.getElementById('filterDPlace').addEventListener('keyup', function(event) {
            // Number 13 is the "Enter" key on the keyboard
            if (event.code === '13') {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        // set all the filter fields, if they have been set from another page...
        this.personService.setParams('pg', '1,25');
        this.page = 1;

        this.filterName.setValue(this.getLocalParam(this.personService.FNAME));
        this.filterBDate.setValue(this.getLocalParam(this.personService.FBDATE));
        this.filterBPlace.setValue(this.getLocalParam(this.personService.FBLOC));
        this.filterDDate.setValue(this.getLocalParam(this.personService.FDDATE));
        this.filterDPlace.setValue(this.getLocalParam(this.personService.FDLOC));
        this.sortTable.setValue(this.getLocalParam(this.personService.SORT), {onlySelf: true});
        this.setSortOrder(this.getLocalParam(this.personService.SORT));

        this.filterMGiven.setValue(this.getLocalParam(this.personService.MGIVEN));
        this.filterMFamily.setValue(this.getLocalParam(this.personService.MFAMILY));
        this.filterMOcc.setValue(this.getLocalParam(this.personService.MOCC));
        this.filterMRel.setValue(this.getLocalParam(this.personService.MREL));
        this.filterMCause.setValue(this.getLocalParam(this.personService.MCAUSE));
        this.filterMFather.setValue(this.getLocalParam(this.personService.MFATHER));
        this.filterMMother.setValue(this.getLocalParam(this.personService.MMOTHER));

        this.filterMBirthDate.setValue(this.getLocalParam(this.personService.MBIRTHDATE));
        this.filterMBirthLoc.setValue(this.getLocalParam(this.personService.MBIRTHLOC));
        this.filterMDeathDate.setValue(this.getLocalParam(this.personService.MDEATHDATE));
        this.filterMDeathLoc.setValue(this.getLocalParam(this.personService.MDEATHLOC));
        this.filterMMarriageDate.setValue(this.getLocalParam(this.personService.MMARRIAGEDATE));
        this.filterMMarriageLoc.setValue(this.getLocalParam(this.personService.MMARRIAGELOC));
        this.filterMOtherDate.setValue(this.getLocalParam(this.personService.MOTHERDATE));
        this.filterMOtherLoc.setValue(this.getLocalParam(this.personService.MOTHERLOC));

        // this is set from the RESOLVER
        console.log('>>>>>> 2. PersonList -> ngOnInit() set person data from resolver' );
        this.persons = <Person[]>(this.activatedRoute.snapshot.data.personlist).items || null;
        const personListTotal = (this.activatedRoute.snapshot.data.personlist).total;

        console.log('>>>>>> 3. PersonList -> ngOnInit() fix the dates' );
        this.fixDates();
        this.pager = this.pagerService.getPager(personListTotal, this.page, this.itemsPerPage);
        this.dataLoaded = Promise.resolve(true);
    }

    getLocalParam(key: string): string {
        return this.personService.getParamValue(key);
    }

    setLocalParam(key: string, value: string) {
        this.personService.setParams(key, value) ;
    }

    ngOnDestroy() {}

    setSortOrder(column) {
        this.isNameDesc      = false;
        this.isBirthDesc     = false;
        this.isBLocationDesc = false;
        this.isDeathDesc     = false;
        this.isDLocationDesc = false;

        this.isSortName      = false;
        this.isSortBirth     = false;
        this.isSortBLocation = false;
        this.isSortDeath     = false;
        this.isSortDLocation = false;

        this.setLocalParam(this.personService.SORT, column);

        if (column === 'nameAsc') {
            this.isSortName      = true;
        } else if (column === 'nameDesc') {
            this.isNameDesc      = true;
            this.isSortName      = true;
        } else if (column === 'bDateAsc') {
            this.isSortBirth     = true;
        } else if (column === 'bDateDesc') {
            this.isBirthDesc     = true;
            this.isSortBirth     = true;
        } else if (column === 'bLocAsc') {
            this.isSortBLocation = true;
        } else if (column === 'bLocDesc') {
            this.isBLocationDesc = true;
            this.isSortBLocation = true;
        } else if (column === 'dDateAsc') {
            this.isSortDeath     = true;
        } else if (column === 'dDateDesc') {
            this.isSortDeath     = true;
            this.isDLocationDesc = true;
        } else if (column === 'dLocAsc') {
            this.isSortDLocation = true;
        } else if (column === 'dLocDesc') {
            this.isSortDLocation = true;
            this.isDLocationDesc = true;
        }
    }

    personSortBy(event) {
        this.sortTable.setValue(event.target.value, {onlySelf: true});
        this.setSortOrder(event.target.value);
        this.loadData(this.page);
    }

    checkboxFilter(event, filterName) {
        // these values are set in the HTML
        if (filterName === 'fMGiven') {
            this.filterMGiven.setValue(event.target.checked ? 'true' : '');
        } else if (filterName === 'fMFamily') {
            this.filterMFamily.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMOcc') {
            this.filterMOcc.setValue(  event.target.checked ? 'true' : '');
        } else if (filterName === 'fMRel') {
            this.filterMRel.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMCause') {
            this.filterMCause.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMFather') {
            this.filterMFather.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMMother') {
            this.filterMMother.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMBirthDate') {
            this.filterMBirthDate.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMBirthLoc') {
            this.filterMBirthLoc.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMDeathDate') {
            this.filterMDeathDate.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMDeathLoc') {
            this.filterMDeathLoc.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMMarriageDate') {
            this.filterMMarriageDate.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMMarriageLoc') {
            this.filterMMarriageLoc.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMOtherDate') {
            this.filterMOtherDate.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'fMOtherLoc') {
            this.filterMOtherLoc.setValue( event.target.checked ? 'true' : '');
        }
    }

    fixDates() {
        const utils = this.utilsService;
        if (this.persons !== undefined && this.persons !== null) {
            this.persons.forEach(function (item) {
                if (item.birth !== null && item.birth !== undefined) {
                    item.birth.formattedDate = utils.getDisplayDatePlace(item.birth);
                } else {
                    item.birth = new LifeEvent();
                }
                if (item.death !== null && item.death !== undefined) {
                    item.death.formattedDate = utils.getDisplayDatePlace(item.death);
                } else {
                    item.death = new LifeEvent();
                }
            });
        }
    }

    setPerson(id: string, gender: string) {
        this.router.navigate(['/person'], { queryParams: { id: id, g: gender }});
    }

    handleClick() {
        this.filterLabel = (this.filterLabel === 'Show Filter' ? 'Hide Filter' : 'Show Filter');
    }

    clearFilters() {
        this.filterName.setValue('');
        this.filterBDate.setValue('');
        this.filterBPlace.setValue('');
        this.filterDDate.setValue('');
        this.filterDPlace.setValue('');
        this.filterMGiven.setValue('');
        this.filterMFamily.setValue('');
        this.filterMOcc.setValue('');
        this.filterMRel.setValue('');
        this.filterMCause.setValue('');
        this.filterMFather.setValue('');
        this.filterMMother.setValue('');
        this.filterMBirthDate.setValue('');
        this.filterMBirthLoc.setValue('');
        this.filterMDeathDate.setValue('');
        this.filterMDeathLoc.setValue('');
        this.filterMMarriageDate.setValue('');
        this.filterMMarriageLoc.setValue('');
        this.filterMOtherDate.setValue('');
        this.filterMOtherLoc.setValue('');
        this.sortTable.setValue('');
        this.setSortOrder('');
        this.loadData(1);
    }

    isEmpty(tmp: any): boolean {
        if (tmp !== undefined && tmp !== null) {
            return tmp === '';
        } else {
            return false;
        }
    }

    loadData(selectedPage: number) {
        console.log('>>>>>> 4. PersonList -> LoadData(), start by clearing the local params');
        this.personService.clearParams();

        // save the values that the person service will pick up
        this.page = selectedPage;
        let correctedBdate: string;
        let correctedDdate: string;

        if (!this.utilsService.isNum(this.filterBDate.value)) {
            correctedBdate = this.utilsService.getMonthNumber(this.filterBDate.value);
        } else {
            correctedBdate = this.filterBDate.value;
        }

        if (!this.utilsService.isNum(this.filterDDate)) {
            correctedDdate = this.utilsService.getMonthNumber(this.filterDDate.value);
        } else {
            correctedDdate = this.filterDDate.value;
        }

        this.setLocalParam(this.personService.PG, selectedPage + ',' + this.itemsPerPage);
        this.setLocalParam(this.personService.FNAME, this.filterName.value);
        this.setLocalParam(this.personService.FNAMEDIR, (this.isSortName ? (this.isNameDesc ? this.DESC : this.ASC) : ''));
        this.setLocalParam(this.personService.FBDATE, correctedBdate);
        this.setLocalParam(this.personService.FBDATEDIR, (this.isSortBirth ? (this.isBirthDesc ? this.DESC : this.ASC) : ''));
        this.setLocalParam(this.personService.FBLOC, this.filterBPlace.value);
        this.setLocalParam(this.personService.FBLOCDIR, (this.isSortBLocation ? (this.isBLocationDesc ? this.DESC : this.ASC) : ''));
        this.setLocalParam(this.personService.FDDATE, correctedDdate);
        this.setLocalParam(this.personService.FDDATEDIR, (this.isSortDeath ? (this.isDeathDesc ? this.DESC : this.ASC) : '') );
        this.setLocalParam(this.personService.FDLOC, this.filterDPlace.value);
        this.setLocalParam(this.personService.FDLOCDIR, (this.isSortDLocation ? (this.isDLocationDesc ? this.DESC : this.ASC) : ''));
        this.setLocalParam(this.personService.MGIVEN, this.filterMGiven.value);
        this.setLocalParam(this.personService.MFAMILY, this.filterMFamily.value);
        this.setLocalParam(this.personService.MOCC, this.filterMOcc.value);
        this.setLocalParam(this.personService.MREL, this.filterMRel.value);
        this.setLocalParam(this.personService.MCAUSE, this.filterMCause.value);
        this.setLocalParam(this.personService.MFATHER, this.filterMFather.value);
        this.setLocalParam(this.personService.MMOTHER, this.filterMMother.value);
        this.setLocalParam(this.personService.MBIRTHDATE, this.filterMBirthDate.value);
        this.setLocalParam(this.personService.MBIRTHLOC, this.filterMBirthLoc.value);
        this.setLocalParam(this.personService.MDEATHDATE, this.filterMDeathDate.value);
        this.setLocalParam(this.personService.MDEATHLOC, this.filterMDeathLoc.value);
        this.setLocalParam(this.personService.MMARRIAGEDATE, this.filterMMarriageDate.value);
        this.setLocalParam(this.personService.MMARRIAGELOC, this.filterMMarriageLoc.value);
        this.setLocalParam(this.personService.MOTHERDATE, this.filterMOtherDate.value);
        this.setLocalParam(this.personService.MOTHERLOC, this.filterMOtherLoc.value);

        this.personService.getPersons()
            .toPromise().then(personList => {
                this.persons = <Person[]>personList.items || null;
                const personListTotal = personList.total;
                this.fixDates();
                this.pager = this.pagerService.getPager(personListTotal, selectedPage, this.itemsPerPage);
                this.dataLoaded = Promise.resolve(true);
            }
        );
    }
}
