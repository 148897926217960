import { LifeEventCreate} from './lifeevent/lifeeventcreate';
import { IPersonCreate} from '../models/ipersoncreate';

export class PersonCreate implements IPersonCreate {
    version:      string;
    givenName:    string;
    middleNames:  string;
    familyName:   string;
    altNames:     string;
    nameSuffix:   string;
    gender:       string;
    causeOfDeath: string;
    deceased:     string;
    occupation:   string;
    religion:     string;
    attributes:   string;
    notes:        string;
    isResearch:   string;
    birth:        LifeEventCreate;
    death:        LifeEventCreate;
    burial:       LifeEventCreate;
    fatherId:     string;
    motherId:     string;

    constructor() {
        this.version = '';
        this.givenName = '';
        this.middleNames = '';
        this.familyName = '';
        this.altNames = '';
        this.nameSuffix = '';
        this.gender = '';
        this.causeOfDeath = '';
        this.deceased = '';
        this.occupation = '';
        this.religion = '';
        this.attributes = '';
        this.notes = '';
        this.isResearch = '';
        this.birth = new LifeEventCreate();
        this.death = new LifeEventCreate();
        this.burial = new LifeEventCreate();
        this.fatherId = '';
        this.motherId = '';
    }

    toJSON() {
        return {
            givenName: this.givenName,
            middleNames: this.middleNames,
            familyName: this.familyName,
            altNames: this.altNames,
            nameSuffix: this.nameSuffix,
            gender: this.gender,
            causeOfDeath: this.causeOfDeath,
            deceased: this.deceased,
            occupation: this.occupation,
            religion: this.religion,
            attributes: this.attributes,
            notes: this.notes,
            isResearch: this.isResearch,
            version: this.version,
            birth: this.birth,
            death: this.death,
            burial: this.burial,
            fatherId: this.fatherId,
            motherId: this.motherId
        };
    }
}
