export class Pedigree {
    id:           string;
    identifier:   string;
    name:         string;
    birthInfo:    string;
    dateRange:    string;
    marriageInfo: string;
    marriageYear: string;
    deathInfo:    string;
    gender:       string;

    constructor() {
        this.id = '';
        this.identifier = '';
        this.name = '';
        this.birthInfo = '';
        this.dateRange = '';
        this.marriageInfo = '';
        this.marriageYear = '';
        this.deathInfo = '';
        this.gender = '';
    }

    toJSON() {
        return {
            id: this.id,
            identifier: this.identifier,
            name: this.name,
            birthInfo: this.birthInfo,
            dateRange: this.dateRange,
            marriageInfo: this.marriageInfo,
            marriageYear: this.marriageYear,
            deathInfo: this.deathInfo,
            gender: this.gender
        };
    }
}
