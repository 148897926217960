import { FormGroup } from '@angular/forms';
import { UtilsService} from '../../shared/utils.service';

export function DateIncorrect(utils: UtilsService) {
    return (formGroup: FormGroup) => {
        const birthDay = formGroup.controls['birthDay'];
        const birthMonth = formGroup.controls['birthMonth'];
        const birthYear = formGroup.controls['birthYear'];
        const deathDay = formGroup.controls['deathDay'];
        const deathMonth = formGroup.controls['deathMonth'];
        const deathYear = formGroup.controls['deathYear'];
        const burialDay = formGroup.controls['burialDay'];
        const burialMonth = formGroup.controls['burialMonth'];
        const burialYear = formGroup.controls['burialYear'];

        const isValidBirthDate = utils.isValidDate(+birthDay.value, +birthMonth.value, +birthYear.value);
        const isValidDeathDate = utils.isValidDate(+deathDay.value, +deathMonth.value, +deathYear.value);
        const isValidBurialDate = utils.isValidDate(+burialDay.value, +burialMonth.value, +burialYear.value);

        if (isValidBirthDate) {
            birthDay.setErrors(null);
            birthMonth.setErrors(null);
            birthYear.setErrors(null);
        } else {
            birthDay.setErrors({dateIncorrect: true});
            birthMonth.setErrors({dateIncorrect: true});
            birthYear.setErrors({dateIncorrect: true});
        }

        if (isValidDeathDate) {
            deathDay.setErrors(null);
            deathMonth.setErrors(null);
            deathYear.setErrors(null);
        } else {
            deathDay.setErrors({dateIncorrect: true});
            deathMonth.setErrors({dateIncorrect: true});
            deathYear.setErrors({dateIncorrect: true});
        }

        if (isValidBurialDate) {
            burialDay.setErrors(null);
            burialMonth.setErrors(null);
            burialYear.setErrors(null);
        } else {
            burialDay.setErrors({dateIncorrect: true});
            burialMonth.setErrors({dateIncorrect: true});
            burialYear.setErrors({dateIncorrect: true});
        }
    };
}
