import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { SourceService } from '../source.service';
import { Repository } from '../../repository/repository';
import { Source } from '../source';
import { RepositoryService } from '../../repository/repository.service';
import { withLatestFrom } from 'rxjs/operators';

@Injectable()
export class SourcelistResolver implements Resolve<[Source[], Repository[]]> {

  constructor(private sourceService: SourceService, private repositoryService: RepositoryService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[Source[], Repository[]]> {
      console.log('0. SourceListResolver called');
      return this.sourceService.getDefaultSources().pipe(
          withLatestFrom(
              this.repositoryService.getDefaultRepositories()
          )
      );
  }
}
