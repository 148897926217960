import { FormGroup } from '@angular/forms';
import { UtilsService} from '../../shared/utils.service';

export function DatesInvalid(utils: UtilsService) {
    return (formGroup: FormGroup) => {
        const birthDay = formGroup.controls['birthDay'];
        const birthMonth = formGroup.controls['birthMonth'];
        const birthYear = formGroup.controls['birthYear'];
        const deathDay = formGroup.controls['deathDay'];
        const deathMonth = formGroup.controls['deathMonth'];
        const deathYear = formGroup.controls['deathYear'];
        const burialDay = formGroup.controls['burialDay'];
        const burialMonth = formGroup.controls['burialMonth'];
        const burialYear = formGroup.controls['burialYear'];

        const birthHasErrors = (birthDay.errors || birthMonth.errors || birthYear.errors);
        const deathHasErrors = (deathDay.errors || deathMonth.errors || deathYear.errors);
        const burialHasErrors = (burialDay.errors || burialMonth.errors || burialYear.errors);

        if (birthHasErrors && !(birthDay.hasError('datesInvalid') ||
                                birthMonth.hasError('datesInvalid') ||
                                birthYear.hasError('datesInvalid'))) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        if (deathHasErrors && !(deathDay.hasError('datesInvalid') ||
                                deathMonth.hasError('datesInvalid') ||
                                deathYear.hasError('datesInvalid'))) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        if (burialHasErrors && !(burialDay.hasError('datesInvalid') ||
                                 burialMonth.hasError('datesInvalid') ||
                                 burialYear.hasError('datesInvalid'))) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        const birthMask = utils.dateMask(birthDay.value, birthMonth.value, birthYear.value);
        const deathMask = utils.dateMask(deathDay.value, deathMonth.value, deathYear.value);
        const burialMask = utils.dateMask(burialDay.value, burialMonth.value, burialYear.value);

        // yyyymmdd: 9999 12 31---00010000
        const isBirthMaskValid  = !((+birthMask < 10000)  || (birthMask === '00000000'));
        const isDeathMaskValid  = !((+deathMask < 10000)  || (deathMask === '00000000'));
        const isBurialMaskValid = !((+burialMask < 10000) || (burialMask === '00000000'));

        let birthOK = true;
        let deathOK = true;
        let burialOK = true;

        if (isBirthMaskValid && isBurialMaskValid && (burialMask <= birthMask)) {
            birthOK = false;
            burialOK = false;
        }

        if (isBirthMaskValid && isDeathMaskValid && (deathMask <= birthMask)) {
            birthOK = false;
            deathOK = false;
        }

        if (isDeathMaskValid && isBurialMaskValid && (burialMask <= deathMask)) {
             deathOK = false;
            burialOK = false;
        }

        if (birthOK) {
            birthDay.setErrors(null);
            birthMonth.setErrors(null);
            birthYear.setErrors(null);
        } else {
            birthDay.setErrors({ datesInvalid: true });
            birthMonth.setErrors({ datesInvalid: true });
            birthYear.setErrors({ datesInvalid: true });
        }

        if (deathOK) {
            deathDay.setErrors(null);
            deathMonth.setErrors(null);
            deathYear.setErrors(null);
        } else {
            deathDay.setErrors({ datesInvalid: true });
            deathMonth.setErrors({ datesInvalid: true });
            deathYear.setErrors({ datesInvalid: true });
        }

        if (burialOK) {
            burialDay.setErrors(null);
            burialMonth.setErrors(null);
            burialYear.setErrors(null);
        } else {
            burialDay.setErrors({ datesInvalid: true});
            burialMonth.setErrors({ datesInvalid: true});
            burialYear.setErrors({ datesInvalid: true});
        }
    };
}
