import { Component } from '@angular/core';
import { AgmMap} from '@agm/core';

@Component({
    selector: 'app-map',
    styles: ['agm-map {height: 500px;}'],
    templateUrl:
    '<agm-map #map [latitude]="lat" [longitude]="lng" [fullscreenControl]="true" [mapTypeControl]="true" [mapTypeId]="\'hybrid\'">' +
    '<agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>' +
    '</agm-map>'
})

export class MapComponent {
    defaultLat = '43.6532';
    defaultLng = '-79.3832';
    lat = 43.6532;
    lng = -79.3832;
    zoom = 16;
    public map: AgmMap;

    public setupDefault() {
        this.setLatLong(this.defaultLat, this.defaultLng);
    }

    public setLatLong(lat: string, lng: string) {
        if (lat !== '' && lng !== '') {
            this.lat = +lat;
            this.lng = +lng;
        } else {
            const options = {enableHighAccuracy: true, timeout: 5000, maximumAge: 0};
            navigator.geolocation.getCurrentPosition(this.successGeolocation, this.errorGeolocation, options);
        }
    }

    successGeolocation(pos) {
        this.lat = pos.coords.latitude;
        this.lng =  pos.coords.longitude;
    }

    errorGeolocation(err) {
        console.warn('ERROR(${err.code}): ${err.message}');
    }

}
