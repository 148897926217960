import { Component, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { ChartService } from './chart.service';
import * as d3 from 'd3';
import * as _ from 'lodash';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector:      'app-dchart',
  templateUrl:   './dchart.component.html',
  styleUrls:     ['./dchart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DchartComponent implements OnInit, OnChanges {
  private w;
  private h;
  private nodeSize: Array<any>;
  private opts: any;
  private svg: any;
  private root: any;
  private allNodes: any;
  private siblings: any;

  constructor(private cs: ChartService, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.w = window.innerWidth * .9 + 40;
    this.h = window.innerHeight * .9 - 125;

    function _textRenderer(name, extra, textClass) {
      let label = '';

      if (extra) {
        label = label + '<div class="bordercard">';

        if (extra.gender && extra.gender === 'woman') {
          label = label + '<div align="center"><i class="fa fa-female fa-3x ' + extra.gender + '" aria-hidden="true"></i></div>';
        } else if (extra.gender && extra.gender === 'man') {
          label = label + '<div align="center"><i class="fa fa-male fa-3x ' + extra.gender + '" aria-hidden="true"></i></div>';
        } else {
          label = label + '<div align="center"><i class="fa fa-user fa-3x man" aria-hidden="true"></i></div>';
        }

        label = label + '<p align="center" class=""' + textClass + '">' + name;
        label = label + '<hr class="chart">';
        label = label + '<div class="dates">';

        if (extra.birth && extra.bplace) {
          label = label + 'b. ' + extra.birth + ',' + extra.bplace + '<br>';
        } else if (extra.birth) {
          label = label + 'b. ' + extra.birth + '<br>';
        } else if (extra.bplace) {
          label = label + 'b. ' + extra.bplace + '<br>';
        } else {
          label = label + 'b. ' + '<br>';
        }
        if (extra.death && extra.dplace) {
          label = label + 'd. ' + extra.death + ',' + extra.dplace + '<br>';
        } else if (extra.death) {
          label = label + 'd. ' + extra.death + '<br>';
        } else if (extra.dplace) {
          label = label + 'd. ' + extra.dplace + '<br>';
        } else {
          label = label + 'd. ' + '<br>';
        }
        label = label + '</div>';
        label = label + '</p>';
        label = label + '</div>';
      } else {
        label = label + '<div class="bordercard">';
        label = label + '<div align="center"><i class="fa fa-user fa-3x" aria-hidden="true"></i></div>';
        label = label + '<p align="center" class="' + textClass + '">' + name;
        label = label + '<hr class="chart">';
        label = label + '</p>';
        label = label + '</div>';
      }
      return label;
    }

    this.opts = _.defaultsDeep({}, {
      target: '#dgraph',
      width: this.w,
      height: this.h,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      nodeWidth: 100,
      callbacks: {
        nodeClick: function (name, extra, id) {
        },
        nodeRenderer: function (name, x, y, height, width, extra, id, nodeClass, textClass) {
          let node = '';
          node += '<div ';
          node += 'style="height:100%;width:100%;" ';
          node += 'class="' + nodeClass + '" ';
          node += 'id="node' + id + '">\n';
          node += _textRenderer(name, extra, textClass);
          node += '</div>';
          return node;
        },
        nodeSize: function (nodes, width) {
          let maxHeight = 30;
          const tmpSvg = document.createElement('svg');
          document.body.appendChild(tmpSvg);

          _.map(nodes, function (n: any) {
            const container = document.createElement('div');
            container.setAttribute('class', n.data.class);
            container.style.visibility = 'hidden';
            container.style.maxWidth = width + 'px';

            const text = _textRenderer(n.data.name, n.data.extra, n.data.textClass);
            container.innerHTML = text;

            tmpSvg.appendChild(container);
            const height = container.offsetHeight;
            tmpSvg.removeChild(container);

            maxHeight = Math.max(maxHeight, height);
            n.cHeight = maxHeight;
            if (n.data.hidden) {
              n.cWidth = 0;
            } else {
              n.cWidth = width;
            }
          });
          document.body.removeChild(tmpSvg);
          return [width, maxHeight];
        },
        nodeSorter: function (aName, aExtra, bName, bExtra) {
          return 0;
        },
        textRenderer: function (name, extra, textClass) {
          return _textRenderer(name, extra, textClass);
        }
      },
      styles: {
        node: 'node',
        linage: 'linage',
        marriage: 'marriage',
        text: 'nodeText'
      }
    });

    const data = this._preprocess(this.cs.getRoot(), this.opts);

    this.root = data.root;
    this.siblings = data.sibs;
    this.constructTree(data.root);
    this.create();

    // give it an ID so that we can print it
    // const tmpsvg = document.getElementById('graph').getElementsByTagName('svg').item(0);
    // tmpsvg.setAttribute('id', 'svgelement');

    // and now the title
    this.svg.append('text')
      .attr('x', 0)
      .attr('y', 70)
      .attr('text-anchor', 'middle')
      .style('font-size', '24px')
      .style('font-weight', 'bold')
      .style('text-decoration', 'none')
      .text('Descendants Chart for Alexander Morrison');
  }

  _preprocess(data, options) {
    const sibs = [];
    let id = 0;
    const opts = options;
    const root = {name: '', id: id++, hidden: true, children: []};

    function _sortPersons(persons) {
      if (persons !== undefined) {
        persons.sort(function (a, b) {
          return opts.callbacks.nodeSorter(a.name, a.extra, b.name, b.extra);
        });
      }
      return persons;
    }

    function _sortMarriages(marriages) {
      if (marriages !== undefined && Array.isArray(marriages)) {
        marriages.sort(function (marriageA, marriageB) {
          const a = marriageA.spouse;
          const b = marriageB.spouse;
          return opts.callbacks.nodeSorter(a.name, a.extra, b.name, b.extra);
        });
      }
      return marriages;
    }

    function reconstructTree(person, parent) {
      // convert to person to d3 node
      const node = {
        name: person.name,
        id: id++,
        hidden: false,
        children: [],
        noParent: false,
        extra: person.extra,
        textClass: person.textClass ? person.textClass : opts.styles.text,
        class: person.class ? person.class : opts.styles.node
      };

      // hide linages to the hidden root node
      if (parent === root) {
        node.noParent = true;
      }

      // apply depth offset
      for (let i = 0; i < person.depthOffset; i++) {
        const pushNode = {
          name: '',
          id: id++,
          hidden: true,
          children: [],
          noParent: node.noParent
        };

        parent.children.push(pushNode);
        parent = pushNode;
      }

      // sort children
      if (person !== undefined && person.children !== undefined) {
        _sortPersons(person.children);

        // add "direct" children
        let pindex;
        for (pindex = 0; pindex < person.children.length; ++pindex) {
          reconstructTree(person.children[pindex], node);
        }
      }

      parent.children.push(node);

      // sort marriages
      if (person !== undefined && person.marriages !== undefined) {

        _sortMarriages(person.marriages);

        // go through marriage
        let mindex = 0;
        for (mindex = 0; mindex < person.marriages.length; ++mindex) {
          const marriage = person.marriages[mindex];

          const m = {
            name: '',
            id: id++,
            hidden: true,
            noParent: true,
            children: [],
            extra: marriage.extra
          };

          const sp = marriage.spouse;

          const spouse = {
            name: sp.name,
            id: id++,
            hidden: false,
            noParent: true,
            children: [],
            textClass: sp.textClass ? sp.textClass : opts.styles.text,
            class: sp.class ? sp.class : opts.styles.node,
            extra: sp.extra,
            marriageNode: m
          };

          parent.children.push(m, spouse);

          _sortPersons(marriage.children);

          if (marriage.children !== undefined && Array.isArray(marriage.children)) {
            let pmindex = 0;
            for (pmindex = 0; pmindex < marriage.children.length; ++pmindex) {
              reconstructTree(marriage.children[pmindex], m);
            }
          }

          sibs.push({
            source: {
              id: node.id
            },
            target: {
              id: spouse.id
            },
            number: mindex
          });
        }
      }
    }

    let index;
    for (index = 0; index < data.length; ++index) {
      reconstructTree(data[index], root);
    }

    //console.log(root);

    const hroot = (<any>d3).hierarchy(root);

    //console.log(hroot);

    return {root: hroot, sibs: sibs};
  }

  ngOnChanges() {
  }

  constructTree(croot) {
    // flatten nodes
    this.allNodes = this._flatten(croot);

    // Calculate node size
    const visibleNodes = _.filter(this.allNodes, function (n) {
      return !n.hidden;
    });

    this.nodeSize = this.opts.callbacks.nodeSize(visibleNodes, this.opts.nodeWidth, this.opts.callbacks.textRenderer);
  }

  // Tree building functions

  create() {
    const options = this.opts;
    const nSize = this.nodeSize;

    const width = options.width + options.margin.left + options.margin.right;
    const height = options.height + options.margin.top + options.margin.bottom;

    const zoomSVG = (<any>d3).zoom()
      .scaleExtent([0.1, 10])
      .on('zoom', function () {
        svg.attr('transform', (<any>d3.event).transform.translate(width / 2, options.margin.top));
      });

    // make an SVG
    const svg = this.svg = d3.select(options.target)
      .append('svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('width', width)
      .attr('height', height)
      .attr('id', 'svgelement')
      .call(zoomSVG)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + options.margin.top + ')');

    // Compute the layout.
    const tree = (<any>d3).tree().nodeSize([nSize[0] * 2, nSize[1] * 1.25]);

    tree.separation(function separation(a: any, b: any) {
      if (a.data.hidden || b.data.hidden) {
        return 0.3;
      } else {
        return 0.6;
      }
    });

    const rtree = tree(this.root);
    const hnodes = rtree.descendants();
    const hlinks = rtree.links();

    function _elbow(d, i) {
      if (d.target.data.noParent) {
        return 'M0,0L0,0';
      }

      const ny = d.target.y + .5 * (d.source.y - d.target.y);

      const linedata = [{
        x: d.target.x,
        y: d.target.y
      }, {
        x: d.target.x,
        y: ny
      }, {
        x: d.source.x,
        y: d.source.y
      }];

      const fun = (<any>d3).line().curve((<any>d3).curveStepAfter)
        .x(function (v) {
          return v.x;
        })
        .y(function (v) {
          return v.y;
        });
      return fun(linedata);
    }

    // Create the link lines.
    this.svg.selectAll('.link')
      .data(hlinks)
      .enter()
      // filter links with no parents to prevent empty nodes
      .filter(function (l: any) {
        return !l.target.data.noParent;
      })
      .append('path')
      .attr('class', options.styles.linage)
      .attr('d', _elbow);

    this._linkSiblings();

    const tnodes = this.svg.selectAll('.node')
      .data(hnodes)
      .enter();

    // Draw siblings (marriage)
    this.svg.selectAll('.sibling')
      .data(this.siblings)
      .enter()
      .append('path')
      .attr('class', options.styles.marriage)
      .attr('d', _.bind(this._siblingLine, this));

    // Create the node rectangles.
    tnodes.append('foreignObject')
      .filter(function (d) {
        return !d.data.hidden;
      })
      .attr('x', function (d) {
        return d.x - d.cWidth / 2 + 'px';
      })
      .attr('y', function (d) {
        return d.y - d.cHeight / 2 + 'px';
      })
      .attr('width', function (d) {
        return d.cWidth + 'px';
      })
      .attr('height', function (d) {
        return d.cHeight + 'px';
      })
      .attr('id', function (d) {
        return d.data.id;
      })
      .html(function (d) {
        return options.callbacks.nodeRenderer(
          d.data.name,
          d.x,
          d.y,
          nSize[0],
          nSize[1],
          d.data.extra,
          d.data.id,
          d.data.class,
          d.data.textClass,
          options.callbacks.textRenderer);
      })
      .on('click', function (d) {
        if (d.data.hidden) {
          return;
        }
        options.callbacks.nodeClick(d.data.name, d.data.extra, d.data.id);
      });
  }

  _flatten(root) {
    const n = [];
    let i = 0;

    function recurse(node) {
      if (node.children !== undefined) { // && Array.isArray(node.children)
        for (let c = 0, len = node.children.length; c < len; c++) {
          recurse(node.children[c]);
        }
      }
      if (node.data.id === undefined) {
        node.data.id = ++i;
      }
      // console.log(node);
      n.push(node);
    }

    recurse(root);
    return n;
  }

  _linkSiblings() {
    const e = this.allNodes;
    _.forEach(this.siblings, function (t) {
      // find the node in ALLNODES that is this sibling...
      const n = e.filter(function (v) {
        return t.source.id === v.data.id;
      })
        , r = e.filter(function (v) {
        return t.target.id === v.data.id;
      });

      t.source.x = n[0].x;
      t.source.y = n[0].y;
      t.target.x = r[0].x;
      t.target.y = r[0].y;

      let a = null;
      if (n[0].data.marriageNode !== undefined && n[0].data.marriageNode !== null) {
        a = n[0].data.marriageNode.id;
      } else if (r[0].data.marriageNode !== undefined && r[0].data.marriageNode !== null) {
        a = r[0].data.marriageNode.id;
      }

      if (a !== undefined && a !== null) {
        const i = e.find(function (v) {
          return v.data.id === a;
        });
        t.source.marriageNode = i;
        t.target.marriageNode = i;
      }

    });
  }

  _siblingLine(d, i) {
    let ny = d.target.y + (d.source.y - d.target.y) * 0.50;
    const nodeWidth = this.nodeSize[0]; // 100
    const nodeHeight = this.nodeSize[1]; // 30

    // Not first marriage
    if (d.number > 0) {
      ny -= nodeHeight * 8 / 10;
    }

    const linedata = [{
      x: d.source.x,
      y: d.source.y
    }, {
      x: d.source.x + nodeWidth * 6 / 10,
      y: d.source.y
    }, {
      x: d.source.x + nodeWidth * 6 / 10,
      y: ny
    }, {
      x: d.target.marriageNode.x,
      y: ny
    }, {
      x: d.target.marriageNode.x,
      y: d.target.y
    }, {
      x: d.target.x,
      y: d.target.y
    }];

    const fun = (<any>d3).line().curve((<any>d3).curveStepAfter)
      .x(function (v) {
        return v.x;
      })
      .y(function (v) {
        return v.y;
      });
    return fun(linedata);
  }
}
