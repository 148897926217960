import { ErrorHandler, Injector, NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER} from '@angular/core';
import { KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule} from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { NgbModule, NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard} from './auth.guard';
import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';
import { ExtraOptions, RouterModule, Routes} from '@angular/router';
import { UtilsService} from './shared/utils.service';
import { AutocompleteService } from './shared/autocomplete.service';
import { RequiredIfDirective } from './person/required-if.directive';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { PublicComponent } from './public/public.component';
import { HeaderComponent} from './layout/header/header.component';
import { FooterComponent} from './layout/footer/footer.component';
import { NavigationComponent} from './layout/navigation/navigation.component';
import { MainComponent} from './layout/main/main.component';
import { LayoutModule } from './layout/layout.module';
import { PagenotfoundComponent } from './errors/pagenotfound.component';
import { DashboardModule} from './dashboard/dashboard.module';
import { DashboardResolver} from './dashboard/dashboard-resolver.service';
import { DashboardComponent} from './dashboard/dashboard.component';
import { ChartComponent } from './feature/chart/chart.component';
import { ChartModule } from './feature/chart/chart.module';
import { PersonModule} from './person/person.module';
import { PersonlistResolver} from './person/list/personlist-resolver.service';
import { PersonlistComponent} from './person/list/personlist.component';
import { PersontabsComponent} from './person/persontabs.component';
import { DetailsComponent} from './person/details/details.component';
import { SpouseComponent} from './person/family/spouse.component';
import { PedigreeComponent } from './feature/pedigree/pedigree.component';
import { LifeEventlistComponent} from './person/lifeevent/lifeeventlist.component';
import { LifeEventlistResolver} from './person/lifeevent/lifeeventlist-resolver.service';
import { PersonmediaComponent} from './person/media/personmedia.component';
import { PersonsourceComponent} from './person/source/personsource.component';
import { LocationModule } from './location/location.module';
import { LocationComponent} from './location/location.component';
import { LocationlistComponent} from './location/list/locationlist.component';
import { LocationlistResolver} from './location/list/locationlist-resolver.service';
import { SourceModule} from './source/source.module';
import { SourceComponent} from './source/source.component';
import { SourcelistComponent} from './source/list/sourcelist.component';
import { GedcomComponent} from './gedcom/gedcom.component';
import { GedcomModule} from './gedcom/gedcom.module';
import { RepositoryModule} from './repository/repository.module';
import { RepositoryComponent} from './repository/repository.component';
import { RepositorylistComponent} from './repository/list/repositorylist.component';
import { RepositorylistResolver} from './repository/list/repositorylist-resolver.service';
import { ContactComponent } from './feature/contact/contact.component';
import { SettingsComponent } from './feature/settings/settings.component';
import { AccountComponent } from './feature/account/account.component';
import { PlanComponent } from './feature/plan/plan.component';
import { GlobalErrorHandler } from './shared/globalerrorhandler';
import { LocatorService } from './shared/locator.service';
import { initializer } from './app-init/app-init.component';
import { AgmCoreModule} from '@agm/core';
import { QuillModule } from 'ngx-quill';
import { SharedPipesModule } from './shared/shared-pipes.module';
import { InterceptorService } from './shared/interceptor.service';
import { PedigreeChartComponent } from './person/chart/pedigree-chart.component';
import { SourcelistResolver} from './source/list/sourcelist-resolver.service';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

const config: ExtraOptions = { onSameUrlNavigation: 'reload' };

const appRoutes: Routes = [
    { path: '',                component: PublicComponent,
                               pathMatch: 'full'
    },
    { path: 'public',          component: PublicComponent },
    { path: 'app-footer',      component: FooterComponent
    },
    { path: 'app-footer-only', component: FooterComponent
    },
    { path: 'navigation',      component: NavigationComponent,
                               canActivate: [AuthGuard]
    },
    { path: 'header',          component: HeaderComponent,
                               canActivate: [AuthGuard]
    },
    { path: 'dashboard',       component: MainComponent,
                               canActivate: [AuthGuard],
                               resolve: {counts: DashboardResolver},
                               children: [ { path: '',  component: DashboardComponent }]
    },
    { path: 'personlist',      component: MainComponent,
                               canActivate: [AuthGuard],
                               resolve: { personlist: PersonlistResolver},
                               children: [ { path: '', component: PersonlistComponent, canActivate: [AuthGuard]}]
    },
    { path: 'person',          component: MainComponent,
                               canActivate: [AuthGuard],
                               children: [ { path: '', component: PersontabsComponent, canActivate: [AuthGuard]}]
    },
    { path: 'locationlist',    component: MainComponent,
                               canActivate: [AuthGuard],
                               resolve: { locationlist: LocationlistResolver},
                               children: [ { path: '', component: LocationlistComponent, canActivate: [AuthGuard]}]
    },
    { path: 'location',        component: MainComponent,
                               canActivate: [AuthGuard],
                               children: [ { path: '', component: LocationComponent, canActivate: [AuthGuard]}]
    },
    { path: 'sourcelist',      component: MainComponent,
                               canActivate: [AuthGuard],
                               resolve: { sourcelist: SourcelistResolver},
                               children: [ { path: '', component: SourcelistComponent, canActivate: [AuthGuard]}]
    },
    { path: 'source',          component: MainComponent,
                               canActivate: [AuthGuard],
                               children: [ { path: '', component: SourceComponent, canActivate: [AuthGuard]}]
    },
    { path: 'detail',          component: DetailsComponent,
                               canActivate: [AuthGuard]
    },
    { path: 'spouse',          component: SpouseComponent,
                               canActivate: [AuthGuard]
    },
    { path: 'lifeevent',       component: LifeEventlistComponent,
                               canActivate: [AuthGuard],
                               resolve: { events: LifeEventlistResolver}
    },
    { path: 'personsource',    component: PersonsourceComponent,
                               canActivate: [AuthGuard]
    },
    { path: 'personmedia',     component: PersonmediaComponent,
                               canActivate: [AuthGuard]
    },
    { path: 'settings',        canActivate: [AuthGuard],
                               component: SettingsComponent
    },
    { path: 'account',         canActivate: [AuthGuard],
                               component: AccountComponent
    },
    { path: 'plan',            canActivate: [AuthGuard],
                               component: PlanComponent
    },
    { path: 'repositorylist',  component: MainComponent,
                               canActivate: [AuthGuard],
                               resolve: { repositorylist: RepositorylistResolver},
                               children: [ { path: '', component: RepositorylistComponent, canActivate: [AuthGuard]}]
    },
    { path: 'repository',      component: MainComponent,
                               canActivate: [AuthGuard],
                               children: [ { path: '', component: RepositoryComponent, canActivate: [AuthGuard]}]
    },
    { path: 'charts',          component: ChartComponent,
                               canActivate: [AuthGuard]
    },
    { path: 'pedigree',        component: PedigreeChartComponent,
                               canActivate: [AuthGuard]
    },
    { path: 'contact',         component: ContactComponent,
                               canActivate: [AuthGuard]
    },
    { path: 'gedcom',          component: MainComponent,
                               canActivate: [AuthGuard],
                               children: [ { path: '',  component: GedcomComponent }]
    }
];

@NgModule({
    declarations: [
        AppComponent,
        PublicComponent,
        PagenotfoundComponent,
        PlanComponent,
        SettingsComponent,
        AccountComponent,
        ContactComponent,
        PedigreeComponent,
        RequiredIfDirective,
    ],
    imports: [
        KeycloakAngularModule,
        BrowserModule,
        FontAwesomeModule,
        BrowserAnimationsModule,
        NgxWebstorageModule.forRoot(),
        LayoutModule,
        DashboardModule,
        PersonModule,
        LocationModule,
        SourceModule,
        NgbModule,
        NgbCollapseModule,
        ReactiveFormsModule,
        HttpClientModule,
        ChartModule,
        GedcomModule,
        RepositoryModule,
        QuillModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyApOsTq4qTfBZ5yLiJ1VSYNJkR1jtl9bVo',
            libraries: ['places']
        }),
        RouterModule.forRoot(appRoutes, config)
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService]
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        SharedPipesModule,
        UtilsService,
        AutocompleteService,
        CookieService,
        AuthGuard
    ],
    bootstrap: [AppComponent],
    exports: [],
    schemas: [NO_ERRORS_SCHEMA]
})

export class AppModule {
    constructor(private injector: Injector, library: FaIconLibrary) {
        LocatorService.injector = injector;
        library.addIconPacks(fas, far, fab);
    }
}
