import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, Observable} from 'rxjs';
import { Router, ActivatedRoute} from '@angular/router';
import { RepositoryCreate} from './repositorycreate';
import { share } from 'rxjs/operators';
import { Repository} from './repository';
import { UtilsService} from '../shared/utils.service';

@Injectable()
export class RepositoryService {

    private repositoryName = new BehaviorSubject<string>('');
    nameOfRepository = this.repositoryName.asObservable();

    constructor(private   ms: NgbModal,
                protected http: HttpClient,
                private   router: Router,
                private   route: ActivatedRoute,
                private   utils: UtilsService) {
    }

    setNameOfRepository(name: string) {
        this.repositoryName.next(name);
    }

    getDefaultRepositories(): Observable<any> {
        return this.getRepositories(1, 25,
            '', 'ASC',
            '', '').pipe(share());
    }

    getRepositories(page, itemsPerPage,
               filterName, filterNameDir,
               filterAddress, filterAddressDir): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };

        let params = '';
        if (filterName != null && filterName !== '') {params = params + '&filterName=' + filterName; }
        if (filterNameDir != null && filterNameDir !== '') {params = params + '&filterNameDir=' + filterNameDir; }
        if (filterAddress != null && filterAddress !== '') {params = params + '&filterAddress=' + filterAddress; }
        if (filterAddressDir != null && filterAddressDir !== '') {params = params + '&filterAddressDir=' + filterAddressDir; }

        if (page.length === 0) {
           page = 1;
        }
        if (itemsPerPage.length === 0) {
           itemsPerPage = 10;
        }
        if (params.length === 0) {
            params = params + '&filterNameDir=ASC';
        }
        const URL = this.utils.getGENURL() + '/gen/repositorys' + '?pg=' + page + ',' + itemsPerPage + params;
        return this.http.get<any>(URL, httpOptions);
    }

    getRepository(id: number): Observable<Repository> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<Repository>(this.utils.getGENURL() + '/gen/repositorys/repository/' + id , httpOptions);
    }

    quickAdd(name: string): Observable<any> {
        const r: RepositoryCreate = new RepositoryCreate();
        r.name = name;
        r.detail =  '';
        r.email =  '';
        r.address =  '';
        r.phoneNum =  '';
        r.url =  '';
        r.notes =  '';
        r.metadata =  '';
        r.transactionId = this.utils.uuid();
        r.version = '4444';

        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        const URLString = this.utils.getGENURL() + '/gen/repositorys/repository/' + this.utils.uuid();
        return this.http.post(URLString, JSON.stringify(r), httpOptions);
    }

    saveRepository(id: string, body: string, version: string): Observable<any> {
        if (id === '' || id === null) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utils.getGENURL() + '/gen/repositorys/repository/' + this.utils.uuid();
            return this.http.post(URLString, body, httpOptions);
        } else {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json',
                    'If-Match': version
                })
            };
            const URLString = this.utils.getGENURL() + '/gen/repositorys/repository/' + this.utils.uuid() + '/' + id;
            return this.http.put(URLString, body, httpOptions);
        }
    }

    deleteRepository(id: string): Observable<any> {
        if (id !== '' && id !== null) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utils.getGENURL() + '/gen/repositorys/repository/' + id;
            return this.http.delete(URLString, httpOptions);
        }
    }

    downloadRepositoryLog(id: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json',
                'Observe': 'body'
            }), responseType: 'blob' as 'blob'}
        ;
        return this.http.get(this.utils.getGENURL() + '/gen/charting/repositorylisting/' + id, httpOptions);
  }
}
