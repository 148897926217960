import { IRelationshipUpdate } from '../../models/irelationshipupdate';
import { LifeEventUpdate} from '../lifeevent/lifeeventupdate';

export class RelationshipUpdate implements IRelationshipUpdate {
    id:                  string;
    transactionId:       string;
    relationType:        string;
    personId:            string;
    personRole:          string;
    relationPersonId:    string;
    relationPersonRole:  string;
    marriage:            LifeEventUpdate;
    childrenIds:         string[];

    constructor() {
        this.id = '';
        this.transactionId = '';
        this.relationType = '';
        this.personId = '';
        this.personRole = '';
        this.relationPersonId = '';
        this.relationPersonRole = '';
        this.marriage = new LifeEventUpdate();
        this.childrenIds = [];
    }

    toJSON() {
        return {
            id:                  this.id,
            transactionId:       this.transactionId,
            relationType:        this.relationType,
            personId:            this.personId,
            personRole:          this.personRole,
            relationPersonId:    this.relationPersonId,
            relationPersonRole:  this.relationPersonRole,
            marriage:            this.marriage,
            childrenIds:         this.childrenIds
        };
    }}
