import { Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { LocationComponent } from '../location.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location} from '../location';
import { LocationService} from '../location.service';
import { UtilsService} from '../../shared/utils.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from '../../shared/pagination.service';
import { FormControl, FormGroup} from '@angular/forms';

@Component({
  templateUrl: './locationlist.component.html',
  styleUrls: ['./locationlist.component.scss']
})
export class LocationlistComponent implements OnInit, OnDestroy {

    @ViewChild(LocationComponent, {static: true}) locationComponent: LocationComponent;
    locations: Location[];
    itemsPerPage = 25;
    page = 1;
    pager: any = {};

    isDescriptionDesc = true;
    isCountryDesc = true;
    isTypeDesc = true;

    isSortDescription = false;
    isSortCountry = false;
    isSortType = false;

    closeResult: string;
    locationSortOptions: Array<any>;

    filterForm: FormGroup;
    filterDescription = new FormControl();
    filterCountry = new FormControl();
    filterType = new FormControl();
    filterMLatitude = new FormControl();
    filterMLongitude = new FormControl();
    filterMLink = new FormControl();
    filterMPhoto = new FormControl();

    sortTable = new FormControl();

    filterCountries: Array<any>;
    filterTypes: Array<any>;
    filterLabel: string;

    dataLoaded: Promise<boolean>;
    DESC = 'DESC';
    ASC = 'ASC';

    constructor(private locationService: LocationService,
                private utilsService: UtilsService,
                private router: Router,
                private modalService: NgbModal,
                private activatedRoute: ActivatedRoute,
                private pagerService: PaginationService) {
    }

    public ngOnInit(): void {
        console.log('LocationList ngOnInit()');
        this.filterLabel = 'Show Filter';
        this.filterForm = new FormGroup({
            filterDescription: this.filterDescription,
            filterCountry: this.filterCountry,
            filterType: this.filterType,
            filterMLatitude: this.filterMLatitude,
            filterMLongitude: this.filterMLongitude,
            filterMLink: this.filterMLink,
            filterMPhoto: this.filterMPhoto,
            sortTable: this.sortTable
        });
        this.filterCountries = this.utilsService.getCountries();
        this.filterTypes = this.utilsService.getLocationTypes();
        this.locationSortOptions = this.utilsService.getLocationSortOptions();

        // Execute a function when the user releases a key on the keyboard
        document.getElementById('filterDescription').addEventListener('keyup', function(event) {
            // Number 13 is the 'Enter' key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        // Execute a function when the user releases a key on the keyboard
        document.getElementById('filterCountry').addEventListener('keyup', function(event) {
            // Number 13 is the 'Enter' key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        // Execute a function when the user releases a key on the keyboard
        document.getElementById('filterType').addEventListener('keyup', function(event) {
            // Number 13 is the 'Enter' key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        // set all the filter fields, if they have been set from another page...
        this.locationService.setParams('pg', '1,25');
        this.page = 1;

        this.filterDescription.setValue(this.getLocalParam(this.locationService.DESCR));
        this.filterCountry.setValue(this.getLocalParam(this.locationService.CNTRY));
        this.filterType.setValue(this.getLocalParam(this.locationService.TYPE));
        this.sortTable.setValue(this.getLocalParam(this.locationService.SORT), {onlySelf: true});
        this.setSortOrder(this.getLocalParam(this.locationService.SORT));

        this.filterMLatitude.setValue(this.getLocalParam(this.locationService.MLAT));
        this.filterMLongitude.setValue(this.getLocalParam(this.locationService.MLON));
        this.filterMLink.setValue(this.getLocalParam(this.locationService.MLINK));
        this.filterMPhoto.setValue(this.getLocalParam(this.locationService.MPHOTO));

        // this is set from the RESOLVER
        this.locations = <Location[]>(this.activatedRoute.snapshot.data.locationlist).items || null;
        const locationListTotal = (this.activatedRoute.snapshot.data.locationlist).total;
        let lindex = 0;
        while (lindex < this.locations.length) {
            this.locations[lindex].countryLabel = this.utilsService.getCountryLabel(this.locations[lindex].country);
            lindex = lindex + 1;
        }

        this.pager = this.pagerService.getPager(locationListTotal, this.page, this.itemsPerPage);
        this.dataLoaded = Promise.resolve(true);
    }

    ngOnDestroy() {}

    getLocalParam(key: string): string {
        return this.locationService.getParamValue(key);
    }

    setLocalParam(key: string, value: string) {
        this.locationService.setParams(key, value) ;
    }

    loadData(selectedPage: number) {
        this.locationService.clearParams();
        this.page = selectedPage;

        this.setLocalParam(this.locationService.PG, selectedPage + ',' + this.itemsPerPage);
        this.setLocalParam(this.locationService.DESCR, this.filterDescription.value);
        this.setLocalParam(this.locationService.CNTRY, this.filterCountry.value);
        this.setLocalParam(this.locationService.TYPE, this.filterType.value);
        this.setLocalParam(this.locationService.DESCRDIR, (this.isSortDescription ? (this.isDescriptionDesc ? this.DESC : this.ASC) : ''));
        this.setLocalParam(this.locationService.CNTRYDIR, (this.isSortCountry ? (this.isCountryDesc ? this.DESC : this.ASC) : ''));
        this.setLocalParam(this.locationService.TYPEDIR, (this.isSortType ? (this.isTypeDesc ? this.DESC : this.ASC) : ''));
        this.setLocalParam(this.locationService.MLAT, this.filterMLatitude.value);
        this.setLocalParam(this.locationService.MLON, this.filterMLongitude.value);
        this.setLocalParam(this.locationService.MLINK, this.filterMLink.value);
        this.setLocalParam(this.locationService.MPHOTO, this.filterMPhoto.value);

        this.locationService.getLocations()
            .toPromise().then(locationList => {
            this.locations = <Location[]>locationList.items || null;
            const locationListTotal = locationList.total;
            let lindex = 0;
            while (lindex < this.locations.length) {
                this.locations[lindex].countryLabel = this.utilsService.getCountryLabel(this.locations[lindex].country);
                lindex = lindex + 1;
            }
            this.pager = this.pagerService.getPager(locationListTotal, selectedPage, this.itemsPerPage);
            this.dataLoaded = Promise.resolve(true);
        });
    }

    setPager(locationListTotal, selectedPage) {
        this.pager = this.pagerService.getPager(locationListTotal, selectedPage, this.itemsPerPage);
    }

    checkboxFilter(event, filterName) {
        // these values come from the HTML
        if (filterName === 'mLat') {
            this.filterMLatitude.setValue(event.target.checked ? 'true' : '');
        } else if (filterName === 'mLon') {
            this.filterMLongitude.setValue( event.target.checked ? 'true' : '');
        } else if (filterName === 'mLink') {
            this.filterMLink.setValue(  event.target.checked ? 'true' : '');
        } else if (filterName === 'mPhoto') {
            this.filterMPhoto.setValue(event.target.checked ? 'true' : '');
        }
    }

    setSortOrder(column) {
        // ascending is the default
        this.isDescriptionDesc = false;
        this.isCountryDesc = false;
        this.isTypeDesc = false;

        this.isSortDescription = false;
        this.isSortCountry = false;
        this.isSortType = false;

        // this.setLocalParam(this.locationService.SORT, column);

        if (column === 'descAsc') {
            this.isSortDescription = true;
        } else if (column === 'descDesc') {
            this.isDescriptionDesc = true;
            this.isSortDescription = true;
        } else if (column === 'countryAsc') {
            this.isSortCountry = true;
        } else if (column === 'countryDesc') {
            this.isCountryDesc = true;
            this.isSortCountry = true;
        } else if (column === 'typeAsc') {
            this.isSortType = true;
        } else if (column === 'typeDesc') {
            this.isTypeDesc = true;
            this.isSortType = true;
        }
    }

    setLocation(id: string) {
        this.router.navigate(['/location'], {queryParams: { id: id }});
    }

    handleClick() {
        this.filterLabel = (this.filterLabel === 'Show Filter' ? 'Hide Filter' : 'Show Filter');
    }

    locationSortBy(event) {
        this.setLocalParam(this.locationService.SORT, event.target.value);
        this.setSortOrder(event.target.value);
        this.loadData(this.page);
    }

    clearFilters() {
        this.filterDescription.setValue('');
        this.filterCountry.setValue('');
        this.filterType.setValue('');
        this.filterMLatitude.setValue('');
        this.filterMLongitude.setValue('');
        this.filterMLink.setValue('');
        this.filterMPhoto.setValue('');
        this.sortTable.setValue('');
        this.setSortOrder('');
        this.loadData(1);
    }

}
