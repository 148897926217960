import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import { UtilsService} from '../../shared/utils.service';

export function MarriageIncorrect(utils: UtilsService): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
        const marriageDay = formGroup.controls['marriageDay'];
        const marriageMonth = formGroup.controls['marriageMonth'];
        const marriageYear = formGroup.controls['marriageYear'];

        const isValidMarriageDate = utils.isValidDate
        (+marriageDay.value, +marriageMonth.value, +marriageYear.value);

        if (isValidMarriageDate) {
            marriageDay.setErrors(null);
            marriageMonth.setErrors(null);
            marriageYear.setErrors(null);
        } else {
            marriageDay.setErrors({dateIncorrect: true});
            marriageMonth.setErrors({dateIncorrect: true});
            marriageYear.setErrors({dateIncorrect: true});
        }

        if (isValidMarriageDate) {
            return null;
        } else {
            return { 'MarriageIncorrect': true};
        }

    };
}
