import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Descendant } from './descendant';
// tslint:disable-next-line:import-blacklist
import {Subscription} from 'rxjs';
import {UtilsService} from '../../shared/utils.service';
import {PersonService} from '../person.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-descendant-report',
  templateUrl: './descendant-report.component.html',
  styleUrls: ['./descendant-report.component.scss']
})
export class DescendantReportComponent implements OnInit, OnDestroy {
    descendantlist: Descendant[];
    activePersonName: string;
    private nameOfPersonSubscription: Subscription;

    // below  will get called when ever the items are passed in by the parent component
    @Input('descendantlist') set descendants (descendants: Descendant[]) {
        console.log('DescendantReportComponent: set descendants');
        this.descendantlist = descendants;
    }

    constructor(private utilsService: UtilsService,
              private personService: PersonService) { }

  ngOnInit() {
      console.log('DescendantReport: OnInit');
      this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe( activePersonName => {
          this.activePersonName = activePersonName;
      });
  }

    ngOnDestroy(): void {
        this.nameOfPersonSubscription.unsubscribe();
    }

}
