import {Component, OnInit, ViewChild } from '@angular/core';
import { SourceComponent } from '../source.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SourceService} from '../source.service';
import { RepositoryService} from '../../repository/repository.service';
import { UtilsService} from '../../shared/utils.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Location} from '@angular/common';
import { PaginationService } from '../../shared/pagination.service';
import { FormControl, FormGroup} from '@angular/forms';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { Source } from '../source';
import { Repository } from '../../repository/repository';

@Component({
    templateUrl: './sourcelist.component.html',
    styleUrls: ['./sourcelist.component.scss']
})
export class SourcelistComponent implements OnInit {

    @ViewChild(SourceComponent, {static: true}) sourceComponent: SourceComponent;
    sources: Source[];
    itemsPerPage = 25;
    pager: any = {};

    isTitleDesc = true;
    isRecordDesc = true;
    isRepositoryDesc = true;

    isSortTitle = false;
    isSortRecord = false;
    isSortRepository = false;

    closeResult: string;
    sourceSortOptions: Array<any>;

    filterForm: FormGroup;
    filterTitle = new FormControl();
    filterRecord = new FormControl();
    filterRepository = new FormControl();
    sortTable = new FormControl();

    filterRecords: Array<any>;
    filterRepositories: Repository[];
    filterLabel: string;

    private queryParams: Subscription;

    constructor(private sourceService: SourceService,
                private repositoryService: RepositoryService,
                private utilsService: UtilsService,
                private router: Router,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private location: Location,
                private pagerService: PaginationService) {
    }

    public ngOnInit(): void {
        this.filterForm = new FormGroup({
            filterTitle: this.filterTitle,
            filterRecord: this.filterRecord,
            filterRepository: this.filterRepository,
            sortTable: this.sortTable
        });
        this.filterRecords = this.utilsService.getRecordTypes();
        this.sourceSortOptions = this.utilsService.getSourceSortOptions();
        this.filterLabel = 'Show Filter';

        const fTitle = document.getElementById('filterTitle');

        // Execute a function when the user releases a key on the keyboard
        fTitle.addEventListener('keyup', function(event) {
            // Number 13 is the 'Enter' key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        const fRecord = document.getElementById('filterRecord');

        // Execute a function when the user releases a key on the keyboard
        fRecord.addEventListener('keyup', function(event) {
            // Number 13 is the 'Enter' key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        const fRepository = document.getElementById('filterRepository');

        // Execute a function when the user releases a key on the keyboard
        fRepository.addEventListener('keyup', function(event) {
            // Number 13 is the 'Enter' key on the keyboard
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                document.getElementById('applyFilters').click();
            }
        });

        this.queryParams = this.route.queryParams.subscribe(params => {
            const fT = (this.route.snapshot.params['title'] === undefined) ? '' : this.route.snapshot.params['title'];
            const fR = (this.route.snapshot.params['record'] === undefined) ? '' : this.route.snapshot.params['record'];
            const fRp = (this.route.snapshot.params['repository'] === undefined) ? '' : this.route.snapshot.params['repository'];
            const sortOrder = (this.route.snapshot.params['sort'] === undefined) ? '' : this.route.snapshot.params['sort'];
            this.sortTable.setValue(sortOrder, {onlySelf: true});
            this.setSortOrder(sortOrder);
            this.filterForm.patchValue({
                filterTitle: fT,
                filterRecord: fR,
                filterRepository: fRp,
                sortTable: sortOrder
            });
            this.loadPage(1);
        });

        // below is called only the first time the component is loaded
        console.log('1. Setting resolver data in ngOnInit');
        this.sources = <Source[]>(this.route.snapshot.data['sourceResolverData'][0]).items || null;
        this.filterRepositories = <Repository[]>(this.route.snapshot.data['sourceResolverData'][1]).items || null;
        const sourceListTotal = (this.route.snapshot.data['sourceResolverData'][0]).total;
        this.fixRecordType();
        this.setPager(sourceListTotal, 1);
    }

    onDestroy() {
        this.queryParams.unsubscribe();
    }

    handleClick() {
        this.filterLabel = (this.filterLabel === 'Show Filter' ? 'Hide Filter' : 'Show Filter');
    }

    loadPage(selectedPage: number) {
        this.sourceService.getSources(
            selectedPage,
            this.itemsPerPage,
            this.filterTitle.value, (this.isSortTitle ? (this.isTitleDesc ? 'DESC' : 'ASC') : ''),
            this.filterRecord.value, (this.isSortRecord ? (this.isRecordDesc ? 'DESC' : 'ASC') : ''),
            this.filterRepository.value, (this.isSortRepository ? (this.isRepositoryDesc ? 'DESC' : 'ASC') : ''))
            .toPromise().then(sourceList => {
                this.sources = <Source[]>sourceList.items;
                this.fixRecordType();
                this.setPager(sourceList.total, selectedPage);
        });
    }

    setPager(sourceListTotal, selectedPage) {
        console.log('Setting the Pager for Sourcelist');
        this.pager = this.pagerService.getPager(sourceListTotal, selectedPage, this.itemsPerPage);
    }

    fixRecordType() {
        let lindex = 0;
        while (lindex < this.sources.length) {
            const tmpSource = this.sources[lindex];
            tmpSource.recordTypeLabel = this.utilsService.getRecordType(tmpSource.recordType);
            lindex = lindex + 1;
        }
    }

    getCountryLabel(code: string): string {
        return this.utilsService.getCountryLabel(code);
    }

    private setSortOrder(column) {
        // ascending is the default
        this.isTitleDesc = false;
        this.isRecordDesc = false;
        this.isRepositoryDesc = false;

        this.isSortTitle = false;
        this.isSortRecord = false;
        this.isSortRepository = false;

        if (column === 'titleAsc') {
            this.isSortTitle = true;
        } else if (column === 'titleDesc') {
            this.isTitleDesc = true;
            this.isSortTitle = true;
        } else if (column === 'recordAsc') {
            this.isSortRecord = true;
        } else if (column === 'recordDesc') {
            this.isRecordDesc = true;
            this.isSortRecord = true;
        } else if (column === 'repositoryAsc') {
            this.isSortRepository = true;
        } else if (column === 'repositoryDesc') {
            this.isRepositoryDesc = true;
            this.isSortRepository = true;
        }
    }

    setSource(id: string) {
        this.router.navigate(['/source'], {queryParams: { id: id }});
    }

    applyFilters() {
        this.updateURL();
        this.handleClick();
    }

    sourceSortBy(event) {
        this.sortTable.setValue(event.target.value, {onlySelf: true});
        this.setSortOrder(event.target.value);
        this.updateURL();
    }

    updateURL() {
        const sort = this.sortTable.value;
        const url = this
            .router
            .createUrlTree([{title: this.filterTitle.value,
                    record: this.filterRecord.value,
                    repository: this.filterRepository.value,
                    sort: sort}],
                {relativeTo: this.route})
            .toString();
        this.location.go(url);
        this.loadPage(1);
    }

    clearFilters() {
        this.filterTitle.setValue('');
        this.filterRecord.setValue('');
        this.filterRepository.setValue('');
        this.updateURL();
    }

}
