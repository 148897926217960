import { Component, OnDestroy, OnInit} from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PersonsourceService} from './personsource.service';
import { FormControl, FormGroup} from '@angular/forms';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { PersonService} from '../person.service';

@Component({
  selector: 'app-personsource',
  templateUrl: './personsource.component.html',
  styleUrls: ['./personsource.component.scss']
})
export class PersonsourceComponent implements OnInit, OnDestroy {
    closeResult: string;
    sources: Array<any>;
    sourcesTotal: any;

    sourcesForm: FormGroup;
    sourceAge = new FormControl();
    sourceBirthDate = new FormControl();
    sourceBirthLocation = new FormControl();
    sourceDeathDate = new FormControl();
    sourceDeathLocation = new FormControl();
    sourceBurialDate = new FormControl();
    sourceBurialLocation = new FormControl();
    sourceEthnicity = new FormControl();
    sourceHistory = new FormControl();
    sourceImmigration = new FormControl();
    sourceRelatives = new FormControl();
    sourceMaidenNameorAlias = new FormControl();
    sourceMarriageDate = new FormControl();
    sourceMarriageLocation = new FormControl();
    sourceOccupation = new FormControl();
    sourcePhysical = new FormControl();
    sourcePolitical = new FormControl();
    sourceResidence = new FormControl();
    sourceReligion = new FormControl();
    sourceSocial = new FormControl();

    activePersonName: string;
    private nameOfPersonSubscription: Subscription;

    constructor(private modalService: NgbModal,
                private personService: PersonService,
                private personSourceService: PersonsourceService) { }

    ngOnInit() {
        this.sources = this.personSourceService.getSourcesForPerson(0);
        this.sourcesTotal = this.personSourceService.getSourcesTotalsForPerson(0);
        this.sourcesForm = new FormGroup({
          sourceAge: this.sourceAge,
          sourceBirthDate: this.sourceBirthDate,
          sourceBirthLocation: this.sourceBirthLocation,
          sourceDeathDate: this.sourceDeathDate,
          sourceDeathLocation: this.sourceDeathLocation,
          sourceBurialDate: this.sourceBurialDate,
          sourceBurialLocation: this.sourceBurialLocation,
          sourceEthnicity: this.sourceEthnicity,
          sourceHistory: this.sourceHistory,
          sourceImmigration: this.sourceImmigration,
          sourceRelatives: this.sourceRelatives,
          sourceMaidenNameorAlias: this.sourceMaidenNameorAlias,
          sourceMarriageDate: this.sourceMarriageDate,
          sourceMarriageLocation: this.sourceMarriageLocation,
          sourceOccupation: this.sourceOccupation,
          sourcePhysical: this.sourcePhysical,
          sourcePolitical: this.sourcePolitical,
          sourceResidence: this.sourceResidence,
          sourceReligion: this.sourceReligion,
          sourceSocial: this.sourceSocial
        });

        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe( activePersonName => {
          this.activePersonName = activePersonName;
        });
    }

    ngOnDestroy() {
        this.nameOfPersonSubscription .unsubscribe();
    }

    updateTracking(fieldId) {
      if (fieldId === 'ageTracking') {
            const value = this.sourcesForm.get('sourceAge').value;
            alert('fieldId is ' + value);
      }
    }

    open(content, size) {
        this.modalService.open(content, size).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

    // At the file input element
    // (change)='selectFile($event)'
    selectFile(event) {
        this.personSourceService.uploadFile('/server/familyId/upload', event.target.files).subscribe();
    }

}
