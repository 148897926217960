import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PersonService } from '../person.service';
import { FormControl} from '@angular/forms';

@Component({
  selector: 'app-personmedia',
  templateUrl: './personmedia.component.html',
  styleUrls: ['./personmedia.component.scss']
})
export class PersonmediaComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  closeResult: string;
  mediaForm: FormControl;

  constructor(private personService: PersonService, private modalService: NgbModal) { }

  ngOnInit() {
    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      { previewZoom: true },
      { thumbnailsRemainingCount: true },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/images/PTDC0001.JPG',
        medium: 'assets/images/PTDC0001.JPG',
        big: 'assets/images/PTDC0001.JPG',
        description: 'But I must explain to you how all this mistaken idea of denouncing ' +
        'pleasure and praising pain was born and I will give you a complete account of the system, ' +
        'and expound the actual teachings of the great explorer of the truth, the master-builder of ' +
        'human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, ' +
        'but because those who do not know how to pursue pleasure rationally encounter consequences that ' +
        'are extremely painful.'
      },
      {
        small: 'assets/images/PTDC0002.JPG',
        medium: 'assets/images/PTDC0002.JPG',
        big: 'assets/images/PTDC0002.JPG',
        description: 'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, ' +
        'because it is pain, but because occasionally circumstances occur in which toil and pain can ' +
        'procure him some great pleasure. To take a trivial example, which of us ever undertakes ' +
        'laborious physical exercise, except to obtain some advantage from it? '
      },
      {
        small: 'assets/images/PTDC0003.JPG',
        medium: 'assets/images/PTDC0003.JPG',
        big: 'assets/images/PTDC0003.JPG',
        description: 'But who has any right to find fault with a man who chooses to enjoy a pleasure that ' +
        'has no annoying consequences, or one who avoids a pain that produces no resultant pleasure? '
      },
      {
        small: 'assets/images/PTDC0006.JPG',
        medium: 'assets/images/PTDC0006.JPG',
        big: 'assets/images/PTDC0006.JPG',
        description: 'On the other hand, we denounce with righteous indignation and dislike men who are so ' +
        'beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee'
      },
      {
        small: 'assets/images/PTDC0007.JPG',
        medium: 'assets/images/PTDC0007.JPG',
        big: 'assets/images/PTDC0007.JPG'
      }
    ];
  }

  open(content, size) {
    this.modalService.open(content, size).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  public delete() {
    alert('Are you sure you want to delete this photo?');
  }

  save() {

  }

  saveMedia() {

  }
}
