import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from '../shared/utils.service';
import { ILocation} from '../models/ilocation';
import { ILocationSuggestion} from '../models/ilocationsuggestion';
// tslint:disable-next-line:import-blacklist
import { Observable,  BehaviorSubject} from 'rxjs';
import { LocalStorageService} from 'ngx-webstorage';
import {zoom} from "d3-zoom";

@Injectable()
export class LocationService {
    private update = new BehaviorSubject<boolean>(false);

    private descriptionLocation = new BehaviorSubject<string>('');
    locationDescription = this.descriptionLocation.asObservable();

    LOCATIONLIST = '.locationlist';
    PG           = 'pg';
    DESCR        = 'fDescr';
    DESCRDIR     = 'fDescrDir';
    CNTRY        = 'fCountry';
    CNTRYDIR     = 'fCountryDir';
    TYPE         = 'fType';
    TYPEDIR      = 'fTypeDir';
    SORT         = 'sort';
    MLAT         = 'mLat';
    MLON         = 'mLon';
    MLINK        = 'mLink';
    MPHOTO       = 'mPhoto';

    constructor(private   ms: NgbModal,
                protected http: HttpClient,
                private   utils: UtilsService,
                private   localStorage: LocalStorageService) {
    }

    setNameOfLocation(locationDescription: string) {
        this.descriptionLocation.next(locationDescription);
    }

    getParams(key: string, setToTrue: boolean): string {
        const value = this.localStorage.retrieve(key + this.LOCATIONLIST);
        if (value != null && value !== '') {
            if (setToTrue) {
                return '&' + key + '=true';

            } else {
                return '&' + key + '=' + value;
            }
        }
        return '';
    }

    getParamValue(key: string) {
        return this.localStorage.retrieve(key + this.LOCATIONLIST);
    }

    setParams(key: string, value: string) {
        this.localStorage.store(key + this.LOCATIONLIST, value);
    }

    clearParams() {
        this.localStorage.clear(this.PG + this.LOCATIONLIST);
        this.localStorage.clear(this.SORT + this.LOCATIONLIST);
        this.localStorage.clear(this.DESCR + this.LOCATIONLIST);
        this.localStorage.clear(this.DESCRDIR + this.LOCATIONLIST);
        this.localStorage.clear(this.CNTRY + this.LOCATIONLIST);
        this.localStorage.clear(this.CNTRYDIR + this.LOCATIONLIST);
        this.localStorage.clear(this.TYPE + this.LOCATIONLIST);
        this.localStorage.clear(this.TYPEDIR + this.LOCATIONLIST);
        this.localStorage.clear(this.MLAT + this.LOCATIONLIST);
        this.localStorage.clear(this.MLON + this.LOCATIONLIST);
        this.localStorage.clear(this.MLINK + this.LOCATIONLIST);
        this.localStorage.clear(this.MPHOTO + this.LOCATIONLIST);
    }

    getLocations(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };

        let params = '';

        params = params + this.getParams(this.PG, false);
        params = params + this.getParams(this.DESCR, false);
        params = params + this.getParams(this.DESCRDIR, false);
        params = params + this.getParams(this.CNTRY, false);
        params = params + this.getParams(this.CNTRYDIR, false);
        params = params + this.getParams(this.TYPE, false);
        params = params + this.getParams(this.TYPEDIR, false);
        params = params + this.getParams(this.MLAT, true);
        params = params + this.getParams(this.MLON, true);
        params = params + this.getParams(this.MLINK, true);
        params = params + this.getParams(this.MPHOTO, true);

        if (params.length === 0) { params = params + '&' + this.DESCRDIR + '=ASC'; }

        console.log('>>>>>> LocationService -> GetLocations() - calls the server with params ' + params);
        const URL = this.utils.getGENURL() + '/gen/locations' + '?' + params;
        return this.http.get<any>(URL, httpOptions);
    }

    getLocation(id: number): Observable<ILocation> {
        this.update.next(false);
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<ILocation>(this.utils.getGENURL() + '/gen/locations/location/' + id , httpOptions);
    }

    searchLocation(text: string): Observable<ILocationSuggestion> {
        const strippedText = text.replace(',', '');
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-search.v1+json',
                'Content-Type': 'application/json'
            })
        };
        return this.http.get<ILocationSuggestion>(this.utils.getSRCHURL() + '/search/locations/suggest/?query=' + text , httpOptions);
    }

    saveLocation(id: string, body: string, version: string): Observable<any> {
        console.log('Saving location ');

        // new location to be saved
        if (id === undefined || id === '' || id === null) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utils.getGENURL() + '/gen/locations/location/' + this.utils.uuid();
            return this.http.post(URLString, body, httpOptions);
        } else { // updated location to be saved
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json',
                    'If-Match': version
                })
            };

            const URLString = this.utils.getGENURL() + '/gen/locations/location/' + id;
            return this.http.put(URLString, body, httpOptions);
        }
    }

    deleteLocation(id: string): Observable<any> {
        if (id !== '' && id !== null) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utils.getGENURL() + '/gen/locations/location/' + id;
            return this.http.delete(URLString, httpOptions);
        }
    }

    updateList(): Observable<any> {
        return this.update.asObservable();
    }

    downloadLocationReport(locationId: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json',
                'Observe': 'body'
            }), responseType: 'blob' as 'blob'}
        ;
        return this.http.get(this.utils.getGENURL() + '/gen/charting/locationreport/' + locationId, httpOptions);
    }

}

