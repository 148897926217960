import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders} from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Observable,  BehaviorSubject} from 'rxjs';
import { UtilsService} from '../../shared/utils.service';

@Injectable()
export class LifeeventService {
    constructor(private   ms: NgbModal,
                protected http: HttpClient,
                private   utils: UtilsService) {
    }

    // these are all the events pertaining to the person
    getEvents(id: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };

        const URL = this.utils.getGENURL() + '/gen/events/person/' + id;
        return this.http.get<any>(URL, httpOptions);
    }

    getEvent(id: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'accept': 'application/vnd.kinalytic-gen.v1+json',
                'Content-Type': 'application/json'
            })
        };

        const URL = this.utils.getGENURL() + '/gen/events/event/' + id;
        return this.http.get<any>(URL, httpOptions);
    }

    saveEvent(id: string, personId: string, body: string, version: string): Observable<any> {
        if (id === '' || id === null) { // new person to be saved
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utils.getGENURL() + '/gen/events/event/' + this.utils.uuid() + '/' + personId;
            return this.http.post(URLString, body, httpOptions);
        } else { // updated event to be saved
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json',
                    'If-Match': version
                })
            };
            const URLString = this.utils.getGENURL() + '/gen/events/event/' + id + '/' + personId;
            return this.http.put(URLString, body, httpOptions);
        }
    }

    deleteEvent(id: string, personId: string): Observable<any> {
        if (id !== '' && id !== null && personId !== '' && personId !== null) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/vnd.kinalytic-gen.v1+json',
                    'Content-Type': 'application/json'
                })
            };
            const URLString = this.utils.getGENURL() + '/gen/events/event/' + id + '/' + personId;
            return this.http.delete(URLString, httpOptions);
        }
    }
}
