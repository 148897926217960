import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { RepositoryService} from '../repository.service';
import { Repository} from '../repository';

@Injectable()
export class RepositorylistResolver implements Resolve<Repository[]> {

  constructor(private rService: RepositoryService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
      return this.rService.getDefaultRepositories();
  }

}
