import { Component, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { ChartService } from './chart.service';
import * as d3 from 'd3';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector:      'app-pchart',
  templateUrl:   './pchart.component.html',
  styleUrls:     ['./pchart.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PchartComponent implements OnInit {
  private w;
  private h;
  private margin = {top: 0, right: 320, bottom: 0, left: 0};

  constructor(private cs: ChartService, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.w = window.innerWidth * .9 + 40;
    this.h = window.innerHeight * .9 - 125;
    const marginRight = 320;

    const width = 960 - this.margin.left - this.margin.right,
          height = 500 - this.margin.top - this.margin.bottom;

    // declares a tree layout and assigns the size
    const treemap = (<any>d3).tree().size([height, width]).separation(function (a, b) {
      return a.parent === b.parent ? 1 : .5;
    });

    // assigns the data to a hierarchy using parent-child relationships
    let nodes = (<any>d3).hierarchy(this.cs.getPedigree(), function (d) {
      return d.children;
    });

    // maps the node data to the tree layout
    nodes = treemap(nodes);

    const svg = d3.select('pgraph').append('svg')
                  .attr('width', width + this.margin.left + this.margin.right)
                  .attr('height', height + this.margin.top + this.margin.bottom),
    g = svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    // adds the links between the nodes
    const link = g.selectAll('.link')
      .data(nodes.descendants().slice(1))
      .enter().append('path')
      .attr('class', 'link')
      .attr('d', function (d) {
        return 'M' + (<any>d).source.y + ',' + (<any>d).source.x +
               'H' + (<any>d).target.y + 'V' + (<any>d).target.x + ((<any>d).target.children ? '' : 'h' + marginRight);
      });

    const node = svg.selectAll('.node')
      .data(nodes)
      .enter().append('g')
      .attr('class', 'node')
      .attr('transform', function (d) {
        return 'translate(' + (<any>d).y + ',' + (<any>d).x + ')';
      });

    node.append('text')
      .attr('class', 'name')
      .attr('x', 8)
      .attr('y', -6)
      .text(function (d) {
        return (<any>d).name;
      });

    node.append('text')
      .attr('x', 8)
      .attr('y', 8)
      .attr('dy', '.71em')
      .attr('class', 'about lifespan')
      .text(function (d) {
        return (<any>d).born + '–' + (<any>d).died;
      });

    node.append('text')
      .attr('x', 8)
      .attr('y', 8)
      .attr('dy', '1.86em')
      .attr('class', 'about location')
      .text(function (d) {
        return (<any>d).location;
      });

  }

}
