import { Component, OnInit, ViewEncapsulation, OnChanges } from '@angular/core';
import { ChartService } from './chart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router} from '@angular/router';
import { FormControl, FormGroup} from '@angular/forms';

@Component({
  templateUrl:   './chart.component.html',
  styleUrls:     ['./chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ChartComponent implements OnInit, OnChanges {
  descendantsChart = false;
  pedigreeChart = false;
  descendantsReport = false;
  ahnentafelReport = false;
  chartForm: FormGroup;

  constructor(private cs: ChartService, private modalService: NgbModal, private router: Router) {
  }

  ngOnInit() {
    this.chartForm = new FormGroup({
      chartPerson: new FormControl(),
      download: new FormControl()
    });
  }
  ngOnChanges() {}

  showDescendantsChart() {
    this.descendantsChart = true;
    this.pedigreeChart = false;
    this.descendantsReport = false;
    this.ahnentafelReport = false;
  }

  showPedigreeChart() {
    this.descendantsChart = false;
    this.pedigreeChart = true;
    this.descendantsReport = false;
    this.ahnentafelReport = false;
  }

  showDescendantsReport() {
    this.descendantsChart = false;
    this.pedigreeChart = false;
    this.descendantsReport = true;
    this.ahnentafelReport = false;
  }

  showAhnentafelReport() {
    this.descendantsChart = false;
    this.pedigreeChart = false;
    this.descendantsReport = false;
    this.ahnentafelReport = true;
  }

  download() {
    const masterSVG = document.getElementById('svgelement');
    const svgWidth = masterSVG.getAttribute('height');
    const svgHeight = masterSVG.getAttribute('width');

    function buildSvgImageUrl(svg) {
      const b64 = window.btoa(svg);
      return 'data:image/svg+xml;base64,' + b64;
    }

    processImage();

    function processImage() {
      const canvas = document.createElement('canvas');
      canvas.width = +svgWidth;
      canvas.height = +svgHeight;

      const ctx = canvas.getContext('2d');
      const xml  = new XMLSerializer().serializeToString(masterSVG);
      const img  = new Image();
      img.crossOrigin = 'Anonymous';

      // this function needs to be set before the img.src
      img.onload = function() {
        ctx.drawImage(img, 0, 0);
        const url = canvas.toDataURL('image/png');
        const png = new Image();
        png.src = url;
        png.crossOrigin = 'Anonymous';

        document.getElementById('pnggraph').appendChild(png);
        URL.revokeObjectURL(url);
      };

      img.src = buildSvgImageUrl(xml);

      img.onerror = function(error) {
        console.log(error);
        // processImage();
      };
    }
  }

}
