import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class PersonsourceService {

  constructor(private http: HttpClient) { }

  getSourcesForPerson(id: number) {
    return SOURCES;
  }

  getSourcesTotalsForPerson(id: number) {
    return SOURCES_TOTALS;
  }

  // file from event.target.files[0]
  uploadFile(url: string, file: File): Observable<HttpEvent<any>> {

    const formData = new FormData();
    formData.append('upload', file);

    const params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req);
  }

}

const SOURCES = [
  { SourceID: 1,
    Description: '1901 Census of Canada, Halton, Oakville, Ontario, Canada',
    People: [ {Name: 'Donald McDonald', PersonId: 12},
      {Name: 'Mary McDonald',   PersonId: 14},
      {Name: 'Angus McDonald',  PersonId: 19}],
    age : false,
    birthDate : true,
    birthLocation : true,
    deathDate : false,
    deathLocation : false,
    ethnicity : true,
    history : false,
    immigration : false,
    relatives : true,
    maidenName : false,
    marriageDate : false,
    marriageLocation : false,
    occupation : true,
    physicalDescription : false,
    residence : true,
    religion : true,
    socialActivity : false
  },
  { SourceID: 2,
    Description: '1911 Census of Canada, York, Toronto, Ontario, Canada',
    People: [ {Name: 'Donald McDonald', PersonId: 12},
      {Name: 'Mary McDonald',   PersonId: 14}],
    age : true,
    birthDate : false,
    birthLocation : true,
    deathDate : false,
    deathLocation : false,
    ethnicity : false,
    history : false,
    immigration : false,
    relatives : true,
    maidenName : false,
    marriageDate : false,
    marriageLocation : false,
    occupation : true,
    physicalDescription : false,
    residence : true,
    religion : false,
    socialActivity : false
  }
];

const SOURCES_TOTALS = {
  age : 1,
  ageTracking : true,
  birthDate : 1,
  birthDateTracking : true,
  birthLocation : 2,
  birthLocationTracking : true,
  deathDate : 0,
  deathDateTracking : true,
  deathLocation : 0,
  deathLocationTracking : true,
  ethnicity : 1,
  ethnicityTracking : false,
  history : 0,
  historyTracking : false,
  immigration : 0,
  immigrationTracking : true,
  relatives : 2,
  relativesTracking : true,
  maidenName : 0,
  maidenNameTracking : true,
  marriageDate : 0,
  marriageDateTracking : false,
  marriageLocation : 0,
  marriageLocationTracking : true,
  occupation : 2,
  occupationTracking : true,
  physicalDescription : 0,
  physicalDescriptionTracking : false,
  residence : 2,
  residenceTracking : true,
  religion : 1,
  religionTracking : true,
  socialActivity : 0,
  socialActivityTracking : true
};
