import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { LifeeventService} from './lifeevent.service';
import { ILifeEvent} from '../../models/ilifeevent';

@Injectable()
export class LifeEventlistResolver implements Resolve<ILifeEvent[]> {

  constructor(private lifeeventService: LifeeventService) { }

  resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<any> {
        const id = route.paramMap.get('id');
        return this.lifeeventService.getEvents(id);
  }

}
