import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { PersonService} from '../person.service';
import { ActivatedRoute, Router} from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { Person} from '../person';
import { PersonUpdate} from '../personupdate';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit, OnDestroy {
    private routeSubscription: Subscription;
    private loadPage: Subscription;
    private nameOfPersonSubscription: Subscription;

    activePersonName: string;
    activeId: string;
    activeGender: string;
    note: string;
    editorStyle = {
        height: '500px'
    };

    constructor(private personService: PersonService,
                private ref: ChangeDetectorRef,
                private route: ActivatedRoute) { }

    ngOnInit() {
        this.routeSubscription = this.route.queryParams.subscribe(params => {
            this.activeId = params['id'];
            this.activeGender = params['g'];
        });

        this.loadPage = this.personService.showStory.subscribe(loadData => {
            console.log('>>> Load page - showStory');
            this.showStory();
        });

        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe( activePersonName => {
            this.activePersonName = activePersonName;
        });

    }

    showStory() {
        this.personService.getPerson(+this.activeId).toPromise().then(person => {
            const activePerson = <Person>person;
            this.note = (activePerson.notes != null) ? activePerson.notes : '';
        });
    }

    saveStory() {
        const p: PersonUpdate = new PersonUpdate();
        p.notes = this.note;
        document.getElementById('editor').setAttribute('enable', 'false');
        this.personService.saveStory(this.activeId, JSON.stringify(p), '4444').toPromise().then(person => { });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.loadPage.unsubscribe();
        this.nameOfPersonSubscription.unsubscribe();
    }

}
