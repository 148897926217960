import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonService} from './person.service';
import { PersontabsComponent } from './persontabs.component';
import { DetailsComponent } from './details/details.component';
import { LifeEventlistComponent} from './lifeevent/lifeeventlist.component';
import { LifeEventlistResolver} from './lifeevent/lifeeventlist-resolver.service';
import { PersonmediaComponent} from './media/personmedia.component';
import { PersonsourceComponent} from './source/personsource.component';
import { SpouseComponent} from './family/spouse.component';
import { ParentComponent} from './family/parent.component';
import { LifeeventService} from './lifeevent/lifeevent.service';
import { PersonsourceService} from './source/personsource.service';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxGalleryModule} from 'ngx-gallery';
import { RouterModule} from '@angular/router';
import { PersonlistComponent} from './list/personlist.component';
import { PersonlistResolver} from './list/personlist-resolver.service';
import { StoryComponent } from './story/story.component';
import { ChartComponent} from './chart/chart.component';
import { DescendantChartComponent} from './chart/descendant-chart.component';
import { PedigreeChartComponent } from './chart/pedigree-chart.component';
import { QuillModule } from 'ngx-quill';
import { SharedPipesModule } from '../shared/shared-pipes.module';
import { DescendantReportComponent } from './chart/descendant-report.component';
import { AhnentafelReportComponent } from './chart/ahnentafel-report.component';
import { FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { LifeeventComponent } from './lifeevent/lifeevent.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        NgxGalleryModule,
        RouterModule,
        QuillModule,
        FormsModule,
        SharedPipesModule,
        FontAwesomeModule
    ],
    declarations: [
        PersonlistComponent,
        PersontabsComponent,
        DetailsComponent,
        ParentComponent,
        SpouseComponent,
        LifeEventlistComponent,
        ChartComponent,
        DescendantChartComponent,
        PedigreeChartComponent,
        PersonmediaComponent,
        PersonsourceComponent,
        StoryComponent,
        DescendantReportComponent,
        AhnentafelReportComponent,
        LifeeventComponent
    ],
    providers: [
        PersonService,
        LifeeventService,
        PersonsourceService,
        PersonlistResolver,
        LifeEventlistResolver
    ]

})
export class PersonModule { }
