import {IParticipant} from '../models/iparticipant';

export class Participant implements IParticipant {
     personId: string;
     sourceId: string;
     name: boolean;
     age: boolean;
     birthDate: boolean;
     birthLocation: boolean;
     deathDate: boolean;
     deathLocation: boolean;
     divorce: boolean;
     burialDate: boolean;
     burialLocation: boolean;
     ethnic: boolean;
     history: boolean;
     immigration: boolean;
     livingRelatives: boolean;
     maidenAlias: boolean;
     marriageDate: boolean;
     marriageLocation: boolean;
     military: boolean;
     occupation: boolean;
     family: boolean;
     physical: boolean;
     residence: boolean;
     political: boolean;
     genealogy: boolean;
     religion: boolean;
     social: boolean;
     birthYear: number;
     deathYear: number;
     givenName: string;
     middleNames: string;
     familyName: string;
     gender: string;
     version: string;
     transactionId: string;

    constructor() {
        this.personId = '';
        this.sourceId = '';
        this.version = '';
        this.name = false;
        this.age = false;
        this.birthDate = false;
        this.birthLocation = false;
        this.deathDate = false;
        this.deathLocation = false;
        this.divorce = false;
        this.burialDate = false;
        this.burialLocation = false;
        this.ethnic = false;
        this.history = false;
        this.immigration = false;
        this.livingRelatives = false;
        this.maidenAlias = false;
        this.marriageDate = false;
        this.marriageLocation = false;
        this.military = false;
        this.occupation = false;
        this.family = false;
        this.physical = false;
        this.residence = false;
        this.political = false;
        this.genealogy = false;
        this.religion = false;
        this.social = false;
        this.birthYear = null;
        this.deathYear = null;
        this.givenName = '';
        this.middleNames = '';
        this.familyName = '';
        this.gender = '';
        this.transactionId = '';
    }

    toJSON() {
        return {
            personId: this.personId,
            sourceId: this.sourceId,
            version: this.version,
            name: this.name,
            age: this.age,
            birthDate: this.birthDate,
            birthLocation: this.birthLocation,
            deathDate: this.deathDate,
            deathLocation: this.deathLocation,
            divorce: this.divorce,
            burialDate: this.burialDate,
            burialLocation: this.burialLocation,
            ethnic: this.ethnic,
            history: this.history,
            immigration: this.immigration,
            livingRelatives: this.livingRelatives,
            maidenAlias: this.maidenAlias,
            marriageDate: this.marriageDate,
            marriageLocation: this.marriageLocation,
            military: this.military,
            occupation: this.occupation,
            family: this.family,
            physical: this.physical,
            residence: this.residence,
            political: this.political,
            genealogy: this.genealogy,
            religion: this.religion,
            social: this.social,
            birthYear: this.birthYear,
            deathYear: this.deathYear,
            givenName: this.givenName,
            middleNames: this.middleNames,
            familyName: this.familyName,
            gender: this.gender,
            transactionId: this.transactionId
        };
    }
}
