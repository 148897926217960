import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { PersonService } from '../person.service';
import { ActivatedRoute, Router } from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { UtilsService } from '../../shared/utils.service';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { AhnentafelReportService } from './ahnentafel-report.service';
// tslint:disable-next-line:import-blacklist
import { Subscription } from 'rxjs';
import { DescendantChartComponent } from './descendant-chart.component';
import { PedigreeChartComponent } from './pedigree-chart.component';
import { Pedigree } from './pedigree';
import { Descendant} from './descendant';
import {Ancestor} from './ancestor';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy {
    @ViewChild(DescendantChartComponent, {static: true})
    private descendantComponent: DescendantChartComponent;

    @ViewChild(PedigreeChartComponent, {static: true})
    private pedigreeComponent: PedigreeChartComponent;

    ancestorlist: Pedigree[];
    descendantlist: Descendant[];
    ahnentafellist: Ancestor[];

    isPedigreeOn: boolean;
    isAhnentafelOn: boolean;
    isFanOn: boolean;
    isChartOn: boolean;
    isReportOn: boolean;

    activeId: string;
    activeGender: string;
    activePersonName: string;

    private nameOfPersonSubscription: Subscription;

    constructor(private utilsService: UtilsService,
                private personService: PersonService,
                private ahnentafelService: AhnentafelReportService,
                private router: Router,
                private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.activeId = this.route.snapshot.queryParams['id'];
        this.activeGender = this.route.snapshot.queryParams['g'];

        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe( activePersonName => {
            this.activePersonName = activePersonName;
        });

        this.showChart('Pedigree');
    }

    ngOnDestroy() {
        this.nameOfPersonSubscription.unsubscribe();
    }

    showChart(chartType: string) {
        this.isPedigreeOn = false;
        this.isAhnentafelOn = false;
        this.isFanOn = false;
        this.isChartOn = false;
        this.isReportOn = false;

        if (chartType === 'Pedigree') {
            this.personService.getPedigreeChart(this.activeId).toPromise().then(ancestors => {
                document.getElementById('Pedigree').className = 'nav-link active';
                document.getElementById('DescendantChart').className = 'nav-link';
                document.getElementById('DescendantReport').className = 'nav-link';
                document.getElementById('AhnentafelReport').className = 'nav-link';
                this.isPedigreeOn = true;
                this.ancestorlist = <Pedigree[]>ancestors;
            });
        } else if (chartType === 'DescendantChart') {
            this.isChartOn = true;
            document.getElementById('Pedigree').className = 'nav-link';
            document.getElementById('DescendantChart').className = 'nav-link active';
            document.getElementById('DescendantReport').className = 'nav-link';
            document.getElementById('AhnentafelReport').className = 'nav-link';
            this.descendantComponent.drawDescendants();
        } else if (chartType === 'DescendantReport') {
            this.personService.getDescendantsList(this.activeId).toPromise().then(descendants => {
                document.getElementById('Pedigree').className = 'nav-link';
                document.getElementById('DescendantChart').className = 'nav-link';
                document.getElementById('DescendantReport').className = 'nav-link active';
                document.getElementById('AhnentafelReport').className = 'nav-link';
                this.isReportOn = true;
                this.descendantlist = <Descendant[]>descendants;
            });
        } else if (chartType === 'AhnentafelReport') {
            this.personService.getAncestorsForReport(this.activeId).toPromise().then(ahnentafels => {
                document.getElementById('Pedigree').className = 'nav-link';
                document.getElementById('DescendantChart').className = 'nav-link';
                document.getElementById('DescendantReport').className = 'nav-link';
                document.getElementById('AhnentafelReport').className = 'nav-link active';
                this.isAhnentafelOn = true;
                this.ahnentafellist = <Ancestor[]>ahnentafels;
            });
        }
    }

    print() {
        if (this.isPedigreeOn) {
            const fileName = this.utilsService.dateToYMD(new Date()) + 'PedigreeChart-' + this.activePersonName;
            this.personService.downloadPedigreeChart(this.activeId)
                .toPromise().then(response => {
                const blob = new Blob([response], {type: 'application/pdf'});
                saveAs(blob, fileName + '.pdf');
            });
        } else if (this.isAhnentafelOn) {
            const fileName = this.utilsService.dateToYMD(new Date()) + 'AhnentafelReport-' + this.activePersonName;
            this.personService.downloadAhnentafelReport(this.activeId)
                .toPromise().then(response => {
                const blob = new Blob([response], {type: 'application/pdf'});
                saveAs(blob, fileName + '.pdf');
            });
        } else if (this.isChartOn) {
            this.downloadDescendantChart();
        } else if (this.isReportOn) {
            const fileName = this.utilsService.dateToYMD(new Date()) + 'DescendantReport-' + this.activePersonName;
            this.personService.downloadDescendantReport(this.activeId)
                .toPromise().then(response => {
                const blob = new Blob([response], {type: 'application/pdf'});
                saveAs(blob, fileName + '.pdf');
            });
        }
    }

    private downloadDescendantChart() {
        const fileName = this.utilsService.dateToYMD(new Date()) + 'DescendantsChart-' + this.activePersonName + '.png';
        const svg = document.getElementById('svgelement');
        const svgString = getSVGString(svg);
        // 1. send svgString to the server
        // 2. response is a PDF to be printed.

        function save(dataBlob, filesize) {saveAs( dataBlob, fileName); }

        // Below are the functions that handle actual exporting:
        function getSVGString( svgNode) {
            svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');

            const cssStyleText = getCSSStyles( svgNode );
            appendCSS( cssStyleText, svgNode );

            const serializer = new XMLSerializer();
            const svgString1: string = serializer.serializeToString(svgNode);
            const svgString2 = svgString1.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
            const svgString3 = svgString2.replace(/NS\d+:href/g, 'xlink:href'); // Safari NS namespace fix
            return svgString3;

            function getCSSStyles( parentElement ) {
                const selectorTextArr = [];

                // Add Parent element Id and Classes to the list
                selectorTextArr.push( '#' + parentElement.id );
                for (let c = 0; c < parentElement.classList.length; c++) {
                    if (!contains('.' + parentElement.classList[c], selectorTextArr)) {
                        selectorTextArr.push('.' + parentElement.classList[c]);
                    }
                }

                // Add Children element Ids and Classes to the list
                const nodes = parentElement.getElementsByTagName('*');
                for (let i = 0; i < nodes.length; i++) {
                    const id = nodes[i].id;
                    if ( !contains('#' + id, selectorTextArr) ) {
                        selectorTextArr.push('#' + id);
                    }

                    const classes = nodes[i].classList;
                    for (let c = 0; c < classes.length; c++) {
                        if (!contains('.' + classes[c], selectorTextArr)) {
                            selectorTextArr.push('.' + classes[c]);
                        }
                    }
                }

                // Extract CSS Rules
                let extractedCSSText = '';

                for (let i = 1; i < document.styleSheets.length; i++) {
                    const s = <CSSStyleSheet>document.styleSheets[i];
                    const cssRules = s.cssRules;
                    for (let r = 0; r < cssRules.length; r++) {
                        const rule = cssRules[r];
                        if (!(rule instanceof CSSStyleRule)) {
                            continue;
                        }
                        if (contains(rule.selectorText, selectorTextArr)) {
                            extractedCSSText += rule.cssText;
                        }
                    }
                }

                return extractedCSSText;

                function contains(str, arr) {
                    return arr.indexOf( str ) === -1 ? false : true;
                }
            }

            function appendCSS( cssText, element ) {
                const styleElement = document.createElement('style');
                styleElement.setAttribute('type', 'text/css');
                styleElement.innerHTML = cssText;
                const refNode = element.hasChildNodes() ? element.children[0] : null;
                element.insertBefore( styleElement, refNode );
            }
        }

        /*
        function svgString2Canvas( svgString, width, height, format, callback ) {
            // Convert SVG string to data URL
            var format = format ? format : 'png';
            var imgsrc = 'data:image/svg+xml;base64,'+ btoa( unescape( encodeURIComponent( svgString ) ) );
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');

            canvas.width = width;
            canvas.height = height;

            var image = new Image();
            image.src = imgsrc;

            image.onload = function() {
                //negative coordinates will not be displayed
                context.clearRect ( 0,0, width, height);
                context.drawImage(image, 0,0, width, height);
                canvas.toBlob( function(blob: Blob) {
                    var filesize = Math.round( blob.size/1024 ) + ' KB';
                    if ( callback ) callback( blob, filesize );
                });
            };
        }*/
    }

}
