import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { DashboardService} from './dashboard.service';
import { IDashboardSummary} from '../models/idashboardsummary';

@Injectable()
export class DashboardResolver implements Resolve<IDashboardSummary> {
  constructor(private dashboardService: DashboardService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IDashboardSummary> {
      return this.dashboardService.getDashboardSummary();
  }
}
