import { Component, OnInit} from '@angular/core';
import { PersonService} from '../person.service';
import { FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { IPersonSave} from '../../models/ipersonsave';
import { IPerson} from '../../models/iperson';
import { ActivatedRoute} from '@angular/router';
import { UtilsService} from '../../shared/utils.service';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { IRelationshipType} from '../../models/irelationshiptype';

@Component({
  moduleId: module.id,
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss']
})

export class ParentComponent implements OnInit {
    fatherRelationshipType: string;
    motherRelationshipType: string;

    activeId: string;
    activeGender: string;
    activePersonName: string;
    activePersonBirthMask: string;
    activePersonDeathMask: string;
    activePersonBurialMask: string;

    parentForm: FormGroup;

    father = new FormControl();
    fatherId = new FormControl();
    mother = new FormControl();
    motherId = new FormControl();
    newFatherFirstName = new FormControl();
    newFatherLastName = new FormControl();
    newMotherFirstName = new FormControl();
    newMotherLastName = new FormControl();

    closeResult: string;

    filteredFather: IPerson[];
    filteredMother: IPerson[];

    showForm = false;
    isValidActiveId = false;
    isValidFather = true;
    isFatherRelationshipFound = false;
    isValidMother = true;
    isMotherRelationshipFound = false;

    fatherBirthDateMask: string;
    fatherDeathDateMask: string;

    motherBirthDateMask: string;
    motherDeathDateMask: string;

    private routeSubscription: Subscription;
    private nameOfPersonSubscription: Subscription;

    constructor(private utilsService: UtilsService,
                private personService: PersonService,
                private modalService: NgbModal,
                private fb: FormBuilder,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        document.addEventListener('click', () => this.closeList());

        this.routeSubscription = this.route.queryParams.subscribe(params => {
            this.activeId = params['id'];
            this.activeGender = params['g'];
            this.initForm();
            this.setParents(this.activeId);
            if (this.activeId !== undefined && this.activeId !== '') {
                this.isValidActiveId = true;
            }
        });

        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe(activePersonName => {
            this.activePersonName = activePersonName;
        });
    }

    onDestroy() {
        this.routeSubscription.unsubscribe();
        this.nameOfPersonSubscription.unsubscribe();
    }

    onChanges(changes) {
        // only run when property 'data' changed
        if (changes['activeId'] || changes['activeGender']) {
            if (this.activeId !== undefined && this.activeId !== '') {
                this.isValidActiveId = true;
            }
            this.setParents(this.activeId);
        }
    }

    closeList() {
        this.filteredFather = null;
        this.filteredMother = null;
    }

    initForm() {
        this.parentForm = this.fb.group({
            father: this.father,
            fatherId: this.fatherId,
            mother: this.mother,
            motherId: this.motherId,
            newFatherFirstName: this.newFatherFirstName,
            newFatherLastName: this.newFatherLastName,
            newMotherFirstName: this.newMotherFirstName,
            newMotherLastName: this.newMotherLastName
        });
    }

    setSelectedFather(tmpFather: IPerson) {
        this.filteredFather = [];
        const name = this.utilsService.generateName(tmpFather);
        this.parentForm.get('father').setValue(name, {onlySelf: true, emitEvent: false});
        this.parentForm.get('fatherId').setValue(tmpFather.id);
        this.parentForm.controls['newFatherFirstName'].setValidators([]);
        this.parentForm.controls['newFatherLastName'].setValidators([]);
        this.fatherBirthDateMask = this.utilsService.dateMask(
            tmpFather.birth.startDateDay,
            tmpFather.birth.startDateMonth,
            '' + tmpFather.birth.startDateYear);
        this.fatherDeathDateMask = this.utilsService.dateMask(
            tmpFather.death.startDateDay,
            tmpFather.death.startDateMonth,
            '' + tmpFather.death.startDateYear);
        this.validateFather();
    }

    showPotentialFather(tmpFather: IPerson): string {
        return this.utilsService.generateName(tmpFather);
    }

    setSelectedMother(tmpMother: IPerson) {
        this.filteredMother = [];
        const name = this.utilsService.generateName(tmpMother);
        this.parentForm.get('mother').setValue(name, {onlySelf: true, emitEvent: false});
        this.parentForm.get('motherId').setValue(tmpMother.id);
        this.parentForm.controls['newMotherFirstName'].setValidators([]);
        this.parentForm.controls['newMotherLastName'].setValidators([]);
        this.motherBirthDateMask = this.utilsService.dateMask(
            tmpMother.birth.startDateDay,
            tmpMother.birth.startDateMonth,
            '' + tmpMother.birth.startDateYear);
        this.motherDeathDateMask = this.utilsService.dateMask(
            tmpMother.death.startDateDay,
            tmpMother.death.startDateMonth,
            '' + tmpMother.death.startDateYear);
        this.validateMother();
    }

    showPotentialMother(tmpMother: IPerson): string {
        return this.utilsService.generateName(tmpMother);
    }

    autocompleteFather() {
        const value = this.parentForm.get('father').value;
        if (this.parentForm.get('father').dirty || this.parentForm.get('father').touched && value !== null && value !== '') {
            this.personService.getMales(value, this.activeId).toPromise().then(perList => {
                this.filteredFather = <IPerson[]>perList.items;
            });
        }
    }

    autocompleteMother() {
        const value = this.parentForm.get('mother').value;
        if (this.parentForm.get('mother').dirty || this.parentForm.get('mother').touched && value !== null && value !== '') {
            this.personService.getFemales(value, this.activeId).toPromise().then(perList => {
                this.filteredMother = <IPerson[]>perList.items;
            });
        }
    }

    setParents(childId: string) {
        this.clearParents();
        this.parentForm.reset();

        if (childId !== '' && childId !== null) {
            this.personService.getParents(+childId).toPromise().then(responseList => {
                if (responseList !== undefined && responseList != null) {
                    const parent1 = <IPerson>responseList[0];
                    const parent2 = <IPerson>responseList[1];

                    let father;
                    let mother;

                    if (parent1 !== undefined && parent1.gender === 'mal') {
                        father = parent1;
                        mother = parent2;
                    } else if (parent1 !== undefined && parent1.gender === 'fem') {
                        mother = parent1;
                        father = parent2;
                    }

                    const fName = this.utilsService.generateName(father);
                    const mName = this.utilsService.generateName(mother);

                    this.parentForm.patchValue({
                        father: fName,
                        fatherId: (father !== undefined ? father.id : ''),
                        mother: mName,
                        motherId: (mother !== undefined ? mother.id : ''),
                        newFatherFirstName: '',
                        newFatherLastName: '',
                        newMotherFirstName: '',
                        newMotherLastName: ''
                    });

                    this.fatherBirthDateMask = this.utilsService.dateMask(
                        father.birth.startDateDay,
                        father.birth.startDateMonth,
                        father.birth.startDateYear);

                    this.fatherDeathDateMask = this.utilsService.dateMask(
                        father.death.startDateDay,
                        father.death.startDateMonth,
                        father.death.startDateYear);
                }
            });
        }
    }

    clearParents() {
        this.parentForm.patchValue({
            father: '',
            fatherId: '',
            mother: '',
            motherId: '',
            newFatherFirstName: '',
            newFatherLastName: '',
            newMotherFirstName: '',
            newMotherLastName: ''
        });
        this.fatherRelationshipType = '';
        this.motherRelationshipType = '';
    }

    saveParents() {
        const saveButton = <HTMLButtonElement> document.getElementById('saveParentsButton');
        saveButton.disabled = true;
        const cancelButton = <HTMLButtonElement> document.getElementById('cancelParentsButton');
        cancelButton.disabled = true;

        this.personService.saveParents(this.activeId, this.fatherId.value, this.motherId.value, '4444').toPromise().then(cdata => {
            this.showForm = false;
            return cdata;
            }, err => {
                    console.log('parent saving error ' + err.toString());
                }
            );
    }

    showParentForm() {
        this.isValidFather = true;
        this.isValidMother = true;
        this.fatherRelationshipType = '';
        this.motherRelationshipType = '';
        this.isFatherRelationshipFound = false;
        this.isMotherRelationshipFound = false;

        if (this.showForm) {
            this.setParents(this.activeId);
        }
        this.showForm = !this.showForm;
    }

    public validateFather() {
        // father cannot be a SON, Grandson, Greatgrandson etc.
        this.personService.getRelationshipType(+this.activeId, +this.fatherId.value, 'mal').toPromise().then(relationships => {
            for (const reltype of relationships) {
                const rType = <IRelationshipType>reltype;
                this.fatherRelationshipType = rType.relationType;
                this.isFatherRelationshipFound = !this.utilsService.isEmpty(rType.relationType);

                if (rType.relationType.includes('Son') ||
                    rType.relationType.includes('Grandson')) {
                    this.isValidFather = false;
                }

                const dateDiff = (+this.activePersonBirthMask - +this.fatherBirthDateMask) / 10000;
                // yyyymmdd 140000
                if (dateDiff < 14 || dateDiff > 85) {
                    this.isValidFather = false;
                }
            }
        }, err => {
            console.log('error ' + err.toString());
        });
    }

    public validateMother() {
        // mother cannot be a DAUGHTER, Granddaughter, Greatgranddaughter etc.
        this.personService.getRelationshipType(+this.activeId, +this.motherId.value, 'fem').toPromise().then(relationships => {
            for (const reltype of relationships) {
                const rType = <IRelationshipType>reltype;
                this.motherRelationshipType = rType.relationType;
                this.isMotherRelationshipFound = !this.utilsService.isEmpty(rType.relationType);

                if (rType.relationType.includes('Daughter') ||
                    rType.relationType.includes('Granddaughter')) {
                    this.isValidMother = false;
                }

                const dateDiff = (+this.activePersonBirthMask - +this.motherBirthDateMask) / 10000;
                // yyyymmdd 140000
                if (dateDiff < 12 || dateDiff > 60) {
                    this.isValidMother = false;
                }
            }
        }, err => {
            console.log('error ' + err.toString());
        });
    }

    classValuesForFatherInput() {
        if (this.isValidFather) {
            return 'form-control form-control-sm ngb-autocomplete';
        } else {
            return 'form-control form-control-sm ngb-autocomplete error';
        }
    }

    classValuesForMotherInput() {
        if (this.isValidMother) {
            return 'form-control form-control-sm ngb-autocomplete';
        } else {
            return 'form-control form-control-sm ngb-autocomplete error';
        }
    }

    public validateNewFather(v: KeyboardEvent, field: string) {
        const charCode = v.keyCode;
        const isBackspace = charCode === 8 || charCode === 46;
        const value = (!isBackspace) ? v.key.replace(/ /g, '') : (<HTMLInputElement>v.currentTarget).value.replace(/ /g, '');
        const fieldEmpty = (value === null || value === undefined || value === '');

        if (field === 'given') {
            const fatherFamilyName = document.getElementById('newFatherLastName') as HTMLInputElement;
            if (fieldEmpty && (fatherFamilyName.value === '')) {
                this.parentForm.controls['newFatherFirstName'].setValidators([Validators.required, Validators.maxLength(250)]);
                this.parentForm.controls['newFatherLastName'].setValidators([Validators.required, Validators.maxLength(250)]);
                document.getElementById('inputFatherNameHelpBlock').className = 'help-block error';
                // this.saveNewFatherDisabled = true;
            } else {
                this.parentForm.controls['newFatherFirstName'].setValidators([Validators.maxLength(250)]);
                this.parentForm.controls['newFatherLastName'].setValidators([Validators.maxLength(250)]);
                document.getElementById('inputFatherNameHelpBlock').className = 'help-block error text-muted';
                // this.saveNewFatherDisabled = false;
            }
        } else {
            const fatherGivenName = document.getElementById('newFatherFirstName') as HTMLInputElement;
            if (fieldEmpty && (fatherGivenName.value === '')) {
                this.parentForm.controls['newFatherFirstName'].setValidators([Validators.required, Validators.maxLength(250)]);
                this.parentForm.controls['newFatherLastName'].setValidators([Validators.required, Validators.maxLength(250)]);
                document.getElementById('inputFatherNameHelpBlock').className = 'help-block error';
                // this.saveNewFatherDisabled = true;
            } else {
                this.parentForm.controls['newFatherFirstName'].setValidators([Validators.maxLength(250)]);
                this.parentForm.controls['newFatherLastName'].setValidators([Validators.maxLength(250)]);
                document.getElementById('inputFatherNameHelpBlock').className = 'help-block error text-muted';
                // this.saveNewFatherDisabled = false;
            }
        }
        this.parentForm.controls['newFatherFirstName'].updateValueAndValidity();
        this.parentForm.controls['newFatherLastName'].updateValueAndValidity();
    }

    public validateNewMother(v: KeyboardEvent, field: string) {
        const charCode = v.keyCode;
        const isBackspace = charCode === 8 || charCode === 46;
        const value = (!isBackspace) ? v.key.replace(/ /g, '') : (<HTMLInputElement>v.currentTarget).value.replace(/ /g, '');
        const fieldEmpty = (value == null || value === undefined || value === '');

        if (field === 'given') {
            const motherFamilyName = document.getElementById('newMotherLastName') as HTMLInputElement;
            if (fieldEmpty && (motherFamilyName.value === '')) {
                this.parentForm.controls['newMotherFirstName'].setValidators([Validators.required, Validators.maxLength(250)]);
                this.parentForm.controls['newMotherLastName'].setValidators([Validators.required, Validators.maxLength(250)]);
                document.getElementById('inputMotherNameHelpBlock').className = 'help-block error';
                // this.saveNewMotherDisabled = true;
            } else {
                this.parentForm.controls['newMotherFirstName'].setValidators([Validators.maxLength(250)]);
                this.parentForm.controls['newMotherLastName'].setValidators([Validators.maxLength(250)]);
                document.getElementById('inputMotherNameHelpBlock').className = 'help-block error text-muted';
                // this.saveNewMotherDisabled = false;
            }
        } else {
            const motherGivenName = document.getElementById('newMotherFirstName') as HTMLInputElement;
            if (fieldEmpty && (motherGivenName.value === '')) {
                this.parentForm.controls['newMotherFirstName'].setValidators([Validators.required, Validators.maxLength(250)]);
                this.parentForm.controls['newMotherLastName'].setValidators([Validators.required, Validators.maxLength(250)]);
                document.getElementById('inputMotherNameHelpBlock').className = 'help-block error';
                // this.saveNewMotherDisabled = true;
            } else {
                this.parentForm.controls['newMotherFirstName'].setValidators([Validators.maxLength(250)]);
                this.parentForm.controls['newMotherLastName'].setValidators([Validators.maxLength(250)]);
                document.getElementById('inputMotherNameHelpBlock').className = 'help-block error text-muted';
                // this.saveNewMotherDisabled = false;
            }
        }

        this.parentForm.controls['newMotherFirstName'].updateValueAndValidity();
        this.parentForm.controls['newMotherLastName'].updateValueAndValidity();
    }

    getFatherId(): string {
        return (this.parentForm.get('fatherId').value !== '' && this.parentForm.get('fatherId').value !== null) ?
            this.parentForm.get('fatherId').value : '0';
    }

    getMotherId() {
        return (this.parentForm.get('motherId').value !== '' && this.parentForm.get('motherId').value !== null) ?
            this.parentForm.get('motherId').value : '0';
    }

    quickSaveFather() {
        const fName = this.parentForm.get('newFatherFirstName').value;
        const gName = this.parentForm.get('newFatherLastName').value;

        this.parentForm.patchValue({
            father: fName + ' ' + gName,
            newFatherFirstName: '',
            newFatherLastName: ''
        });

        this.personService.quickAdd(fName, gName, 'mal').toPromise()
            .then(data => {
                    const saveData = <IPersonSave>data;
                    this.parentForm.get('fatherId').setValue(saveData.ids.id);
                    return data; },
                err => {
                    console.log('new male person saving error ' + err.toString());
                });

        this.parentForm.updateValueAndValidity();
    }

    quickSaveMother() {
        const fName = this.parentForm.get('newMotherFirstName').value;
        const gName = this.parentForm.get('newMotherLastName').value;

        this.parentForm.patchValue({
            mother: fName + ' ' + gName,
            newMotherFirstName: '',
            newMotherLastName: ''
        });

        this.personService.quickAdd(fName, gName, 'fem').toPromise()
            .then(data => {
                    const saveData = <IPersonSave>data;
                    this.parentForm.get('motherId').setValue(saveData.ids.id);
                    return data; },
                err => {
                    console.log('new female person saving error ' + err.toString());
                });

        this.parentForm.updateValueAndValidity();
    }

    open(content, size) {
        this.parentForm.get('newFatherFirstName').setValue('');
        this.parentForm.get('newFatherLastName').setValue('');
        this.parentForm.get('newMotherFirstName').setValue('');
        this.parentForm.get('newMotherLastName').setValue('');

        this.parentForm.controls['newFatherFirstName'].setValidators([Validators.required, Validators.maxLength(250)]);
        this.parentForm.controls['newFatherLastName'].setValidators([Validators.required, Validators.maxLength(250)]);
        this.parentForm.controls['newFatherFirstName'].updateValueAndValidity();
        this.parentForm.controls['newFatherLastName'].updateValueAndValidity();

        this.parentForm.controls['newMotherFirstName'].setValidators([Validators.required, Validators.maxLength(250)]);
        this.parentForm.controls['newMotherLastName'].setValidators([Validators.required, Validators.maxLength(250)]);
        this.parentForm.controls['newMotherFirstName'].updateValueAndValidity();
        this.parentForm.controls['newMotherLastName'].updateValueAndValidity();

        this.modalService.open(content, size).result.then((result) => {
            this.parentForm.controls['newFatherFirstName'].setValidators([]);
            this.parentForm.controls['newFatherLastName'].setValidators([]);
            this.parentForm.controls['newMotherFirstName'].setValidators([]);
            this.parentForm.controls['newMotherLastName'].setValidators([]);
            this.parentForm.controls['newFatherFirstName'].updateValueAndValidity();
            this.parentForm.controls['newFatherLastName'].updateValueAndValidity();
            this.parentForm.controls['newMotherFirstName'].updateValueAndValidity();
            this.parentForm.controls['newMotherLastName'].updateValueAndValidity();
            this.parentForm.updateValueAndValidity();
        }, (reason) => {});
    }

    removeFatherButton() {
        this.parentForm.get('father').setValue('');
        this.parentForm.get('fatherId').setValue('0');
        this.fatherRelationshipType = '';
        this.isFatherRelationshipFound = false;
        this.isValidFather = true;
    }

    removeMotherButton() {
        this.parentForm.get('mother').setValue('');
        this.parentForm.get('motherId').setValue('0');
        this.motherRelationshipType = '';
        this.isMotherRelationshipFound = false;
        this.isValidMother = true;
    }

}
