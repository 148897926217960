import { ILocation } from '../models/ilocation';
import { ILocationPerson } from '../models/ilocationperson';

export class Location implements ILocation {
    id:            string;
    externalId:    string;
    displayText:   string;
    description:   string;
    latitude:      string;
    longitude:     string;
    country:       string;
    countryLabel:  string;
    metadata:      string;
    updateDate:    Date;
    notes:         string;
    version:       string;
    transactionId: string;
    persons:       ILocationPerson[];

    constructor() {
        this.id = '';
        this.externalId = '';
        this.displayText = '';
        this.description = '';
        this.latitude = '';
        this.longitude = '';
        this.country = '';
        this.countryLabel = '';
        this.metadata = '';
        this.notes = '';
        this.version = '';
        this.transactionId = '';
        this.persons = [];
    }

    toJSON() {
        return {
            id: this.id,
            externalId: this.externalId,
            description: this.description,
            latitude: this.latitude,
            longitude: this.longitude,
            country: this.country,
            notes: this.notes,
            version: this.version,
            transactionId: this.transactionId
        };
    }

}
