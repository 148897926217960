import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
    nameTracking: number;
    ageTracking: number;
    birthDateTracking: number;
    birthLocationTracking: number;
    deathDateTracking: number;
    deathLocationTracking: number;
    burialDateTracking: number;
    burialLocationTracking: number;
    ethnicTracking: number;
    familyTracking: number;
    historyTracking: number;
    immigrationTracking: number;
    livingRelativesTracking: number;
    maidenAliasTracking: number;
    marriageDateTracking: number;
    marriageLocationTracking: number;
    divorceTracking: number;
    militaryTracking: number;
    occupationTracking: number;
    physicalTracking: number;
    politicalTracking: number;
    residenceTracking: number;
    religionTracking: number;
    socialTracking: number;
    genealogyTracking: number;

    trackingTotal: number;

    constructor() {
        this.nameTracking = 0;
        this.ageTracking = 0;
        this.birthDateTracking = 0;
        this.birthLocationTracking = 0;
        this.deathDateTracking = 0;
        this.deathLocationTracking = 0;
        this.burialDateTracking = 0;
        this.burialLocationTracking = 0;
        this.ethnicTracking = 0;
        this.familyTracking = 0;
        this.historyTracking = 0;
        this.immigrationTracking = 0;
        this.livingRelativesTracking = 0;
        this.maidenAliasTracking = 0;
        this.marriageDateTracking = 0;
        this.marriageLocationTracking = 0;
        this.divorceTracking = 0;
        this.militaryTracking = 0;
        this.occupationTracking = 0;
        this.physicalTracking = 0;
        this.politicalTracking = 0;
        this.residenceTracking = 0;
        this.religionTracking = 0;
        this.socialTracking = 0;
        this.genealogyTracking = 0;
    }

    ngOnInit() { }

    calculateTotal() {
        this.trackingTotal =
            this.nameTracking +
            this.ageTracking +
            this.birthDateTracking +
            this.birthLocationTracking +
            this.deathDateTracking +
            this.deathLocationTracking +
            this.burialDateTracking +
            this.burialLocationTracking +
            this.ethnicTracking +
            this.familyTracking +
            this.historyTracking +
            this.immigrationTracking +
            this.livingRelativesTracking +
            this.maidenAliasTracking +
            this.marriageDateTracking +
        this.marriageLocationTracking +
        this.divorceTracking +
        this.militaryTracking +
        this.occupationTracking +
        this.physicalTracking +
        this.politicalTracking +
        this.residenceTracking +
        this.religionTracking +
        this.socialTracking +
        this.genealogyTracking;
    }

}
