import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { ILocationSearchResults} from '../../models/ilocationsearchresults';
import { UtilsService} from '../../shared/utils.service';
import { LifeEventCreate} from './lifeeventcreate';
import { LifeeventService} from './lifeevent.service';
import { LocationService} from '../../location/location.service';
import { ILifeEventSave} from '../../models/ilifeeventsave';
import { LifeEventUpdate} from './lifeeventupdate';
import { ILocationSuggestion} from '../../models/ilocationsuggestion';
import { LifeEvent} from './lifeevent';
import { ILocationSave} from '../../models/ilocationsave';
import { Location} from '../../location/location';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-lifeevent',
  templateUrl: './lifeevent.component.html',
  styleUrls: ['./lifeevent.component.scss']
})
export class LifeeventComponent implements OnInit {
    @Input() activeId: string;
    @Input() activeGender: string;
    @Input() id: string;
    @Output() eventFormHide = new EventEmitter<string>();
    @Output() updateEvent = new EventEmitter<LifeEvent>();

    eventForm: FormGroup;
    days: Array<any>;
    months: Array<any>;
    eventTypes: Array<any>;
    countries: Array<any>;

    eventTypeCd = new FormControl(null, Validators.required);
    eventTypeLabel = new FormControl();
    eventDay = new FormControl();
    eventMonth = new FormControl();
    eventYear = new FormControl(null,
        [Validators.required,
            Validators.maxLength(4),
            Validators.min(1),
            Validators.pattern(/^[0-9]\d*$/)]);
    eventDate = new FormControl();
    eventId = new FormControl();
    eventDescription = new FormControl(null, Validators.maxLength(1000));
    eventNotes = new FormControl(null, Validators.maxLength(2000));
    version = new FormControl();
    eventLocation = new FormControl();
    eventLocationId = new FormControl();
    eventExternalId = new FormControl();
    eventLat = new FormControl();
    eventLon = new FormControl();
    eventCountry = new FormControl();

    filteredEventLocations: ILocationSearchResults[];

    newLocationDescription = new FormControl();
    newLocationCountry = new FormControl();
    showNewLocationDescriptionHelp = false;
    showNewLocationCountryHelp = false;
    saveNewLocationDisabled: boolean;

    isValidDate: boolean;
    isValidType: boolean;
    isValidDesc: boolean;
    isValidNote: boolean;

    private routeSubscription: Subscription;

    constructor(private utilsService: UtilsService,
                private eventService: LifeeventService,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private locationService: LocationService,
                private ref: ChangeDetectorRef) { }

    ngOnInit() {
        this.days = this.utilsService.getDays();
        this.months = this.utilsService.getMonths();
        this.eventTypes = this.utilsService.getEventTypes();
        this.countries = this.utilsService.getCountries();

        this.isValidDate = true;
        this.isValidType = true;
        this.isValidDesc = true;
        this.isValidNote = true;

        document.addEventListener('click', () => this.closeList());

        this.routeSubscription = this.route.queryParams.subscribe(params => {
            this.activeId = params['id'];
            this.activeGender = params['g'];
        });

        this.eventForm = new FormGroup({
            eventTypeCd: this.eventTypeCd,
            eventTypeLabel: this.eventTypeLabel,
            eventDescription: this.eventDescription,
            eventDay: this.eventDay,
            eventMonth: this.eventMonth,
            eventYear: this.eventYear,
            eventDate: this.eventDate,
            eventLocation: this.eventLocation,
            eventLocationId: this.eventLocationId,
            eventExternalId: this.eventExternalId,
            eventLat: this.eventLat,
            eventLon: this.eventLon,
            eventCountry: this.eventCountry,
            eventNotes: this.eventNotes,
            eventId: this.eventId,
            version: this.version,
            newLocationDescription: this.newLocationDescription,
            newLocationCountry: this.newLocationCountry
        });

        this.setEvent(this.id);
    }

    closeList() {
        this.filteredEventLocations = null;
    }

    editEvent(index) {
        if (+this.eventId.value > 0 &&
            (this.eventTypeCd.value === 'birth' ||
                this.eventTypeCd.value === 'death' ||
                this.eventTypeCd.value === 'bural')) {
            this.eventTypeCd.disable();
        } else {
            this.eventTypeCd.enable();
        }
        this.eventDay.enable();
        this.eventMonth.enable();
        this.eventYear.enable();
        this.eventLocation.enable();
        this.eventDescription.enable();
        this.eventNotes.enable();
        this.eventDate.enable();
    }

    disableForm() {
        this.eventDay.disable();
        this.eventMonth.disable();
        this.eventYear.disable();
        this.eventLocation.disable();
        this.eventDescription.disable();
        this.eventTypeCd.disable();
        this.eventNotes.disable();
        this.eventDate.disable();
        (document.getElementById('newLocationButton') as HTMLInputElement).disabled = true;
        (document.getElementById('clearLocationButton') as HTMLInputElement).disabled = true;
        (document.getElementById('saveEventButton') as HTMLInputElement).disabled = true;
        (document.getElementById('cancelEventButton') as HTMLInputElement).disabled = true;
    }

    saveEvent() {
        this.disableForm();
        const utils = this.utilsService;
        const transactionId = utils.uuid();

        /* if the location doesn't have an ID, then save and get it */
        if (this.eventId.value === null || this.eventId.value === '' || this.eventId.value === 'new') {
            const p: LifeEventCreate = new LifeEventCreate();
            p.eventTypeCd = this.eventTypeCd.value;
            p.participantType = 'subjt';
            p.description = this.eventDescription.value;
            p.notes = this.eventNotes.value;
            p.startDateDay = utils.emptyValue(this.eventDay);
            p.startDateMonth = utils.emptyValue(this.eventMonth);
            p.startDateYear = +utils.emptyValue(this.eventYear);

            if (this.eventLocationId.value === '0') {
                // this means the user has selected a geonames location
                p.location.id = '';
            } else {
                // this is a user created location
                p.location.id = this.eventLocationId.value;
            }
            p.location.description = this.eventLocation.value;
            p.location.externalId = this.eventExternalId.value;
            p.location.latitude = (this.eventLat.value !== null && this.eventLat.value !== '') ?
                this.eventLat.value : '';
            p.location.longitude = (this.eventLon.value !== null && this.eventLon.value !== '') ?
                this.eventLon.value : '';
            p.location.country = this.eventCountry.value;
            p.location.version = '4444';
            p.location.transactionId = transactionId;
            p.version = '';

            this.eventService.saveEvent('', this.activeId, JSON.stringify(p), '4444')
                .toPromise().then(data => {
                    const saveData = <ILifeEventSave>data;
                    this.eventId.setValue(saveData.ids.id);
                    this.eventLocationId.setValue(saveData.ids.locationId);
                    return data;
                },
                err => {
                    console.log('event saving error ' + err.toString());
                }).then(data => {
                console.log('Emitting new life event to parent');
                this.eventService.getEvent(this.eventId.value).toPromise().then(event => {
                    this.updateEvent.emit(<LifeEvent>event);
                    });
                });
        } else {
            const p: LifeEventUpdate = new LifeEventUpdate();
            p.id = this.eventId.value;
            p.description = this.eventDescription.value;
            p.notes = this.eventNotes.value;
            p.eventTypeCd = this.eventTypeCd.value;
            p.participantType = 'subjt';
            p.startDateDay = utils.emptyValue(this.eventDay);
            p.startDateMonth = utils.emptyValue(this.eventMonth);
            p.startDateYear = +utils.emptyValue(this.eventYear);

            if (this.eventLocationId.value === '0') {
                // this means the user has selected a geonames location
                p.location.id = '';
            } else {
                // this is a user created location
                p.location.id = this.eventLocationId.value;
            }

            p.location.description = this.eventLocation.value;
            p.location.externalId = this.eventExternalId.value;
            p.location.latitude = (this.eventLat.value !== null && this.eventLat.value !== '') ?
                this.eventLat.value : '';
            p.location.longitude = (this.eventLon.value !== null && this.eventLon.value !== '') ?
                this.eventLon.value : '';
            p.location.country = this.eventCountry.value;
            p.location.version = '4444';
            p.location.transactionId = transactionId;
            p.version = '';

            this.eventService.saveEvent(this.eventId.value, this.activeId, JSON.stringify(p), '4444')
                .toPromise().then(data => {
                    return data;
                },
                err => {
                    console.log('event saving error ' + err.toString());
                }).then(data => {
                    console.log('Emitting updated life event to parent');
                    this.eventService.getEvent(this.eventId.value).toPromise().then(event => {
                        this.updateEvent.emit(<LifeEvent>event);
                    });
                });
        }
    }

    openLocation(content, size) {
        this.saveNewLocationDisabled = true;
        this.eventForm.get('newLocationDescription').setValue('');
        this.eventForm.get('newLocationCountry').setValue('');
        this.eventForm.controls['newLocationDescription'].setValidators([Validators.required, Validators.maxLength(1000)]);
        this.eventForm.controls['newLocationCountry'].setValidators(Validators.required);
        this.eventForm.controls['newLocationDescription'].updateValueAndValidity();
        this.eventForm.controls['newLocationCountry'].updateValueAndValidity();
        this.modalService.open(content, size).result.then((result) => {}, (reason) => {});
    }

    validateDescription() {
        this.isValidDesc = this.eventDescription.valid;
        this.validateSaveButton(this.eventTypeCd.value, this.eventDay.value, this.eventMonth.value, this.eventYear.value);
    }

    validateNotes() {
        this.isValidNote = this.eventNotes.valid;
        this.validateSaveButton(this.eventTypeCd.value, this.eventDay.value, this.eventMonth.value, this.eventYear.value);
    }

    onDayChange($event) {
        const newDay = $event.target.value;
        this.validateSaveButton(this.eventTypeCd.value, newDay, this.eventMonth.value, this.eventYear.value);
    }

    onMonthChange($event) {
        const newMonth = $event.target.value;
        this.validateSaveButton(this.eventTypeCd.value, this.eventDay.value, newMonth, this.eventYear.value);
    }

    onYearChange($event) {
        const newYear = $event.target.value;
        this.validateSaveButton(this.eventTypeCd.value, this.eventDay.value, this.eventMonth.value, newYear);
    }

    onLocationChange($event) {
        this.validateSaveButton(this.eventTypeCd.value, this.eventDay.value, this.eventMonth.value, this.eventYear.value);
    }

    autocompleteLocation(value) {
        if (this.eventLocation.dirty || this.eventLocation.touched) {
            this.locationService.searchLocation(value).toPromise().then(locList => {
                const tmp = <ILocationSuggestion>locList;
                this.filteredEventLocations = tmp.searchResults;
            });
        }
    }

    validateSaveButton(type: string, day: string, month: string, year: string) {
        this.isValidType = !(type == null || type === '');
        const isValidYear = !(year == null || year === '') && this.eventYear.valid;
        this.isValidDate = this.utilsService.isValidDate(
            +this.eventDay.value, +this.eventMonth.value, +this.eventYear.value);

        (document.getElementById('saveEventButton') as HTMLInputElement).disabled =
            !(this.isValidType && isValidYear && this.isValidDate);

        this.eventTypeCd.updateValueAndValidity();
        this.eventDay.updateValueAndValidity();
        this.eventMonth.updateValueAndValidity();
        this.eventYear.updateValueAndValidity();
        this.eventDescription.updateValueAndValidity();
        this.eventForm.updateValueAndValidity();
        this.ref.detectChanges();
    }

    setEvent(eventId: string) {
        if (eventId !== '' && eventId !== null && eventId !== undefined && eventId !== 'new') {
            const utils = this.utilsService;
            this.eventService.getEvent(eventId).toPromise().then(event => {
                const tmpEvent = <LifeEvent>event;
                this.eventForm.patchValue({
                    eventId: tmpEvent.id,
                    eventDescription: tmpEvent.description,
                    eventNotes: (tmpEvent.notes !== null) ? tmpEvent.notes : '',
                    eventTypeCd: tmpEvent.eventTypeCd,
                    eventTypeLabel: utils.getEventType(tmpEvent.eventTypeCd),
                    eventDay: utils.getDay(tmpEvent),
                    eventMonth: utils.getMonth(tmpEvent),
                    eventYear: utils.getYear(tmpEvent),
                    eventLocation: (tmpEvent.location !== null) ? tmpEvent.location.description : '',
                    eventLocationId: (tmpEvent.location !== null) ? tmpEvent.location.id : '',
                    eventExternalId: (tmpEvent.location !== null) ? tmpEvent.location.externalId : '',
                    eventLat: (tmpEvent.location !== null) ? tmpEvent.location.latitude : '',
                    eventLon: (tmpEvent.location !== null) ? tmpEvent.location.longitude : '',
                    eventCountry: (tmpEvent.location !== null) ? tmpEvent.location.country : '',
                    version: tmpEvent.version
                });
            });
        } else {
            this.eventId.setValue('new');
        }
        this.eventForm.updateValueAndValidity();
    }

    clearForm() {
        this.eventForm.patchValue({
            eventId: '',
            eventTypeCd: '',
            eventDay: '',
            eventMonth: '',
            eventYear: '',
            eventDescription: '',
            eventNotes: '',
            eventLocation: '',
            eventLocationId: '',
            eventExternalId: '',
            eventLat: '',
            eventLon: '',
            eventCountry: '',
            version: '',
            newLocationDescription: '',
            newLocationCountry: ''
        });
    }

    cancelEvent() {
        this.eventFormHide.emit(this.eventId.value);
    }

    autocompleteEventLocation(value) {
        if (this.eventLocation.dirty || this.eventLocation.touched) {
            this.locationService.searchLocation(value).toPromise().then(locList => {
                const tmp = <ILocationSuggestion>locList;
                this.filteredEventLocations = tmp.searchResults;
            });
        }
    }

    setEventLocation(location: string, id: string, externalId: string,
                     locationId: string, lat: string, lon: string, country: string) {
        this.filteredEventLocations = null;
        this.eventLocation.setValue(location, {onlySelf: true, emitEvent: false});
        this.eventLocationId.setValue(locationId);
        this.eventExternalId.setValue(externalId);
        this.eventLat.setValue(lat);
        this.eventLon.setValue(lon);
        this.eventCountry.setValue(country);
    }

    validateNewLocation() {
        const vDescription = this.eventForm.controls['newLocationDescription'];
        const vCountry = this.eventForm.controls['newLocationCountry'];

        const isDescriptionValid = (vDescription.value !== '');
        const isCountryValid = (vCountry.value !== '');

        if (isDescriptionValid && isCountryValid) {
            this.saveNewLocationDisabled = false;
            this.eventForm.controls['newLocationDescription'].setValidators([Validators.required, Validators.maxLength(250)]);
            this.eventForm.controls['newLocationCountry'].setValidators(Validators.required);
            this.showNewLocationDescriptionHelp = false;
            this.showNewLocationCountryHelp = false;

        } else {
            if (!isDescriptionValid) {
                this.saveNewLocationDisabled = true;
                this.eventForm.controls['newLocationDescription'].setValidators([Validators.required, Validators.maxLength(250)]);
                this.eventForm.controls['newLocationCountry'].setValidators(Validators.required);
                this.showNewLocationDescriptionHelp = true;
            }

            if (!isCountryValid) {
                this.saveNewLocationDisabled = true;
                this.eventForm.controls['newLocationDescription'].setValidators([Validators.required, Validators.maxLength(250)]);
                this.eventForm.controls['newLocationCountry'].setValidators(Validators.required);
                this.showNewLocationCountryHelp = true;
            }
        }
    }

    quickSaveLocation() {
        const nLocationDescription = this.eventForm.get('newLocationDescription').value;
        const nLocationCountry = this.eventForm.get('newLocationCountry').value;
        const newLocation: Location = new Location();
        newLocation.description  = nLocationDescription;
        newLocation.country      = nLocationCountry;
        newLocation.latitude     = '';
        newLocation.longitude    = '';
        newLocation.notes        = '';
        newLocation.id           = '';
        newLocation.version      = '4444'; // this.version.value;

        this.eventForm.controls['newLocationDescription'].setValidators(null);
        this.eventForm.controls['newLocationCountry'].setValidators(null);
        this.eventForm.patchValue({
            newLocationDescription: '',
            newLocationCountry: ''
        });
        this.eventForm.updateValueAndValidity();

        this.locationService.saveLocation(newLocation.id, JSON.stringify(newLocation), '4444')
            .subscribe(data => {
                    const saveData = <ILocationSave>data;
                    this.setEventLocation(
                        nLocationDescription,
                        saveData.ids.id,
                        'K' + saveData.ids.id,
                        saveData.ids.id,
                        '',
                        '',
                        nLocationCountry);
                    return data;
                },
                err => {
                    console.log('location saving error ' + err.toString());
                });
    }

    removeLocationButton() {
        this.eventForm.get('eventLocation').setValue('');
        this.eventForm.get('eventLocationId').setValue('');
        this.eventForm.get('newLocationCountry').setValue('');
        this.eventForm.updateValueAndValidity();
    }
}
