import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { SourceService } from './source.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
// tslint:disable-next-line:import-blacklist
import { Subscription} from 'rxjs';
import { UtilsService} from '../shared/utils.service';
import { ActivatedRoute, Router} from '@angular/router';
import { ISourceSave} from '../models/isourcesave';
import { RepositoryService} from '../repository/repository.service';
import { SourceCreate} from './sourcecreate';
import { SourceUpdate} from './sourceupdate';
import { Repository} from '../repository/repository';
import { IPerson} from '../models/iperson';
import { PersonService} from '../person/person.service';
import { Participant} from './participant';
import { IParticipant} from '../models/iparticipant';
import { Source } from './source';
import { PersonSuggestion} from '../person/personsuggestion';
import { PersonSearchResults} from '../person/personsearchresults';

@Component({
  selector: 'app-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.scss']
})
export class SourceComponent implements OnInit {
   @ViewChild('t', {static: true}) t;
    recordTypes: Array<any>;
    repositorys: Repository[];
    activeSourceTitle: string;
    activeId: string;
    showTitleHelp = false;
    showOriginalOrDerivedHelp = false;
    source: Source;

    private routeSubscription: Subscription;
    private nameOfSourceSubscription: Subscription;

    sourceForm: FormGroup;
    sourceId = new FormControl();
    title = new FormControl(null, [Validators.required, Validators.maxLength(250)]);
    citation = new FormControl();
    recordType = new FormControl();  // also called source type
    repository = new FormControl();
    original = new FormControl();
    transcription = new FormControl(null, Validators.maxLength(5000));
    notes = new FormControl(null, Validators.maxLength(2000));
    version = new FormControl();
    participants: {}[];
    filteredParticipants: PersonSearchResults[];

    closeResult: string;
    showEditButton: boolean;

    constructor(private utilsService: UtilsService,
                private route: ActivatedRoute,
                private router: Router,
                private sourceService: SourceService,
                private repositoryService: RepositoryService,
                private modalService: NgbModal,
                private fb: FormBuilder,
                private personService: PersonService) {
  }

    ngOnInit() {
        this.recordTypes = this.utilsService.getRecordTypes();
        this.showEditButton = true;
        this.filteredParticipants = [];
        this.sourceForm = this.fb.group({
            sourceId: this.sourceId,
            title: this.title,
            citation: this.citation,
            recordType: this.recordType,
            repository: this.repository,
            original: this.original,
            transcription: this.transcription,
            notes: this.notes,
            participants: this.fb.array([this.createParticipantFormGroup(new Participant())]),
            version: this.version
        });

        // document.addEventListener('click', () => this.closeList());

        this.repositoryService.getRepositories(1,  500,
            '', 'ASC', '', '')
            .toPromise().then(repositoryList => {
            this.repositorys = <Repository[]>repositoryList.items;
            const blank = new Repository();
            blank.id = '';
            blank.name = '';
            this.repositorys.unshift(blank);
        });

        this.routeSubscription = this.route.queryParams.subscribe(
            params => {
                this.activeId = params['id'];
                this.setSource(this.activeId);
            }
        );

        this.nameOfSourceSubscription = this.sourceService.sourceTitle.subscribe( activeSource => {
            this.activeSourceTitle = activeSource;
        });
    }

    onDestroy() {
        this.routeSubscription.unsubscribe();
        this.nameOfSourceSubscription.unsubscribe();
    }

    setSource(id: string) {
        this.sourceService.setTitleOfSource('');
        this.removeAllParticipants();

        if (id === '' || id === null || id === undefined) {
            // this.participants = this.fb.array([]);
            this.sourceForm.patchValue({
                sourceId: '',
                title: '',
                citation: '',
                repositoryId: '',
                repository: '',
                recordType: '',
                original: true,
                transcription: '',
                notes: '',
                version: ''
            });

            this.sourceForm.updateValueAndValidity();
            this.enableForm();
            this.activeId = '';
            this.source = null;
        } else {
            this.sourceService.getSource(+id).subscribe(source => {
                this.source = source;

                this.sourceForm.patchValue({
                    sourceId: this.source.id,
                    title: this.source.title,
                    citation: this.source.citation,
                    repositoryId: (this.source.repository ? this.source.repository.id : ''),
                    repository: (this.source.repository ? this.source.repository.id : ''),
                    recordType: this.source.recordType.trim(),
                    original: String(this.source.original),
                    transcription: this.source.transcription,
                    notes: this.source.notes,
                    version: this.source.version
                });

                for (let i = 0; i < this.source.participants.length; i = i + 1) {
                    const participantsArray = this.sourceForm.get('participants') as FormArray;
                    participantsArray.push(this.createParticipantFormGroup(this.source.participants[i]));
                }

                this.activeId = id;
                this.sourceService.setTitleOfSource(this.source.title);
                this.disableForm();
                this.sourceForm.updateValueAndValidity();
            });
        }
    }

    enableForm() {
        this.showEditButton = false;
        this.showTitleHelp = false;
        this.title.enable();
        this.citation.enable();
        this.repository.enable();
        this.recordType.enable();
        this.original.enable();
        this.notes.enable();
        this.transcription.enable();

        const participantsArray = this.sourceForm.get('participants') as FormArray;

        for (const pForm of participantsArray.controls) {
            pForm.get('participantName').enable();
            pForm.get('originalParticipantId').enable();
            pForm.get('participantId').enable();
            pForm.get('participantBirthYear').enable();
            pForm.get('participantDeathYear').enable();
            pForm.get('participantGivenName').enable();
            pForm.get('participantMiddleNames').enable();
            pForm.get('participantFamilyName').enable();
            pForm.get('participantGender').enable();
            pForm.get('name').enable();
            pForm.get('age').enable();
            pForm.get('birthDate').enable();
            pForm.get('birthLocation').enable();
            pForm.get('deathDate').enable();
            pForm.get('deathLocation').enable();
            pForm.get('divorce').enable();
            pForm.get('burialDate').enable();
            pForm.get('burialLocation').enable();
            pForm.get('ethnic').enable();
            pForm.get('history').enable();
            pForm.get('immigration').enable();
            pForm.get('livingRelatives').enable();
            pForm.get('maidenAlias').enable();
            pForm.get('marriageDate').enable();
            pForm.get('marriageLocation').enable();
            pForm.get('military').enable();
            pForm.get('occupation').enable();
            pForm.get('family').enable();
            pForm.get('physical').enable();
            pForm.get('residence').enable();
            pForm.get('political').enable();
            pForm.get('genealogy').enable();
            pForm.get('religion').enable();
            pForm.get('social').enable();
            pForm.get('showSpouseHelp').enable();
            pForm.get('showEdit').enable();
        }
    }

    disableForm() {
        this.title.disable();
        this.citation.disable();
        this.repository.disable();
        this.recordType.disable();
        this.original.disable();
        this.notes.disable();
        this.transcription.disable();

        const participantsArray = this.sourceForm.get('participants') as FormArray;

        for (const pForm of participantsArray.controls) {
            pForm.get('participantName').disable();
            pForm.get('originalParticipantId').disable();
            pForm.get('participantId').disable();
            pForm.get('participantBirthYear').disable();
            pForm.get('participantDeathYear').disable();
            pForm.get('participantGivenName').disable();
            pForm.get('participantMiddleNames').disable();
            pForm.get('participantFamilyName').disable();
            pForm.get('participantGender').disable();
            pForm.get('name').disable();
            pForm.get('age').disable();
            pForm.get('birthDate').disable();
            pForm.get('birthLocation').disable();
            pForm.get('deathDate').disable();
            pForm.get('deathLocation').disable();
            pForm.get('divorce').disable();
            pForm.get('burialDate').disable();
            pForm.get('burialLocation').disable();
            pForm.get('ethnic').disable();
            pForm.get('history').disable();
            pForm.get('immigration').disable();
            pForm.get('livingRelatives').disable();
            pForm.get('maidenAlias').disable();
            pForm.get('marriageDate').disable();
            pForm.get('marriageLocation').disable();
            pForm.get('military').disable();
            pForm.get('occupation').disable();
            pForm.get('family').disable();
            pForm.get('physical').disable();
            pForm.get('residence').disable();
            pForm.get('political').disable();
            pForm.get('genealogy').disable();
            pForm.get('religion').disable();
            pForm.get('social').disable();
            pForm.get('showSpouseHelp').disable();
            pForm.get('showEdit').disable();
        }
    }

    validateParticipant(index: number) {
        const participantsArray = this.sourceForm.get('participants') as FormArray;
        const participant = participantsArray.controls[index] as FormGroup;
        participant.get('participantName').updateValueAndValidity();
    }

    clear() {
        this.sourceForm.reset();
    }

    addNewParticipant() {
        const participantsArray = this.sourceForm.get('participants') as FormArray;
        const participant = this.createParticipantFormGroup(new Participant());
        participant.get('showEdit').setValue(false);
        participant.get('participantName').enable();
        participantsArray.push(participant);
    }

    setSelectedParticipant(index: number, tmpParticipant: IPerson) {
        this.filteredParticipants = [];
        const name = this.utilsService.generateName(tmpParticipant);
        const participantsArray = this.sourceForm.get('participants') as FormArray;
        const participant = participantsArray.controls[index] as FormGroup;
        participant.get('participantName').setValue(name, {onlySelf: true, emitEvent: false});
        participant.get('originalParticipantId').setValue(tmpParticipant.id);
        participant.get('participantId').setValue(tmpParticipant.id);
        participant.get('participantBirthYear').setValue(tmpParticipant.birth.startDateYear);
        participant.get('participantDeathYear').setValue(tmpParticipant.death.startDateYear);
        participant.get('participantGivenName').setValue(tmpParticipant.givenName);
        participant.get('participantMiddleNames').setValue(tmpParticipant.middleNames);
        participant.get('participantFamilyName').setValue(tmpParticipant.familyName);
        participant.get('participantGender').setValue(tmpParticipant.gender);
    }

    showPotentialParticipant(tmpParticipant: IPerson): string {
        return this.utilsService.generateName(tmpParticipant);
    }

    createParticipantFormGroup(item: IParticipant): FormGroup {
        return this.fb.group({
            participantName: this.utilsService.generateParticipant(item),
            originalParticipantId: item.personId,
            participantId: item.personId,
            participantBirthYear: item.birthYear,
            participantDeathYear: item.deathYear,
            participantGivenName: item.givenName,
            participantMiddleNames: item.middleNames,
            participantFamilyName: item.familyName,
            participantGender: item.gender,
            name: item.name,
            age: item.age,
            birthDate: item.birthDate,
            birthLocation: item.birthLocation,
            deathDate: item.deathDate,
            deathLocation: item.deathLocation,
            divorce: item.divorce,
            burialDate: item.burialDate,
            burialLocation: item.burialLocation,
            ethnic: item.ethnic,
            history: item.history,
            immigration: item.immigration,
            livingRelatives: item.livingRelatives,
            maidenAlias: item.maidenAlias,
            marriageDate: item.marriageDate,
            marriageLocation: item.marriageLocation,
            military: item.military,
            occupation: item.occupation,
            family: item.family,
            physical: item.physical,
            residence: item.residence,
            political: item.political,
            genealogy: item.genealogy,
            religion: item.religion,
            social: item.social,
            showSpouseHelp: false,
            showEdit: true
        });
    }

    saveSource() {
        this.showEditButton = true;
        this.disableForm();

        /* if the source doesn't have an ID, then save and get it */
        if (this.sourceId.value === null || this.sourceId.value === '') {
            const newSource: SourceCreate = new SourceCreate();
            newSource.version = '4444'; // this.version.value;
            newSource.title = this.title.value;
            newSource.citation = this.citation.value;
            newSource.original = this.original.value;
            newSource.recordType = this.recordType.value;
            newSource.notes = this.notes.value;
            newSource.transcription = this.transcription.value;

            const tmpParticipants = [];

            const participantsArray = this.sourceForm.get('participants') as FormArray;

            for (const pForm of participantsArray.controls) {
                const tmpParticipant = new Participant();
                tmpParticipant.personId = pForm.get('participantId').value;
                tmpParticipant.sourceId = '';
                tmpParticipant.version = '4444';
                tmpParticipant.name = pForm.get('name').value;
                tmpParticipant.age = pForm.get('age').value;
                tmpParticipant.birthDate = pForm.get('birthDate').value;
                tmpParticipant.birthLocation = pForm.get('birthLocation').value;
                tmpParticipant.deathDate = pForm.get('deathDate').value;
                tmpParticipant.deathLocation = pForm.get('deathLocation').value;
                tmpParticipant.divorce = pForm.get('divorce').value;
                tmpParticipant.burialDate = pForm.get('burialDate').value;
                tmpParticipant.burialLocation = pForm.get('burialLocation').value;
                tmpParticipant.ethnic = pForm.get('ethnic').value;
                tmpParticipant.history = pForm.get('history').value;
                tmpParticipant.immigration = pForm.get('immigration').value;
                tmpParticipant.livingRelatives = pForm.get('livingRelatives').value;
                tmpParticipant.maidenAlias = pForm.get('maidenAlias').value;
                tmpParticipant.marriageDate = pForm.get('marriageDate').value;
                tmpParticipant.marriageLocation = pForm.get('marriageLocation').value;
                tmpParticipant.occupation = pForm.get('occupation').value;
                tmpParticipant.family = pForm.get('family').value;
                tmpParticipant.physical = pForm.get('physical').value;
                tmpParticipant.residence = pForm.get('residence').value;
                tmpParticipant.political = pForm.get('political').value;
                tmpParticipant.genealogy = pForm.get('genealogy').value;
                tmpParticipant.religion = pForm.get('religion').value;
                tmpParticipant.social = pForm.get('social').value;
                tmpParticipant.transactionId = this.utilsService.uuid();
                console.log(tmpParticipant.toJSON());
                tmpParticipants.push(tmpParticipant);
            }

            newSource.participants = tmpParticipants;

            if (this.repository.value !== null) {
                // this means the user has selected a repository
                newSource.repository.id = this.repository.value;
            } else {
                newSource.repository.id = '';
            }

            this.sourceService.saveSource('', JSON.stringify(newSource), '4444')
                .subscribe(data => {
                        const saveData = <ISourceSave>data;
                        this.sourceId.setValue(saveData.ids.id);
                        return data;
                    },
                    err => {
                        console.log('source saving error ' + err.toString());
                    });

        } else {
            const existingSource: SourceUpdate = new SourceUpdate();
            existingSource.id = (this.sourceId.value !== null && this.sourceId.value !== 0) ? this.sourceId.value : '';
            existingSource.version = '4444'; // this.version.value;
            existingSource.title = this.title.value;
            existingSource.citation = this.citation.value;
            existingSource.original = this.original.value;
            existingSource.recordType = this.recordType.value;
            existingSource.notes = this.notes.value;
            existingSource.transcription = this.transcription.value;

            const tmpParticipants = [];

            const participantsArray = this.sourceForm.get('participants') as FormArray;

            for (const pForm of participantsArray.controls) {
                const tmpParticipant = new Participant();
                tmpParticipant.personId = pForm.get('participantId').value;
                tmpParticipant.sourceId = this.sourceId.value;
                tmpParticipant.version = '4444';
                tmpParticipant.name = pForm.get('name').value;
                tmpParticipant.age = pForm.get('age').value;
                tmpParticipant.birthDate = pForm.get('birthDate').value;
                tmpParticipant.birthLocation = pForm.get('birthLocation').value;
                tmpParticipant.deathDate = pForm.get('deathDate').value;
                tmpParticipant.deathLocation = pForm.get('deathLocation').value;
                tmpParticipant.divorce = pForm.get('divorce').value;
                tmpParticipant.burialDate = pForm.get('burialDate').value;
                tmpParticipant.burialLocation = pForm.get('burialLocation').value;
                tmpParticipant.ethnic = pForm.get('ethnic').value;
                tmpParticipant.history = pForm.get('history').value;
                tmpParticipant.immigration = pForm.get('immigration').value;
                tmpParticipant.livingRelatives = pForm.get('livingRelatives').value;
                tmpParticipant.maidenAlias = pForm.get('maidenAlias').value;
                tmpParticipant.marriageDate = pForm.get('marriageDate').value;
                tmpParticipant.marriageLocation = pForm.get('marriageLocation').value;
                tmpParticipant.occupation = pForm.get('occupation').value;
                tmpParticipant.family = pForm.get('family').value;
                tmpParticipant.physical = pForm.get('physical').value;
                tmpParticipant.residence = pForm.get('residence').value;
                tmpParticipant.political = pForm.get('political').value;
                tmpParticipant.genealogy = pForm.get('genealogy').value;
                tmpParticipant.religion = pForm.get('religion').value;
                tmpParticipant.social = pForm.get('social').value;
                tmpParticipant.transactionId = this.utilsService.uuid();
                console.log(tmpParticipant.toJSON());
                tmpParticipants.push(tmpParticipant);
            }

            existingSource.participants = tmpParticipants;

            if (this.repository.value !== null) {
                // this means the user has selected a repository
                existingSource.repository.id = this.repository.value;
            } else {
                existingSource.repository.id = '';
            }

            this.sourceService.saveSource(existingSource.id, JSON.stringify(existingSource), '4444')
                .subscribe(data => {
                        const saveData = <ISourceSave>data;
                        this.sourceId.setValue(saveData.ids.id);
                        return data;
                    },
                    err => {
                        console.log('source saving error ' + err.toString());
                    });
        }
    }

    cancelSource(id: string) {
        if (id === '') {
            this.router.navigate(['/sourcelist']);
        } else {
            this.router.navigate(['/source'], { queryParams: { id: id} });
        }
    }

    deleteSource(id: string) {
        if (id === '') {
            this.router.navigate(['/sourcelist']);
        } else {
            this.sourceService.deleteSource(id).toPromise().then(deleted => {
                this.router.navigate(['/sourcelist']);
            });
        }
    }

    open(content, size) {
        this.modalService.open(content, size).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

    autocompleteParticipant(value, index) {
        console.log('value: ' + value + ' index: ' + index);
        const participantsArray = this.sourceForm.get('participants') as FormArray;
        const participant = participantsArray.controls[index];

        if (participant.get('participantName').dirty || participant.get('participantName').touched) {
            if (value !== null && value !== '') {
                this.personService.searchPerson(value).toPromise().then(perList => {
                    const tmp = <PersonSuggestion>perList;
                    this.filteredParticipants = tmp.searchResults;
                });
            }
        }
    }

    validateNewParticipant(v: KeyboardEvent, field: string, index: number, type: string) {
        const participantsArray = this.sourceForm.get('participants') as FormArray;
        const participant = participantsArray.controls[index] as FormGroup;

        let saveNewParticipantDisabled = true;
        let areNamesValid = false;
        let isGenderValid = false;

        if (field === 'given' || field === 'family') {
            const charCode = v.keyCode;
            const isBackspace = charCode === 8 || charCode === 46;
            const value = (!isBackspace) ? v.key.replace(/ /g, '') :
                (<HTMLInputElement>v.currentTarget).value.replace(/ /g, '');
            const fieldEmpty = (value === null || value === undefined || value === '');

            const participantGivenName = participant.get('new' + type + 'FirstName').value;
            const isGivenNameValid = this.isValidFirstName(participant, participantGivenName, index, type);
            if (field === 'family' && fieldEmpty && isGivenNameValid) {
                areNamesValid = true;
            } else if (field === 'family' && !fieldEmpty) {
                areNamesValid = true;
            }

            const participantFamilyName = participant.get('new' + type + 'LastName').value;
            const isParticipantNameValid = this.isValidLastName(participant, participantFamilyName, index, type);
            if (field === 'given' && fieldEmpty && isParticipantNameValid) {
                areNamesValid = true;
            } else if (field === 'given' && !fieldEmpty) {
                areNamesValid = true;
            }

            if (areNamesValid) {
                document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error text-muted';
                participant.get('new' + type + 'FirstName').setValidators([Validators.maxLength(250)]);
                participant.get('new' + type + 'LastName').setValidators([Validators.maxLength(250)]);
            } else {
                document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error';
                participant.get('new' + type + 'FirstName').setValidators([Validators.required, Validators.maxLength(250)]);
                participant.get('new' + type + 'LastName').setValidators([Validators.required, Validators.maxLength(250)]);
            }

            const genderValue = participant.get('new' + type + 'Gender').value;
            isGenderValid = this.isGenderValid(participant, genderValue, index, type);
        } else if (field === 'gender') {
            const spouseGivenName  = participant.get('new' + type + 'FirstName').value;
            const spouseFamilyName = participant.get('new' + type + 'LastName').value;
            const genderValue      = document.getElementById('new' + type + 'Gender' + index).valueOf();

            const isFirstNameValid = this.isValidFirstName(participant, spouseGivenName.value, index, type);
            const isLastNameValid  = this.isValidLastName(participant, spouseFamilyName.value, index, type);

            areNamesValid = (isFirstNameValid && isLastNameValid);

            if (areNamesValid) {
                document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error text-muted';
            } else {
                document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error';
            }

            isGenderValid = this.isGenderValid(participant, genderValue, index, type);
        }

        participant.get('new' + type + 'FirstName').updateValueAndValidity();
        participant.get('new' + type + 'LastName').updateValueAndValidity();
        participant.get('new' + type + 'Gender').updateValueAndValidity();

        // change this to a form variable
        if (areNamesValid && isGenderValid) {
            saveNewParticipantDisabled = false;
        }

        (<HTMLButtonElement>document.getElementById('saveNew' + type + index)).disabled = saveNewParticipantDisabled;
    }

    validateNewParticipantNoEvent(index: number, type: string) {
        const participantsArray = this.sourceForm.get('participants') as FormArray;
        const participant = participantsArray.controls[index] as FormGroup;
        let saveNewParticipantDisabled = true;

        const participantGivenName  = participant.get('new' + type + 'FirstName').value;
        const participantFamilyName = participant.get('new' + type + 'LastName').value;
        const genderValue      = document.getElementById('new' + type + 'Gender' + index).valueOf();

        const isFirstNameValid = this.isValidFirstName(participant, participantGivenName.value, index, type);
        const isLastNameValid  = this.isValidLastName(participant, participantFamilyName.value, index, type);

        const areNamesValid = (isFirstNameValid && isLastNameValid);

        if (areNamesValid) {
            document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error text-muted';
        } else {
            document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error';
        }

        const isGenderValid = this.isGenderValid(participant, genderValue, index, type);

        participant.get('new' + type + 'FirstName').updateValueAndValidity();
        participant.get('new' + type + 'LastName').updateValueAndValidity();
        participant.get('new' + type + 'Gender').updateValueAndValidity();

        if (areNamesValid && isGenderValid) {
            saveNewParticipantDisabled = false;
        }
        // change this to a form variable
        (<HTMLButtonElement>document.getElementById('saveNew' + type + index)).disabled = saveNewParticipantDisabled;
    }

    private isValidFirstName(participant: FormGroup, firstName: string, index: number, type: string): boolean {
        if (firstName === '') {
            participant.get('new' + type + 'FirstName').setValidators([Validators.required, Validators.maxLength(250)]);
            document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error';
            return false;
        } else {
            participant.get('new' + type + 'FirstName').setValidators([Validators.maxLength(250)]);
            participant.get('new' + type + 'LastName').setValidators([Validators.maxLength(250)]);
            document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error text-muted';
            return participant.get('new' + type + 'FirstName').valid;
        }
    }

    private isValidLastName(participant: FormGroup, lastName: string, index: number, type: string): boolean {
        if (lastName === '') {
            participant.get('new' + type + 'LastName').setValidators([Validators.required, Validators.maxLength(250)]);
            document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error';
            return false;
        } else {
            participant.get('new' + type + 'FirstName').setValidators([Validators.maxLength(250)]);
            participant.get('new' + type + 'LastName').setValidators([Validators.maxLength(250)]);
            document.getElementById('input' + type + 'NameHelpBlock' + index).className = 'help-block error text-muted';
            return participant.get('new' + type + 'LastName').valid;
        }
    }

    private isGenderValid(participant: FormGroup, gender, index: number, type: string): boolean {
        if (gender === null || gender === undefined || gender === '') {
            if (document.getElementById('input' + type + 'GenderHelpBlock' + index) != null) {
                document.getElementById('input' + type + 'GenderHelpBlock' + index).className = 'help-block error';
                document.getElementById('new' + type + 'Gender' + index).className = 'btn-group btn-group-toggle genderInvalid';
                return false;
            }
        } else {
            if (document.getElementById('input' + type + 'GenderHelpBlock' + index) != null) {
                document.getElementById('input' + type + 'GenderHelpBlock' + index).className = 'help-block text-muted';
                document.getElementById('new' + type + 'Gender' + index).className = 'btn-group btn-group-toggle genderValid';
                return true;
            }
        }
    }

    clearParticipantButton(index) {
        const participantsArray = this.sourceForm.get('participants') as FormArray;
        const participant = participantsArray.controls[index] as FormGroup;
        participant.get('participantName').setValue('');
        participant.get('participantId').setValue('');
        participant.get('participantBirthYear').setValue('');
        participant.get('participantDeathYear').setValue('');
        participant.get('participantGivenName').setValue('');
        participant.get('participantMiddleNames').setValue('');
        participant.get('participantFamilyName').setValue('');
        participant.get('participantGender').setValue('');
        participant.updateValueAndValidity();
    }

    removeParticipantButton(index) {
        const participantsArray = this.sourceForm.get('participants') as FormArray;
        participantsArray.removeAt(index);
        this.sourceForm.updateValueAndValidity();
    }

    removeAllParticipants() {
        const participantsArray = this.sourceForm.get('participants') as FormArray;
        const participantsCount = participantsArray.length;

        for (let i = 0; i < participantsCount; i = i + 1) {
            this.removeParticipantButton(i);
        }
    }

    toggleOptions(index: number) {
        const x = document.getElementById('sourceData' + index);
        if (x.style.display === 'none') {
            x.style.display = 'block';
        } else {
            x.style.display = 'none';
        }
    }

    getChecked(checkBoxName: string, index: number): boolean {
        const checkBox = <HTMLInputElement>document.getElementById(checkBoxName + index);
        if (checkBox.checked) {
            return true;
        } else {
            return false;
        }
    }

}
