import { Repository} from '../repository/repository';
import { ISourceCreate} from '../models/isourcecreate';
import { Participant} from './participant';

export class SourceCreate implements ISourceCreate {
    version:              string;
    title:                string;
    citation:             string;
    recordType:           string;
    original:             boolean;
    transcription:        string;
    notes:                string;
    repository:           Repository;
    participants:         Participant[];

    constructor() {
        this.version = '';
        this.title = '';
        this.citation = '';
        this.recordType = '';
        this.original = true;
        this.transcription = '';
        this.notes = '';
        this.repository = new Repository();
        this.participants = [];
    }

    toJSON() {
        return {
            title: this.title,
            citation: this.citation,
            recordType: this.recordType,
            original: this.original,
            transcription: this.transcription,
            notes: this.notes,
            version: this.version,
            repository: this.repository,
            participants: this.participants
        };
    }

}
