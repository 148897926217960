import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHTMLPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

@NgModule({
  declarations: [
      SafeHTMLPipe,
      SafeUrlPipe
  ],
  imports: [
    CommonModule
  ],
    exports: [
        SafeHTMLPipe,
        SafeUrlPipe
    ]
})
export class SharedPipesModule { }
