import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { PersonService} from '../person.service';
import { ActivatedRoute, Router} from '@angular/router';
import { UtilsService} from '../../shared/utils.service';
import { saveAs } from 'file-saver';
// tslint:disable-next-line:import-blacklist
import { Subscription } from 'rxjs';
import { Pedigree } from './pedigree';

@Component({
    selector: 'app-pedigree-chart',
    templateUrl: './pedigree-chart.component.html',
    styleUrls: ['./pedigree-chart.component.scss']
})
export class PedigreeChartComponent implements OnInit, OnDestroy {
    ancestorlist: Pedigree[];
    activePersonName: string;
    private nameOfPersonSubscription: Subscription;

    P: Pedigree = new Pedigree();

    // Parents
    MP: Pedigree = new Pedigree();
    FP: Pedigree = new Pedigree();

    // Grandparents
    MMP: Pedigree = new Pedigree();
    FMP: Pedigree = new Pedigree();

    MFP: Pedigree = new Pedigree();
    FFP: Pedigree = new Pedigree();

    // Great-Grandparents
    MMMP: Pedigree = new Pedigree();
    FMMP: Pedigree = new Pedigree();

    MFMP: Pedigree = new Pedigree();
    FFMP: Pedigree = new Pedigree();

    MMFP: Pedigree = new Pedigree();
    FMFP: Pedigree = new Pedigree();

    MFFP: Pedigree = new Pedigree();
    FFFP: Pedigree = new Pedigree();

    // Great-Great-Grandparents
    MMMMP: Pedigree = new Pedigree();
    FMMMP: Pedigree = new Pedigree();

    MFMMP: Pedigree = new Pedigree();
    FFMMP: Pedigree = new Pedigree();

    MMFMP: Pedigree = new Pedigree();
    FMFMP: Pedigree = new Pedigree();

    MFFMP: Pedigree = new Pedigree();
    FFFMP: Pedigree = new Pedigree();

    MMMFP: Pedigree = new Pedigree();
    FMMFP: Pedigree = new Pedigree();

    MFMFP: Pedigree = new Pedigree();
    FFMFP: Pedigree = new Pedigree();

    MMFFP: Pedigree = new Pedigree();
    FMFFP: Pedigree = new Pedigree();

    MFFFP: Pedigree = new Pedigree();
    FFFFP: Pedigree = new Pedigree();

    // below  will get called when ever the items are passed in by the parent component
    @Input('ancestorlist') set ancestors (ancestors: Pedigree[]) {
        console.log('PedigreeChartComponent: set ancestors');
        this.ancestorlist = ancestors;

        let index = 0;
        while (index < this.ancestorlist.length) {
            const tmpPedigree = <Pedigree>(this.ancestorlist[index]);
            if (tmpPedigree.identifier === 'P') {
                this.P = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MP') {
                this.MP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FP') {
                this.FP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MMP') {
                this.MMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FMP') {
                this.FMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MFP') {
                this.MFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FFP') {
                this.FFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MMMP') {
                this.MMMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FMMP') {
                this.FMMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MFMP') {
                this.MFMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FFMP') {
                this.FFMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MMFP') {
                this.MMFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FMFP') {
                this.FMFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MFFP') {
                this.MFFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FFFP') {
                this.FFFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MMMMP') {
                this.MMMMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FMMMP') {
                this.FMMMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MFMMP') {
                this.MFMMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FFMMP') {
                this.FFMMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MMFMP') {
                this.MMFMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FMFMP') {
                this.FMFMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MFFMP') {
                this.MFFMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FFFMP') {
                this.FFFMP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MMMFP') {
                this.MMMFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FMMFP') {
                this.FMMFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MFMFP') {
                this.MFMFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FFMFP') {
                this.FFMFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MMFFP') {
                this.MMFFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FMFFP') {
                this.FMFFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'MFFFP') {
                this.MFFFP = tmpPedigree;
            } else if (tmpPedigree.identifier === 'FFFFP') {
                this.FFFFP = tmpPedigree;
            }
            index = index + 1;
        }
    }

    constructor(private utilsService: UtilsService,
                private personService: PersonService,
                private router: Router,
                private route: ActivatedRoute,
                private ref: ChangeDetectorRef) { }

    ngOnInit() {
        console.log('PedigreeChart: OnInit');
        this.nameOfPersonSubscription = this.personService.nameOfPerson.subscribe( activePersonName => {
            this.activePersonName = activePersonName;
        });
    }

    ngOnDestroy(): void {
        this.nameOfPersonSubscription.unsubscribe();
    }

    print() {
        const fileName = this.activePersonName + this.utilsService.dateToYMD(new Date());
        const activeId = this.route.queryParams['id'];
        this.personService.downloadPedigreeChart(activeId)
            .toPromise().then(response => {
            const blob = new Blob([response], {type: 'application/pdf'});
            saveAs(blob, fileName + '.pdf');
        });
    }

}
