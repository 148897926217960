import {IRepositoryCreate} from '../models/irepositorycreate';

export class RepositoryCreate implements IRepositoryCreate {
    name:          string;
    detail:        string;
    email:         string;
    address:       string;
    phoneNum:      string;
    url:           string;
    notes:         string;
    metadata:      string;
    version:       string;
    transactionId: string;

    constructor() {
        this.version = '';
        this.name = '';
        this.detail = '';
        this.email = '';
        this.address = '';
        this.phoneNum = '';
        this.url = '';
        this.notes = '';
    }

    toJSON() {
        return {
            name: this.name,
            detail: this.detail,
            email: this.email,
            address: this.address,
            phoneNum: this.phoneNum,
            url: this.url,
            notes: this.notes,
            version: this.version,
            transactionId: this.transactionId
        };
    }
}
