import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IPerson} from '../models/iperson';
import { IParticipant} from '../models/iparticipant';
import { LifeEvent} from '../person/lifeevent/lifeevent';
import { Person} from '../person/person';
import { Relationship} from '../person/family/relationship';
import { IPersonCreate} from '../models/ipersoncreate';
import { IPersonUpdate} from '../models/ipersonupdate';

@Injectable( {
    providedIn: 'root'
})
export class UtilsService {
    constructor() { }

    uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            // tslint:disable-next-line:no-bitwise
            const r = Math.random() * 16 | 0;
            // tslint:disable-next-line:no-bitwise
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    getIAMURL(): string {
        return 'https://id.kinalytic.com'; // local instance only
    }

    getGENURL(): string {
        return 'https://gen.kinalytic.com'; // local instance only
    }

    getSRCHURL(): string {
        return 'https://search.kinalytic.com'; // local instance only
    }

    setDate(value: string): string {
        if (value !== null && value !== '') {
            const numValue = +value;
            // zero fill the numbers for the front end only
            if (numValue < 10 && numValue > 0) {
                return '0' + numValue;
            } else if (numValue === 0) {
                return '';
            } else {
                return value;
            }
        }
        return '';
    }

    emptyValue(obj: AbstractControl): string {
        if (typeof obj === 'undefined') {return ''; }
        if (obj.value !== null) {return obj.value; }
        return '';
    }

    emptyBoolean(obj: AbstractControl): string {
        if (typeof obj === 'undefined') {return 'false'; }
        if (obj.value !== null) {return obj.value; }
        return 'false';
    }

    isNum(n) {
        return !isNaN(n / 0 );
    }

    generateName(relative: IPerson): string {
        let name = '';

        if (relative !== undefined) {

            let bYear = '';
            let dYear = '';

            if (relative.birth !== null) {
                bYear = ((relative.birth.startDateYear !== null) && (relative.birth.startDateYear !== 0)) ?
                    relative.birth.startDateYear.toString() : ' ';
            }

            if (relative.death !== null) {
                dYear = ((relative.death.startDateYear !== null) && (relative.death.startDateYear !== 0)) ?
                    relative.death.startDateYear.toString() : ' ';
            }

            name = relative.givenName;
            name = name + (relative.middleNames !== '' ? (' ' + relative.middleNames) : '');
            name = name + (relative.familyName !== '' ? (' ' + relative.familyName) : '');

                name = name + ' (';

                if ((relative.birth !== null && relative.birth.startDateYear !== 0)) {
                    name = name + (relative.birth.startDateYear !== 0 ? relative.birth.startDateYear : '');
                } else {
                    name = name + bYear;
                }

                name = name + ' - ';

                if ((relative.death !== null && relative.death.startDateYear !== 0)) {
                    name = name + (relative.death.startDateYear !== 0 ? relative.death.startDateYear : '');
                } else {
                    name = name + dYear;
                }

                name = name + ')';
        }
        return name;
    }

    generateNamePersonCreate(relative: IPersonCreate): string {
        let name = '';

        if (relative !== undefined) {

            let bYear = '';
            let dYear = '';

            if (relative.birth !== null) {
                bYear = ((relative.birth.startDateYear !== null) && (relative.birth.startDateYear !== 0)) ?
                    relative.birth.startDateYear.toString() : ' ';
            }

            if (relative.death !== null) {
                dYear = ((relative.death.startDateYear !== null) && (relative.death.startDateYear !== 0)) ?
                    relative.death.startDateYear.toString() : ' ';
            }

            name = relative.givenName;
            name = name + (relative.middleNames !== '' ? (' ' + relative.middleNames) : '');
            name = name + (relative.familyName !== '' ? (' ' + relative.familyName) : '');

            name = name + ' (';

            if ((relative.birth !== null && relative.birth.startDateYear !== 0)) {
                name = name + (relative.birth.startDateYear !== 0 ? relative.birth.startDateYear : '');
            } else {
                name = name + bYear;
            }

            name = name + ' - ';

            if ((relative.death !== null && relative.death.startDateYear !== 0)) {
                name = name + (relative.death.startDateYear !== 0 ? relative.death.startDateYear : '');
            } else {
                name = name + dYear;
            }

            name = name + ')';
        }
        return name;
    }

    generateNamePersonUpdate(relative: IPersonUpdate): string {
        let name = '';

        if (relative !== undefined) {

            let bYear = '';
            let dYear = '';

            if (relative.birth !== null) {
                bYear = ((relative.birth.startDateYear !== null) && (relative.birth.startDateYear !== 0)) ?
                    relative.birth.startDateYear.toString() : ' ';
            }

            if (relative.death !== null) {
                dYear = ((relative.death.startDateYear !== null) && (relative.death.startDateYear !== 0)) ?
                    relative.death.startDateYear.toString() : ' ';
            }

            name = relative.givenName;
            name = name + (relative.middleNames !== '' ? (' ' + relative.middleNames) : '');
            name = name + (relative.familyName !== '' ? (' ' + relative.familyName) : '');

            name = name + ' (';

            if ((relative.birth !== null && relative.birth.startDateYear !== 0)) {
                name = name + (relative.birth.startDateYear !== 0 ? relative.birth.startDateYear : '');
            } else {
                name = name + bYear;
            }

            name = name + ' - ';

            if ((relative.death !== null && relative.death.startDateYear !== 0)) {
                name = name + (relative.death.startDateYear !== 0 ? relative.death.startDateYear : '');
            } else {
                name = name + dYear;
            }

            name = name + ')';
        }
        return name;
    }

    generateNameOnly(relative: IPerson): string {
        let name = '';
        if (relative !== undefined) {
            name = relative.givenName;
            name = name + (relative.middleNames !== '' ? (' ' + relative.middleNames) : '');
            name = name + (relative.familyName !== '' ? (' ' + relative.familyName) : '');
        }
        return name;
    }

    generateParticipant(relative: IParticipant): string {
        let name = '';
        if (relative !== undefined) {

            const bYear = ((relative.birthYear !== null) && (relative.birthYear !== 0)) ? relative.birthYear : ' ';
            const dYear = ((relative.deathYear !== null) && (relative.deathYear !== 0)) ? relative.deathYear : ' ';

            name = relative.givenName;
            name = name + (relative.middleNames !== '' ? (' ' + relative.middleNames) : '');
            name = name + (relative.familyName !== '' ? (' ' + relative.familyName) : '');

            if (name.trim().length > 0) {
                if (relative.birthYear !== 0 || relative.deathYear !== 0) {
                    name = name + ' (';
                    name = name + (relative.birthYear !== 0 ? relative.birthYear : '');
                    name = name + ' - ';
                    name = name + (relative.deathYear !== 0 ? relative.deathYear : '');
                    name = name + ')';
                } else {
                    name = name + ' (' + bYear + ' - ' + dYear + ')';
                }
            }
        }
        return name;
    }

    generateAge(eventBirth: number, eventDeath: number, eventDate: number): string {
        if (eventBirth !== null && eventBirth !== undefined && eventDate !== null && eventDate !== undefined) {
            if (eventDate >= eventBirth) {
                if (eventDeath !== null && eventDeath !== undefined && eventDeath < eventDate) {
                    return null;
                }
                return (eventDate - eventBirth).toString();
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    dateToYMD(date) {
        const d = date.getDate();
        const m = date.getMonth() + 1; // Month from 0 to 11
        const  y = date.getFullYear();
        return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    public splitTextToLines(text) {
        const idealSplit = 23;
        let lineCounter = 0;
        let lineIndex = 0;
        const lines = [''];
        let ch, i;

        for (i = 0; i < text.length; i++) {
            ch = text[i];
            if (lineCounter >= idealSplit && ch === ' ') {
                ch = '';
                lineCounter = -1;
                lineIndex++;
                lines.push('');
            }
            lines[lineIndex] += ch;
            lineCounter++;
        }
        return lines;
    }

    romanize(num) {
        if (isNaN(num)) {
            return NaN;
        }
        const digits = String(+num).split('');
        const key = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
                '', 'x', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
                '', 'i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix'];
        let roman = '';
        let i = 3;
        while (i--) {
            roman = (key[+digits.pop() + (i * 10)] || '') + roman;
        }
        return Array(+digits.join('') + 1).join('M') + roman;
    }

    getDay(event: LifeEvent): string {
        if (event !== null && event !== undefined && event.startDateDay !== null && event.startDateDay !== '') {
            const numValue = +event.startDateDay;
            // zero fill the numbers for the front end only
            if (numValue < 10 && numValue > 0) {
                return '0' + numValue;
            } else if (numValue === 0) {
                return '';
            } else {
                return event.startDateDay;
            }
        } else {
            return '';
        }
    }

    getMonth(event: LifeEvent): string {
        if (event !== null && event !== undefined && event.startDateMonth !== null && event.startDateMonth !== '') {
            const numValue = +event.startDateMonth;
            // zero fill the numbers for the front end only
            if (numValue < 10 && numValue > 0) {
                return '0' + numValue;
            } else if (numValue === 0) {
                return '';
            } else {
                return event.startDateMonth;
            }
        } else {
            return '';
        }
    }

    getYear(event: LifeEvent): string {
        if (event !== null && event !== undefined && event.startDateYear !== null && event.startDateYear !== 0) {
            return event.startDateYear + '';
        } else {
            return '';
        }
    }

    getDisplayDate(event: LifeEvent): string {
        let date = '';
        if (event !== null && event !== undefined) {
            const dayZero = this.getDay(event);
            date = date + dayZero;
            const monthShort = this.getMonthShort(String(event.startDateMonth));
            date = date + (date.length > 0 ? (' ' + monthShort) : monthShort);
            const yearFormatted = (
                (event.startDateYear !== undefined) &&
                (event.startDateYear !== null) &&
                (event.startDateYear !== 0)) ?
                event.startDateYear : '';
            date = date + (date.length > 0 ? (' ' + yearFormatted) : yearFormatted);
        }
        return date;
    }

    getDisplayDateBeforeLocationFromFields(day: string, month: string, year: string, location: string): string {
        let date = '';
        if (day !== undefined && day !== null && day !== '') {
            if (+day < 10 && +day > 0) {
                date = date + '0' + day;
            } else if (day === '0') {
                date = date + '';
            } else {
                date = date + day;
            }
        } else {
            date = date + '';
        }

        let monthShort = '';
        if (month !== undefined && month !== null && month !== '') {
            if (+month < 10 && +month > 0) {
                monthShort = this.getMonthShort('0' + month);
            } else if (month === '0') {
                monthShort = '';
            } else {
                monthShort = this.getMonthShort(month);
            }
        } else {
            monthShort = '';
        }
        date = date + (date.length > 0 ? (' ' + monthShort) : monthShort);

        const yearFormatted = (
            (year !== undefined) &&
            (year !== null) &&
            (year !== '0')) ?
            year : '';
        date = date + (date.length > 0 ? (' ' + yearFormatted) : yearFormatted);

        if (location !== undefined && location !== null && location !== '') {
            if (date.trim().length > 0) {
                date = date + ', ';
            }
        }
        return date;
    }

    getDisplayDateBeforeLocation(event: LifeEvent): string {
        let date = '';
        if (event !== null && event !== undefined) {
            const dayZero = this.getDay(event);
            date = date + dayZero;
            const monthShort = this.getMonthShort(String(event.startDateMonth));
            date = date + (date.length > 0 ? (' ' + monthShort) : monthShort);
            const yearFormatted = (
                (event.startDateYear !== undefined) &&
                (event.startDateYear !== null) &&
                (event.startDateYear !== 0)) ?
                event.startDateYear : '';
            date = date + (date.length > 0 ? (' ' + yearFormatted) : yearFormatted);

            if (event.location !== undefined && event.location !== null) {
                if (date.trim().length > 0) {
                    date = date + ', ';
                }
            }
        }

        return date;
    }

    getDisplayDateRange(birth: LifeEvent, death: LifeEvent): string {
        let range = '';
        if (birth !== null && birth !== undefined) {
            const yearFormatted = (
                (birth.startDateYear !== undefined) &&
                (birth.startDateYear !== null) &&
                (birth.startDateYear !== 0)) ?
                birth.startDateYear : '';
            range = range + yearFormatted;
        }
        range = range + ' - ';
        if (death !== null && death !== undefined) {
            const yearFormatted = (
                (death.startDateYear !== undefined) &&
                (death.startDateYear !== null) &&
                (death.startDateYear !== 0)) ?
                death.startDateYear : '';
            range = range + yearFormatted;
        }
        return range;
    }

    getDisplayDatePlace(event: LifeEvent): string {
        let formattedDate = this.getDisplayDate(event);
        if (event !== null && event !== undefined) {
            if (event.location !== undefined && event.location !== null) {
                if (formattedDate.trim().length > 0) {
                    formattedDate = formattedDate + ', ';
                }
                formattedDate = formattedDate + event.location.description;
            }
        }
        return formattedDate;
    }

    getDisplayName(person: Person): string {
        let name = '';
        name = person.givenName;
        name = name + (person.middleNames !== '' ? (' ' + person.middleNames) : '');
        name = name + (person.familyName !== '' ? (' ' + person.familyName) : '');
        return name;
    }

    generateNameFromFields(givenName: string, middleNames: string, familyName: string): string {
        let name = '';
        name = name + ((givenName !== null && givenName !== '') ? givenName : '');
        name = name + ((middleNames !== null && middleNames !== '') ? ' ' + middleNames : '');
        name = name + ((familyName !== null && familyName !== '') ? ' ' + familyName : '');
        return name;
    }

    getDisplayNameWithDates(person: Relationship): string {
        let name = '';
        const bYear = ((person.birthYear !== null) && (person.birthYear !== '0')) ? person.birthYear : ' ';
        const dYear = ((person.deathYear !== null) && (person.deathYear !== '0')) ? person.deathYear : ' ';

        name = person.givenName;
        name = name + (person.middleNames !== '' ? (' ' + person.middleNames) : '');
        name = name + (person.familyName !== '' ? (' ' + person.familyName) : '');

        if (person.birthYear !== '0' || person.deathYear !== '0') {
            name = name + ' (';
            name = name + (person.birthYear !== '0' ? person.birthYear : '');
            name = name + ' - ';
            name = name + (person.deathYear !== '0' ? person.deathYear : '');
            name = name + ')';
        } else {
            name = name + ' (' + bYear + ' - ' + dYear + ')';
        }
        return name;
    }

    getDays() {
        return days;
    }

    getMonths() {
        return months;
    }

    getChildRelationshipTypes() {
        return childRelationshipTypes;
    }

    getChildRelationshipTypesLabel(type: string) {
        return childRelationshipTypes.find(item => item.value === type).label;
    }

    getGenders() {
        return genderTypes;
    }

    getGenderLabel(gen: string) {
        let gLabel = '';
        try {
            gLabel = genderTypes.find(item => item.value === gen).label;
        } catch (e) {
            return '';
        }
        return gLabel;
    }

    getMarriageTypes() {
        return marriageTypes;
    }

    getPersonFilterTypes() {
        return personFilterTypes;
    }

    getCitations() {
        return citations;
    }

    getCitation(id: string) {
        return citationExample.find(item => item.value === id);
    }

    getMonthShort(month: string): string {
        for (let i = 0; i < monthsShort.length; i++) {
            if (+monthsShort[i].value === +month) {
                return monthsShort[i].label;
            }
        }
        return '';
    }

    getMonthNumber(month: string): string {
        for (let i = 0; i < monthsShort.length; i++) {
            if (monthsShort[i].label === month) {
                return monthsShort[i].value;
            }
        }
        return '';
    }

    zeroFillDay(day: string): string {
        const dayNum = + day;
        if (dayNum < 10 && dayNum > 0) {
            return '0' + day;
        } else if (dayNum === 0) {
            return '';
        }
        return day;
    }

    zeroFillMonth(month: string): string {
        const monthNum = +month;
        if (monthNum < 10 && monthNum > 0) {
            return '0' + month;
        } else if (monthNum === 0) {
            return '';
        }
        return month;
    }

    isZeroFilled(value: string) {
        if (value !== null || value !== undefined || value.charAt(0) === '0') {
            return true;
        }
        return false;
    }

    dateMask(day: string, month: string, year: string): string {
        let mask = '';
        if (year === null || year === undefined || year.length === 0) {
            mask = '0000';
        } else if (year.length === 1) {
            mask = '000' + year;
        } else if (year.length === 2) {
            mask = '00' + year;
        } else if (year.length === 3) {
            mask = '0' + year;
        } else {
            mask = year;
        }

        if (month === null || month === undefined || (month === '')) {
            mask = mask + '00';
        } else if (this.isZeroFilled(month) && month.length > 1) {
            mask = mask + month;
        } else {
            const monthNum = +month;
            if (monthNum < 10 && monthNum > -1) {
                mask = mask + '0' + monthNum;
            }
        }

        if (day === null || day === undefined || (day === '')) {
            mask = mask + '00';
        } else if (this.isZeroFilled(day) && day.length > 1) {
            mask = mask + day;
        } else {
            const dayNum = +day;
            if (dayNum < 10 && dayNum > -1) {
                mask = mask + '0' + dayNum;
            }
        }
        return mask;
    }

    emptyYear(year: number): string {
        if (year === 0) {
            return '';
        }
        return year + '';
    }

    isEmpty(stringValue: string): boolean {
        if (stringValue === null || stringValue === undefined || stringValue === '') {
            return true;
        }
        return false;
    }

    isLeap(year: number): boolean {
        // Return true if year is a multiple pf 4 and not multiple of 100.
        // OR year is multiple of 400.
        return ( ( (year % 4 === 0) &&
                (year % 100 !== 0)) ||
                (year % 400 === 0));
    }

    // Returns true if given year is valid or not.
    isValidDate(d: number, m: number, y: number): boolean {
        if (d === 0 || m === 0 || y === 0) {
            return true; // unable to determine if valid date
        }

        // If year, month and day are not in given range
        if (y > 9999 || y < 0) {
            return false;
        }

        if (m < 1 || m > 12) {
            return false;
        }

        if (d < 1 || d > 31) {
            return false;
        }

        // Handle February month with leap year
        if (m === 2) {
            if (this.isLeap(y)) {
                return (d <= 29);
            } else {
                return (d <= 28);
            }
        }

        // Months of April, June, Sept and Nov must have number of days less than or equal to 30.
        if (m === 4 || m === 6 || m === 9 || m === 11) {
            return (d <= 30);
        } else {
            return true;
        }
    }

    getEventTypes() {
        return eventTypes.sort(this.compareEvents);
    }

    getEventType(id: string) {
        let eventTypeLabel = '';
        try {
            eventTypeLabel = eventTypes.find(item => item.value === id).label;
        } catch (e) {
            return '';
        }
        return eventTypeLabel;
    }

    getRecordTypes() {
        return recordTypes.sort(this.compareEvents);
    }

    getRecordType(id: string) {
        let recordTypeLabel = '';
        try {
            recordTypeLabel = recordTypes.find(item => item.value === id.trim()).label;
        } catch (e) {
            return '';
        }
        return recordTypeLabel;
    }

    compareEvents(a, b) {
        const eventTypeA = a.label.toUpperCase();
        const eventTypeB = b.label.toUpperCase();

        let comparison = 0;
        if (eventTypeA > eventTypeB) {
            comparison = 1;
        } else if (eventTypeA < eventTypeB) {
            comparison = -1;
        }
        return comparison;
    }

    getPersonSortOptions() {
        return sortPersonTable;
    }

    getLocationSortOptions() {
        return sortLocationTable;
    }

    getSourceSortOptions() {
        return sortSourceTable;
    }

    getRepositorySortOptions() {
        return sortRepositoryTable;
    }

    getCountries() {
        return countries;
    }

    getLocationTypes() {
        return locationTypes;
    }

    getCountryLabel(id: string) {
        let typeLabel = '';
        try {
            typeLabel = countries.find(item => item.value === id).label;
        } catch (e) {
            return '';
        }
        return typeLabel;
    }


}

const days = [
    { value: '',   label: ''},
    { value: '01', label: '1' },
    { value: '02', label: '2' },
    { value: '03', label: '3' },
    { value: '04', label: '4' },
    { value: '05', label: '5' },
    { value: '06', label: '6' },
    { value: '07', label: '7' },
    { value: '08', label: '8' },
    { value: '09', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' }
];

const months = [
    { value: '',   label: ''},
    { value: '01', label: 'January'},
    { value: '02', label: 'February'},
    { value: '03', label: 'March'},
    { value: '04', label: 'April'},
    { value: '05', label: 'May'},
    { value: '06', label: 'June'},
    { value: '07', label: 'July'},
    { value: '08', label: 'August'},
    { value: '09', label: 'September'},
    { value: '10', label: 'October'},
    { value: '11', label: 'November'},
    { value: '12', label: 'December'}
];

const monthsShort = [
    { value: '1', label:  'JAN'},
    { value: '2', label:  'FEB'},
    { value: '3', label:  'MAR'},
    { value: '4', label:  'APR'},
    { value: '5', label:  'MAY'},
    { value: '6', label:  'JUN'},
    { value: '7', label:  'JUL'},
    { value: '8', label:  'AUG'},
    { value: '9', label:  'SEP'},
    { value: '10', label: 'OCT'},
    { value: '11', label: 'NOV'},
    { value: '12', label: 'DEC'}
];

const marriageTypes = [
    { value: 'marrd', label: 'Married'},
    { value: 'notmr', label: 'Not Married'},
    { value: 'cmlaw', label: 'Common Law'}
];

const childRelationshipTypes = [
    { value: '',      label: ''},
    { value: 'bio',   label: 'Biological'},
    { value: 'adopt', label: 'Adopted'},
    { value: 'step',  label: 'Step'}
];

const genderTypes = [
    { value: 'mal', label: 'Male'},
    { value: 'fem', label: 'Female'}
];

const locationTypes = [
    { value: 'K', label: 'User'},
    { value: 'G', label: 'System'}
];

const sortPersonTable = [
    {value: 'nameAsc',   label: 'Name, Ascending'},
    {value: 'nameDesc',  label: 'Name, Descending'},
    {value: 'bDateAsc',  label: 'Birth Date, Ascending'},
    {value: 'bDateDesc', label: 'Birth Date, Descending'},
    {value: 'bLocAsc',   label: 'Birth Place, Ascending'},
    {value: 'bLocDesc',  label: 'Birth Place, Descending'},
    {value: 'dDateAsc',  label: 'Death Date, Ascending'},
    {value: 'dDateDesc', label: 'Death Date, Descending'},
    {value: 'dLocAsc',   label: 'Death Place, Ascending'},
    {value: 'dLocDesc',  label: 'Death Place, Descending'}
];

const sortLocationTable = [
    {value: 'descAsc',   label: 'Description, Ascending'},
    {value: 'descDesc',  label: 'Description, Descending'},
    {value: 'countryAsc',  label: 'Country, Ascending'},
    {value: 'countryDesc', label: 'Country, Descending'},
    {value: 'typeAsc',  label: 'Type, Ascending'},
    {value: 'typeDesc', label: 'Type, Descending'}
];

const sortSourceTable = [
    {value: 'titleAsc',   label: 'Title, Ascending'},
    {value: 'titleDesc',  label: 'Title, Descending'},
    {value: 'recordAsc',  label: 'Record, Ascending'},
    {value: 'recordDesc', label: 'Record, Descending'},
    {value: 'repositoryAsc',  label: 'Repository, Ascending'},
    {value: 'repositoryDesc', label: 'Repository, Descending'}
];

const sortRepositoryTable = [
    {value: 'nameAsc', label: 'Name, Ascending'},
    {value: 'nameDesc', label: 'Name, Descending'},
    {value: 'addressAsc', label: 'Address, Ascending'},
    {value: 'addressDesc', label: 'Address, Descending'}
];

const personFilterTypes = [
    { value: 'filterNoDataOnlyFirstName', label: 'Missing Data: Only First Name'},
    { value: 'filterNoDataOnlyLastName', label: 'Missing Data: Only Last Name'},
    { value: 'filterNoDataNoOccupation', label: 'Missing Data: No Occupation'},
    { value: 'filterNoDataNoReligion', label: 'Missing Data: No Religion'},
    { value: 'filterNoDataNoAttributes', label: 'Missing Data: No Attributes'},
    { value: 'filterNoEventBirthDate', label: 'Missing Events: Birth Date'},
    { value: 'filterNoEventDeathDate', label: 'Missing Events: Death Date'},
    { value: 'filterNoEventBurialDate', label: 'Missing Events: Burial Date'},
    { value: 'filterNoEventMarriageDate', label: 'Missing Events: Marriage Date'},
    { value: 'filterNoLocationBirth', label: 'Missing Locations: Birth Location'},
    { value: 'filterNoLocationDeath', label: 'Missing Locations: Death Location'},
    { value: 'filterNoLocationBurial', label: 'Missing Locations: Burial Location'},
    { value: 'filterNoLocationMarriage', label: 'Missing Locations: Marriage Location'},
    { value: 'filterNoRelationshipOneParent', label: 'Missing Relationships: Only One Parent'},
    { value: 'filterNoRelationshipNoParent', label: 'Missing Relationships: No Parent'},
    { value: 'filterNoRelationshipNoSpouse', label: 'Missing Relationships: No Spouse'},
    { value: 'filterNoRelationshipNoChildren', label: 'Missing Relationships: No Children'}
];

const citations = [
    { value: '01', label: 'Article (Journal, Magazine, Newspaper)' },
    { value: '02', label: 'Artifact (Photos, Cards, Scrapbook, Diary or Journal' },
    { value: '03', label: 'Book' },
    { value: '04', label: 'Book Chapter' },
    { value: '05', label: 'Census' },
    { value: '06', label: 'Certificate (Birth, Marriage, Death)' },
    { value: '07', label: 'Document, Letter, Manuscript' },
    { value: '08', label: 'Manuscript Register (Courthouse, City Hall, Church' }
];

const citationExample = [
    { value: '01', label: 'Author, "Article Title", periodical title, volume number, date issued, page number(s) for the article.' },
    { value: '02', label: 'Description, date viewed or examined, current owner\'s name and address, ' +
            'anything known about its origin and ownership.' },
    { value: '03', label: 'Author, title, publication place, publisher, date, page.' },
    { value: '04', label: 'Author, "Chapter Title", editor, book title, publication place, publisher, date, page number(s).' },
    { value: '05', label: 'Creator of census, type, year, state, county, town, ward, enumeration district, page, dwelling, ' +
            'family numbers, person by exact spelling, media type, library label for film or fiche, agency that created the ' +
            ' film and date, OR creator, title, publication place/URL, date, location of item, ' +
            ' OR author, "Article or Database Title", website creator, website title/URL, date, specific item, ' +
            ' OR website creator, website title, publication place/URL, date, specific item.'},
    { value: '06', label: 'Name of agency that issued certificate (city/county, state/province), certificate number, ' +
            ' name of key person, ' +
            'date of record.' },
    { value: '07', label: 'Author, "Name of Document or Manuscript", date, place written, file and/or collection name and number, ' +
            'archives name, city, state/province, country.'},
    { value: '08', label: 'Details, page number and specific item, office or church name, city/county, state/province, country.' }
];

const eventTypes = [
    { value: 'cmlaw', label: 'Common Law'},
    { value: 'sames', label: 'Same Sex Marriage'},
    { value: 'birth', label: 'Birth'},
    { value: 'death', label: 'Death'},
    { value: 'marrd', label: 'Marriage'},
    { value: 'bural', label: 'Burial'},
    { value: 'resd', label: 'Residence'},
    { value: 'annl', label: 'Annulment'},
    { value: 'milt', label: 'Military Service'},
    { value: 'dvrc', label: 'Divorce'},
    { value: 'educ', label: 'Education'},
    { value: 'trvl', label: 'Travel'},
    { value: 'relg', label: 'Religious'},
    { value: 'crrr', label: 'Career'},
    { value: 'empl', label: 'Employment'},
    { value: 'adop', label: 'Adoption'},
    { value: 'awrd', label: 'Award'},
    { value: 'memb', label: 'Membership'},
    { value: 'goal', label: 'Personal Goal'},
    { value: 'invt', label: 'Invention'},
    { value: 'ownr', label: 'Ownership'},
    { value: 'eassc', label: 'Association'},
    { value: 'cens', label: 'Census'},
    { value: 'fost', label: 'Foster'},
    { value: 'codi', label: 'Codicil'},
    { value: 'crim', label: 'Criminal'},
    { value: 'emig', label: 'Emigration'},
    { value: 'endo', label: 'Endowment'},
    { value: 'engm', label: 'Engagement'},
    { value: 'grad', label: 'Graduation'},
    { value: 'illn', label: 'Illness'},
    { value: 'immg', label: 'Immigration'},
    { value: 'licn', label: 'Licensed'},
    { value: 'name', label: 'Name Change'},
    { value: 'nske', label: 'Namesake'},
    { value: 'citz', label: 'Citizenship'},
    { value: 'natr', label: 'Naturalization	'},
    { value: 'occp', label: 'Occupation'},
    { value: 'pssg', label: 'Passenger'},
    { value: 'prob', label: 'Probate'},
    { value: 'retr', label: 'Retirement'},
    { value: 'misc', label: 'Miscellaneous'}
];

const recordTypes = [
    { value: 'ADOPTN', label: 'Adoption'},
    { value: 'BIBLE', label: 'Bible Record'},
    { value: 'BUSNREC', label: 'Business Record'},
    { value: 'CEMETR', label: 'Cemetery'},
    { value: 'CENSUS', label: 'Census'},
    { value: 'CHRCHR', label: 'Church Record'},
    { value: 'CHRCHD', label: 'Church Directory'},
    { value: 'CHRCHH', label: 'Church History'},
    { value: 'CIVILR', label: 'Civil Registration'},
    { value: 'COLLECT', label: 'Collection'},
    { value: 'CINST', label: 'Correctional Institution'},
    { value: 'CRTREC', label: 'Court Record'},
    { value: 'CRPUN', label: 'Crime and Punishment'},
    { value: 'DIRCT', label: 'Directory'},
    { value: 'DWELL', label: 'Dwelling'},
    { value: 'EMIMMI', label: 'Emigration and Immigration'},
    { value: 'FUNHMS', label: 'Funeral Home'},
    { value: 'GENEAL', label: 'Genealogy'},
    { value: 'HERALD', label: 'Heraldry'},
    { value: 'MANOR', label: 'Manor Records'},
    { value: 'LANDPROP', label: 'Land and Property'},
    { value: 'MANUMSS', label: 'Manumission'},
    { value: 'MAPS', label: 'Maps'},
    { value: 'MEDICAL', label: 'Medical Record'},
    { value: 'MEDIEVAL', label: 'Medical Record'},
    { value: 'MARINE', label: 'Merchant Marine'},
    { value: 'MILITARY', label: 'Military Record'},
    { value: 'CITIZEN', label: 'Naturalization and Citizenship'},
    { value: 'PERSONAL', label: 'Personal'},
    { value: 'NEWSPAPER', label: 'Newspaper'},
    { value: 'NOBILITY', label: 'Nobility'},
    { value: 'NOTARY', label: 'Notarial Record'},
    { value: 'OBITUARIES', label: 'Obituary'},
    { value: 'OCCUPATION', label: 'Occupation'},
    { value: 'ONLINE', label: 'Online Record'},
    { value: 'PARISH', label: 'Parish'},
    { value: 'PERIODICAL', label: 'Periodical'},
    { value: 'POOR', label: 'Poorhouses, Poor Law'},
    { value: 'PROBATE', label: 'Probate Record'},
    { value: 'SCHOOL', label: 'School Record'},
    { value: 'SHIPS', label: 'Ships and Shipwrecks'},
    { value: 'TAX', label: 'Taxation'},
    { value: 'TOWN', label: 'Town Record'},
    { value: 'VITAL', label: 'Vital Record'},
    { value: 'VOTING', label: 'Voting Record'},
    { value: 'WEBSITE', label: 'Website'}
];

const countries = [
    { value: 'AF', label: 'Afghanistan'},
    { value: 'AX', label: 'Åland'},
    { value: 'AL', label: 'Albania'},
    { value: 'DZ', label: 'Algeria'},
    { value: 'AS', label: 'American Samoa'},
    { value: 'AD', label: 'Andorra'},
    { value: 'AO', label: 'Angola'},
    { value: 'AI', label: 'Anguilla'},
    { value: 'AQ', label: 'Antarctica'},
    { value: 'AG', label: 'Antigua and Barbuda'},
    { value: 'AR', label: 'Argentina'},
    { value: 'AM', label: 'Armenia'},
    { value: 'AW', label: 'Aruba'},
    { value: 'AU', label: 'Australia'},
    { value: 'AT', label: 'Austria'},
    { value: 'AZ', label: 'Azerbaijan'},
    { value: 'BS', label: 'Bahamas'},
    { value: 'BH', label: 'Bahrain'},
    { value: 'BD', label: 'Bangladesh'},
    { value: 'BB', label: 'Barbados'},
    { value: 'BY', label: 'Belarus'},
    { value: 'BE', label: 'Belgium'},
    { value: 'BZ', label: 'Belize'},
    { value: 'BJ', label: 'Benin'},
    { value: 'BM', label: 'Bermuda'},
    { value: 'BT', label: 'Bhutan'},
    { value: 'BO', label: 'Bolivia'},
    { value: 'BQ', label: 'Bonaire, Sint Eustatius, and Saba'},
    { value: 'BA', label: 'Bosnia and Herzegovina'},
    { value: 'BW', label: 'Botswana'},
    { value: 'BV', label: 'Bouvet Island'},
    { value: 'BR', label: 'Brazil'},
    { value: 'IO', label: 'British Indian Ocean Territory'},
    { value: 'VG', label: 'British Virgin Islands'},
    { value: 'BN', label: 'Brunei'},
    { value: 'BG', label: 'Bulgaria'},
    { value: 'BF', label: 'Burkina Faso'},
    { value: 'BI', label: 'Burundi'},
    { value: 'CV', label: 'Cabo Verde'},
    { value: 'KH', label: 'Cambodia'},
    { value: 'CM', label: 'Cameroon'},
    { value: 'CA', label: 'Canada'},
    { value: 'KY', label: 'Cayman Islands'},
    { value: 'CF', label: 'Central African Republic'},
    { value: 'TD', label: 'Chad'},
    { value: 'CL', label: 'Chile'},
    { value: 'CN', label: 'China'},
    { value: 'CX', label: 'Christmas Island'},
    { value: 'CC', label: 'Cocos [Keeling] Islands'},
    { value: 'CO', label: 'Colombia'},
    { value: 'KM', label: 'Comoros'},
    { value: 'CG', label: 'Congo Republic'},
    { value: 'CK', label: 'Cook Islands'},
    { value: 'CR', label: 'Costa Rica'},
    { value: 'HR', label: 'Croatia'},
    { value: 'CU', label: 'Cuba'},
    { value: 'CW', label: 'Curaçao'},
    { value: 'CY', label: 'Cyprus'},
    { value: 'CZ', label: 'Czechia'},
    { value: 'DK', label: 'Denmark'},
    { value: 'DJ', label: 'Djibouti'},
    { value: 'DM', label: 'Dominica'},
    { value: 'DO', label: 'Dominican Republic'},
    { value: 'CD', label: 'DR Congo'},
    { value: 'EC', label: 'Ecuador'},
    { value: 'EG', label: 'Egypt'},
    { value: 'SV', label: 'El Salvador'},
    { value: 'GQ', label: 'Equatorial Guinea'},
    { value: 'ER', label: 'Eritrea'},
    { value: 'EE', label: 'Estonia'},
    { value: 'SZ', label: 'Eswatini'},
    { value: 'ET', label: 'Ethiopia'},
    { value: 'FK', label: 'Falkland Islands'},
    { value: 'FO', label: 'Faroe Islands'},
    { value: 'FJ', label: 'Fiji'},
    { value: 'FI', label: 'Finland'},
    { value: 'FR', label: 'France'},
    { value: 'GF', label: 'French Guiana'},
    { value: 'PF', label: 'French Polynesia'},
    { value: 'TF', label: 'French Southern Territories'},
    { value: 'GA', label: 'Gabon'},
    { value: 'GM', label: 'Gambia'},
    { value: 'GE', label: 'Georgia'},
    { value: 'DE', label: 'Germany'},
    { value: 'GH', label: 'Ghana'},
    { value: 'GI', label: 'Gibraltar'},
    { value: 'GR', label: 'Greece'},
    { value: 'GL', label: 'Greenland'},
    { value: 'GD', label: 'Grenada'},
    { value: 'GP', label: 'Guadeloupe'},
    { value: 'GU', label: 'Guam'},
    { value: 'GT', label: 'Guatemala'},
    { value: 'GG', label: 'Guernsey'},
    { value: 'GN', label: 'Guinea'},
    { value: 'GW', label: 'Guinea-Bissau'},
    { value: 'GY', label: 'Guyana'},
    { value: 'HT', label: 'Haiti'},
    { value: 'HM', label: 'Heard Island and McDonald Islands'},
    { value: 'HN', label: 'Honduras'},
    { value: 'HK', label: 'Hong Kong'},
    { value: 'HU', label: 'Hungary'},
    { value: 'IS', label: 'Iceland'},
    { value: 'IN', label: 'India'},
    { value: 'ID', label: 'Indonesia'},
    { value: 'IR', label: 'Iran'},
    { value: 'IQ', label: 'Iraq'},
    { value: 'IE', label: 'Ireland'},
    { value: 'IM', label: 'Isle of Man'},
    { value: 'IL', label: 'Israel'},
    { value: 'IT', label: 'Italy'},
    { value: 'CI', label: 'Ivory Coast'},
    { value: 'JM', label: 'Jamaica'},
    { value: 'JP', label: 'Japan'},
    { value: 'JE', label: 'Jersey'},
    { value: 'JO', label: 'Jordan'},
    { value: 'KZ', label: 'Kazakhstan'},
    { value: 'KE', label: 'Kenya'},
    { value: 'KI', label: 'Kiribati'},
    { value: 'XK', label: 'Kosovo'},
    { value: 'KW', label: 'Kuwait'},
    { value: 'KG', label: 'Kyrgyzstan'},
    { value: 'LA', label: 'Laos'},
    { value: 'LV', label: 'Latvia'},
    { value: 'LB', label: 'Lebanon'},
    { value: 'LS', label: 'Lesotho'},
    { value: 'LR', label: 'Liberia'},
    { value: 'LY', label: 'Libya'},
    { value: 'LI', label: 'Liechtenstein'},
    { value: 'LT', label: 'Lithuania'},
    { value: 'LU', label: 'Luxembourg'},
    { value: 'MO', label: 'Macao'},
    { value: 'MG', label: 'Madagascar'},
    { value: 'MW', label: 'Malawi'},
    { value: 'MY', label: 'Malaysia'},
    { value: 'MV', label: 'Maldives'},
    { value: 'ML', label: 'Mali'},
    { value: 'MT', label: 'Malta'},
    { value: 'MH', label: 'Marshall Islands'},
    { value: 'MQ', label: 'Martinique'},
    { value: 'MR', label: 'Mauritania'},
    { value: 'MU', label: 'Mauritius'},
    { value: 'YT', label: 'Mayotte'},
    { value: 'MX', label: 'Mexico'},
    { value: 'FM', label: 'Micronesia'},
    { value: 'MD', label: 'Moldova'},
    { value: 'MC', label: 'Monaco'},
    { value: 'MN', label: 'Mongolia'},
    { value: 'ME', label: 'Montenegro'},
    { value: 'MS', label: 'Montserrat'},
    { value: 'MA', label: 'Morocco'},
    { value: 'MZ', label: 'Mozambique'},
    { value: 'MM', label: 'Myanmar'},
    { value: 'NA', label: 'Namibia'},
    { value: 'NR', label: 'Nauru'},
    { value: 'NP', label: 'Nepal'},
    { value: 'NL', label: 'Netherlands'},
    { value: 'AN', label: 'Netherlands Antilles'},
    { value: 'NC', label: 'New Caledonia'},
    { value: 'NZ', label: 'New Zealand'},
    { value: 'NI', label: 'Nicaragua'},
    { value: 'NE', label: 'Niger'},
    { value: 'NG', label: 'Nigeria'},
    { value: 'NU', label: 'Niue'},
    { value: 'NF', label: 'Norfolk Island'},
    { value: 'KP', label: 'North Korea'},
    { value: 'MK', label: 'North Macedonia'},
    { value: 'MP', label: 'Northern Mariana Islands'},
    { value: 'NO', label: 'Norway'},
    { value: 'OM', label: 'Oman'},
    { value: 'PK', label: 'Pakistan'},
    { value: 'PW', label: 'Palau'},
    { value: 'PS', label: 'Palestine'},
    { value: 'PA', label: 'Panama'},
    { value: 'PG', label: 'Papua New Guinea'},
    { value: 'PY', label: 'Paraguay'},
    { value: 'PE', label: 'Peru'},
    { value: 'PH', label: 'Philippines'},
    { value: 'PN', label: 'Pitcairn Islands'},
    { value: 'PL', label: 'Poland'},
    { value: 'PT', label: 'Portugal'},
    { value: 'PR', label: 'Puerto Rico'},
    { value: 'QA', label: 'Qatar'},
    { value: 'RE', label: 'Réunion'},
    { value: 'RO', label: 'Romania'},
    { value: 'RU', label: 'Russia'},
    { value: 'RW', label: 'Rwanda'},
    { value: 'BL', label: 'Saint Barthélemy'},
    { value: 'SH', label: 'Saint Helena'},
    { value: 'LC', label: 'Saint Lucia'},
    { value: 'MF', label: 'Saint Martin'},
    { value: 'PM', label: 'Saint Pierre and Miquelon'},
    { value: 'WS', label: 'Samoa'},
    { value: 'SM', label: 'San Marino'},
    { value: 'ST', label: 'São Tomé and Príncipe'},
    { value: 'SA', label: 'Saudi Arabia'},
    { value: 'SN', label: 'Senegal'},
    { value: 'RS', label: 'Serbia'},
    { value: 'CS', label: 'Serbia and Montenegro'},
    { value: 'SC', label: 'Seychelles'},
    { value: 'SL', label: 'Sierra Leone'},
    { value: 'SG', label: 'Singapore'},
    { value: 'SX', label: 'Sint Maarten'},
    { value: 'SK', label: 'Slovakia'},
    { value: 'SI', label: 'Slovenia'},
    { value: 'SB', label: 'Solomon Islands'},
    { value: 'SO', label: 'Somalia'},
    { value: 'ZA', label: 'South Africa'},
    { value: 'GS', label: 'South Georgia and South Sandwich Islands'},
    { value: 'KR', label: 'South Korea'},
    { value: 'SS', label: 'South Sudan'},
    { value: 'ES', label: 'Spain'},
    { value: 'LK', label: 'Sri Lanka'},
    { value: 'KN', label: 'St Kitts and Nevis'},
    { value: 'VC', label: 'St Vincent and Grenadines'},
    { value: 'SD', label: 'Sudan'},
    { value: 'SR', label: 'Suriname'},
    { value: 'SJ', label: 'Svalbard and Jan Mayen'},
    { value: 'SE', label: 'Sweden'},
    { value: 'CH', label: 'Switzerland'},
    { value: 'SY', label: 'Syria'},
    { value: 'TW', label: 'Taiwan'},
    { value: 'TJ', label: 'Tajikistan'},
    { value: 'TZ', label: 'Tanzania'},
    { value: 'TH', label: 'Thailand'},
    { value: 'TL', label: 'Timor-Leste'},
    { value: 'TG', label: 'Togo'},
    { value: 'TK', label: 'Tokelau'},
    { value: 'TO', label: 'Tonga'},
    { value: 'TT', label: 'Trinidad and Tobago'},
    { value: 'TN', label: 'Tunisia'},
    { value: 'TR', label: 'Turkey'},
    { value: 'TM', label: 'Turkmenistan'},
    { value: 'TC', label: 'Turks and Caicos Islands'},
    { value: 'TV', label: 'Tuvalu'},
    { value: 'UM', label: 'U.S. Minor Outlying Islands'},
    { value: 'VI', label: 'U.S. Virgin Islands'},
    { value: 'UG', label: 'Uganda'},
    { value: 'UA', label: 'Ukraine'},
    { value: 'AE', label: 'United Arab Emirates'},
    { value: 'GB', label: 'United Kingdom'},
    { value: 'US', label: 'United States'},
    { value: 'UY', label: 'Uruguay'},
    { value: 'UZ', label: 'Uzbekistan'},
    { value: 'VU', label: 'Vanuatu'},
    { value: 'VA', label: 'Vatican City'},
    { value: 'VE', label: 'Venezuela'},
    { value: 'VN', label: 'Vietnam'},
    { value: 'WF', label: 'Wallis and Futuna'},
    { value: 'EH', label: 'Western Sahara'},
    { value: 'YE', label: 'Yemen'},
    { value: 'ZM', label: 'Zambia'},
    { value: 'ZW', label: 'Zimbabwe'}
];

/*
insert into relationship_chart values (0,2,-2,'mal','Grandson');
insert into relationship_chart values (0,3,-3,'mal','Great Grandson');
insert into relationship_chart values (0,4,-4,'mal','2X Great Grandson');
insert into relationship_chart values (0,5,-5,'mal','3X Great Grandson');
insert into relationship_chart values (0,6,-6,'mal','4X Great Grandson');
insert into relationship_chart values (0,7,-7,'mal','5X Great Grandson');
insert into relationship_chart values (0,8,-8,'mal','6X Great Grandson');
insert into relationship_chart values (0,9,-9,'mal','7X Great Grandson');
insert into relationship_chart values (0,10,-10,'mal','8X Great Grandson');
insert into relationship_chart values (0,11,-11,'mal','9X Great Grandson');
insert into relationship_chart values (0,12,-12,'mal','10X Great Grandson');
insert into relationship_chart values (0,13,-13,'mal','11X Great Grandson');
insert into relationship_chart values (0,14,-14,'mal','12X Great Grandson');
insert into relationship_chart values (0,15,-15,'mal','13X Great Grandson');
insert into relationship_chart values (0,16,-16,'mal','14X Great Grandson');
insert into relationship_chart values (0,17,-17,'mal','15X Great Grandson');
insert into relationship_chart values (0,18,-18,'mal','16X Great Grandson');
insert into relationship_chart values (0,19,-19,'mal','17X Great Grandson');
insert into relationship_chart values (0,20,-20,'mal','18X Great Grandson');
insert into relationship_chart values (0,21,-21,'mal','19X Great Grandson');
insert into relationship_chart values (0,22,-22,'mal','20X Great Grandson');
 */
