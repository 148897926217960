import { NgModule } from '@angular/core';
import { GedcomService} from './gedcom.service';
import { GedcomComponent} from './gedcom.component';
import { CommonModule} from '@angular/common';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        GedcomComponent
    ],
    providers: [
        GedcomService
    ]

})
export class GedcomModule { }
